import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { currency } from "~/common/currency"
import { adminStudyPackagePath } from "~/common/paths"
import {
  getStudyCountDisplay,
  getStudyCountTypePlural,
} from "~/common/studyPackage"
import { LinkedTableCell, TableCell, TableRow } from "~/components/ui/Table"
import { boolToString } from "~/lib/utils"
import { ButtonLink } from "~/ui/Button"

export const AdminStudyPackageRow_StudyPackageFragment = gql(`
  fragment AdminStudyPackageRow_StudyPackageFragment on StudyPackage {
    id
    name
    priceCents
    studyCount
    manualCount
    isImageCount
    showStudies
    createdAt
    modalities
    featured
    bodyPart {
      id
      name
      uid
    }
  }
`)

const AdminStudyPackageRow = ({
  data,
}: {
  data: FragmentType<typeof AdminStudyPackageRow_StudyPackageFragment>
}) => {
  const studyPackage = getFragmentData(
    AdminStudyPackageRow_StudyPackageFragment,
    data
  )
  const path = adminStudyPackagePath({
    id: studyPackage.id,
  })

  return (
    <TableRow>
      <LinkedTableCell to={path}>{studyPackage.name}</LinkedTableCell>
      <LinkedTableCell to={path} className="max-w-[580px]">
        <div className="truncate">
          <b>{getStudyCountDisplay({ studyPackage })}</b>{" "}
          {getStudyCountTypePlural({ studyPackage })}
        </div>
      </LinkedTableCell>

      <LinkedTableCell to={path}>
        <div className="uppercase">{studyPackage.modalities.join(", ")}</div>
      </LinkedTableCell>

      <LinkedTableCell to={path}>
        <div className="uppercase">{studyPackage.bodyPart.name}</div>
      </LinkedTableCell>

      <LinkedTableCell to={path} className="max-w-[580px]">
        <div className="truncate">
          {studyPackage.priceCents ? currency(studyPackage.priceCents) : ""}
        </div>
      </LinkedTableCell>

      <LinkedTableCell to={path}>
        {boolToString(studyPackage.featured)}
      </LinkedTableCell>

      <TableCell>
        <div className="flex gap-2">
          <ButtonLink to={path} display="inline">
            View
          </ButtonLink>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default AdminStudyPackageRow
