import { useQuery } from "@apollo/client"
import clsx from "clsx"
import pluralize from "pluralize"
import { useSearchParams } from "react-router-dom"
import { getFragmentData, gql } from "~/__generated__"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import AdminStudiesTable, {
  AdminStudies_QueryFragment,
} from "./components/AdminStudiesTable"

export const AdminStudiesScreen_Query = gql(`
  query AdminStudiesScreen_Query($recent: Boolean, $archived: Boolean, $page: Int, $limit: Int) {
    ...AdminStudies_QueryFragment
  }
`)

export const STUDIES_LIMIT = 12

const AdminStudiesIndexScreen = () => {
  const [searchParams] = useSearchParams()
  const page = searchParams.get("page")

  const result = useQuery(AdminStudiesScreen_Query, {
    variables: {
      recent: true,
      page: page ? Number(page) : 1,
      limit: STUDIES_LIMIT,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { studies } = getFragmentData(AdminStudies_QueryFragment, data)
  const totalCount = studies.studies.totalCount

  return (
    <div>
      <div
        className={clsx(
          "flex flex-col gap-6",
          "lg:flex-row lg:justify-between"
        )}
      >
        <div>
          <H1>Studies</H1>
        </div>
      </div>
      <div className="mb-6 flex flex-col gap-6 text-base">
        <div className="my-1">
          <b>
            {totalCount.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}
          </b>{" "}
          {pluralize("study", totalCount)}
        </div>
      </div>
      <div
        className={clsx({
          "animate-pulse opacity-50": loading,
        })}
      >
        {studies.studies.edges.length > 0 && (
          <AdminStudiesTable
            studies={studies.studies.edges.map((edge) => edge.node)}
            totalCount={totalCount}
            page={studies.page}
          />
        )}
      </div>
    </div>
  )
}

export default AdminStudiesIndexScreen
