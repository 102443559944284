import { useMutation } from "@apollo/client"
import { FormikHelpers } from "formik"
import { ChevronLeft } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { ModalityEnum } from "~/__generated__/graphql"
import { adminStudyPackagePath, adminStudyPackagesPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import AdminStudyPackageForm, {
  StudyPackageFormValues,
} from "../../components/AdminStudyPackageForm"

export const STUDY_PACKAGE_CREATE_MUTATION = gql(`
  mutation StudyPackageCreate($input: StudyPackageCreateInput!) {
    studyPackageCreate(input: $input) {
      studyPackage {
        id
      }
    }
  }
`)

const initialValues = {
  name: "",
  bodyPartId: "",
  priceDollars: "",
  manualCount: "",
  isImageCount: "false",
  modalities: [],
  featured: false,
  published: false,
  studiesCsv: "",
  description: "",
  showStudies: true,
  studiesCsvUrl: "",
}

const AdminNewStudyPackageScreen = () => {
  const backPath = adminStudyPackagesPath.pattern
  const [mutate] = useMutation(STUDY_PACKAGE_CREATE_MUTATION)
  const navigate = useNavigate()

  const onSubmit = async (
    values: StudyPackageFormValues,
    { setFieldError }: FormikHelpers<StudyPackageFormValues>
  ) => {
    try {
      const resp = await mutate({
        variables: {
          input: {
            studyPackageInput: {
              name: values.name,
              bodyPartId: values.bodyPartId,
              manualCount: values.manualCount,
              isImageCount: values.isImageCount === "true",
              priceCents: Math.round(Number(values.priceDollars) * 100),
              featured: values.featured,
              published: values.published,
              studiesCsv: values.studiesCsv,
              description: values.description,
              showStudies: values.showStudies,
            },
            modalities: (values.modalities as ModalityEnum[]) || [],
          },
        },
      })
      toast.success("Study package has been created")
      const id = resp.data?.studyPackageCreate?.studyPackage?.id
      invariant(id, "expected study package id")
      navigate(adminStudyPackagePath({ id }))
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center">
        <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
          <ChevronLeft size={28} />
        </Link>
        <H1 margin={false}>New Image Package</H1>
      </div>
      <HR padding={false} />
      <AdminStudyPackageForm
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </div>
  )
}

export default AdminNewStudyPackageScreen
