import { useQuery } from "@apollo/client"
import clsx from "clsx"
import { ChevronLeft } from "lucide-react"
import { PropsWithChildren, useState } from "react"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { currency } from "~/common/currency"
import { dateTime } from "~/common/dates"
import {
  adminOrdersPath,
  adminStudyPackagePath,
  adminUserPath,
} from "~/common/paths"
import ArrowRight from "~/images/icons/arrow-right.svg"
import UserSquare from "~/images/icons/user-square.svg"
import ArchiveOrderButton from "~/screens/AdminScreen/components/ArchiveOrderButton"
import { ButtonLink } from "~/ui/Button"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import { H1, H3 } from "~/ui/Headings"
import { Link, LinkButton } from "~/ui/Link"
import Loading from "~/ui/Loading"
import AdminOrderContractDocument from "./components/AdminOrderContractDocument"
import AdminOrderDownloadLinkForm from "./components/AdminOrderDownloadLinkForm"
import AdminOrderFormDialog from "./components/AdminOrderFormDialog"
import AdminOrderPODocument from "./components/AdminOrderPODocument"
import AdminOrderSearchSection from "./components/AdminOrderSearchSection"

export const AdminOrderScreen_OrderFragment = gql(`
  fragment AdminOrderScreen_OrderFragment on Order {
    id
    createdAt
    statusDisplay
    orderNumber
    studyCount
    imageCount
    estimatedPriceCents
    priceCents
    status
    sentPoDocumentAt
    sentContractDocumentAt
    sentOrderStudiesCsvAt
    deliveredAt
    contractDocument {
      filename
      url
      kb
    }
    defaultContractDocument {
      filename
      url
      kb
    }
    poDocument {
      filename
      url
      kb
    }
    downloadUrl
    orderStudiesCsvUrl
    studyPackage {
      id
      name
    }
    search {
      ...StudySearchResultsSummary_SearchFragment
    }
    user {
      id
      name
      company
      email
    }
  }
`)

const AdminOrderScreen_Query = gql(`
  query AdminOrderScreen_Query($id: ID!) {
    node(id: $id) {
      ...AdminOrderScreen_OrderFragment
    }
  }
`)

const DataRows = ({ children }: PropsWithChildren) => {
  return <div className="flex flex-col gap-4">{children}</div>
}

const DataRow = ({
  label,
  value,
  onEdit,
}: {
  label: string
  value: string | React.ReactElement
  onEdit?: () => void
}) => {
  return (
    <div className="flex items-center text-sm">
      <div className="w-[150px] text-xs text-neutral-400">{label}</div>
      <div className="flex min-w-[150px] items-center justify-between gap-3 font-semibold text-blue-zodiac">
        <div>{value}</div>
        {onEdit && (
          <LinkButton
            variant="blue"
            onClick={onEdit}
            className="text-sm font-semibold"
          >
            Edit
          </LinkButton>
        )}
      </div>
    </div>
  )
}

const AdminOrderScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")
  const [showEditFormDialog, setshowEditFormDialog] = useState(false)

  const { data, loading, error } = useQuery(AdminOrderScreen_Query, {
    variables: {
      id: id,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "Order")
    return <Error error={error} />

  const order = getFragmentData(AdminOrderScreen_OrderFragment, data.node)
  const backPath = adminOrdersPath.pattern

  const onEdit = () => setshowEditFormDialog(true)

  return (
    <div className="flex flex-col gap-8">
      <div
        className={clsx(
          "flex flex-col gap-4",
          "lg:flex-row lg:items-center lg:justify-between"
        )}
      >
        <div className="flex items-center">
          <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
            <ChevronLeft size={28} />
          </Link>
          <H1 margin={false}>Order #{order.orderNumber}</H1>
        </div>

        <div className="flex items-center">
          <div className="mr-2 text-sm text-neutral-400">Status:</div>{" "}
          <div className="mr-4 text-blue-zodiac">{order.statusDisplay}</div>
          <LinkButton
            variant="blue"
            onClick={onEdit}
            className="text-sm font-semibold"
          >
            Edit
          </LinkButton>
        </div>
      </div>
      <HR padding={false} />
      {!!order.search && (
        <AdminOrderSearchSection
          orderStudiesCsvUrl={order.orderStudiesCsvUrl}
          data={order.search}
        />
      )}
      <H3 margin={false}>Buyer Information</H3>
      <DataRows>
        <DataRow label="Name" value={order.user.name} />
        <DataRow label="Company" value={order.user.company || "-"} />
        <DataRow label="Email" value={order.user.email} />
      </DataRows>
      <div>
        <ButtonLink
          to={adminUserPath({ id: order.user.id })}
          display="inline"
          size="lg"
        >
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
              <UserSquare />
              View Buyer Profile
            </div>
            <ArrowRight />
          </div>
        </ButtonLink>
      </div>
      <HR padding={false} />
      <H3 margin={false}>Order Details</H3>
      <DataRows>
        {!!order.studyPackage && (
          <DataRow
            label="Image Package"
            value={
              <>
                <Link
                  to={adminStudyPackagePath({ id: order.studyPackage.id })}
                  variant="blue"
                >
                  {order.studyPackage.name}
                </Link>
              </>
            }
          />
        )}
        <DataRow label="Status" value={order.statusDisplay} onEdit={onEdit} />
        {!!order.deliveredAt && (
          <DataRow
            label="Delivered At"
            value={dateTime(new Date(order.deliveredAt))}
          />
        )}
        {!!order.estimatedPriceCents && (
          <DataRow
            label="Estimated Price"
            value={currency(order.estimatedPriceCents)}
          />
        )}
        <DataRow
          label="Final Price"
          value={order.priceCents ? currency(order.priceCents) : "-"}
          onEdit={!!order.studyPackage ? undefined : onEdit}
        />
      </DataRows>
      <div className="inline-flex">
        <ArchiveOrderButton id={order.id} />
      </div>
      <HR padding={false} />
      <H3 margin={false}>Contracts & Documents</H3>
      <AdminOrderContractDocument
        orderId={id}
        sentAt={order.sentContractDocumentAt || undefined}
        contractDocumentUrl={order.contractDocument?.url || undefined}
        defaultContractDocumentUrl={
          order.defaultContractDocument?.url || undefined
        }
      />
      <AdminOrderPODocument
        orderId={id}
        sentAt={order.sentPoDocumentAt || undefined}
        poDocumentUrl={order.poDocument?.url}
      />
      <AdminOrderDownloadLinkForm
        orderId={id}
        sentAt={order.sentOrderStudiesCsvAt || undefined}
        downloadUrl={order.downloadUrl}
      />
      <br />
      <br />
      {showEditFormDialog && (
        <AdminOrderFormDialog
          data={data.node}
          setIsOpen={setshowEditFormDialog}
        />
      )}
    </div>
  )
}

export default AdminOrderScreen
