import clsx from "clsx"
import pluralize from "pluralize"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import AdminSeriesImportRowRow, {
  AdminSeriesImportRowRow_SeriesImportRowFragment,
} from "./AdminSeriesImportRowRow"

export const AdminSeriesImportRows_QueryFragment = gql(`
  fragment AdminSeriesImportRows_QueryFragment on Query {
    seriesImportRows(page: $page, bulkUpdateId: $bulkUpdateId, limit: $limit) {
      seriesImportRows {
        edges {
          node {
            ...AdminSeriesImportRowRow_SeriesImportRowFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

export const SERIES_IMPORT_ROWS_LIMIT = 21

const AdminSeriesImportRowsTable = ({
  seriesImportRows,
  totalCount,
  page,
  loading,
}: {
  seriesImportRows: FragmentType<
    typeof AdminSeriesImportRowRow_SeriesImportRowFragment
  >[]
  totalCount: number
  page: number
  loading: boolean
}) => {
  const totalPages = Math.ceil(totalCount / SERIES_IMPORT_ROWS_LIMIT)

  return (
    <div
      className={clsx("flex flex-col gap-6 text-base", {
        "animate-pulse opacity-90": loading,
      })}
    >
      <div className="flex flex-col gap-6 text-base">
        <div className="my-1">
          <b>
            {totalCount.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}
          </b>{" "}
          {pluralize("row", totalCount)}
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[100px]">CSV Row</TableHead>
            <TableHead className="min-w-[100px]">Accession</TableHead>
            <TableHead className="min-w-[100px]">Patient ID</TableHead>
            <TableHead className="min-w-[200px]">Description</TableHead>
            <TableHead className="min-w-[200px]">Error</TableHead>
            <TableHead className="min-w-[200px]">Skipped</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {seriesImportRows.map((seriesImportRow) => (
            <AdminSeriesImportRowRow
              key={
                getFragmentData(
                  AdminSeriesImportRowRow_SeriesImportRowFragment,
                  seriesImportRow
                ).id
              }
              data={seriesImportRow}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination page={page} totalPages={totalPages} />
    </div>
  )
}

export default AdminSeriesImportRowsTable
