import { ModalityEnum, SearchSexEnum, SexEnum } from "~/__generated__/graphql"

export const sexDisplay = (sex: SexEnum) => {
  return sex === SexEnum.M ? "Male" : "Female"
}

export const searchSexDisplay = (sex: SearchSexEnum) => {
  switch (sex) {
    case SearchSexEnum.A:
      return "All"
    case SearchSexEnum.F:
      return "Female"
    case SearchSexEnum.M:
      return "Male"
    default:
      throw new Error("Incorrect SearchSexEnum")
  }
}

export const modalityDisplay = (modality: ModalityEnum) => {
  switch (modality) {
    case ModalityEnum.Ct:
      return "Computed Tomography (CT)"
    case ModalityEnum.Mr:
      return "Magnetic Resonance Imaging (MR)"
    case ModalityEnum.Pt:
      return "Positron Emission Tomography (PT)"
    case ModalityEnum.Us:
      return "Ultrasound (US)"
    case ModalityEnum.Xr:
      return "X-ray (XR)"
    default:
      throw new Error(`Unknown modality: ${modality}`)
  }
}
