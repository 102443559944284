import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import * as Yup from "yup"
import { ModalityEnum } from "~/__generated__/graphql"
import useBodyPartsQuery from "~/hooks/useBodyPartsQuery"
import { currencyValidator } from "~/lib/utils"
import Button from "~/ui/Button"
import Container from "~/ui/Container"
import Error from "~/ui/Error"
import FieldGroup from "~/ui/FieldGroup"
import FormActions from "~/ui/FormActions"
import FormikField from "~/ui/FormikField"
import Loading from "~/ui/Loading"

export type StudyPackageFormValues = {
  name: string
  bodyPartId: string
  priceDollars: string
  manualCount: string
  isImageCount: string
  modalities: string[]
  featured: boolean
  published: boolean
  studiesCsv: string
  description: string
  showStudies: boolean
  studiesCsvUrl?: string
}

export const adminStudyPackageValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  bodyPartId: Yup.string().required("Body part is required"),
  priceDollars: currencyValidator,
  description: Yup.string(),
  showStudies: Yup.boolean().required(),
  studiesCsv: Yup.string().when(
    ["$showStudies", "studiesCsvUrl"],
    ([showStudies, studiesCsvUrl], schema) =>
      showStudies && !studiesCsvUrl
        ? schema.required("Studies CSV is required")
        : schema
  ),
  modalities: Yup.array().when("$showStudies", ([showStudies], schema) =>
    showStudies ? schema : schema.min(1, "At least one modality is required")
  ),
  manualCount: Yup.string().when("$showStudies", ([showStudies], schema) =>
    showStudies ? schema : schema.required("Study count is required")
  ),
})

export const modalityOptions = Object.values(ModalityEnum).map((value) => ({
  label: value.toLocaleUpperCase(),
  value,
}))

const AdminStudyPackageForm = ({
  onSubmit,
  initialValues,
  studiesCsvUrl,
}: {
  onSubmit: (
    values: StudyPackageFormValues,
    { setFieldError }: FormikHelpers<StudyPackageFormValues>
  ) => void
  initialValues: StudyPackageFormValues
  studiesCsvUrl?: string
}) => {
  const { bodyParts, loading, error } = useBodyPartsQuery()

  if (loading) return <Loading />
  if (error || !bodyParts?.length) return <Error />

  return (
    <Container size="md">
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={adminStudyPackageValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting }: FormikProps<StudyPackageFormValues>) => (
          <Form className="w-full">
            <FieldGroup>
              <FormikField
                name="name"
                label="Name"
                placeholder="Name"
                required={true}
              />
              <FormikField
                name="description"
                label="Description"
                placeholder="Description"
                type="textarea"
              />
              <FormikField
                name="priceDollars"
                label="Price"
                placeholder="Price"
                required={true}
              />
              <FormikField
                name="bodyPartId"
                label="Body Part"
                placeholder="Select Body Part"
                type="select"
                options={bodyParts.map((bodyPart) => ({
                  label: bodyPart.name,
                  value: bodyPart.uid,
                }))}
                required={true}
              />
              <FormikField
                name="showStudies"
                type="checkbox"
                label="Show Studies"
                labelInline={true}
              />

              {values.showStudies ? (
                <FormikField
                  name="studiesCsv"
                  type="file"
                  label={
                    <div className="mb-1">
                      <div>Upload studies CSV</div>
                      <div className="text-xs text-neutral-500">
                        Replaces any existing package studies. Required headers:
                        Accession Number, MRN
                      </div>
                    </div>
                  }
                  uploadedFileUrl={studiesCsvUrl}
                />
              ) : (
                <>
                  <div className="flex items-center gap-4">
                    <div className="flex-1">
                      <FormikField
                        name="manualCount"
                        label="Count"
                        placeholder="Count"
                        required={true}
                      />
                    </div>
                    <div className="mt-8 flex flex-1 items-center gap-4">
                      <label className="flex gap-2">
                        <FormikField
                          type="radio"
                          name="isImageCount"
                          value="false"
                        />
                        Studies
                      </label>
                      <label className="flex gap-2">
                        <FormikField
                          type="radio"
                          name="isImageCount"
                          value="true"
                        />
                        Images
                      </label>
                    </div>
                  </div>

                  <FormikField
                    type="multiselect"
                    label="Modalities"
                    name="modalities"
                    placeholder="Select modalities"
                    options={modalityOptions}
                    required={true}
                  />
                </>
              )}
              <div className="mt-4 max-w-[200px]">
                <FormikField
                  name="featured"
                  type="checkbox"
                  label="Featured"
                  labelInline={true}
                />
                <FormikField
                  name="published"
                  type="checkbox"
                  label="Published"
                  labelInline={true}
                />
              </div>
            </FieldGroup>
            <FormActions>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </FormActions>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default AdminStudyPackageForm
