import { Link } from "react-router-dom"
import { orderPath } from "~/common/paths"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/DropdownMenu"
import CreditCardIcon from "~/images/icons/credit-card.svg"
import EllipsisSvg from "~/images/icons/ellipsis.svg"
import Button from "~/ui/Button"

const OrderRowActions = ({ id }: { id: string }) => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outlineSecondary"
            display="inline"
            className="bg-white"
          >
            <EllipsisSvg />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[150px]" side="bottom" align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem asChild={true}>
              <Link to={orderPath({ id })}>
                <DropdownMenuIcon>
                  <CreditCardIcon />
                </DropdownMenuIcon>
                View Order
              </Link>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default OrderRowActions
