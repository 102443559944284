import { Form, Formik } from "formik"
import queryString from "query-string"
import { useNavigate } from "react-router-dom"
import {
  NumberParam,
  encodeQueryParams,
  useQueryParams,
} from "use-query-params"
import { imageSearchResultsPath } from "~/common/paths"
import { imageSearchParamSchema } from "~/hooks/useImageSearchParamValues"
import ImageSearchFields, {
  ImageSearchFormValues,
  imageSearchValidationSchema,
} from "./ImageSearchFields"

export const paginationParamSchema = {
  page: NumberParam,
}

const NewImageSearchForm = ({ id }: { id: string }) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useQueryParams(imageSearchParamSchema)

  const initialValues: ImageSearchFormValues = {
    imageCount: searchParams.imageCount || "",
    ganLabelId: searchParams.ganLabelId || "",
  }

  const onSubmit = async (values: ImageSearchFormValues) => {
    setSearchParams(values, "replace")
    const encodedSearchParams = encodeQueryParams(
      imageSearchParamSchema,
      values
    )
    navigate(
      `${imageSearchResultsPath({ id })}?${queryString.stringify(
        encodedSearchParams
      )}`
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={imageSearchValidationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <Form>
        <ImageSearchFields />
      </Form>
    </Formik>
  )
}

export default NewImageSearchForm
