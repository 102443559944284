import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { adminUserPath } from "~/common/paths"
import DateTimeCell from "~/components/DateTimeCell"
import { LinkedTableCell, TableCell, TableRow } from "~/components/ui/Table"
import { ButtonLink } from "~/ui/Button"

export const AdminUserRow_UserFragment = gql(`
  fragment AdminUserRow_UserFragment on User {
    id
    name
    email
    company
    createdAt
  }
`)

const AdminUserRow = ({
  data,
}: {
  data: FragmentType<typeof AdminUserRow_UserFragment>
}) => {
  const user = getFragmentData(AdminUserRow_UserFragment, data)
  const path = adminUserPath({
    id: user.id,
  })

  return (
    <TableRow>
      <LinkedTableCell to={path}>{user.name}</LinkedTableCell>
      <LinkedTableCell to={path}>{user.email}</LinkedTableCell>
      <LinkedTableCell to={path}>{user.company}</LinkedTableCell>
      <LinkedTableCell to={path}>
        <DateTimeCell date={user.createdAt} />
      </LinkedTableCell>
      <TableCell>
        <div className="flex gap-2">
          <ButtonLink to={path} display="inline">
            View
          </ButtonLink>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default AdminUserRow
