import { Helmet } from "react-helmet-async"
import { Link, Outlet } from "react-router-dom"
import { dashboardPath } from "~/common/paths"
import useScrollToTop from "~/hooks/useScrollToTop"
import logo from "~/images/login-logo.png"
import Container from "~/ui/Container"

export const RegistrationLayout = () => {
  useScrollToTop()

  return (
    <>
      <Helmet>
        <body className="bg-blue-zodiac" />
      </Helmet>
      <Container size="sm" centerContent={true} centerSelf={true} padded={true}>
        <Link
          to={dashboardPath.pattern}
          className="mb-20 mt-12 flex justify-center"
        >
          <img src={logo} className="h-[66px] w-[174px]" alt="RadImageNet" />
        </Link>
        <Outlet />
      </Container>
    </>
  )
}
