import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import { toast } from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import * as Yup from "yup"
import { resetPasswordFormPath } from "~/common/paths"
import { request } from "~/common/request"
import { displayActiveRecordErrors } from "~/common/validations"
import LoggedOutAccountFooter from "~/components/LoggedOutAccountFooter"
import Button from "~/ui/Button"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"

type Values = {
  password: string
  passwordConfirmation: string
}

const initialValues = {
  password: "",
  passwordConfirmation: "",
}

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "New password must be at least 6 characters")
    .required("A password is required"),
  passwordConfirmation: Yup.string()
    .required("A password confirmation is required")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
})

const errorMessage = "Password update failed, please try again"

const ResetPasswordForm = () => {
  const [search] = useSearchParams()

  const onSubmit = async (
    values: Values,
    { setFieldError }: FormikHelpers<Values>
  ) => {
    try {
      const [status, response] = await request(
        resetPasswordFormPath.pattern,
        { method: "PUT" },
        {
          user: {
            password: values.password,
            password_confirmation: values.passwordConfirmation,
            reset_password_token: search.get("reset_password_token"),
          },
        }
      )

      if (status === "success") {
        window.location.replace("/")
      } else {
        const validationErrors = response.errors
        if (
          validationErrors &&
          (validationErrors["password"] ||
            validationErrors["password_confirmation"])
        ) {
          displayActiveRecordErrors(validationErrors, setFieldError)
        } else {
          const message = response?.error || errorMessage
          toast.error(message)
        }
      }
    } catch (error) {
      console.error(error)
      toast.error(errorMessage)
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }: FormikProps<Values>) => (
          <Form>
            <H1 id="reset-password" light={true} centered={true} font="tight">
              Reset Your Password
            </H1>
            <div className="mt-8">
              <FieldGroup>
                <FormikField
                  name="password"
                  type="password"
                  label="Create New Password"
                  autoFocus={true}
                  light={true}
                />
                <FormikField
                  name="passwordConfirmation"
                  type="password"
                  label="Confirm New Password"
                  light={true}
                />
              </FieldGroup>
            </div>
            <HR dark={true} />
            <Button type="submit" disabled={isSubmitting}>
              Update Password
            </Button>

            <div className="mt-8">
              <LoggedOutAccountFooter action="Update Password" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ResetPasswordForm
