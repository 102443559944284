import clsx from "clsx"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"

const ReportText = ({
  text,
  className,
}: {
  text: string
  className?: string
}) => {
  const { currentUser } = useCurrentUserMaybe()

  return (
    <div
      className={clsx(className, {
        "italic text-neutral-400/80": !currentUser,
      })}
    >
      {currentUser
        ? text
        : "Create an account or sign in to view the report copy"}
    </div>
  )
}

export default ReportText
