import clsx from "clsx"

const Panel = ({ label, value }: { label: string; value: string }) => (
  <div className="flex flex-1 items-center justify-center gap-1 p-2 text-base">
    <div className="">{label}:</div>
    <div className="font-bold">{value}</div>
  </div>
)

const ImageSearchResultsAppliedFilters = ({
  imageCount,
  label,
}: {
  imageCount: number
  label: string
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col items-stretch divide-y divide-border-gray rounded-lg border border-border-gray text-sm",
        "xl:flex-row xl:divide-x xl:divide-y-0"
      )}
    >
      <Panel
        label="Image Count"
        value={imageCount.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        })}
      />
      <Panel label="Label" value={label} />
    </div>
  )
}

export default ImageSearchResultsAppliedFilters
