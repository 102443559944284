import { PropsWithChildren } from "react"

export const TH = ({
  colSpan,
  children,
}: { colSpan?: number } & PropsWithChildren) => (
  <th
    className="border-gray border px-3 py-1.5 text-left text-base text-elderberry"
    colSpan={colSpan}
    children={children}
  />
)

export const TD = ({
  colSpan,
  children,
}: { colSpan?: number } & PropsWithChildren) => (
  <td
    className="border-gray border px-3 py-1 text-left align-top text-sm text-elderberry"
    colSpan={colSpan}
    children={children}
  />
)

export const Table = ({ children }: PropsWithChildren) => (
  <table className="w-full border border-border-gray" children={children} />
)
