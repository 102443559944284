import { useField } from "formik"
import { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import useFileUpload, { UploadProgress } from "~/hooks/useFileUpload"
import Button from "./Button"
import { FormikFieldType } from "./FormikField"
import { Link } from "./Link"

const FormikFileUpload = ({
  name,
  type,
  uploadedFileUrl,
}: Pick<FormikFieldType, "name" | "type" | "uploadedFileUrl">) => {
  const [fileName, setFileName] = useState("")
  const [_field, _meta, { setValue }] = useField({ name, type })
  const [isUploading, setIsUploading] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const { uploadFile, progress, checksumProgress } = useFileUpload()

  const openUploadDialog = () => hiddenFileInput?.current?.click()

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true)
    const file = e?.target?.files?.[0]
    if (!file) {
      toast.error("Missing file, please try again")
      setIsUploading(false)
      return
    }

    try {
      const signedId = await uploadFile(file)
      setFileName(file.name)
      setValue(signedId)
    } catch (error) {
      console.error(error)
      toast.error("File upload failed, please try again")
    } finally {
      setIsUploading(false)
    }
  }

  useEffect(() => {
    setFileName("")
  }, [uploadedFileUrl])

  return (
    <div className="rounded-md">
      <div className="flex items-center justify-between gap-8">
        <div>
          <Button
            onClick={openUploadDialog}
            display="inline"
            type="button"
            disabled={isUploading}
          >
            Upload
          </Button>
          <UploadProgress
            progress={progress}
            checksumProgress={checksumProgress}
            fileName={fileName}
          />
        </div>
        <div>
          {uploadedFileUrl && (
            <Link variant="blue" to={uploadedFileUrl} className="text-sm">
              Download
            </Link>
          )}
        </div>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={onChange}
        className="hidden"
      />
    </div>
  )
}

export default FormikFileUpload
