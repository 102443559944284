import { ModalityEnum, SexEnum } from "~/__generated__/graphql"
import { sexDisplay } from "~/common/study"
import { LinkedTableCell, TableCell, TableHead } from "~/components/ui/Table"
import ImageSvg from "~/images/icons/image.svg"
import ContactSheetThumb from "./ContactSheetThumb"
import PurchasedStudyBadge from "./PurchasedStudyBadge"
import ReportText from "./ReportText"

export const StudiesTableSharedHeadings = () => {
  return (
    <>
      <TableHead className="w-[65px] text-center">
        <div className="flex items-center justify-center">
          <ImageSvg />
        </div>
      </TableHead>
      <TableHead className="w-[30%] min-w-[200px]">Study</TableHead>
      <TableHead>Age</TableHead>
      <TableHead>Sex</TableHead>
      <TableHead className="min-w-[102px]">Modality</TableHead>
      <TableHead className="min-w-[400px]">Report Text</TableHead>
    </>
  )
}

export const StudiesTableSharedColumns = ({
  description,
  purchased,
  sex,
  modality,
  studyPath,
  patientAge,
  reportConclusion,
  contactSheetUrl,
}: {
  description: string
  purchased: boolean
  sex: SexEnum
  modality: ModalityEnum
  studyPath: string
  patientAge?: string | null
  textPreviewShort?: string | null
  contactSheetUrl?: string | null
  reportConclusion?: string | null
}) => {
  return (
    <>
      <TableCell className="p-3">
        <ContactSheetThumb contactSheetUrl={contactSheetUrl} />
      </TableCell>
      <LinkedTableCell to={studyPath}>
        <div className="line-clamp-2 font-bold">{description}</div>
        {purchased && <PurchasedStudyBadge />}
      </LinkedTableCell>
      <LinkedTableCell to={studyPath}>{patientAge}</LinkedTableCell>
      <LinkedTableCell to={studyPath}>{sexDisplay(sex)}</LinkedTableCell>
      <LinkedTableCell to={studyPath}>
        <div className="uppercase">{modality}</div>
      </LinkedTableCell>
      <LinkedTableCell to={studyPath}>
        <ReportText text={reportConclusion || ""} className="line-clamp-2" />
      </LinkedTableCell>
    </>
  )
}
