import { Outlet } from "react-router-dom"

const AdminUsersScreen = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default AdminUsersScreen
