import { useMutation, useQuery } from "@apollo/client"
import { Form, Formik, FormikHelpers } from "formik"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { orderSuccessPath, studyPackagePath } from "~/common/paths"
import { getStudyCountDisplay } from "~/common/studyPackage"
import { displayErrors } from "~/common/validations"
import NewOrderScreenContent from "~/components/NewOrderScreenContent"
import Error from "~/ui/Error"
import Loading from "~/ui/Loading"

type Values = {
  acceptedTerms: boolean
}

const initialValues: Values = {
  acceptedTerms: true,
}

export const StudyPackageNewOrder_StudyPackageFragment = gql(`
  fragment StudyPackageNewOrder_StudyPackageFragment on StudyPackage {
    id
    studyCount
    showStudies
    isImageCount
    manualCount
    priceCents
  }
`)

const StudyPackageNewOrderScreen_Query = gql(`
  query StudyPackageNewOrderScreen_Query($id: ID!) {
    node(id: $id) {
      ...StudyPackageNewOrder_StudyPackageFragment
    }
  }
`)

export const PACKAGE_ORDER_CREATE_MUTATION = gql(`
  mutation PackageOrderCreate($input: PackageOrderCreateInput!) {
    packageOrderCreate(input: $input) {
      order {
        id
      }
    }
  }
`)

const StudyPackageNewOrderScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")
  const navigate = useNavigate()

  const { data, loading, error } = useQuery(StudyPackageNewOrderScreen_Query, {
    variables: {
      id: id,
    },
  })
  const [mutate] = useMutation(PACKAGE_ORDER_CREATE_MUTATION)

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "StudyPackage")
    return <Error error={error} />

  const studyPackage = getFragmentData(
    StudyPackageNewOrder_StudyPackageFragment,
    data.node
  )

  const onSubmit = async (
    _values: Values,
    { setFieldError }: FormikHelpers<Values>
  ) => {
    try {
      const resp = await mutate({
        variables: {
          input: {
            studyPackageId: id,
          },
        },
      })

      const orderId = resp?.data?.packageOrderCreate?.order?.id
      invariant(orderId, "Expected order id")
      navigate(orderSuccessPath({ id: orderId }), { replace: true })
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, submitForm }) => (
        <Form>
          <NewOrderScreenContent
            backPath={studyPackagePath({ id })}
            studyCountDisplay={
              !studyPackage.isImageCount
                ? getStudyCountDisplay({ studyPackage })
                : undefined
            }
            imageCountDisplay={
              studyPackage.isImageCount
                ? getStudyCountDisplay({ studyPackage })
                : undefined
            }
            onSubmit={submitForm}
            disabled={isSubmitting || values.acceptedTerms !== true}
            priceCents={studyPackage.priceCents}
          />
        </Form>
      )}
    </Formik>
  )
}

export default StudyPackageNewOrderScreen
