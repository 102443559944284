import axios from "axios"
import { useState } from "react"
import { toast } from "react-hot-toast"
import { fileChecksum } from "~/lib/fileChecksum"
import usePresignUpload from "./usePresignUpload"

export const UploadProgress = ({
  progress,
  checksumProgress,
  fileName,
}: {
  progress: number
  checksumProgress: number
  fileName?: string
}) => {
  return (
    <div className="flex-items-center mt-2 h-[14px] text-center text-2xs text-gray-500">
      {checksumProgress > 0 && checksumProgress < 1 ? (
        <div className="animate-pulse">
          Processing {Math.round(checksumProgress * 100)}%
        </div>
      ) : progress > 0 && progress < 1 ? (
        <div className="h-full w-full overflow-hidden rounded-full bg-gray-200">
          <div
            className="h-full rounded-full bg-candlelight transition-all"
            style={{ width: `${progress * 100}%`, minWidth: "1%" }}
          />
        </div>
      ) : !!fileName ? (
        <div>{fileName}</div>
      ) : null}
    </div>
  )
}

const useFileUpload = () => {
  const [progress, setProgress] = useState(0)
  const [checksumProgress, setChecksumProgress] = useState(0)
  const [mutate] = usePresignUpload()

  const uploadToS3 = async (
    file: File,
    url: string,
    headers: Record<string, string>
  ) => {
    let data = new FormData()
    data.append("file", file)
    const resp = await axios.put(url, file, {
      onUploadProgress: (progressEvent) => {
        setProgress(progressEvent.progress || 0)
      },
      headers,
    })

    if (resp.status !== 200) {
      throw new Error("aws upload failed")
    }
  }

  const uploadFile = async (file: File) => {
    if (file.size > 5000000000) {
      toast.error("File must be less than 5GB")
      return
    }

    const checksum = await fileChecksum(file, setChecksumProgress)
    const resp = await mutate({
      variables: {
        input: {
          filename: file.name,
          byteSize: file.size,
          contentType: file.type,
          checksum,
        },
      },
    })

    const presignUpload = resp?.data?.presignUpload
    if (presignUpload) {
      await uploadToS3(file, presignUpload.url, presignUpload.headers)
      return presignUpload.signedId
    } else {
      throw new Error("presignUpload failed")
    }
  }

  return { uploadFile, progress, checksumProgress }
}

export default useFileUpload
