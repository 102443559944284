import { gql, useMutation } from "@apollo/client"
import { useFormikContext } from "formik"
import { useState } from "react"
import toast from "react-hot-toast"
import { useOutletContext } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import Button from "~/ui/Button"
import {
  SearchResultsScreenOutletContext,
  StudySearchResultsFormValues,
} from "../StudySearchResultsScreen"
import {
  StudySearchResultsSummary_SearchFragment,
  StudySearchResultsSummary_SearchParamsFragment,
} from "./StudySearchResultsSummary"

export const ExportRequirestCreate_Mutation = gql(`
  mutation ExportRequestCreate($input: ExportRequestCreateInput!) {
    exportRequestCreate(input: $input) {
      search {
        id
        name
      }
    }
  }
`)

const RequestExportButton = () => {
  const [mutate, { loading }] = useMutation(ExportRequirestCreate_Mutation)
  const { values } = useFormikContext<StudySearchResultsFormValues>()
  const { search } = useOutletContext<SearchResultsScreenOutletContext>()

  const [isDisabled, setIsDisabled] = useState(false)

  const onClick = async () => {
    const searchData = getFragmentData(
      StudySearchResultsSummary_SearchFragment,
      search
    )
    const studySearchParams = getFragmentData(
      StudySearchResultsSummary_SearchParamsFragment,
      searchData.studySearchParams
    )
    invariant(studySearchParams, "Expected studySearchParams")

    try {
      const resp = await mutate({
        variables: {
          input: {
            studySearchParamsInput: {
              patientAgeMin: studySearchParams.patientAgeMin,
              patientAgeMax: studySearchParams.patientAgeMax,
              sex: studySearchParams.sex,
              mr: studySearchParams.mr,
              mrStrength: studySearchParams.mrStrength,
              mrContrast: studySearchParams.mrContrast,
              mrReportKeywords: studySearchParams.mrReportKeywords,
              mrDescriptionKeywords: studySearchParams.mrDescriptionKeywords,
              mrSeriesDescriptionKeywords:
                studySearchParams.mrSeriesDescriptionKeywords,
              ct: studySearchParams.ct,
              ctContrast: studySearchParams.ctContrast,
              ctReportKeywords: studySearchParams.ctReportKeywords,
              ctDescriptionKeywords: studySearchParams.ctDescriptionKeywords,
              ctSeriesDescriptionKeywords:
                studySearchParams.ctSeriesDescriptionKeywords,
              xr: studySearchParams.xr,
              xrReportKeywords: studySearchParams.xrReportKeywords,
              xrDescriptionKeywords: studySearchParams.xrDescriptionKeywords,
              xrSeriesDescriptionKeywords:
                studySearchParams.xrSeriesDescriptionKeywords,
              us: studySearchParams.us,
              usReportKeywords: studySearchParams.usReportKeywords,
              usDescriptionKeywords: studySearchParams.usDescriptionKeywords,
              usSeriesDescriptionKeywords:
                studySearchParams.usSeriesDescriptionKeywords,
            },
            studyIdsDiff: values.studyIdsDiff,
            selectAll: values.selectAll,
          },
        },
      })

      const id = resp?.data?.exportRequestCreate?.search?.id
      invariant(id, "Expected search id")

      toast.success(
        "Your request has been sent to a RadImageNet team member. Please allow a few days for your request to be processed.",
        {
          duration: 5000,
        }
      )
      setIsDisabled(true)
    } catch (error: any) {
      console.error(error)
      toast.error("There was an error requesting the export, please try again")
    }
  }

  return (
    <div>
      <Button
        type="button"
        onClick={onClick}
        disabled={loading || isDisabled}
        variant="outlineSecondary"
        title={isDisabled ? "Request has been sent" : undefined}
      >
        Request Export
      </Button>
    </div>
  )
}

export default RequestExportButton
