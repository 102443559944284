/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment CurrentUser on User {\n    id\n    email\n    firstName\n    lastName\n    company\n    companyAddress1\n    companyAddress2\n    companyCity\n    companyState\n    companyZip\n    isAdmin\n    __typename\n  }\n": types.CurrentUserFragmentDoc,
    "\n  query CurrentUserProvider {\n    currentUser {\n      ...CurrentUser\n    }\n  }\n": types.CurrentUserProviderDocument,
    "\n  mutation UserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...CurrentUser\n      }\n    }\n  }\n": types.UserUpdateDocument,
    "\n  fragment StudyScreenContent_StudyFragment on Study {\n    id\n    patientAge\n    sex\n    date\n    modality\n    contactSheetUrl\n    imageCount\n    description\n    reportConclusion\n    purchased\n    seriesDescriptions\n    bodyPart {\n      id\n      name\n    }\n  }\n": types.StudyScreenContent_StudyFragmentFragmentDoc,
    "\n  fragment BodyPartFragment on BodyPart {\n    id\n    uid\n    name\n  }\n": types.BodyPartFragmentFragmentDoc,
    "\n  query BodyParts {\n    bodyParts(first: 1000) {\n      nodes {\n        ...BodyPartFragment\n      }\n    }\n  }\n": types.BodyPartsDocument,
    "\n  fragment GanLabelFragment on GanLabel {\n    id\n    uid\n    name\n  }\n": types.GanLabelFragmentFragmentDoc,
    "\n  query GanLabels {\n    ganLabels(first: 1000) {\n      nodes {\n        ...GanLabelFragment\n      }\n    }\n  }\n": types.GanLabelsDocument,
    "\n  mutation PresignUpload($input: PresignUploadInput!) {\n    presignUpload(input: $input) {\n      url\n      headers\n      signedId\n    }\n  }\n": types.PresignUploadDocument,
    "\n  fragment SearchUpdatedSubscriptionFragment on Search {\n    id\n    isDuplicatingSearch\n    isCreatingResults\n    isUpdatingSelections\n    resultsCount\n  }\n": types.SearchUpdatedSubscriptionFragmentFragmentDoc,
    "\n  subscription SearchUpdated($id: ID!) {\n    searchUpdated(id: $id) {\n      search {\n        ...SearchUpdatedSubscriptionFragment\n      }\n    }\n  }\n": types.SearchUpdatedDocument,
    "\n  mutation BulkUpdateCreate($input: BulkUpdateCreateInput!) {\n    bulkUpdateCreate(input: $input) {\n      bulkUpdate {\n        id\n      }\n    }\n  }\n": types.BulkUpdateCreateDocument,
    "\n  query AdminBulkUpdatesScreen_Query($page: Int, $limit: Int) {\n    ...AdminBulkUpdates_QueryFragment\n  }\n": types.AdminBulkUpdatesScreen_QueryDocument,
    "\n  fragment AdminBulkUpdateRow_BulkUpdateFragment on BulkUpdate {\n    id\n    number\n    description\n    completedCsvImportAt\n    completedAt\n    createdAt\n    bulkUpdateType\n  }\n": types.AdminBulkUpdateRow_BulkUpdateFragmentFragmentDoc,
    "\n  fragment AdminBulkUpdates_QueryFragment on Query {\n    bulkUpdates(page: $page, limit: $limit) {\n      bulkUpdates {\n        edges {\n          node {\n            ...AdminBulkUpdateRow_BulkUpdateFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.AdminBulkUpdates_QueryFragmentFragmentDoc,
    "\n  fragment AdminBulkUpdate_BulkUpdateFragment on BulkUpdate {\n    id\n    number\n    description\n    importedSeriesImportRowsCount\n    completedAt\n    createdAt\n    studyCount\n    importErrorCounts\n    importSkippedCounts\n    csvUrl\n  }\n": types.AdminBulkUpdate_BulkUpdateFragmentFragmentDoc,
    "\n  query AdminStudyArchiveScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminBulkUpdate_BulkUpdateFragment\n    }\n  }\n": types.AdminStudyArchiveScreen_QueryDocument,
    "\n  fragment AdminSeriesImportRowRow_SeriesImportRowFragment on SeriesImportRow {\n    id\n    index\n    accessionNumber\n    patientId\n    studyDescription\n    importErrorMessage\n    importSkippedMessage\n  }\n": types.AdminSeriesImportRowRow_SeriesImportRowFragmentFragmentDoc,
    "\n  fragment AdminSeriesImportRows_QueryFragment on Query {\n    seriesImportRows(page: $page, bulkUpdateId: $bulkUpdateId, limit: $limit) {\n      seriesImportRows {\n        edges {\n          node {\n            ...AdminSeriesImportRowRow_SeriesImportRowFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.AdminSeriesImportRows_QueryFragmentFragmentDoc,
    "\n  fragment AdminBulkUpdateScreen_SeriesImportRowFragment on SeriesImportRow {\n    id\n    accessionNumber\n    studyDescription\n    patientSex\n    patientAge\n    patientAgeStr\n    patientId\n    studyDate\n    magneticFieldStrength\n    modality\n    seriesDescription\n    bodyPartExamined\n    contrastOrBolusAgent\n    index\n    importErrorMessage\n    importSkippedMessage\n    sliceThickness\n    studyId\n    completedImportAt\n    createdAt\n  }\n": types.AdminBulkUpdateScreen_SeriesImportRowFragmentFragmentDoc,
    "\n  query AdminBulkUpdateScreen_Query($id: ID!, $page: Int, $bulkUpdateId: ID, $limit: Int) {\n    node(id: $id) {\n      ...AdminBulkUpdate_BulkUpdateFragment\n    }\n    ...AdminSeriesImportRows_QueryFragment\n  }\n": types.AdminBulkUpdateScreen_QueryDocument,
    "\n  mutation BulkUpdateExportSkipped($input: BulkUpdateExportSkippedInput!) {\n    bulkUpdateExportSkipped(input: $input) {\n      id\n    }\n  }\n": types.BulkUpdateExportSkippedDocument,
    "\n  mutation ExportRequestUpdate_Mutation($input: ExportRequestUpdateInput!) {\n    exportRequestUpdate(input: $input) {\n      exportRequest {\n        id\n        status\n      }\n    }\n  }\n": types.ExportRequestUpdate_MutationDocument,
    "\n  fragment AdminExportRequestScreen_ExportRequestFragment on ExportRequest {\n    id\n    createdAt\n    sentExportAt\n    status\n    csvUrl\n    search {\n      ...StudySearchResultsSummary_SearchFragment\n    }\n    user {\n      id\n      company\n      name\n      email\n    }\n  }\n": types.AdminExportRequestScreen_ExportRequestFragmentFragmentDoc,
    "\n  query AdminExportRequestScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminExportRequestScreen_ExportRequestFragment\n    }\n  }\n": types.AdminExportRequestScreen_QueryDocument,
    "\n  query AdminExportRequestsScreen_Query($page: Int, $limit: Int) {\n    ...AdminExportRequests_QueryFragment\n  }\n": types.AdminExportRequestsScreen_QueryDocument,
    "\n  fragment AdminExportRequestRow_ExportRequestFragment on ExportRequest {\n    id\n    createdAt\n    status\n    search {\n      id\n    }\n    user {\n      id\n      company\n      name\n      email\n    }\n  }\n": types.AdminExportRequestRow_ExportRequestFragmentFragmentDoc,
    "\n  fragment AdminExportRequests_QueryFragment on Query {\n    exportRequests(page: $page, limit: $limit) {\n      exportRequests {\n        edges {\n          node {\n            ...AdminExportRequestRow_ExportRequestFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.AdminExportRequests_QueryFragmentFragmentDoc,
    "\n  fragment AdminOrderScreen_OrderFragment on Order {\n    id\n    createdAt\n    statusDisplay\n    orderNumber\n    studyCount\n    imageCount\n    estimatedPriceCents\n    priceCents\n    status\n    sentPoDocumentAt\n    sentContractDocumentAt\n    sentOrderStudiesCsvAt\n    deliveredAt\n    contractDocument {\n      filename\n      url\n      kb\n    }\n    defaultContractDocument {\n      filename\n      url\n      kb\n    }\n    poDocument {\n      filename\n      url\n      kb\n    }\n    downloadUrl\n    orderStudiesCsvUrl\n    studyPackage {\n      id\n      name\n    }\n    search {\n      ...StudySearchResultsSummary_SearchFragment\n    }\n    user {\n      id\n      name\n      company\n      email\n    }\n  }\n": types.AdminOrderScreen_OrderFragmentFragmentDoc,
    "\n  query AdminOrderScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminOrderScreen_OrderFragment\n    }\n  }\n": types.AdminOrderScreen_QueryDocument,
    "\n  mutation OrderContractDocumentUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        id\n        contractDocument {\n          filename\n          url\n          kb\n        }\n      }\n    }\n  }\n": types.OrderContractDocumentUpdateDocument,
    "\n  mutation SendContractDocEmail($input: SendContractDocEmailInput!) {\n    sendContractDocEmail(input: $input) {\n      order {\n        id\n        sentContractDocumentAt\n      }\n    }\n  }\n": types.SendContractDocEmailDocument,
    "\n  mutation OrderDownloadUrlUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        id\n        downloadUrl\n      }\n    }\n  }\n": types.OrderDownloadUrlUpdateDocument,
    "\n  mutation SendOrderDownloadLinkEmail($input: SendOrderDownloadLinkEmailInput!) {\n    sendOrderDownloadLinkEmail(input: $input) {\n      order {\n        id\n        sentOrderStudiesCsvAt\n      }\n    }\n  }\n": types.SendOrderDownloadLinkEmailDocument,
    "\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        ...AdminOrderScreen_OrderFragment\n      }\n    }\n  }\n": types.OrderUpdateDocument,
    "\n  mutation OrderPoDocumentUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        id\n        poDocument{\n          filename\n          url\n          kb\n        }\n      }\n    }\n  }\n": types.OrderPoDocumentUpdateDocument,
    "\n  mutation SendPoDocEmail($input: SendPoDocEmailInput!) {\n    sendPoDocEmail(input: $input) {\n      order {\n        id\n        sentPoDocumentAt\n      }\n    }\n  }\n": types.SendPoDocEmailDocument,
    "\n  query AdminOrdersScreen_Query($page: Int, $status: [String!], $search: String, $userId: ID, $limit: Int) {\n    ...AdminOrders_QueryFragment\n  }\n": types.AdminOrdersScreen_QueryDocument,
    "\n  fragment AdminOrderRow_OrderFragment on Order {\n    id\n    orderNumber\n    status\n    statusDisplay\n    studyCount\n    imageCount\n    manualCount\n    isImageCount\n    estimatedPriceCents\n    priceCents\n    createdAt\n    deliveredAt\n    user {\n      id\n      company\n      name\n    }\n  }\n": types.AdminOrderRow_OrderFragmentFragmentDoc,
    "\n  fragment AdminOrders_QueryFragment on Query {\n    orders(page: $page, status: $status, search: $search, userId: $userId, limit: $limit) {\n      orders {\n        edges {\n          node {\n            ...AdminOrderRow_OrderFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.AdminOrders_QueryFragmentFragmentDoc,
    "\n  query AdminStudiesScreen_Query($recent: Boolean, $archived: Boolean, $page: Int, $limit: Int) {\n    ...AdminStudies_QueryFragment\n  }\n": types.AdminStudiesScreen_QueryDocument,
    "\n  fragment AdminStudies_QueryFragment on Query {\n    studies(recent: $recent, archived: $archived, page: $page, limit: $limit) {\n      studies {\n        edges {\n          node {\n            ...AdminStudyRow_StudyFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.AdminStudies_QueryFragmentFragmentDoc,
    "\n  fragment AdminStudyRow_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    description\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    reportConclusion\n    mrn\n    accession\n    sliceThicknessMin\n    contrast\n    archived\n  }\n": types.AdminStudyRow_StudyFragmentFragmentDoc,
    "\n  fragment AdminStudyScreen_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    description\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    report\n    reportConclusion\n    mrn\n    accession\n    sliceThicknessMin\n    contrast\n    bulkUpdateId\n    seriesDescriptions\n    seriesStrengths\n  }\n": types.AdminStudyScreen_StudyFragmentFragmentDoc,
    "\n  query AdminStudyScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminStudyScreen_StudyFragment\n    }\n  }\n": types.AdminStudyScreen_QueryDocument,
    "\n  query AdminEditStudyPackageScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminStudyPackageScreen_StudyPackageFragment\n    }\n  }\n": types.AdminEditStudyPackageScreen_QueryDocument,
    "\n  mutation StudyPackageUpdate($input: StudyPackageUpdateInput!) {\n    studyPackageUpdate(input: $input) {\n      studyPackage {\n        id\n        ...AdminStudyPackageScreen_StudyPackageFragment\n      }\n    }\n  }\n": types.StudyPackageUpdateDocument,
    "\n  mutation StudyPackageCreate($input: StudyPackageCreateInput!) {\n    studyPackageCreate(input: $input) {\n      studyPackage {\n        id\n      }\n    }\n  }\n": types.StudyPackageCreateDocument,
    "\n  fragment AdminStudyPackageScreen_StudyPackageFragment on StudyPackage {\n    id\n    name\n    priceCents\n    studyCount\n    manualCount\n    isImageCount\n    createdAt\n    modalities\n    featured\n    published\n    studiesCsvUrl\n    description\n    showStudies\n    bodyPart {\n      id\n      name\n      uid\n    }\n  }\n": types.AdminStudyPackageScreen_StudyPackageFragmentFragmentDoc,
    "\n  query AdminStudyPackageScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminStudyPackageScreen_StudyPackageFragment\n    }\n  }\n": types.AdminStudyPackageScreen_QueryDocument,
    "\n  query AdminStudyPackagesScreen_Query($page: Int, $limit: Int) {\n    ...AdminStudyPackages_QueryFragment\n  }\n": types.AdminStudyPackagesScreen_QueryDocument,
    "\n  fragment AdminStudyPackageRow_StudyPackageFragment on StudyPackage {\n    id\n    name\n    priceCents\n    studyCount\n    manualCount\n    isImageCount\n    showStudies\n    createdAt\n    modalities\n    featured\n    bodyPart {\n      id\n      name\n      uid\n    }\n  }\n": types.AdminStudyPackageRow_StudyPackageFragmentFragmentDoc,
    "\n  fragment AdminStudyPackages_QueryFragment on Query {\n    studyPackages(includeUnpublished: true, page: $page, limit: $limit) {\n      studyPackages {\n        edges {\n          node {\n            ...AdminStudyPackageRow_StudyPackageFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.AdminStudyPackages_QueryFragmentFragmentDoc,
    "\n  query AdminEditUserScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminUserScreen_UserFragment\n    }\n  }\n": types.AdminEditUserScreen_QueryDocument,
    "\n  mutation AdminUserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...AdminUserScreen_UserFragment\n      }\n    }\n  }\n": types.AdminUserUpdateDocument,
    "\n  fragment AdminUserScreen_UserFragment on User {\n    id\n    name\n    firstName\n    lastName\n    email\n    company\n    companyAddress1\n    companyAddress2\n    companyCity\n    companyState\n    companyZip\n    isAdmin\n    updatedAt\n    createdAt\n  }\n": types.AdminUserScreen_UserFragmentFragmentDoc,
    "\n  query AdminUserScreen_Query($id: ID!, $page: Int, $status: [String!], $search: String, $userId: ID, $limit: Int) {\n    node(id: $id) {\n      ...AdminUserScreen_UserFragment\n    }\n    ...AdminOrders_QueryFragment\n  }\n": types.AdminUserScreen_QueryDocument,
    "\n  query AdminUsersScreen_Query($admin: Boolean, $page: Int, $search: String, $limit: Int) {\n    ...AdminUsers_QueryFragment\n  }\n": types.AdminUsersScreen_QueryDocument,
    "\n  fragment AdminUserRow_UserFragment on User {\n    id\n    name\n    email\n    company\n    createdAt\n  }\n": types.AdminUserRow_UserFragmentFragmentDoc,
    "\n  fragment AdminUsers_QueryFragment on Query {\n    users(admin: $admin, page: $page, search: $search, limit: $limit) {\n      users {\n        edges {\n          node {\n            ...AdminUserRow_UserFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.AdminUsers_QueryFragmentFragmentDoc,
    "\n  mutation OrderArchive($input: OrderArchiveInput!) {\n    orderArchive(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n": types.OrderArchiveDocument,
    "\n  query DashboardScreen_Query($userId: ID!) {\n    ...DashboardStudyPackages_QueryFragment\n    ...DashboardSearches_QueryFragment\n    ...DashboardOrders_QueryFragment\n  }\n": types.DashboardScreen_QueryDocument,
    "\n  fragment DashboardOrders_QueryFragment on Query {\n    orders(userId: $userId, limit: 21) {\n      orders {\n        edges {\n          node {\n            ...OrderRow_OrderFragment\n          }\n        }\n      }\n    }\n  }\n": types.DashboardOrders_QueryFragmentFragmentDoc,
    "\n  fragment DashboardStudyPackages_QueryFragment on Query {\n    studyPackages(sortFeatured: true, limit: 3) {\n      studyPackages {\n        edges {\n          node {\n            ...StudyPackageListItem_StudyPackageFragment\n          }\n        }\n      }\n    }\n  }\n": types.DashboardStudyPackages_QueryFragmentFragmentDoc,
    "\n  fragment DashboardSearches_QueryFragment on Query {\n    searches(limit: 21) {\n      searches {\n        edges {\n          node {\n            ...SearchRow_SearchFragment\n          }\n        }\n      }\n    }\n  }\n": types.DashboardSearches_QueryFragmentFragmentDoc,
    "\n  mutation ForgotPassword($input: ForgotPasswordInput!) {\n    forgotPassword(input: $input) {\n      email\n    }\n  }\n": types.ForgotPasswordDocument,
    "\n  fragment OrderScreen_OrderFragment on Order {\n    id\n    createdAt\n    statusDisplay\n    orderNumber\n    studyCount\n    imageCount\n    estimatedPriceCents\n    priceCents\n    downloadUrl\n    contractDocument {\n      filename\n      url\n      kb\n    }\n    defaultContractDocument {\n      filename\n      url\n      kb\n    }\n    poDocument {\n      filename\n      url\n      kb\n    }\n  }\n": types.OrderScreen_OrderFragmentFragmentDoc,
    "\n  query OrderScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...OrderScreen_OrderFragment\n    }\n  }\n": types.OrderScreen_QueryDocument,
    "\n  query OrderSuccessScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...OrderSuccessScreenOrder\n    }\n  }\n": types.OrderSuccessScreen_QueryDocument,
    "\n  fragment OrderSuccessScreenOrder on Order {\n    id\n    orderNumber\n    createdAt\n  }\n": types.OrderSuccessScreenOrderFragmentDoc,
    "\n  query OrdersScreen_Query($userId: ID!, $page: Int, $limit: Int) {\n    ...Orders_QueryFragment\n  }\n": types.OrdersScreen_QueryDocument,
    "\n  fragment OrderRow_OrderFragment on Order {\n    id\n    orderNumber\n    status\n    statusDisplay\n    studyCount\n    imageCount\n    manualCount\n    isImageCount\n    estimatedPriceCents\n    priceCents\n    createdAt\n    isCreatingStudies\n  }\n": types.OrderRow_OrderFragmentFragmentDoc,
    "\n  fragment Orders_QueryFragment on Query {\n    orders(userId: $userId, page: $page, limit: $limit) {\n      orders {\n        edges {\n          node {\n            ...OrderRow_OrderFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.Orders_QueryFragmentFragmentDoc,
    "\n  query ImageSearchResultsScreen_Query($id: ID!) {\n    search: node(id: $id) {\n      ...ImageSearchResultsSummary_SearchFragment\n    }\n  }\n": types.ImageSearchResultsScreen_QueryDocument,
    "\n  query ImageSearchResultsGanLabel_Query($id: ID!) {\n    ganLabel(id: $id) {\n      ...ImageSearchResultsSummary_GanLabelFragment\n    }\n  }\n": types.ImageSearchResultsGanLabel_QueryDocument,
    "\n  fragment ImageSearchResultsSummary_SearchFragment on Search {\n    id\n    imageSearchParams {\n      id\n      imageCount\n      ganLabel {\n        id\n        uid\n        name\n      }\n    }\n  }\n": types.ImageSearchResultsSummary_SearchFragmentFragmentDoc,
    "\n  fragment ImageSearchResultsSummary_GanLabelFragment on GanLabel {\n    id\n    name\n  }\n": types.ImageSearchResultsSummary_GanLabelFragmentFragmentDoc,
    "\n  mutation ImageSearchCreate($input: ImageSearchCreateInput!) {\n    imageSearchCreate(input: $input) {\n      search {\n        id\n        name\n      }\n    }\n  }\n": types.ImageSearchCreateDocument,
    "\n  query SearchFormScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudySearchResultsSummary_SearchFragment\n    }\n  }\n": types.SearchFormScreen_QueryDocument,
    "\n  mutation ImageSearchUpdate($input: ImageSearchUpdateInput!) {\n    imageSearchUpdate(input: $input) {\n      search {\n        id\n      }\n    }\n  }\n": types.ImageSearchUpdateDocument,
    "\n  mutation SearchUpdate($input: SearchUpdateInput!) {\n    searchUpdate(input: $input) {\n      search {\n        ...StudySearchResultsSummary_SearchFragment\n      }\n    }\n  }\n": types.SearchUpdateDocument,
    "\n  query StudySearchResultsScreen_Query($id: ID!, $searchParams: StudySearchParamsInput, $page: Int, $limit: Int) {\n    ...StudySearchResultsSummary_QueryFragment\n  }\n": types.StudySearchResultsScreen_QueryDocument,
    "\n  mutation SearchStudySelectionUpdate($input: SearchStudySelectionUpdateInput!) {\n    searchStudySelectionUpdate(input: $input) {\n      search {\n        ...StudySearchResultsSummary_SearchFragment\n      }\n    }\n  }\n": types.SearchStudySelectionUpdateDocument,
    "\n  mutation StudyOrderCreate($input: StudyOrderCreateInput!) {\n    studyOrderCreate(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n": types.StudyOrderCreateDocument,
    "\n  mutation SearchCreate($input: SearchCreateInput!) {\n    searchCreate(input: $input) {\n      search {\n        id\n        name\n        isDuplicatingSearch\n        isCreatingResults\n      }\n    }\n  }\n": types.SearchCreateDocument,
    "\n  mutation ExportRequestCreate($input: ExportRequestCreateInput!) {\n    exportRequestCreate(input: $input) {\n      search {\n        id\n        name\n      }\n    }\n  }\n": types.ExportRequestCreateDocument,
    "\n  fragment StudyResultsRow_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    description\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    reportConclusion\n  }\n": types.StudyResultsRow_StudyFragmentFragmentDoc,
    "\n  fragment StudySearchResultsSummary_SearchParamsFragment on StudySearchParams {\n    id\n    patientAgeMin\n    patientAgeMax\n    sex\n    mr\n    mrStrength\n    mrContrast\n    mrReportKeywords\n    mrDescriptionKeywords\n    mrSeriesDescriptionKeywords\n    ct\n    ctContrast\n    ctReportKeywords\n    ctDescriptionKeywords\n    ctSeriesDescriptionKeywords\n    us\n    usReportKeywords\n    usDescriptionKeywords\n    usSeriesDescriptionKeywords\n    xr\n    xrReportKeywords\n    xrDescriptionKeywords\n    xrSeriesDescriptionKeywords\n  }\n": types.StudySearchResultsSummary_SearchParamsFragmentFragmentDoc,
    "\n  fragment StudySearchResultsSummary_SearchFragment on Search {\n    id\n    name\n    studyIdsDiff\n    selectAll\n    isDuplicatingSearch\n    isCreatingResults\n    isUpdatingSelections\n    resultsCount\n    studySearchParams {\n      ...StudySearchResultsSummary_SearchParamsFragment\n    }\n    imageSearchParams {\n      id\n      imageCount\n      ganLabel {\n        id\n        uid\n        name\n      }\n    }\n  }\n": types.StudySearchResultsSummary_SearchFragmentFragmentDoc,
    "\n  fragment StudySearchResultsSummary_QueryFragment on Query {\n    searchResults(id: $id, searchParams: $searchParams, page: $page, limit: $limit) {\n      studies {\n        edges {\n          node {\n            ...StudyResultsRow_StudyFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      search {\n        ...StudySearchResultsSummary_SearchFragment\n      }\n      page\n    }\n  }\n": types.StudySearchResultsSummary_QueryFragmentFragmentDoc,
    "\n  query SearchResultScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudyScreenContent_StudyFragment\n    }\n  }\n": types.SearchResultScreen_QueryDocument,
    "\n  query SearchesScreen_Query($page: Int, $limit: Int) {\n    ...Searches_QueryFragment\n  }\n": types.SearchesScreen_QueryDocument,
    "\n  mutation SearchRename($input: SearchRenameInput!) {\n    searchRename(input: $input) {\n      search {\n        id\n        name\n      }\n    }\n  }\n": types.SearchRenameDocument,
    "\n  fragment SearchRow_SearchFragment on Search {\n    id\n    name\n    createdAt\n    resultsCount\n    modalities\n    isDuplicatingSearch\n    isCreatingResults\n    studySearchParams {\n      ...StudySearchResultsSummary_SearchParamsFragment\n    }\n    imageSearchParams {\n      id\n      imageCount\n    }\n  }\n": types.SearchRow_SearchFragmentFragmentDoc,
    "\n  mutation SearchDuplicate($input: SearchDuplicateInput!) {\n    searchDuplicate(input: $input) {\n      search {\n        ...SearchRow_SearchFragment\n      }\n    }\n  }\n": types.SearchDuplicateDocument,
    "\n  mutation SearchArchive($input: SearchArchiveInput!) {\n    searchArchive(input: $input) {\n      search {\n        id\n      }\n    }\n  }\n": types.SearchArchiveDocument,
    "\n  fragment Searches_QueryFragment on Query {\n    searches(page: $page, limit: $limit) {\n      searches {\n        edges {\n          node {\n            ...SearchRow_SearchFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.Searches_QueryFragmentFragmentDoc,
    "\n  mutation ImageOrderCreate($input: ImageOrderCreateInput!) {\n    imageOrderCreate(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n": types.ImageOrderCreateDocument,
    "\n  fragment StudyPackageNewOrder_StudyPackageFragment on StudyPackage {\n    id\n    studyCount\n    showStudies\n    isImageCount\n    manualCount\n    priceCents\n  }\n": types.StudyPackageNewOrder_StudyPackageFragmentFragmentDoc,
    "\n  query StudyPackageNewOrderScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudyPackageNewOrder_StudyPackageFragment\n    }\n  }\n": types.StudyPackageNewOrderScreen_QueryDocument,
    "\n  mutation PackageOrderCreate($input: PackageOrderCreateInput!) {\n    packageOrderCreate(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n": types.PackageOrderCreateDocument,
    "\n  fragment StudyPackageScreen_StudyPackageFragment on StudyPackage {\n    id\n    name\n    manualCount\n    studyCount\n    showStudies\n    isImageCount\n    priceCents\n    modalities\n    description\n    bodyPart {\n      id\n      name\n    }\n  }\n": types.StudyPackageScreen_StudyPackageFragmentFragmentDoc,
    "\n  query StudyPackageScreen_Query($id: ID!, $page: Int, $limit: Int) {\n    node(id: $id) {\n      ...StudyPackageScreen_StudyPackageFragment\n    }\n    ...StudyPackageStudies_QueryFragment\n  }\n": types.StudyPackageScreen_QueryDocument,
    "\n  fragment StudyPackageStudies_QueryFragment on Query {\n    studies(studyPackageId: $id, page: $page, limit: $limit) {\n      studies {\n        edges {\n          node {\n            ...StudyPackageStudyRow_StudyFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.StudyPackageStudies_QueryFragmentFragmentDoc,
    "\n  fragment StudyPackageStudyRow_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    description\n    reportConclusion\n  }\n": types.StudyPackageStudyRow_StudyFragmentFragmentDoc,
    "\n  query StudyPackageStudyScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudyScreenContent_StudyFragment\n    }\n  }\n": types.StudyPackageStudyScreen_QueryDocument,
    "\n  query StudyPackagesScreen_Query($modalities: [ModalityEnum!], $bodyPartIds: [ID!], $featured: Boolean, $page: Int, $limit: Int) {\n    ...StudyPackagesList_QueryFragment\n  }\n": types.StudyPackagesScreen_QueryDocument,
    "\n  fragment StudyPackagesList_QueryFragment on Query {\n    studyPackages(modalities: $modalities, bodyPartIds: $bodyPartIds, featured: $featured, page: $page, limit: $limit) {\n      studyPackages {\n        edges {\n          node {\n            ...StudyPackageListItem_StudyPackageFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n": types.StudyPackagesList_QueryFragmentFragmentDoc,
    "\n  fragment StudyPackageListItem_StudyPackageFragment on StudyPackage {\n    id\n    name\n    priceCents\n    studyCount\n    manualCount\n    isImageCount\n    showStudies\n    createdAt\n    modalities\n    bodyPart {\n      id\n      name\n    }\n  }\n": types.StudyPackageListItem_StudyPackageFragmentFragmentDoc,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CurrentUser on User {\n    id\n    email\n    firstName\n    lastName\n    company\n    companyAddress1\n    companyAddress2\n    companyCity\n    companyState\n    companyZip\n    isAdmin\n    __typename\n  }\n"): (typeof documents)["\n  fragment CurrentUser on User {\n    id\n    email\n    firstName\n    lastName\n    company\n    companyAddress1\n    companyAddress2\n    companyCity\n    companyState\n    companyZip\n    isAdmin\n    __typename\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CurrentUserProvider {\n    currentUser {\n      ...CurrentUser\n    }\n  }\n"): (typeof documents)["\n  query CurrentUserProvider {\n    currentUser {\n      ...CurrentUser\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...CurrentUser\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...CurrentUser\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudyScreenContent_StudyFragment on Study {\n    id\n    patientAge\n    sex\n    date\n    modality\n    contactSheetUrl\n    imageCount\n    description\n    reportConclusion\n    purchased\n    seriesDescriptions\n    bodyPart {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment StudyScreenContent_StudyFragment on Study {\n    id\n    patientAge\n    sex\n    date\n    modality\n    contactSheetUrl\n    imageCount\n    description\n    reportConclusion\n    purchased\n    seriesDescriptions\n    bodyPart {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BodyPartFragment on BodyPart {\n    id\n    uid\n    name\n  }\n"): (typeof documents)["\n  fragment BodyPartFragment on BodyPart {\n    id\n    uid\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query BodyParts {\n    bodyParts(first: 1000) {\n      nodes {\n        ...BodyPartFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query BodyParts {\n    bodyParts(first: 1000) {\n      nodes {\n        ...BodyPartFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GanLabelFragment on GanLabel {\n    id\n    uid\n    name\n  }\n"): (typeof documents)["\n  fragment GanLabelFragment on GanLabel {\n    id\n    uid\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GanLabels {\n    ganLabels(first: 1000) {\n      nodes {\n        ...GanLabelFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GanLabels {\n    ganLabels(first: 1000) {\n      nodes {\n        ...GanLabelFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PresignUpload($input: PresignUploadInput!) {\n    presignUpload(input: $input) {\n      url\n      headers\n      signedId\n    }\n  }\n"): (typeof documents)["\n  mutation PresignUpload($input: PresignUploadInput!) {\n    presignUpload(input: $input) {\n      url\n      headers\n      signedId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SearchUpdatedSubscriptionFragment on Search {\n    id\n    isDuplicatingSearch\n    isCreatingResults\n    isUpdatingSelections\n    resultsCount\n  }\n"): (typeof documents)["\n  fragment SearchUpdatedSubscriptionFragment on Search {\n    id\n    isDuplicatingSearch\n    isCreatingResults\n    isUpdatingSelections\n    resultsCount\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription SearchUpdated($id: ID!) {\n    searchUpdated(id: $id) {\n      search {\n        ...SearchUpdatedSubscriptionFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription SearchUpdated($id: ID!) {\n    searchUpdated(id: $id) {\n      search {\n        ...SearchUpdatedSubscriptionFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation BulkUpdateCreate($input: BulkUpdateCreateInput!) {\n    bulkUpdateCreate(input: $input) {\n      bulkUpdate {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BulkUpdateCreate($input: BulkUpdateCreateInput!) {\n    bulkUpdateCreate(input: $input) {\n      bulkUpdate {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminBulkUpdatesScreen_Query($page: Int, $limit: Int) {\n    ...AdminBulkUpdates_QueryFragment\n  }\n"): (typeof documents)["\n  query AdminBulkUpdatesScreen_Query($page: Int, $limit: Int) {\n    ...AdminBulkUpdates_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminBulkUpdateRow_BulkUpdateFragment on BulkUpdate {\n    id\n    number\n    description\n    completedCsvImportAt\n    completedAt\n    createdAt\n    bulkUpdateType\n  }\n"): (typeof documents)["\n  fragment AdminBulkUpdateRow_BulkUpdateFragment on BulkUpdate {\n    id\n    number\n    description\n    completedCsvImportAt\n    completedAt\n    createdAt\n    bulkUpdateType\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminBulkUpdates_QueryFragment on Query {\n    bulkUpdates(page: $page, limit: $limit) {\n      bulkUpdates {\n        edges {\n          node {\n            ...AdminBulkUpdateRow_BulkUpdateFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment AdminBulkUpdates_QueryFragment on Query {\n    bulkUpdates(page: $page, limit: $limit) {\n      bulkUpdates {\n        edges {\n          node {\n            ...AdminBulkUpdateRow_BulkUpdateFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminBulkUpdate_BulkUpdateFragment on BulkUpdate {\n    id\n    number\n    description\n    importedSeriesImportRowsCount\n    completedAt\n    createdAt\n    studyCount\n    importErrorCounts\n    importSkippedCounts\n    csvUrl\n  }\n"): (typeof documents)["\n  fragment AdminBulkUpdate_BulkUpdateFragment on BulkUpdate {\n    id\n    number\n    description\n    importedSeriesImportRowsCount\n    completedAt\n    createdAt\n    studyCount\n    importErrorCounts\n    importSkippedCounts\n    csvUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminStudyArchiveScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminBulkUpdate_BulkUpdateFragment\n    }\n  }\n"): (typeof documents)["\n  query AdminStudyArchiveScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminBulkUpdate_BulkUpdateFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminSeriesImportRowRow_SeriesImportRowFragment on SeriesImportRow {\n    id\n    index\n    accessionNumber\n    patientId\n    studyDescription\n    importErrorMessage\n    importSkippedMessage\n  }\n"): (typeof documents)["\n  fragment AdminSeriesImportRowRow_SeriesImportRowFragment on SeriesImportRow {\n    id\n    index\n    accessionNumber\n    patientId\n    studyDescription\n    importErrorMessage\n    importSkippedMessage\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminSeriesImportRows_QueryFragment on Query {\n    seriesImportRows(page: $page, bulkUpdateId: $bulkUpdateId, limit: $limit) {\n      seriesImportRows {\n        edges {\n          node {\n            ...AdminSeriesImportRowRow_SeriesImportRowFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment AdminSeriesImportRows_QueryFragment on Query {\n    seriesImportRows(page: $page, bulkUpdateId: $bulkUpdateId, limit: $limit) {\n      seriesImportRows {\n        edges {\n          node {\n            ...AdminSeriesImportRowRow_SeriesImportRowFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminBulkUpdateScreen_SeriesImportRowFragment on SeriesImportRow {\n    id\n    accessionNumber\n    studyDescription\n    patientSex\n    patientAge\n    patientAgeStr\n    patientId\n    studyDate\n    magneticFieldStrength\n    modality\n    seriesDescription\n    bodyPartExamined\n    contrastOrBolusAgent\n    index\n    importErrorMessage\n    importSkippedMessage\n    sliceThickness\n    studyId\n    completedImportAt\n    createdAt\n  }\n"): (typeof documents)["\n  fragment AdminBulkUpdateScreen_SeriesImportRowFragment on SeriesImportRow {\n    id\n    accessionNumber\n    studyDescription\n    patientSex\n    patientAge\n    patientAgeStr\n    patientId\n    studyDate\n    magneticFieldStrength\n    modality\n    seriesDescription\n    bodyPartExamined\n    contrastOrBolusAgent\n    index\n    importErrorMessage\n    importSkippedMessage\n    sliceThickness\n    studyId\n    completedImportAt\n    createdAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminBulkUpdateScreen_Query($id: ID!, $page: Int, $bulkUpdateId: ID, $limit: Int) {\n    node(id: $id) {\n      ...AdminBulkUpdate_BulkUpdateFragment\n    }\n    ...AdminSeriesImportRows_QueryFragment\n  }\n"): (typeof documents)["\n  query AdminBulkUpdateScreen_Query($id: ID!, $page: Int, $bulkUpdateId: ID, $limit: Int) {\n    node(id: $id) {\n      ...AdminBulkUpdate_BulkUpdateFragment\n    }\n    ...AdminSeriesImportRows_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation BulkUpdateExportSkipped($input: BulkUpdateExportSkippedInput!) {\n    bulkUpdateExportSkipped(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation BulkUpdateExportSkipped($input: BulkUpdateExportSkippedInput!) {\n    bulkUpdateExportSkipped(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ExportRequestUpdate_Mutation($input: ExportRequestUpdateInput!) {\n    exportRequestUpdate(input: $input) {\n      exportRequest {\n        id\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ExportRequestUpdate_Mutation($input: ExportRequestUpdateInput!) {\n    exportRequestUpdate(input: $input) {\n      exportRequest {\n        id\n        status\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminExportRequestScreen_ExportRequestFragment on ExportRequest {\n    id\n    createdAt\n    sentExportAt\n    status\n    csvUrl\n    search {\n      ...StudySearchResultsSummary_SearchFragment\n    }\n    user {\n      id\n      company\n      name\n      email\n    }\n  }\n"): (typeof documents)["\n  fragment AdminExportRequestScreen_ExportRequestFragment on ExportRequest {\n    id\n    createdAt\n    sentExportAt\n    status\n    csvUrl\n    search {\n      ...StudySearchResultsSummary_SearchFragment\n    }\n    user {\n      id\n      company\n      name\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminExportRequestScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminExportRequestScreen_ExportRequestFragment\n    }\n  }\n"): (typeof documents)["\n  query AdminExportRequestScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminExportRequestScreen_ExportRequestFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminExportRequestsScreen_Query($page: Int, $limit: Int) {\n    ...AdminExportRequests_QueryFragment\n  }\n"): (typeof documents)["\n  query AdminExportRequestsScreen_Query($page: Int, $limit: Int) {\n    ...AdminExportRequests_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminExportRequestRow_ExportRequestFragment on ExportRequest {\n    id\n    createdAt\n    status\n    search {\n      id\n    }\n    user {\n      id\n      company\n      name\n      email\n    }\n  }\n"): (typeof documents)["\n  fragment AdminExportRequestRow_ExportRequestFragment on ExportRequest {\n    id\n    createdAt\n    status\n    search {\n      id\n    }\n    user {\n      id\n      company\n      name\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminExportRequests_QueryFragment on Query {\n    exportRequests(page: $page, limit: $limit) {\n      exportRequests {\n        edges {\n          node {\n            ...AdminExportRequestRow_ExportRequestFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment AdminExportRequests_QueryFragment on Query {\n    exportRequests(page: $page, limit: $limit) {\n      exportRequests {\n        edges {\n          node {\n            ...AdminExportRequestRow_ExportRequestFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminOrderScreen_OrderFragment on Order {\n    id\n    createdAt\n    statusDisplay\n    orderNumber\n    studyCount\n    imageCount\n    estimatedPriceCents\n    priceCents\n    status\n    sentPoDocumentAt\n    sentContractDocumentAt\n    sentOrderStudiesCsvAt\n    deliveredAt\n    contractDocument {\n      filename\n      url\n      kb\n    }\n    defaultContractDocument {\n      filename\n      url\n      kb\n    }\n    poDocument {\n      filename\n      url\n      kb\n    }\n    downloadUrl\n    orderStudiesCsvUrl\n    studyPackage {\n      id\n      name\n    }\n    search {\n      ...StudySearchResultsSummary_SearchFragment\n    }\n    user {\n      id\n      name\n      company\n      email\n    }\n  }\n"): (typeof documents)["\n  fragment AdminOrderScreen_OrderFragment on Order {\n    id\n    createdAt\n    statusDisplay\n    orderNumber\n    studyCount\n    imageCount\n    estimatedPriceCents\n    priceCents\n    status\n    sentPoDocumentAt\n    sentContractDocumentAt\n    sentOrderStudiesCsvAt\n    deliveredAt\n    contractDocument {\n      filename\n      url\n      kb\n    }\n    defaultContractDocument {\n      filename\n      url\n      kb\n    }\n    poDocument {\n      filename\n      url\n      kb\n    }\n    downloadUrl\n    orderStudiesCsvUrl\n    studyPackage {\n      id\n      name\n    }\n    search {\n      ...StudySearchResultsSummary_SearchFragment\n    }\n    user {\n      id\n      name\n      company\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminOrderScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminOrderScreen_OrderFragment\n    }\n  }\n"): (typeof documents)["\n  query AdminOrderScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminOrderScreen_OrderFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation OrderContractDocumentUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        id\n        contractDocument {\n          filename\n          url\n          kb\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OrderContractDocumentUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        id\n        contractDocument {\n          filename\n          url\n          kb\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendContractDocEmail($input: SendContractDocEmailInput!) {\n    sendContractDocEmail(input: $input) {\n      order {\n        id\n        sentContractDocumentAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendContractDocEmail($input: SendContractDocEmailInput!) {\n    sendContractDocEmail(input: $input) {\n      order {\n        id\n        sentContractDocumentAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation OrderDownloadUrlUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        id\n        downloadUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OrderDownloadUrlUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        id\n        downloadUrl\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendOrderDownloadLinkEmail($input: SendOrderDownloadLinkEmailInput!) {\n    sendOrderDownloadLinkEmail(input: $input) {\n      order {\n        id\n        sentOrderStudiesCsvAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendOrderDownloadLinkEmail($input: SendOrderDownloadLinkEmailInput!) {\n    sendOrderDownloadLinkEmail(input: $input) {\n      order {\n        id\n        sentOrderStudiesCsvAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        ...AdminOrderScreen_OrderFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        ...AdminOrderScreen_OrderFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation OrderPoDocumentUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        id\n        poDocument{\n          filename\n          url\n          kb\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OrderPoDocumentUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      order {\n        id\n        poDocument{\n          filename\n          url\n          kb\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendPoDocEmail($input: SendPoDocEmailInput!) {\n    sendPoDocEmail(input: $input) {\n      order {\n        id\n        sentPoDocumentAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendPoDocEmail($input: SendPoDocEmailInput!) {\n    sendPoDocEmail(input: $input) {\n      order {\n        id\n        sentPoDocumentAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminOrdersScreen_Query($page: Int, $status: [String!], $search: String, $userId: ID, $limit: Int) {\n    ...AdminOrders_QueryFragment\n  }\n"): (typeof documents)["\n  query AdminOrdersScreen_Query($page: Int, $status: [String!], $search: String, $userId: ID, $limit: Int) {\n    ...AdminOrders_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminOrderRow_OrderFragment on Order {\n    id\n    orderNumber\n    status\n    statusDisplay\n    studyCount\n    imageCount\n    manualCount\n    isImageCount\n    estimatedPriceCents\n    priceCents\n    createdAt\n    deliveredAt\n    user {\n      id\n      company\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment AdminOrderRow_OrderFragment on Order {\n    id\n    orderNumber\n    status\n    statusDisplay\n    studyCount\n    imageCount\n    manualCount\n    isImageCount\n    estimatedPriceCents\n    priceCents\n    createdAt\n    deliveredAt\n    user {\n      id\n      company\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminOrders_QueryFragment on Query {\n    orders(page: $page, status: $status, search: $search, userId: $userId, limit: $limit) {\n      orders {\n        edges {\n          node {\n            ...AdminOrderRow_OrderFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment AdminOrders_QueryFragment on Query {\n    orders(page: $page, status: $status, search: $search, userId: $userId, limit: $limit) {\n      orders {\n        edges {\n          node {\n            ...AdminOrderRow_OrderFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminStudiesScreen_Query($recent: Boolean, $archived: Boolean, $page: Int, $limit: Int) {\n    ...AdminStudies_QueryFragment\n  }\n"): (typeof documents)["\n  query AdminStudiesScreen_Query($recent: Boolean, $archived: Boolean, $page: Int, $limit: Int) {\n    ...AdminStudies_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminStudies_QueryFragment on Query {\n    studies(recent: $recent, archived: $archived, page: $page, limit: $limit) {\n      studies {\n        edges {\n          node {\n            ...AdminStudyRow_StudyFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment AdminStudies_QueryFragment on Query {\n    studies(recent: $recent, archived: $archived, page: $page, limit: $limit) {\n      studies {\n        edges {\n          node {\n            ...AdminStudyRow_StudyFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminStudyRow_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    description\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    reportConclusion\n    mrn\n    accession\n    sliceThicknessMin\n    contrast\n    archived\n  }\n"): (typeof documents)["\n  fragment AdminStudyRow_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    description\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    reportConclusion\n    mrn\n    accession\n    sliceThicknessMin\n    contrast\n    archived\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminStudyScreen_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    description\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    report\n    reportConclusion\n    mrn\n    accession\n    sliceThicknessMin\n    contrast\n    bulkUpdateId\n    seriesDescriptions\n    seriesStrengths\n  }\n"): (typeof documents)["\n  fragment AdminStudyScreen_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    description\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    report\n    reportConclusion\n    mrn\n    accession\n    sliceThicknessMin\n    contrast\n    bulkUpdateId\n    seriesDescriptions\n    seriesStrengths\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminStudyScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminStudyScreen_StudyFragment\n    }\n  }\n"): (typeof documents)["\n  query AdminStudyScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminStudyScreen_StudyFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminEditStudyPackageScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminStudyPackageScreen_StudyPackageFragment\n    }\n  }\n"): (typeof documents)["\n  query AdminEditStudyPackageScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminStudyPackageScreen_StudyPackageFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation StudyPackageUpdate($input: StudyPackageUpdateInput!) {\n    studyPackageUpdate(input: $input) {\n      studyPackage {\n        id\n        ...AdminStudyPackageScreen_StudyPackageFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation StudyPackageUpdate($input: StudyPackageUpdateInput!) {\n    studyPackageUpdate(input: $input) {\n      studyPackage {\n        id\n        ...AdminStudyPackageScreen_StudyPackageFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation StudyPackageCreate($input: StudyPackageCreateInput!) {\n    studyPackageCreate(input: $input) {\n      studyPackage {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation StudyPackageCreate($input: StudyPackageCreateInput!) {\n    studyPackageCreate(input: $input) {\n      studyPackage {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminStudyPackageScreen_StudyPackageFragment on StudyPackage {\n    id\n    name\n    priceCents\n    studyCount\n    manualCount\n    isImageCount\n    createdAt\n    modalities\n    featured\n    published\n    studiesCsvUrl\n    description\n    showStudies\n    bodyPart {\n      id\n      name\n      uid\n    }\n  }\n"): (typeof documents)["\n  fragment AdminStudyPackageScreen_StudyPackageFragment on StudyPackage {\n    id\n    name\n    priceCents\n    studyCount\n    manualCount\n    isImageCount\n    createdAt\n    modalities\n    featured\n    published\n    studiesCsvUrl\n    description\n    showStudies\n    bodyPart {\n      id\n      name\n      uid\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminStudyPackageScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminStudyPackageScreen_StudyPackageFragment\n    }\n  }\n"): (typeof documents)["\n  query AdminStudyPackageScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminStudyPackageScreen_StudyPackageFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminStudyPackagesScreen_Query($page: Int, $limit: Int) {\n    ...AdminStudyPackages_QueryFragment\n  }\n"): (typeof documents)["\n  query AdminStudyPackagesScreen_Query($page: Int, $limit: Int) {\n    ...AdminStudyPackages_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminStudyPackageRow_StudyPackageFragment on StudyPackage {\n    id\n    name\n    priceCents\n    studyCount\n    manualCount\n    isImageCount\n    showStudies\n    createdAt\n    modalities\n    featured\n    bodyPart {\n      id\n      name\n      uid\n    }\n  }\n"): (typeof documents)["\n  fragment AdminStudyPackageRow_StudyPackageFragment on StudyPackage {\n    id\n    name\n    priceCents\n    studyCount\n    manualCount\n    isImageCount\n    showStudies\n    createdAt\n    modalities\n    featured\n    bodyPart {\n      id\n      name\n      uid\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminStudyPackages_QueryFragment on Query {\n    studyPackages(includeUnpublished: true, page: $page, limit: $limit) {\n      studyPackages {\n        edges {\n          node {\n            ...AdminStudyPackageRow_StudyPackageFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment AdminStudyPackages_QueryFragment on Query {\n    studyPackages(includeUnpublished: true, page: $page, limit: $limit) {\n      studyPackages {\n        edges {\n          node {\n            ...AdminStudyPackageRow_StudyPackageFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminEditUserScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminUserScreen_UserFragment\n    }\n  }\n"): (typeof documents)["\n  query AdminEditUserScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...AdminUserScreen_UserFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminUserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...AdminUserScreen_UserFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminUserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...AdminUserScreen_UserFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminUserScreen_UserFragment on User {\n    id\n    name\n    firstName\n    lastName\n    email\n    company\n    companyAddress1\n    companyAddress2\n    companyCity\n    companyState\n    companyZip\n    isAdmin\n    updatedAt\n    createdAt\n  }\n"): (typeof documents)["\n  fragment AdminUserScreen_UserFragment on User {\n    id\n    name\n    firstName\n    lastName\n    email\n    company\n    companyAddress1\n    companyAddress2\n    companyCity\n    companyState\n    companyZip\n    isAdmin\n    updatedAt\n    createdAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUserScreen_Query($id: ID!, $page: Int, $status: [String!], $search: String, $userId: ID, $limit: Int) {\n    node(id: $id) {\n      ...AdminUserScreen_UserFragment\n    }\n    ...AdminOrders_QueryFragment\n  }\n"): (typeof documents)["\n  query AdminUserScreen_Query($id: ID!, $page: Int, $status: [String!], $search: String, $userId: ID, $limit: Int) {\n    node(id: $id) {\n      ...AdminUserScreen_UserFragment\n    }\n    ...AdminOrders_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUsersScreen_Query($admin: Boolean, $page: Int, $search: String, $limit: Int) {\n    ...AdminUsers_QueryFragment\n  }\n"): (typeof documents)["\n  query AdminUsersScreen_Query($admin: Boolean, $page: Int, $search: String, $limit: Int) {\n    ...AdminUsers_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminUserRow_UserFragment on User {\n    id\n    name\n    email\n    company\n    createdAt\n  }\n"): (typeof documents)["\n  fragment AdminUserRow_UserFragment on User {\n    id\n    name\n    email\n    company\n    createdAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminUsers_QueryFragment on Query {\n    users(admin: $admin, page: $page, search: $search, limit: $limit) {\n      users {\n        edges {\n          node {\n            ...AdminUserRow_UserFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment AdminUsers_QueryFragment on Query {\n    users(admin: $admin, page: $page, search: $search, limit: $limit) {\n      users {\n        edges {\n          node {\n            ...AdminUserRow_UserFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation OrderArchive($input: OrderArchiveInput!) {\n    orderArchive(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OrderArchive($input: OrderArchiveInput!) {\n    orderArchive(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query DashboardScreen_Query($userId: ID!) {\n    ...DashboardStudyPackages_QueryFragment\n    ...DashboardSearches_QueryFragment\n    ...DashboardOrders_QueryFragment\n  }\n"): (typeof documents)["\n  query DashboardScreen_Query($userId: ID!) {\n    ...DashboardStudyPackages_QueryFragment\n    ...DashboardSearches_QueryFragment\n    ...DashboardOrders_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DashboardOrders_QueryFragment on Query {\n    orders(userId: $userId, limit: 21) {\n      orders {\n        edges {\n          node {\n            ...OrderRow_OrderFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment DashboardOrders_QueryFragment on Query {\n    orders(userId: $userId, limit: 21) {\n      orders {\n        edges {\n          node {\n            ...OrderRow_OrderFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DashboardStudyPackages_QueryFragment on Query {\n    studyPackages(sortFeatured: true, limit: 3) {\n      studyPackages {\n        edges {\n          node {\n            ...StudyPackageListItem_StudyPackageFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment DashboardStudyPackages_QueryFragment on Query {\n    studyPackages(sortFeatured: true, limit: 3) {\n      studyPackages {\n        edges {\n          node {\n            ...StudyPackageListItem_StudyPackageFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DashboardSearches_QueryFragment on Query {\n    searches(limit: 21) {\n      searches {\n        edges {\n          node {\n            ...SearchRow_SearchFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment DashboardSearches_QueryFragment on Query {\n    searches(limit: 21) {\n      searches {\n        edges {\n          node {\n            ...SearchRow_SearchFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ForgotPassword($input: ForgotPasswordInput!) {\n    forgotPassword(input: $input) {\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation ForgotPassword($input: ForgotPasswordInput!) {\n    forgotPassword(input: $input) {\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrderScreen_OrderFragment on Order {\n    id\n    createdAt\n    statusDisplay\n    orderNumber\n    studyCount\n    imageCount\n    estimatedPriceCents\n    priceCents\n    downloadUrl\n    contractDocument {\n      filename\n      url\n      kb\n    }\n    defaultContractDocument {\n      filename\n      url\n      kb\n    }\n    poDocument {\n      filename\n      url\n      kb\n    }\n  }\n"): (typeof documents)["\n  fragment OrderScreen_OrderFragment on Order {\n    id\n    createdAt\n    statusDisplay\n    orderNumber\n    studyCount\n    imageCount\n    estimatedPriceCents\n    priceCents\n    downloadUrl\n    contractDocument {\n      filename\n      url\n      kb\n    }\n    defaultContractDocument {\n      filename\n      url\n      kb\n    }\n    poDocument {\n      filename\n      url\n      kb\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrderScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...OrderScreen_OrderFragment\n    }\n  }\n"): (typeof documents)["\n  query OrderScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...OrderScreen_OrderFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrderSuccessScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...OrderSuccessScreenOrder\n    }\n  }\n"): (typeof documents)["\n  query OrderSuccessScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...OrderSuccessScreenOrder\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrderSuccessScreenOrder on Order {\n    id\n    orderNumber\n    createdAt\n  }\n"): (typeof documents)["\n  fragment OrderSuccessScreenOrder on Order {\n    id\n    orderNumber\n    createdAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrdersScreen_Query($userId: ID!, $page: Int, $limit: Int) {\n    ...Orders_QueryFragment\n  }\n"): (typeof documents)["\n  query OrdersScreen_Query($userId: ID!, $page: Int, $limit: Int) {\n    ...Orders_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrderRow_OrderFragment on Order {\n    id\n    orderNumber\n    status\n    statusDisplay\n    studyCount\n    imageCount\n    manualCount\n    isImageCount\n    estimatedPriceCents\n    priceCents\n    createdAt\n    isCreatingStudies\n  }\n"): (typeof documents)["\n  fragment OrderRow_OrderFragment on Order {\n    id\n    orderNumber\n    status\n    statusDisplay\n    studyCount\n    imageCount\n    manualCount\n    isImageCount\n    estimatedPriceCents\n    priceCents\n    createdAt\n    isCreatingStudies\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Orders_QueryFragment on Query {\n    orders(userId: $userId, page: $page, limit: $limit) {\n      orders {\n        edges {\n          node {\n            ...OrderRow_OrderFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment Orders_QueryFragment on Query {\n    orders(userId: $userId, page: $page, limit: $limit) {\n      orders {\n        edges {\n          node {\n            ...OrderRow_OrderFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ImageSearchResultsScreen_Query($id: ID!) {\n    search: node(id: $id) {\n      ...ImageSearchResultsSummary_SearchFragment\n    }\n  }\n"): (typeof documents)["\n  query ImageSearchResultsScreen_Query($id: ID!) {\n    search: node(id: $id) {\n      ...ImageSearchResultsSummary_SearchFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ImageSearchResultsGanLabel_Query($id: ID!) {\n    ganLabel(id: $id) {\n      ...ImageSearchResultsSummary_GanLabelFragment\n    }\n  }\n"): (typeof documents)["\n  query ImageSearchResultsGanLabel_Query($id: ID!) {\n    ganLabel(id: $id) {\n      ...ImageSearchResultsSummary_GanLabelFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ImageSearchResultsSummary_SearchFragment on Search {\n    id\n    imageSearchParams {\n      id\n      imageCount\n      ganLabel {\n        id\n        uid\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ImageSearchResultsSummary_SearchFragment on Search {\n    id\n    imageSearchParams {\n      id\n      imageCount\n      ganLabel {\n        id\n        uid\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ImageSearchResultsSummary_GanLabelFragment on GanLabel {\n    id\n    name\n  }\n"): (typeof documents)["\n  fragment ImageSearchResultsSummary_GanLabelFragment on GanLabel {\n    id\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ImageSearchCreate($input: ImageSearchCreateInput!) {\n    imageSearchCreate(input: $input) {\n      search {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ImageSearchCreate($input: ImageSearchCreateInput!) {\n    imageSearchCreate(input: $input) {\n      search {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchFormScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudySearchResultsSummary_SearchFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchFormScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudySearchResultsSummary_SearchFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ImageSearchUpdate($input: ImageSearchUpdateInput!) {\n    imageSearchUpdate(input: $input) {\n      search {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ImageSearchUpdate($input: ImageSearchUpdateInput!) {\n    imageSearchUpdate(input: $input) {\n      search {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SearchUpdate($input: SearchUpdateInput!) {\n    searchUpdate(input: $input) {\n      search {\n        ...StudySearchResultsSummary_SearchFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SearchUpdate($input: SearchUpdateInput!) {\n    searchUpdate(input: $input) {\n      search {\n        ...StudySearchResultsSummary_SearchFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudySearchResultsScreen_Query($id: ID!, $searchParams: StudySearchParamsInput, $page: Int, $limit: Int) {\n    ...StudySearchResultsSummary_QueryFragment\n  }\n"): (typeof documents)["\n  query StudySearchResultsScreen_Query($id: ID!, $searchParams: StudySearchParamsInput, $page: Int, $limit: Int) {\n    ...StudySearchResultsSummary_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SearchStudySelectionUpdate($input: SearchStudySelectionUpdateInput!) {\n    searchStudySelectionUpdate(input: $input) {\n      search {\n        ...StudySearchResultsSummary_SearchFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SearchStudySelectionUpdate($input: SearchStudySelectionUpdateInput!) {\n    searchStudySelectionUpdate(input: $input) {\n      search {\n        ...StudySearchResultsSummary_SearchFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation StudyOrderCreate($input: StudyOrderCreateInput!) {\n    studyOrderCreate(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation StudyOrderCreate($input: StudyOrderCreateInput!) {\n    studyOrderCreate(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SearchCreate($input: SearchCreateInput!) {\n    searchCreate(input: $input) {\n      search {\n        id\n        name\n        isDuplicatingSearch\n        isCreatingResults\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SearchCreate($input: SearchCreateInput!) {\n    searchCreate(input: $input) {\n      search {\n        id\n        name\n        isDuplicatingSearch\n        isCreatingResults\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ExportRequestCreate($input: ExportRequestCreateInput!) {\n    exportRequestCreate(input: $input) {\n      search {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ExportRequestCreate($input: ExportRequestCreateInput!) {\n    exportRequestCreate(input: $input) {\n      search {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudyResultsRow_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    description\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    reportConclusion\n  }\n"): (typeof documents)["\n  fragment StudyResultsRow_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    description\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    reportConclusion\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudySearchResultsSummary_SearchParamsFragment on StudySearchParams {\n    id\n    patientAgeMin\n    patientAgeMax\n    sex\n    mr\n    mrStrength\n    mrContrast\n    mrReportKeywords\n    mrDescriptionKeywords\n    mrSeriesDescriptionKeywords\n    ct\n    ctContrast\n    ctReportKeywords\n    ctDescriptionKeywords\n    ctSeriesDescriptionKeywords\n    us\n    usReportKeywords\n    usDescriptionKeywords\n    usSeriesDescriptionKeywords\n    xr\n    xrReportKeywords\n    xrDescriptionKeywords\n    xrSeriesDescriptionKeywords\n  }\n"): (typeof documents)["\n  fragment StudySearchResultsSummary_SearchParamsFragment on StudySearchParams {\n    id\n    patientAgeMin\n    patientAgeMax\n    sex\n    mr\n    mrStrength\n    mrContrast\n    mrReportKeywords\n    mrDescriptionKeywords\n    mrSeriesDescriptionKeywords\n    ct\n    ctContrast\n    ctReportKeywords\n    ctDescriptionKeywords\n    ctSeriesDescriptionKeywords\n    us\n    usReportKeywords\n    usDescriptionKeywords\n    usSeriesDescriptionKeywords\n    xr\n    xrReportKeywords\n    xrDescriptionKeywords\n    xrSeriesDescriptionKeywords\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudySearchResultsSummary_SearchFragment on Search {\n    id\n    name\n    studyIdsDiff\n    selectAll\n    isDuplicatingSearch\n    isCreatingResults\n    isUpdatingSelections\n    resultsCount\n    studySearchParams {\n      ...StudySearchResultsSummary_SearchParamsFragment\n    }\n    imageSearchParams {\n      id\n      imageCount\n      ganLabel {\n        id\n        uid\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment StudySearchResultsSummary_SearchFragment on Search {\n    id\n    name\n    studyIdsDiff\n    selectAll\n    isDuplicatingSearch\n    isCreatingResults\n    isUpdatingSelections\n    resultsCount\n    studySearchParams {\n      ...StudySearchResultsSummary_SearchParamsFragment\n    }\n    imageSearchParams {\n      id\n      imageCount\n      ganLabel {\n        id\n        uid\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudySearchResultsSummary_QueryFragment on Query {\n    searchResults(id: $id, searchParams: $searchParams, page: $page, limit: $limit) {\n      studies {\n        edges {\n          node {\n            ...StudyResultsRow_StudyFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      search {\n        ...StudySearchResultsSummary_SearchFragment\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment StudySearchResultsSummary_QueryFragment on Query {\n    searchResults(id: $id, searchParams: $searchParams, page: $page, limit: $limit) {\n      studies {\n        edges {\n          node {\n            ...StudyResultsRow_StudyFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      search {\n        ...StudySearchResultsSummary_SearchFragment\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchResultScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudyScreenContent_StudyFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchResultScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudyScreenContent_StudyFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchesScreen_Query($page: Int, $limit: Int) {\n    ...Searches_QueryFragment\n  }\n"): (typeof documents)["\n  query SearchesScreen_Query($page: Int, $limit: Int) {\n    ...Searches_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SearchRename($input: SearchRenameInput!) {\n    searchRename(input: $input) {\n      search {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SearchRename($input: SearchRenameInput!) {\n    searchRename(input: $input) {\n      search {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SearchRow_SearchFragment on Search {\n    id\n    name\n    createdAt\n    resultsCount\n    modalities\n    isDuplicatingSearch\n    isCreatingResults\n    studySearchParams {\n      ...StudySearchResultsSummary_SearchParamsFragment\n    }\n    imageSearchParams {\n      id\n      imageCount\n    }\n  }\n"): (typeof documents)["\n  fragment SearchRow_SearchFragment on Search {\n    id\n    name\n    createdAt\n    resultsCount\n    modalities\n    isDuplicatingSearch\n    isCreatingResults\n    studySearchParams {\n      ...StudySearchResultsSummary_SearchParamsFragment\n    }\n    imageSearchParams {\n      id\n      imageCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SearchDuplicate($input: SearchDuplicateInput!) {\n    searchDuplicate(input: $input) {\n      search {\n        ...SearchRow_SearchFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SearchDuplicate($input: SearchDuplicateInput!) {\n    searchDuplicate(input: $input) {\n      search {\n        ...SearchRow_SearchFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SearchArchive($input: SearchArchiveInput!) {\n    searchArchive(input: $input) {\n      search {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SearchArchive($input: SearchArchiveInput!) {\n    searchArchive(input: $input) {\n      search {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Searches_QueryFragment on Query {\n    searches(page: $page, limit: $limit) {\n      searches {\n        edges {\n          node {\n            ...SearchRow_SearchFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment Searches_QueryFragment on Query {\n    searches(page: $page, limit: $limit) {\n      searches {\n        edges {\n          node {\n            ...SearchRow_SearchFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ImageOrderCreate($input: ImageOrderCreateInput!) {\n    imageOrderCreate(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ImageOrderCreate($input: ImageOrderCreateInput!) {\n    imageOrderCreate(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudyPackageNewOrder_StudyPackageFragment on StudyPackage {\n    id\n    studyCount\n    showStudies\n    isImageCount\n    manualCount\n    priceCents\n  }\n"): (typeof documents)["\n  fragment StudyPackageNewOrder_StudyPackageFragment on StudyPackage {\n    id\n    studyCount\n    showStudies\n    isImageCount\n    manualCount\n    priceCents\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudyPackageNewOrderScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudyPackageNewOrder_StudyPackageFragment\n    }\n  }\n"): (typeof documents)["\n  query StudyPackageNewOrderScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudyPackageNewOrder_StudyPackageFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PackageOrderCreate($input: PackageOrderCreateInput!) {\n    packageOrderCreate(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation PackageOrderCreate($input: PackageOrderCreateInput!) {\n    packageOrderCreate(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudyPackageScreen_StudyPackageFragment on StudyPackage {\n    id\n    name\n    manualCount\n    studyCount\n    showStudies\n    isImageCount\n    priceCents\n    modalities\n    description\n    bodyPart {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment StudyPackageScreen_StudyPackageFragment on StudyPackage {\n    id\n    name\n    manualCount\n    studyCount\n    showStudies\n    isImageCount\n    priceCents\n    modalities\n    description\n    bodyPart {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudyPackageScreen_Query($id: ID!, $page: Int, $limit: Int) {\n    node(id: $id) {\n      ...StudyPackageScreen_StudyPackageFragment\n    }\n    ...StudyPackageStudies_QueryFragment\n  }\n"): (typeof documents)["\n  query StudyPackageScreen_Query($id: ID!, $page: Int, $limit: Int) {\n    node(id: $id) {\n      ...StudyPackageScreen_StudyPackageFragment\n    }\n    ...StudyPackageStudies_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudyPackageStudies_QueryFragment on Query {\n    studies(studyPackageId: $id, page: $page, limit: $limit) {\n      studies {\n        edges {\n          node {\n            ...StudyPackageStudyRow_StudyFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment StudyPackageStudies_QueryFragment on Query {\n    studies(studyPackageId: $id, page: $page, limit: $limit) {\n      studies {\n        edges {\n          node {\n            ...StudyPackageStudyRow_StudyFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudyPackageStudyRow_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    description\n    reportConclusion\n  }\n"): (typeof documents)["\n  fragment StudyPackageStudyRow_StudyFragment on Study {\n    id\n    uid\n    patientAge\n    sex\n    date\n    modality\n    contactSheetUrl\n    purchased\n    description\n    reportConclusion\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudyPackageStudyScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudyScreenContent_StudyFragment\n    }\n  }\n"): (typeof documents)["\n  query StudyPackageStudyScreen_Query($id: ID!) {\n    node(id: $id) {\n      ...StudyScreenContent_StudyFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudyPackagesScreen_Query($modalities: [ModalityEnum!], $bodyPartIds: [ID!], $featured: Boolean, $page: Int, $limit: Int) {\n    ...StudyPackagesList_QueryFragment\n  }\n"): (typeof documents)["\n  query StudyPackagesScreen_Query($modalities: [ModalityEnum!], $bodyPartIds: [ID!], $featured: Boolean, $page: Int, $limit: Int) {\n    ...StudyPackagesList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudyPackagesList_QueryFragment on Query {\n    studyPackages(modalities: $modalities, bodyPartIds: $bodyPartIds, featured: $featured, page: $page, limit: $limit) {\n      studyPackages {\n        edges {\n          node {\n            ...StudyPackageListItem_StudyPackageFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"): (typeof documents)["\n  fragment StudyPackagesList_QueryFragment on Query {\n    studyPackages(modalities: $modalities, bodyPartIds: $bodyPartIds, featured: $featured, page: $page, limit: $limit) {\n      studyPackages {\n        edges {\n          node {\n            ...StudyPackageListItem_StudyPackageFragment\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n      page\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StudyPackageListItem_StudyPackageFragment on StudyPackage {\n    id\n    name\n    priceCents\n    studyCount\n    manualCount\n    isImageCount\n    showStudies\n    createdAt\n    modalities\n    bodyPart {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment StudyPackageListItem_StudyPackageFragment on StudyPackage {\n    id\n    name\n    priceCents\n    studyCount\n    manualCount\n    isImageCount\n    showStudies\n    createdAt\n    modalities\n    bodyPart {\n      id\n      name\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;