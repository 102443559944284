import clsx from "clsx"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import { ORDERS_LIMIT } from "../OrdersIndexScreen"
import OrderRow, { OrderRow_OrderFragment } from "./OrderRow"

export const Orders_QueryFragment = gql(`
  fragment Orders_QueryFragment on Query {
    orders(userId: $userId, page: $page, limit: $limit) {
      orders {
        edges {
          node {
            ...OrderRow_OrderFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const OrdersTable = ({
  orders,
  totalCount,
  page,
  loading,
}: {
  orders: FragmentType<typeof OrderRow_OrderFragment>[]
  totalCount: number
  page: number
  loading: boolean
}) => {
  const totalPages = Math.ceil(totalCount / ORDERS_LIMIT)

  return (
    <div
      className={clsx("flex flex-col gap-6 text-base", {
        "animate-pulse opacity-90": loading,
      })}
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px]">Order Number</TableHead>
            <TableHead className="min-w-[120px]">Status</TableHead>
            <TableHead className="min-w-[120px]">Items Found</TableHead>
            <TableHead className="min-w-[158px]">Price</TableHead>
            <TableHead className="min-w-[120px]">Order Date</TableHead>
            <TableHead className="min-w-[204px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {orders.map((order) => (
            <OrderRow
              key={getFragmentData(OrderRow_OrderFragment, order).id}
              data={order}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination page={page} totalPages={totalPages} />
    </div>
  )
}

export default OrdersTable
