import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import * as Yup from "yup"
import { gql } from "~/__generated__"
import { BulkUpdateTypeEnum } from "~/__generated__/graphql"
import Button from "~/ui/Button"
import Container from "~/ui/Container"
import FieldGroup from "~/ui/FieldGroup"
import { FieldLabelRequired } from "~/ui/FieldLabelContainer"
import FormActions from "~/ui/FormActions"
import FormikField from "~/ui/FormikField"

export const BULK_UPDATE_CREATE_MUTATION = gql(`
  mutation BulkUpdateCreate($input: BulkUpdateCreateInput!) {
    bulkUpdateCreate(input: $input) {
      bulkUpdate {
        id
      }
    }
  }
`)

export type BulkUpdateFormValues = {
  description: string
  csv: string
}

export const adminBulkUpdateValidationSchema = Yup.object({
  description: Yup.string(),
  csv: Yup.string().required("CSV is required"),
})

const AdminBulkUpdateForm = ({
  onSubmit,
  initialValues,
  csvUrl,
  type,
}: {
  onSubmit: (
    values: BulkUpdateFormValues,
    { setFieldError }: FormikHelpers<BulkUpdateFormValues>
  ) => void
  initialValues: BulkUpdateFormValues
  csvUrl?: string
  type?: BulkUpdateTypeEnum
}) => {
  return (
    <Container size="md">
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={adminBulkUpdateValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }: FormikProps<BulkUpdateFormValues>) => (
          <Form className="w-full">
            <FieldGroup>
              <FormikField
                name="csv"
                type="file"
                label={
                  <div className="mb-1">
                    <div>
                      CSV
                      <FieldLabelRequired />
                    </div>
                    {type === BulkUpdateTypeEnum.StudyArchive && (
                      <div className="text-xs text-neutral-500">
                        Archives all studies included in the CSV. Required
                        headers: Accession Number, MRN
                      </div>
                    )}
                  </div>
                }
                uploadedFileUrl={csvUrl}
              />
              <FormikField
                name="description"
                label="Description"
                placeholder="Description"
                type="textarea"
              />
            </FieldGroup>
            <div className="text-green-500 mt-4 text-sm font-semibold">
              * Upload contact sheet images and report text files to S3 before
              starting the import
            </div>
            <FormActions>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </FormActions>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default AdminBulkUpdateForm
