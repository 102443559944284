import { useMutation, useQuery } from "@apollo/client"
import clsx from "clsx"
import { ChevronLeft } from "lucide-react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { ExportRequestStatusEnum } from "~/__generated__/graphql"
import { dateTime } from "~/common/dates"
import { adminExportRequestsPath } from "~/common/paths"
import DateTimeCell from "~/components/DateTimeCell"
import StudySearchResultsAppliedSeriesFilters from "~/screens/SearchScreen/screens/StudySearchResultsScreen/components/StudySearchResultsAppliedSeriesFilters"
import StudySearchResultsAppliedStudyFilters from "~/screens/SearchScreen/screens/StudySearchResultsScreen/components/StudySearchResultsAppliedStudyFilters"
import Button from "~/ui/Button"
import { TD, TH, Table } from "~/ui/DetailTable"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import { H1, H3 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import Loading from "~/ui/Loading"

const ExportRequestUpdate_Mutation = gql(`
  mutation ExportRequestUpdate_Mutation($input: ExportRequestUpdateInput!) {
    exportRequestUpdate(input: $input) {
      exportRequest {
        id
        status
      }
    }
  }
`)

export const AdminExportRequestScreen_ExportRequestFragment = gql(`
  fragment AdminExportRequestScreen_ExportRequestFragment on ExportRequest {
    id
    createdAt
    sentExportAt
    status
    csvUrl
    search {
      ...StudySearchResultsSummary_SearchFragment
    }
    user {
      id
      company
      name
      email
    }
  }
`)

const AdminExportRequestScreen_Query = gql(`
  query AdminExportRequestScreen_Query($id: ID!) {
    node(id: $id) {
      ...AdminExportRequestScreen_ExportRequestFragment
    }
  }
`)

const AdminExportRequestScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")

  const [mutate, { loading: mutateLoading }] = useMutation(
    ExportRequestUpdate_Mutation
  )
  const { data, loading, error } = useQuery(AdminExportRequestScreen_Query, {
    variables: {
      id: id,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "ExportRequest")
    return <Error error={error} />

  const exportRequest = getFragmentData(
    AdminExportRequestScreen_ExportRequestFragment,
    data.node
  )
  const backPath = adminExportRequestsPath.pattern

  const updateStatus = async (status: ExportRequestStatusEnum) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure?") !== true) {
      return
    }

    try {
      mutate({
        variables: {
          input: {
            id: exportRequest.id,
            exportRequestInput: {
              status,
            },
          },
        },
      })
    } catch (error) {
      console.error(error)
      toast.error("Export request update failed")
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <div
        className={clsx(
          "flex flex-col gap-4",
          "lg:flex-row lg:items-center lg:justify-between"
        )}
      >
        <div className="flex items-center">
          <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
            <ChevronLeft size={28} />
          </Link>
          <H1 margin={false}>Export Request</H1>
        </div>
        <div className="flex items-center gap-4">
          <Button
            variant="outlineSecondary"
            onClick={() => updateStatus(ExportRequestStatusEnum.Denied)}
            disabled={
              mutateLoading ||
              exportRequest.status === ExportRequestStatusEnum.Denied
            }
          >
            Deny
          </Button>
          <Button
            onClick={() => updateStatus(ExportRequestStatusEnum.Approved)}
            disabled={mutateLoading}
          >
            {exportRequest.status === ExportRequestStatusEnum.Approved
              ? "Resend"
              : "Approve"}
          </Button>
        </div>
      </div>
      <HR padding={false} />
      <H3 margin={false}>Query Details</H3>
      {exportRequest.search && (
        <>
          <StudySearchResultsAppliedStudyFilters
            search={exportRequest.search}
          />
          <StudySearchResultsAppliedSeriesFilters
            search={exportRequest.search}
            admin={true}
          />
        </>
      )}
      <HR padding={false} />
      <H3 margin={false}>Export Request Details</H3>

      <Table>
        <thead>
          <tr>
            <TH colSpan={2}>Details</TH>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TD>Name</TD>
            <TD>{exportRequest.user.name}</TD>
          </tr>
          <tr>
            <TD>Status</TD>
            <TD>
              <span className="capitalize">{exportRequest.status}</span>
            </TD>
          </tr>
          <tr>
            <TD>Sent at</TD>
            <TD>
              {exportRequest.sentExportAt &&
                dateTime(new Date(exportRequest.sentExportAt))}
            </TD>
          </tr>
          <tr>
            <TD>Email</TD>
            <TD>
              <a href={`mailto:${exportRequest.user.email}`}>
                {exportRequest.user.email}
              </a>
            </TD>
          </tr>
          <tr>
            <TD>Company</TD>
            <TD>{exportRequest.user.company}</TD>
          </tr>
          <tr>
            <TD>Date</TD>
            <TD>
              <DateTimeCell date={exportRequest.createdAt} />
            </TD>
          </tr>
          <tr>
            <TD>CSV</TD>
            <TD>
              {!!exportRequest.csvUrl && (
                <Link to={exportRequest.csvUrl} target="_blank" variant="blue">
                  Download
                </Link>
              )}
            </TD>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default AdminExportRequestScreen
