const LoggedOutAccountFooter = ({
  action,
  children,
}:
  | { action: string; children?: undefined }
  | { action?: undefined; children: React.ReactNode }) => {
  return (
    <div className="mx-auto mb-8 max-w-[260px] text-center text-2xs leading-4 text-white opacity-[0.5]">
      {children || `By clicking “${action}” above, you agree to RadImageNet's`}{" "}
      <span className="underline">Terms & Conditions</span> and{" "}
      <span className="underline">Privacy Policy</span>.
    </div>
  )
}

export default LoggedOutAccountFooter
