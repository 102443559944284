import { useMutation } from "@apollo/client"
import clsx from "clsx"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import UploadButton from "~/components/UploadButton"
import ReceiptSvg from "~/images/icons/receipt.svg"
import Container from "~/ui/Container"
import { Link } from "~/ui/Link"
import AdminOrderDownloadSection from "./AdminOrderDownloadSection"
import AdminOrderSendEmailButton from "./AdminOrderSendEmailButton"

const OrderUpdate_Mutation = gql(`
  mutation OrderContractDocumentUpdate($input: OrderUpdateInput!) {
    orderUpdate(input: $input) {
      order {
        id
        contractDocument {
          filename
          url
          kb
        }
      }
    }
  }
`)

const SendContractEmailDoc_Mutation = gql(`
  mutation SendContractDocEmail($input: SendContractDocEmailInput!) {
    sendContractDocEmail(input: $input) {
      order {
        id
        sentContractDocumentAt
      }
    }
  }
`)

const AdminOrderContractDocument = ({
  orderId,
  sentAt,
  contractDocumentUrl,
  defaultContractDocumentUrl,
}: {
  orderId: string
  sentAt?: string
  contractDocumentUrl?: string
  defaultContractDocumentUrl?: string
}) => {
  const [mutate, { loading }] = useMutation(OrderUpdate_Mutation)
  const [sendContractDocEmail, { loading: sendContractDocEmailLoading }] =
    useMutation(SendContractEmailDoc_Mutation, {
      onCompleted: () => {
        toast.success("Contract has been sent")
      },
      onError: (error) => {
        console.error(error)
        toast.error("Contract has not been sent")
      },
    })

  const onFileUploadSuccess = (signedId: string) => {
    mutate({
      variables: {
        input: {
          id: orderId,
          orderInput: {
            contractDocument: signedId,
          },
        },
      },
      onCompleted: () => {
        toast.success("Contract Document has been uploaded")
      },
      onError: (error) => {
        console.error(error)
        toast.error("Contract Document upload has failed")
      },
    })
  }

  return (
    <Container size="lg">
      <AdminOrderDownloadSection title="Contract Document" sentAt={sentAt}>
        <div
          className={clsx("flex flex-col justify-between gap-4", "lg:flex-row")}
        >
          <div className="flex items-center gap-6">
            <UploadButton loading={loading} onSuccess={onFileUploadSuccess} />

            {!!contractDocumentUrl && (
              <Link to={contractDocumentUrl} variant="blue" className="text-sm">
                Download Document
              </Link>
            )}

            {defaultContractDocumentUrl && (
              <Link
                to={defaultContractDocumentUrl}
                variant="blue"
                className="text-sm"
                target="_blank"
              >
                Download Default Contract
              </Link>
            )}

            {defaultContractDocumentUrl && (
              <Link
                to="https://docs.google.com/document/d/1qr5Jvni9f0LWhScwCeYu8GK4zFmAdf1o/edit"
                variant="blue"
                className="text-sm"
                target="_blank"
              >
                Download Template
              </Link>
            )}
          </div>
          <div className="flex items-center">
            <AdminOrderSendEmailButton
              onClick={() =>
                sendContractDocEmail({ variables: { input: { orderId } } })
              }
              disabled={!contractDocumentUrl || sendContractDocEmailLoading}
            >
              <ReceiptSvg /> Send Contract
            </AdminOrderSendEmailButton>
          </div>
        </div>
      </AdminOrderDownloadSection>
    </Container>
  )
}

export default AdminOrderContractDocument
