import clsx from "clsx"
import { PropsWithChildren } from "react"

const DetailsTableWithDescription = ({
  description,
  children,
}: {
  description?: {
    title: string
    content: string | React.ReactElement
  }
} & PropsWithChildren) => {
  return (
    <div className={clsx("mt-8 flex flex-col gap-8", "lg:flex-row")}>
      <div className="flex-1">{children}</div>
      <div className="flex-1">
        {description && (
          <>
            <div className="mb-4 text-2xl font-medium text-blue-zodiac">
              {description.title}
            </div>
            <div className="whitespace-pre-wrap text-sm leading-6 text-neutral-400">
              {description.content}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default DetailsTableWithDescription
