import { gql, useMutation } from "@apollo/client"
import { Form, Formik, FormikHelpers } from "formik"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData } from "~/__generated__"
import { imageSearchResultsPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import ImageSearchFields, {
  ImageSearchFormValues,
  imageSearchValidationSchema,
} from "./ImageSearchFields"
import { StudySearchResultsSummary_SearchFragment } from "../../StudySearchResultsScreen/components/StudySearchResultsSummary"

const IMAGE_SEARCH_UPDATE_MUTATION = gql(`
  mutation ImageSearchUpdate($input: ImageSearchUpdateInput!) {
    imageSearchUpdate(input: $input) {
      search {
        id
      }
    }
  }
`)

const EditImageSearchForm = ({
  data,
}: {
  data: FragmentType<typeof StudySearchResultsSummary_SearchFragment>
}) => {
  const navigate = useNavigate()
  const search = getFragmentData(StudySearchResultsSummary_SearchFragment, data)
  const [mutate] = useMutation(IMAGE_SEARCH_UPDATE_MUTATION)
  const imageSearchParams = search.imageSearchParams
  invariant(imageSearchParams, "Expected imageSearchParams")

  const onSubmit = async (
    values: ImageSearchFormValues,
    { setFieldError }: FormikHelpers<ImageSearchFormValues>
  ) => {
    try {
      await mutate({
        variables: {
          input: {
            id: search.id,
            searchInput: {
              name: search.name,
            },
            imageSearchParamsInput: {
              imageCount: Number(values.imageCount),
              ganLabelId: values.ganLabelId,
            },
          },
        },
      })

      toast.success("Search has been saved")
      navigate(imageSearchResultsPath({ id: search.id }))
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  const initialValues: ImageSearchFormValues = {
    imageCount: imageSearchParams?.imageCount?.toString() || "",
    ganLabelId: imageSearchParams?.ganLabel.uid,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={imageSearchValidationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <Form>
        <ImageSearchFields />
      </Form>
    </Formik>
  )
}

export default EditImageSearchForm
