import { useLocation } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { logInPath, registrationPath } from "~/common/paths"

const useLoggedInLink = () => {
  const { currentUser } = useCurrentUserMaybe()
  const location = useLocation()

  return (url: string, includeCurrentPath?: boolean, signIn?: boolean) => {
    if (currentUser) {
      return url
    }

    const redirectPath = signIn ? logInPath.pattern : registrationPath.pattern

    if (includeCurrentPath && location.search) {
      const redirect = encodeURIComponent(
        `${location.pathname}${location.search}`
      )
      return `${redirectPath}?redirect=${redirect}`
    }

    return redirectPath
  }
}

export default useLoggedInLink
