import { useCurrentUser } from "~/auth/CurrentUserContext"
import { editPasswordPath, editProfilePath } from "~/common/paths"
import { fullName } from "~/common/user"
import { ButtonLink } from "~/ui/Button"
import FieldGroup from "~/ui/FieldGroup"
import FieldLabelContainer, { InnerFieldLabel } from "~/ui/FieldLabelContainer"
import HR from "~/ui/HR"
import { H1, H3 } from "~/ui/Headings"

const ProfileScreenIndex = () => {
  const currentUser = useCurrentUser()

  return (
    <>
      <H1 id="profile-information" font="tight">
        Profile Information
      </H1>
      <HR />
      <H3 id="personal-information">Personal Information</H3>
      <div className="flex flex-col gap-6">
        <FieldGroup>
          <FieldLabelContainer label="Full Name">
            {fullName(currentUser)}
          </FieldLabelContainer>
          <FieldLabelContainer label="Email">
            {currentUser.email}
          </FieldLabelContainer>
        </FieldGroup>
        <FieldGroup>
          <H3 id="personal-information">School / Corporate Affiliation</H3>

          <FieldLabelContainer label="Name">
            {currentUser.company}
          </FieldLabelContainer>
          <FieldLabelContainer label="Address">
            {currentUser.companyAddress1}
          </FieldLabelContainer>
          <FieldLabelContainer label="Address 2">
            {currentUser.companyAddress2}
          </FieldLabelContainer>
          <FieldLabelContainer label="City">
            {currentUser.companyCity}
          </FieldLabelContainer>
          <FieldLabelContainer label="State">
            {currentUser.companyState}
          </FieldLabelContainer>
          <FieldLabelContainer label="Zip">
            {currentUser.companyZip}
          </FieldLabelContainer>
        </FieldGroup>
        <div className="pb-2">
          <ButtonLink to={editProfilePath.pattern} display="inline">
            Edit Profile Details
          </ButtonLink>
        </div>
      </div>
      <HR />
      <H3 id="personal-information">Password</H3>
      <InnerFieldLabel label="Click “Update Password” below if you would like to change your password." />
      <div className="py-4">
        <ButtonLink to={editPasswordPath.pattern} display="inline">
          Update Password
        </ButtonLink>
      </div>
    </>
  )
}

export default ProfileScreenIndex
