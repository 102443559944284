import { useMutation, useQuery } from "@apollo/client"
import { FormikHelpers } from "formik"
import { ChevronLeft } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { RoleEnum } from "~/__generated__/graphql"
import { adminUserPath, adminUsersPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import Loading from "~/ui/Loading"
import { AdminUserScreen_UserFragment } from "../AdminUserScreen/AdminUserScreen"
import AdminUserForm, { UserFormValues } from "./components/AdminUserForm"

const AdminEditUserScreen_Query = gql(`
  query AdminEditUserScreen_Query($id: ID!) {
    node(id: $id) {
      ...AdminUserScreen_UserFragment
    }
  }
`)

const ADMIN_USER_UPDATE_MUTATION = gql(`
  mutation AdminUserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...AdminUserScreen_UserFragment
      }
    }
  }
`)

const AdminEditUserScreen = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  invariant(id, "expected id")
  const { data, loading, error } = useQuery(AdminEditUserScreen_Query, {
    variables: {
      id: id,
    },
  })

  const [mutate] = useMutation(ADMIN_USER_UPDATE_MUTATION)

  const onSubmit = async (
    values: UserFormValues,
    { setFieldError }: FormikHelpers<UserFormValues>
  ) => {
    try {
      await mutate({
        variables: {
          input: {
            id,
            userInput: {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              company: values.company,
              companyAddress1: values.companyAddress1,
              companyAddress2: values.companyAddress2,
              companyCity: values.companyCity,
              companyState: values.companyState,
              companyZip: values.companyZip,
              role: values.isAdmin ? RoleEnum.Admin : RoleEnum.User,
            },
          },
        },
      })
      toast.success("User has been updated")
      navigate(adminUserPath({ id }))
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "User")
    return <Error error={error} />

  const user = getFragmentData(AdminUserScreen_UserFragment, data.node)
  const backPath = adminUsersPath.pattern

  const initialValues = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    company: user.company || "",
    companyAddress1: user.companyAddress1 || "",
    companyAddress2: user.companyAddress2 || "",
    companyCity: user.companyCity || "",
    companyState: user.companyState || "",
    companyZip: user.companyZip || "",
    isAdmin: user.isAdmin,
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center">
        <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
          <ChevronLeft size={28} />
        </Link>
        <H1 margin={false}>Edit {user.name}</H1>
      </div>
      <HR padding={false} />
      <AdminUserForm onSubmit={onSubmit} initialValues={initialValues} />
    </div>
  )
}

export default AdminEditUserScreen
