import EditPasswordForm from "~/components/EditPasswordForm"
import Container from "~/ui/Container"
import { H1 } from "~/ui/Headings"

const EditPasswordScreen = () => {
  return (
    <Container size="sm">
      <H1 id="update-password" font="tight">
        Update Password
      </H1>
      <EditPasswordForm />
    </Container>
  )
}

export default EditPasswordScreen
