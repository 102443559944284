import * as Yup from "yup"
import { DialogClose, DialogFooter } from "~/components/ui/Dialog"
import Button from "~/ui/Button"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"

export const saveNewSearchValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
})

export type SaveNewSearchValues = {
  name: string
}

const SaveNewSearchFields = ({ disabled }: { disabled: boolean }) => {
  return (
    <div>
      <FieldGroup margin={false}>
        <FormikField name="name" label="Name This Search" autoFocus={true} />
      </FieldGroup>
      <DialogFooter>
        <div className="flex gap-2">
          <Button display="inline" disabled={disabled}>
            Save Search
          </Button>
          <DialogClose asChild>
            <Button
              display="inline"
              variant="outlineSecondary"
              className="text-neutral-500"
            >
              Cancel
            </Button>
          </DialogClose>
        </div>
      </DialogFooter>
    </div>
  )
}

export default SaveNewSearchFields
