import clsx from "clsx"
import { Outlet } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import {
  adminAdminsPath,
  adminBulkUpdatesPath,
  adminExportRequestsPath,
  adminOrdersPath,
  adminStudiesPath,
  adminStudyPackagesPath,
  adminUsersPath,
  dashboardPath,
  destroySessionPath,
  newObjectId,
  ordersPath,
  profilePath,
  searchPath,
  searchesPath,
  studyPackagesPath,
} from "~/common/paths"
import Breadcrumbs from "~/components/Breadcrumbs"
import MobileNav from "~/components/MobileNav"
import SidebarNav from "~/components/SidebarNav"
import useScrollToTop from "~/hooks/useScrollToTop"
import BulkIcon from "~/images/icons/bulk.svg"
import CreditCardIcon from "~/images/icons/credit-card.svg"
import ExportIcon from "~/images/icons/export.svg"
import FunnelIcon from "~/images/icons/funnel.svg"
import GridIcon from "~/images/icons/grid.svg"
import HouseIcon from "~/images/icons/house.svg"
import SearchIcon from "~/images/icons/search.svg"
import UserIcon from "~/images/icons/user.svg"

export const ROOT_PATHS: Record<
  string,
  {
    name: string
    path: string
    icon: string
    requireUser?: boolean
    external?: boolean
  }
> = {
  home: {
    name: "Home",
    icon: HouseIcon,
    path: dashboardPath.pattern,
    requireUser: true,
  },
  searches: {
    name: "Query Builder",
    icon: FunnelIcon,
    path: searchPath({ id: newObjectId }),
  },
  savedSearches: {
    name: "Saved Searches",
    icon: SearchIcon,
    path: searchesPath.pattern,
    requireUser: true,
  },
  imagePackages: {
    name: "Image Packages",
    icon: GridIcon,
    path: studyPackagesPath.pattern,
    requireUser: true,
  },
  orders: {
    name: "Orders",
    icon: CreditCardIcon,
    path: ordersPath.pattern,
    requireUser: true,
  },
  profile: {
    name: "Profile",
    icon: UserIcon,
    path: profilePath.pattern,
    requireUser: true,
  },
  admin: {
    name: "Admin",
    icon: CreditCardIcon,
    path: adminOrdersPath.pattern,
    requireUser: true,
  },
  signOut: {
    name: "Sign Out",
    icon: ExportIcon,
    path: destroySessionPath.pattern,
    external: true,
  },
}

export const ADMIN_ROOT_PATHS: Record<
  string,
  {
    name: string
    path: string
    icon: string
    external?: boolean
    requireUser?: boolean
  }
> = {
  orders: {
    name: "Orders",
    icon: CreditCardIcon,
    path: adminOrdersPath.pattern,
  },
  exportRequests: {
    name: "Export Requests",
    icon: CreditCardIcon,
    path: adminExportRequestsPath.pattern,
  },
  users: {
    name: "Users",
    icon: UserIcon,
    path: adminUsersPath.pattern,
  },
  admins: {
    name: "Admins",
    icon: UserIcon,
    path: adminAdminsPath.pattern,
  },
  imagePackages: {
    name: "Image Packages",
    icon: GridIcon,
    path: adminStudyPackagesPath.pattern,
  },
  studies: {
    name: "Studies",
    icon: SearchIcon,
    path: adminStudiesPath.pattern,
  },
  bulkUpdates: {
    name: "Bulk Updates",
    icon: BulkIcon,
    path: adminBulkUpdatesPath.pattern,
  },
  signOut: {
    name: "Sign Out",
    icon: ExportIcon,
    path: destroySessionPath.pattern,
    external: true,
  },
}

const DashboardLayout = () => {
  const { currentUser } = useCurrentUserMaybe()
  useScrollToTop()

  return (
    <div
      className={clsx("flex w-full flex-col", "md:flex-row")}
      data-test={currentUser ? "logged-in" : undefined}
    >
      <MobileNav />
      <SidebarNav />
      <div className="min-w-0 flex-1">
        <div className="flex flex-1 flex-col">
          <Breadcrumbs />
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
