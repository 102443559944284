import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover"

const ContactSheetThumb = ({
  contactSheetUrl,
}: {
  contactSheetUrl?: string | null | undefined
}) => {
  if (!contactSheetUrl) {
    return (
      <div className="h-[40px] w-[40px] overflow-hidden border border-gray-200 bg-gray-50" />
    )
  }
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div
          className="h-[40px] w-[40px] overflow-hidden bg-no-repeat"
          style={{
            background: `url(${contactSheetUrl})`,
            backgroundSize: "280px",
          }}
        />
      </PopoverTrigger>
      <PopoverContent
        className="z-20 flex h-[500px] w-full items-center border-0 p-0 focus:outline-none"
        side="right"
        align="center"
        style={{
          maxWidth: "var(--radix-popover-content-available-width)",
          maxHeight: "var(--radix-popover-content-available-height)",
        }}
      >
        <div className="h-full shadow-md">
          <img
            src={contactSheetUrl}
            alt="Contact sheet"
            className="h-full w-full object-contain"
          />
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default ContactSheetThumb
