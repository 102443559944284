import { useMutation, useQuery } from "@apollo/client"
import { Form, Formik, FormikHelpers } from "formik"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { newObjectId, orderSuccessPath, studyPackagePath } from "~/common/paths"
import { imageOrderPriceCents } from "~/common/price"
import { displayErrors } from "~/common/validations"
import NewOrderScreenContent from "~/components/NewOrderScreenContent"
import useImageSearchParamValues from "~/hooks/useImageSearchParamValues"
import { ImageSearchResultsScreen_Query } from "~/screens/SearchScreen/screens/ImageSearchResultsScreen/ImageSearchResultsScreen"
import { ImageSearchResultsSummary_SearchFragment } from "~/screens/SearchScreen/screens/ImageSearchResultsScreen/components/ImageSearchResultsSummary"
import Error from "~/ui/Error"
import Loading from "~/ui/Loading"

type ImageOrderValues = {
  acceptedTerms: boolean
  imageCount: string
  ganLabelId: string
  searchId: string | undefined
}

export const IMAGE_ORDER_CREATE_MUTATION = gql(`
  mutation ImageOrderCreate($input: ImageOrderCreateInput!) {
    imageOrderCreate(input: $input) {
      order {
        id
      }
    }
  }
`)

const ImageSearchNewOrderScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")
  const imageSearchParamValues = useImageSearchParamValues()
  const navigate = useNavigate()

  const { data, loading, error } = useQuery(ImageSearchResultsScreen_Query, {
    variables: {
      id,
    },
    skip: id === newObjectId,
  })
  const [mutate] = useMutation(IMAGE_ORDER_CREATE_MUTATION)

  if (loading) return <Loading />
  if (error) return <Error error={error} />

  const search =
    data?.search.__typename === "Search"
      ? getFragmentData(ImageSearchResultsSummary_SearchFragment, data.search)
      : undefined

  const initialValues: ImageOrderValues = {
    imageCount:
      search?.imageSearchParams?.imageCount?.toString() ||
      imageSearchParamValues.imageCount ||
      "",
    ganLabelId:
      search?.imageSearchParams?.ganLabel.uid ||
      imageSearchParamValues.ganLabelId,
    searchId: id === newObjectId ? undefined : id,
    acceptedTerms: true,
  }

  const onSubmit = async (
    values: ImageOrderValues,
    { setFieldError }: FormikHelpers<ImageOrderValues>
  ) => {
    try {
      const resp = await mutate({
        variables: {
          input: {
            searchId: id,
            imageSearchParams: {
              imageCount: Number(values.imageCount),
              ganLabelId: values.ganLabelId,
            },
          },
        },
      })

      const orderId = resp?.data?.imageOrderCreate?.order?.id
      invariant(orderId, "Expected order id")
      navigate(orderSuccessPath({ id: orderId }), { replace: true })
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  const imageCount = parseInt(initialValues.imageCount)

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, submitForm }) => (
        <Form>
          <NewOrderScreenContent
            backPath={studyPackagePath({ id })}
            imageCountDisplay={imageCount.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}
            onSubmit={submitForm}
            disabled={isSubmitting || values.acceptedTerms !== true}
            priceCents={imageOrderPriceCents(imageCount)}
          />
        </Form>
      )}
    </Formik>
  )
}

export default ImageSearchNewOrderScreen
