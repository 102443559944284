import { useQuery } from "@apollo/client"
import clsx from "clsx"
import { useSearchParams } from "react-router-dom"
import { getFragmentData, gql } from "~/__generated__"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import AdminExportRequestsTable, {
  AdminExportRequests_QueryFragment,
} from "./components/AdminExportRequestsTable"

export const AdminExportRequestsScreen_Query = gql(`
  query AdminExportRequestsScreen_Query($page: Int, $limit: Int) {
    ...AdminExportRequests_QueryFragment
  }
`)

export const EXPORT_REQUESTS_LIMIT = 12

const AdminExportRequestsIndexScreen = () => {
  const [searchParams] = useSearchParams()

  const page = searchParams.get("page")

  const result = useQuery(AdminExportRequestsScreen_Query, {
    variables: {
      page: page ? Number(page) : 1,
      limit: EXPORT_REQUESTS_LIMIT,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { exportRequests } = getFragmentData(
    AdminExportRequests_QueryFragment,
    data
  )
  const totalCount = exportRequests.exportRequests.totalCount

  return (
    <div>
      <div
        className={clsx(
          "mb-6 flex flex-col gap-6",
          "lg:mb-0 lg:flex-row lg:justify-between"
        )}
      >
        <div>
          <H1>All Export Requests</H1>
        </div>
      </div>
      <AdminExportRequestsTable
        exportRequests={exportRequests.exportRequests.edges.map(
          (edge) => edge.node
        )}
        totalCount={totalCount}
        page={exportRequests.page}
        loading={loading}
      />
    </div>
  )
}

export default AdminExportRequestsIndexScreen
