import { useFormikContext } from "formik"
import { useOutletContext, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { studySearchResultsPath } from "~/common/paths"
import { studyOrderPriceCents } from "~/common/price"
import { getSelectedStudyCount } from "~/common/search"
import NewOrderScreenContent from "~/components/NewOrderScreenContent"
import {
  SearchResultsScreenOutletContext,
  StudySearchResultsFormValues,
} from "../../StudySearchResultsScreen"

const StudySearchNewOrderScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")

  const { studyCount } = useOutletContext<SearchResultsScreenOutletContext>()
  const { values, isSubmitting, setFieldValue, submitForm } =
    useFormikContext<StudySearchResultsFormValues>()

  const selectedStudiesCount = getSelectedStudyCount(
    values.selectAll,
    values.studyIdsDiff.length,
    studyCount
  )

  const backPath = `${studySearchResultsPath({
    id,
  })}${window.location.search}`

  const onSubmit = () => {
    setFieldValue("submitType", "order")
    submitForm()
  }

  return (
    <NewOrderScreenContent
      backPath={backPath}
      studyCountDisplay={selectedStudiesCount.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      })}
      onSubmit={onSubmit}
      disabled={isSubmitting || values.acceptedTerms !== true}
      priceCents={studyOrderPriceCents(selectedStudiesCount)}
      isEstimate={true}
    />
  )
}

export default StudySearchNewOrderScreen
