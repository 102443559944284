import { Link } from "react-router-dom"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { newObjectId, searchPath, searchesPath } from "~/common/paths"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import HeartSearchIcon from "~/images/icons/heart-search.svg"
import SearchRow, {
  SearchRow_SearchFragment,
} from "~/screens/SearchesScreen/components/SearchRow"
import { ButtonLink } from "~/ui/Button"
import DashboardEmptyTable from "./DashboardEmptyTable"
import DashboardTitle from "./DashboardTitle"

export const DashboardSearches_QueryFragment = gql(`
  fragment DashboardSearches_QueryFragment on Query {
    searches(limit: 21) {
      searches {
        edges {
          node {
            ...SearchRow_SearchFragment
          }
        }
      }
    }
  }
`)

const DashboardSavedSearches = ({
  searches,
}: {
  searches: FragmentType<typeof SearchRow_SearchFragment>[]
}) => {
  return (
    <div className="flex flex-col gap-8">
      <Link to={searchesPath.pattern}>
        <DashboardTitle text="Saved Searches">
          <HeartSearchIcon />
        </DashboardTitle>
      </Link>
      <div className="h-[460px] overflow-auto border border-border-gray">
        {searches.length > 0 ? (
          <Table>
            <TableHeader outerBorder={false}>
              <TableRow outerBorder={false}>
                <TableHead>Search Name</TableHead>
                <TableHead className="w-[150px]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {searches.map((search) => (
                <SearchRow
                  key={getFragmentData(SearchRow_SearchFragment, search).id}
                  data={search}
                  compact={true}
                  outerBorder={false}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <DashboardEmptyTable
            text="No saved searches, save a search from the query builder to view
          them here."
            wide={true}
          >
            <ButtonLink
              to={searchPath({ id: newObjectId })}
              className="rounded-md bg-candlelight px-8 py-4 font-semibold"
              display="inline"
            >
              Start a Query
            </ButtonLink>
          </DashboardEmptyTable>
        )}
      </div>
    </div>
  )
}

export default DashboardSavedSearches
