import pluralize from "pluralize"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { dateMedium } from "~/common/dates"
import { imageSearchResultsPath, studySearchResultsPath } from "~/common/paths"
import ImageSearchBadge from "~/components/ImageSearchBadge"
import { LinkedTableCell, TableCell, TableRow } from "~/components/ui/Table"
import { ButtonLink } from "~/ui/Button"
import SearchRowActions from "./SearchRowActions"

export const SearchRow_SearchFragment = gql(`
  fragment SearchRow_SearchFragment on Search {
    id
    name
    createdAt
    resultsCount
    modalities
    isDuplicatingSearch
    isCreatingResults
    studySearchParams {
      ...StudySearchResultsSummary_SearchParamsFragment
    }
    imageSearchParams {
      id
      imageCount
    }
  }
`)

const SearchRow = ({
  data,
  page,
  compact,
  outerBorder,
}: {
  data: FragmentType<typeof SearchRow_SearchFragment>
  page?: number
  compact?: boolean
  outerBorder?: boolean
}) => {
  const search = getFragmentData(SearchRow_SearchFragment, data)
  const isImage = !!search.imageSearchParams
  const searchPath = isImage
    ? imageSearchResultsPath({ id: search.id })
    : studySearchResultsPath({
        id: search.id,
      })

  return (
    <>
      <TableRow outerBorder={outerBorder}>
        <LinkedTableCell to={searchPath} className="max-w-[580px]">
          <div className="truncate font-bold">{search.name}</div>
          {isImage && <ImageSearchBadge />}
        </LinkedTableCell>
        {!compact && (
          <>
            <LinkedTableCell to={searchPath} className="uppercase">
              {search.modalities.join(", ")}
            </LinkedTableCell>
            <LinkedTableCell to={searchPath}>
              {(!search.isCreatingResults || !!search?.resultsCount) && (
                <>
                  <span className="mr-1 font-semibold">
                    {(
                      (isImage
                        ? search.imageSearchParams?.imageCount
                        : search.resultsCount) || 0
                    ).toLocaleString("en-US", {
                      maximumFractionDigits: 0,
                    })}
                  </span>
                  {isImage
                    ? pluralize(
                        "image",
                        search.imageSearchParams?.imageCount || 0
                      )
                    : pluralize("study", search.resultsCount || 0)}
                </>
              )}
            </LinkedTableCell>
            <LinkedTableCell to={searchPath}>
              {dateMedium(new Date(search.createdAt))}
            </LinkedTableCell>
          </>
        )}
        <TableCell>
          <div className="flex gap-2">
            <ButtonLink to={searchPath} display="inline">
              View Search
            </ButtonLink>
            {!compact && !!search.name && (
              <SearchRowActions
                searchId={search.id}
                searchName={search.name}
                isCreatingResults={search.isCreatingResults}
                page={page || 1}
              />
            )}
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}

export default SearchRow
