import { useField } from "formik"
import Combobox from "~/components/ui/ComboBox"
import { FormikSelectFieldType } from "./FormikField"

const encodeSelectValue = (value: string | undefined) => {
  if (typeof value === "boolean") {
    return value ? "1" : "0"
  }
  return value
}

const decodeSelectValue = (value: string | undefined) => {
  if (value === "1") {
    return true
  } else if (value === "0") {
    return false
  }
  return value
}

const FormikSelectField = ({
  options,
  placeholder,
  beforeChange,
  booleanSelect, // Booleans need to be encoded/decoded for the combobox
  inputClassName,
  ...props
}: FormikSelectFieldType) => {
  const [field, _meta, { setValue }] = useField(props)
  return (
    <Combobox
      options={options}
      value={encodeSelectValue(field.value)}
      onChange={(newValue: string | undefined) => {
        const val = booleanSelect ? decodeSelectValue(newValue) : newValue
        beforeChange && beforeChange(val)
        setValue(val)
      }}
      triggerClassName={inputClassName}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default FormikSelectField
