import { currency } from "~/common/currency"
import { studyPackageNewOrderPath } from "~/common/paths"
import HeaderStatsContainer from "~/components/HeaderStats/HeaderStatsContainer"
import HeaderStatsItem from "~/components/HeaderStats/HeaderStatsItem"
import BookSvg from "~/images/icons/book.svg"
import DollarSvg from "~/images/icons/dollar.svg"
import { ButtonLink } from "~/ui/Button"

const StudyPackageStats = ({
  studyPackageId,
  studyCountDisplay,
  isImageCount,
  priceCents,
}: {
  studyPackageId: string
  studyCountDisplay: string
  isImageCount: boolean
  priceCents: number
}) => {
  return (
    <HeaderStatsContainer>
      <HeaderStatsItem value={studyCountDisplay}>
        <BookSvg />
        <div>{isImageCount ? "Image" : "Study"} Count</div>
      </HeaderStatsItem>
      <HeaderStatsItem value={currency(priceCents)} data-test="estimated-price">
        <DollarSvg />
        <div>Price</div>
      </HeaderStatsItem>
      <div className="flex flex-1">
        <ButtonLink
          variant="primary"
          size="xl"
          className="h-full"
          to={studyPackageNewOrderPath({
            id: studyPackageId,
          })}
        >
          Order Packaged Data Set
        </ButtonLink>
      </div>
    </HeaderStatsContainer>
  )
}

export default StudyPackageStats
