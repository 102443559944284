import clsx from "clsx"
import { useField } from "formik"
import { useRef } from "react"
import TextareaAutosize from "react-autosize-textarea"
import { FormikFieldType } from "./FormikField"

export const FormikInputField = ({
  name,
  type,
  autoFocus,
  light,
  inputClassName,
  beforeChange,
  step,
  min,
  disabled,
  placeholder,
}: Pick<
  FormikFieldType,
  | "name"
  | "type"
  | "autoFocus"
  | "light"
  | "inputClassName"
  | "min"
  | "step"
  | "beforeChange"
  | "disabled"
  | "placeholder"
>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [field, _meta, { setValue }] = useField({ name, type })

  const className = clsx(
    "w-full rounded py-3 text-xs",
    light ? "border-0" : "border-1 border-border-gray",
    inputClassName
  )

  if (type === "textarea") {
    return (
      <TextareaAutosize
        className={className}
        type={type}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        {...field}
        ref={textareaRef}
        onChange={(e) => {
          // @ts-expect-error
          const newValue = e.target.value
          beforeChange && beforeChange(newValue)
          setValue(newValue)
        }}
      />
    )
  }

  return (
    <input
      className={className}
      type={type}
      autoFocus={autoFocus}
      ref={inputRef}
      min={min}
      step={step}
      disabled={disabled}
      placeholder={placeholder}
      {...field}
      onChange={(e) => {
        const newValue = e.target.value
        beforeChange && beforeChange(newValue)
        setValue(newValue)
      }}
    />
  )
}

export default FormikInputField
