import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import { USERS_LIMIT } from "../AdminUsersIndexScreen"
import AdminUserRow, { AdminUserRow_UserFragment } from "./AdminUserRow"

export const AdminUsers_QueryFragment = gql(`
  fragment AdminUsers_QueryFragment on Query {
    users(admin: $admin, page: $page, search: $search, limit: $limit) {
      users {
        edges {
          node {
            ...AdminUserRow_UserFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const AdminUsersTable = ({
  users,
  totalCount,
  page,
}: {
  users: FragmentType<typeof AdminUserRow_UserFragment>[]
  totalCount: number
  page: number
}) => {
  const totalPages = Math.ceil(totalCount / USERS_LIMIT)

  return (
    <div className="flex flex-col gap-6 text-base">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[150px]">Name</TableHead>
            <TableHead className="min-w-[150px]">Email</TableHead>
            <TableHead className="min-w-[150px]">Company</TableHead>
            <TableHead className="min-w-[115px]">Created</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => (
            <AdminUserRow
              key={getFragmentData(AdminUserRow_UserFragment, user).id}
              data={user}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination page={page} totalPages={totalPages} />
    </div>
  )
}

export default AdminUsersTable
