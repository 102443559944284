// Env vars are assigned in app/views/spa/_js_env.html.erb

interface Env {
  ENVIRONMENT: string
  ENABLE_SENTRY: string
  SENTRY_DSN: string
  GENERAL_CONTRACT_URL: string
  TURNSTILE_SITE_KEY: string
  ENABLE_CAPTCHA: string
}

declare global {
  interface Window {
    env: Env
    gtag: any
  }
}

export default window.env
