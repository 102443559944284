import clsx from "clsx"
import { useFormikContext } from "formik"
import { useOutletContext, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { newObjectId, registrationPath, searchPath } from "~/common/paths"
import ResultsHr from "~/components/ResultsHr"
import useLoggedInLink from "~/hooks/useLoggedInLink"
import useSearchSubscription from "~/hooks/useSearchSubscription"
import Button, { ButtonLink } from "~/ui/Button"
import { H1 } from "~/ui/Headings"
import {
  SearchResultsScreenOutletContext,
  StudySearchResultsFormValues,
} from "../../StudySearchResultsScreen"
import DisabledSearchSave from "../../components/DisabledSearchSave"
import NewStudySearchDialog from "../../components/NewStudySearchDialog"
import StudySearchResultsAppliedSeriesFilters from "../../components/StudySearchResultsAppliedSeriesFilters"
import StudySearchResultsAppliedStudyFilters from "../../components/StudySearchResultsAppliedStudyFilters"
import StudySearchResultsStats from "../../components/StudySearchResultsStats"
import StudySearchResultsSummary, {
  StudySearchResultsSummary_SearchFragment,
} from "../../components/StudySearchResultsSummary"

const HeaderActions = ({
  isDuplicatingSearch,
  isUpdatingSelections,
}: {
  isDuplicatingSearch: boolean
  isUpdatingSelections: boolean
}) => {
  const { id } = useParams()
  invariant(id, "Expected search id")
  const { currentUser } = useCurrentUserMaybe()
  const { submitForm, isSubmitting } =
    useFormikContext<StudySearchResultsFormValues>()
  const loggedInLink = useLoggedInLink()

  return (
    <div className="flex items-center gap-2">
      <ButtonLink
        to={searchPath({ id })}
        preserveSearch={true}
        display="inline"
      >
        Edit Search
      </ButtonLink>
      {!!currentUser ? (
        <>
          {id === newObjectId ? (
            <NewStudySearchDialog />
          ) : (
            <DisabledSearchSave
              disabled={isDuplicatingSearch || isUpdatingSelections}
              loading={isUpdatingSelections}
            >
              <Button
                variant="outlineSecondary"
                display="inline"
                type="button"
                className={clsx({
                  "animate-pulse": isSubmitting,
                })}
                onClick={submitForm}
                disabled={isSubmitting}
              >
                Save Search
              </Button>
            </DisabledSearchSave>
          )}
        </>
      ) : (
        <ButtonLink
          to={loggedInLink(registrationPath.pattern, true)}
          display="inline"
          variant="outlineSecondary"
        >
          Save Search
        </ButtonLink>
      )}
    </div>
  )
}

const StudySearchResultsIndexScreen = () => {
  const { id } = useParams()
  invariant(id, "Expected search id")

  const { search, studyCount, studies, page, loading } =
    useOutletContext<SearchResultsScreenOutletContext>()
  const { isDuplicatingSearch, isUpdatingSelections } = getFragmentData(
    StudySearchResultsSummary_SearchFragment,
    search
  )

  useSearchSubscription(
    id,
    !id || (!isDuplicatingSearch && !isUpdatingSelections)
  )

  return (
    <div className="flex flex-col gap-8">
      <div
        className={clsx(
          "flex flex-col gap-6",
          "lg:flex-row lg:justify-between"
        )}
      >
        <div>
          <H1 margin={false}>Study Results</H1>
        </div>
        <HeaderActions
          isDuplicatingSearch={isDuplicatingSearch}
          isUpdatingSelections={isUpdatingSelections}
        />
      </div>
      <StudySearchResultsStats studyCount={studyCount} />
      <StudySearchResultsAppliedStudyFilters search={search} />
      <StudySearchResultsAppliedSeriesFilters search={search} />
      <ResultsHr />
      <StudySearchResultsSummary
        data={search}
        studies={studies}
        studyCount={studyCount}
        page={page}
        loading={loading}
      />
      <ResultsHr />
      <StudySearchResultsStats studyCount={studyCount} />
    </div>
  )
}

export default StudySearchResultsIndexScreen
