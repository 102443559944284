import { PropsWithChildren } from "react"

const HeaderStatsItem = ({
  value,
  "data-test": dataTest,
  children,
}: { value: string; "data-test"?: string } & PropsWithChildren) => {
  return (
    <div
      className="flex flex-1 items-center justify-between gap-4 rounded-lg border border-border-gray p-4"
      data-test={dataTest}
    >
      <div className="flex items-center gap-2">{children}</div>
      <div className="mt-[1px] flex items-center text-base font-bold">
        {value}
      </div>
    </div>
  )
}

export default HeaderStatsItem
