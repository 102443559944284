import { Outlet } from "react-router-dom"
import PageContent from "~/ui/PageContent"

const StudyPackagesScreen = () => {
  return (
    <PageContent>
      <Outlet />
    </PageContent>
  )
}

export default StudyPackagesScreen
