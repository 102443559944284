import { useQuery } from "@apollo/client"
import clsx from "clsx"
import { ChevronLeft } from "lucide-react"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { dateMedium } from "~/common/dates"
import { adminStudiesPath } from "~/common/paths"
import { sexDisplay } from "~/common/study"
import { boolToString } from "~/lib/utils"
import Container from "~/ui/Container"
import { TD, TH, Table } from "~/ui/DetailTable"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import Loading from "~/ui/Loading"

export const AdminStudyScreen_StudyFragment = gql(`
  fragment AdminStudyScreen_StudyFragment on Study {
    id
    uid
    patientAge
    description
    sex
    date
    modality
    contactSheetUrl
    purchased
    report
    reportConclusion
    mrn
    accession
    sliceThicknessMin
    contrast
    bulkUpdateId
    seriesDescriptions
    seriesStrengths
  }
`)

const AdminStudyScreen_Query = gql(`
  query AdminStudyScreen_Query($id: ID!) {
    node(id: $id) {
      ...AdminStudyScreen_StudyFragment
    }
  }
`)

const AdminStudyScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")

  const { data, loading, error } = useQuery(AdminStudyScreen_Query, {
    variables: {
      id: id,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "Study")
    return <Error error={error} />

  const study = getFragmentData(AdminStudyScreen_StudyFragment, data.node)
  const backPath = adminStudiesPath.pattern

  return (
    <div className="flex flex-col gap-8">
      <div
        className={clsx(
          "flex flex-col gap-4",
          "lg:flex-row lg:items-center lg:justify-between"
        )}
      >
        <div className="flex items-center">
          <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
            <ChevronLeft size={28} />
          </Link>
          <H1 margin={false}>{study.description}</H1>
        </div>
      </div>
      <HR padding={false} />
      <Container size="lg">
        <Table>
          <thead>
            <tr>
              <TH colSpan={2}>Details</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD>Description</TD>
              <TD>{study.description}</TD>
            </tr>
            <tr>
              <TD>Accession</TD>
              <TD>{study.accession}</TD>
            </tr>
            <tr>
              <TD>MRN</TD>
              <TD>{study.mrn}</TD>
            </tr>
            <tr>
              <TD>Modality</TD>
              <TD>
                <div className="uppercase">{study.modality}</div>
              </TD>
            </tr>
            <tr>
              <TD>Min Slice Thickness</TD>
              <TD>{study.sliceThicknessMin}</TD>
            </tr>
            <tr>
              <TD>Contrast</TD>
              <TD>{boolToString(study.contrast)}</TD>
            </tr>
            <tr>
              <TD>Age</TD>
              <TD>{study.patientAge}</TD>
            </tr>
            <tr>
              <TD>Sex</TD>
              <TD>{sexDisplay(study.sex)}</TD>
            </tr>
            <tr>
              <TD>Date</TD>
              <TD>{study.date ? dateMedium(new Date(study.date)) : ""}</TD>
            </tr>
            <tr>
              <TD>Import</TD>
              <TD>{study.bulkUpdateId}</TD>
            </tr>
            <tr>
              <TD>Report</TD>
              <TD>{study.report}</TD>
            </tr>
            <tr>
              <TD>Report Conclusion/Impression</TD>
              <TD>{study.reportConclusion}</TD>
            </tr>
            <tr>
              <TD>Series Descriptions</TD>
              <TD>
                {study.seriesDescriptions?.map((description) => (
                  <div>{description}</div>
                ))}
              </TD>
            </tr>
            <tr>
              <TD>Series Strengths</TD>
              <TD>
                {" "}
                {study.seriesStrengths?.map((strength) => (
                  <div>{strength}</div>
                ))}
              </TD>
            </tr>
            <tr>
              <TD>Contact Sheet</TD>
              <TD>
                {!!study.contactSheetUrl && (
                  <img src={study.contactSheetUrl} alt="Contact Sheet" />
                )}
              </TD>
            </tr>
          </tbody>
        </Table>
      </Container>
    </div>
  )
}

export default AdminStudyScreen
