import { FragmentType, getFragmentData } from "~/__generated__"
import ImageSearchResultsAppliedFilters from "~/screens/SearchScreen/screens/ImageSearchResultsScreen/ImageSearchResultsAppliedFilters"
import StudySearchResultsAppliedSeriesFilters from "~/screens/SearchScreen/screens/StudySearchResultsScreen/components/StudySearchResultsAppliedSeriesFilters"
import StudySearchResultsAppliedStudyFilters from "~/screens/SearchScreen/screens/StudySearchResultsScreen/components/StudySearchResultsAppliedStudyFilters"
import { StudySearchResultsSummary_SearchFragment } from "~/screens/SearchScreen/screens/StudySearchResultsScreen/components/StudySearchResultsSummary"
import { ButtonLink } from "~/ui/Button"
import HR from "~/ui/HR"
import { H3 } from "~/ui/Headings"

const AdminOrderSearchSection = ({
  orderStudiesCsvUrl,
  data,
}: {
  orderStudiesCsvUrl: string | null | undefined
  data: FragmentType<typeof StudySearchResultsSummary_SearchFragment>
}) => {
  const search = getFragmentData(StudySearchResultsSummary_SearchFragment, data)

  return (
    <>
      <H3 margin={false}>Query Details</H3>
      {search?.studySearchParams && (
        <>
          <StudySearchResultsAppliedStudyFilters search={data} />
          <StudySearchResultsAppliedSeriesFilters search={data} admin={true} />
        </>
      )}
      {search?.imageSearchParams && (
        <ImageSearchResultsAppliedFilters
          imageCount={search.imageSearchParams.imageCount}
          label={search.imageSearchParams.ganLabel.name}
        />
      )}
      {!!search.studySearchParams && (
        <div>
          {orderStudiesCsvUrl ? (
            <ButtonLink to={orderStudiesCsvUrl} display="inline" size="lg">
              Download Study IDs
            </ButtonLink>
          ) : (
            <ButtonLink
              to={""}
              display="inline"
              size="lg"
              className="pointer-events-none opacity-50"
            >
              Generating Study IDs
            </ButtonLink>
          )}
        </div>
      )}
      <HR padding={false} />
    </>
  )
}

export default AdminOrderSearchSection
