import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { studySearchResultsPath } from "~/common/paths"
import StudyScreenContent from "~/components/StudyScreenContent"
import Error from "~/ui/Error"
import Loading from "~/ui/Loading"

const SearchResultScreen_Query = gql(`
  query SearchResultScreen_Query($id: ID!) {
    node(id: $id) {
      ...StudyScreenContent_StudyFragment
    }
  }
`)

const StudySearchResultScreen = () => {
  const { id, studyId } = useParams()
  invariant(id, "expected id")
  invariant(studyId, "expected studyId")

  const { data, loading, error } = useQuery(SearchResultScreen_Query, {
    variables: {
      id: studyId,
    },
  })

  if (loading) return <Loading />
  if (error || !data) return <Error error={error} />

  const node = data.node.__typename === "Study" ? data.node : null
  invariant(node, "expected study")

  return (
    <StudyScreenContent
      data={node}
      backPath={`${studySearchResultsPath({
        id,
      })}${window.location.search}`}
    />
  )
}

export default StudySearchResultScreen
