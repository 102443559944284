import clsx from "clsx"
import { useFormikContext } from "formik"
import * as Yup from "yup"
import useGanLabelsQuery from "~/hooks/useGanLabelsQuery"
import Button from "~/ui/Button"
import Container from "~/ui/Container"
import Error from "~/ui/Error"
import FormikField from "~/ui/FormikField"
import Loading from "~/ui/Loading"

export type ImageSearchFormValues = {
  imageCount: string
  ganLabelId: string
}

export const imageSearchValidationSchema = Yup.object({
  imageCount: Yup.number().required("Image count is required"),
  ganLabelId: Yup.string().required("Label is required"),
})

const ImageSearchFields = () => {
  const { isSubmitting } = useFormikContext<ImageSearchFormValues>()
  const { ganLabels, loading, error } = useGanLabelsQuery()

  if (loading) return <Loading />
  if (error || !ganLabels?.length) return <Error />

  return (
    <>
      <Container size="md">
        <div className={clsx("flex w-full flex-col gap-2")}>
          <div className="max-w-[200px]">
            <FormikField
              name="imageCount"
              label="Image Count"
              type="number"
              min="1"
            />
          </div>
          <FormikField
            name="ganLabelId"
            label="Label"
            placeholder="Select Label"
            type="select"
            options={ganLabels.map((ganLabel) => ({
              label: ganLabel.name,
              value: ganLabel.uid,
            }))}
            required={true}
          />
        </div>
      </Container>
      <div className="mt-6 flex justify-between">
        <Button disabled={isSubmitting} display="inline" type="submit">
          Search Images
        </Button>
      </div>
    </>
  )
}

export default ImageSearchFields
