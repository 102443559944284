import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { dashboardPath, supportEmail, supportEmailPath } from "~/common/paths"
import { ButtonLink } from "~/ui/Button"
import Container from "~/ui/Container"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import Loading from "~/ui/Loading"
import OrderSummaryTable, {
  OrderSuccessScreenOrder_Fragment,
} from "./components/OrderSummaryTable"

const OrderSuccessScreen_Query = gql(`
  query OrderSuccessScreen_Query($id: ID!) {
    node(id: $id) {
      ...OrderSuccessScreenOrder
    }
  }
`)

const OrderSuccessScreen = () => {
  const { id } = useParams()
  invariant(id, "expected orderId")

  const { data, loading, error } = useQuery(OrderSuccessScreen_Query, {
    variables: {
      id,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "Order")
    return <Error error={error} />

  const order = getFragmentData(OrderSuccessScreenOrder_Fragment, data.node)

  return (
    <>
      <div className="mb-8">
        <H1 margin={false}>🎉 Order Confirmation</H1>
      </div>
      <Container size="md">
        <div className="flex flex-col gap-8">
          <div>
            <div className="text-2xl font-medium text-black">
              Your order has been submitted successfully!
            </div>
            <div className="mt-4 text-sm leading-6 text-neutral-500">
              Your order has been submitted to our team. We will be back to you
              shortly with additional information to process your order. If you
              don't hear back from us in 7 business days, please reach out to{" "}
              <Link
                to={`${supportEmailPath}?subject=Order ${order.orderNumber} inquiry`}
                variant="blue"
              >
                {supportEmail}
              </Link>{" "}
              and include the order number below.
            </div>
          </div>
          <OrderSummaryTable order={data.node} />
          <div className="flex justify-between">
            <div>
              <ButtonLink
                to={dashboardPath.pattern}
                display="inline"
                type="submit"
              >
                Back to Home
              </ButtonLink>
            </div>
            <div>
              <ButtonLink
                to={supportEmailPath}
                variant="outlineSecondary"
                className="px-10 font-normal text-neutral-400"
              >
                Need Help? Contact Us
              </ButtonLink>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default OrderSuccessScreen
