import { useField } from "formik"
import { useRef } from "react"
import { FormikFieldType } from "./FormikField"

export const FormikRadioField = ({
  name,
  value,
  type,
}: Pick<FormikFieldType, "name" | "value" | "type">) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [field] = useField({ name, type, value })
  return (
    <label id={name} className="cursor-pointer">
      <input type={type} ref={inputRef} {...field} />
    </label>
  )
}

export default FormikRadioField
