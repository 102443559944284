import { useCurrentUser } from "~/auth/CurrentUserContext"
import EditProfileForm from "~/components/EditProfileForm"
import Container from "~/ui/Container"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"

const EditProfileScreen = () => {
  const currentUser = useCurrentUser()

  return (
    <>
      <Container size="md">
        <H1 id="profile-information" font="tight">
          Profile Information
        </H1>
        <HR />
        <Container size="sm">
          <EditProfileForm user={currentUser} />
        </Container>
      </Container>
    </>
  )
}

export default EditProfileScreen
