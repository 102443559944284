import * as Sentry from "@sentry/react"
import { isRouteErrorResponse, useRouteError } from "react-router-dom"

export const ErrorBoundary = () => {
  const error = useRouteError()
  if (isRouteErrorResponse(error)) {
    return (
      <div className="mx-auto max-w-7xl">
        <div className="border border-red-700 p-4 py-2">
          <h1 className="text-red-700">{error.status}</h1>
        </div>
      </div>
    )
  }

  console.error(error)
  Sentry.captureException(error)

  return <div>An unknown error has occurred</div>
}
