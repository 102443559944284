import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params"

export const adminOrdersParamValues = {
  search: withDefault(StringParam, ""),
  page: NumberParam,
}

const useAdminUsersParamValues = () => {
  return useQueryParams(adminOrdersParamValues)
}

export default useAdminUsersParamValues
