import { Form, Formik } from "formik"
import queryString from "query-string"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import {
  NumberParam,
  encodeQueryParams,
  useQueryParams,
} from "use-query-params"
import { SearchSexEnum } from "~/__generated__/graphql"
import { studySearchResultsPath } from "~/common/paths"
import { searchParamSchema } from "~/hooks/useSearchParamValues"
import StudySearchFields, {
  SearchFormValues,
  searchValidationSchema,
} from "./StudySearchFields"

export const paginationParamSchema = {
  page: NumberParam,
}

export const boolOrUndefined = (value: boolean | null | undefined) => {
  if (typeof value === "boolean") {
    return value
  }
  return undefined
}

const NewStudySearchForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  invariant(id, "Search ID is required")

  const [searchParams, setSearchParams] = useQueryParams(searchParamSchema)

  const initialValues = {
    patientAgeMin: searchParams.patientAgeMin || "12",
    patientAgeMax: searchParams.patientAgeMax || "100",
    sex: searchParams.sex || SearchSexEnum.A,
    mr: searchParams.mr || false,
    mrStrength: searchParams.mrStrength || undefined,
    mrContrast: boolOrUndefined(searchParams.mrContrast),
    mrReportKeywords: searchParams.mrReportKeywords || "",
    mrDescriptionKeywords: searchParams.mrDescriptionKeywords || "",
    mrSeriesDescriptionKeywords: searchParams.mrSeriesDescriptionKeywords || "",
    ct: searchParams.ct || false,
    ctContrast: boolOrUndefined(searchParams.ctContrast),
    ctReportKeywords: searchParams.ctReportKeywords || "",
    ctDescriptionKeywords: searchParams.ctDescriptionKeywords || "",
    ctSeriesDescriptionKeywords: searchParams.ctSeriesDescriptionKeywords || "",
    us: searchParams.us || false,
    usReportKeywords: searchParams.usReportKeywords || "",
    usDescriptionKeywords: searchParams.usDescriptionKeywords || "",
    usSeriesDescriptionKeywords: searchParams.usSeriesDescriptionKeywords || "",
    xr: searchParams.xr || false,
    xrReportKeywords: searchParams.xrReportKeywords || "",
    xrDescriptionKeywords: searchParams.xrDescriptionKeywords || "",
    xrSeriesDescriptionKeywords: searchParams.xrSeriesDescriptionKeywords || "",
  }

  const onSubmit = async (values: SearchFormValues) => {
    setSearchParams(values, "replace")
    const encodedSearchParams = encodeQueryParams(searchParamSchema, values)
    navigate(
      `${studySearchResultsPath({ id })}?${queryString.stringify(
        encodedSearchParams
      )}`
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={searchValidationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <Form>
        <StudySearchFields isDuplicatingSearch={false} />
      </Form>
    </Formik>
  )
}

export default NewStudySearchForm
