import pluralize from "pluralize"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { currency } from "~/common/currency"
import { dateMedium } from "~/common/dates"
import { orderPath } from "~/common/paths"
import { LinkedTableCell, TableCell, TableRow } from "~/components/ui/Table"
import { ButtonLink } from "~/ui/Button"
import OrderRowActions from "./OrderRowActions"
import OrderStatus from "./OrderStatus"
import { getManualCountTypePlural } from "~/common/order"

export const OrderRow_OrderFragment = gql(`
  fragment OrderRow_OrderFragment on Order {
    id
    orderNumber
    status
    statusDisplay
    studyCount
    imageCount
    manualCount
    isImageCount
    estimatedPriceCents
    priceCents
    createdAt
    isCreatingStudies
  }
`)

const OrderRow = ({
  data,
  compact,
  outerBorder,
}: {
  data: FragmentType<typeof OrderRow_OrderFragment>
  compact?: boolean
  outerBorder?: boolean
}) => {
  const order = getFragmentData(OrderRow_OrderFragment, data)
  const path = orderPath({
    id: order.id,
  })

  const isEstimate = order.priceCents === null || order.priceCents === undefined

  return (
    <>
      <TableRow outerBorder={outerBorder}>
        <LinkedTableCell to={path} className="max-w-[580px]">
          <div className="truncated font-semibold">{order.orderNumber}</div>
        </LinkedTableCell>
        <LinkedTableCell to={path} className="max-w-[580px]">
          <OrderStatus
            status={order.status}
            statusDisplay={order.statusDisplay}
            compact={compact}
          />
        </LinkedTableCell>
        {!compact && (
          <>
            <LinkedTableCell to={path} className="max-w-[580px]">
              <div className="truncate">
                {order.manualCount ? (
                  <>
                    <b>{order.manualCount}</b>{" "}
                    {getManualCountTypePlural({ order })}
                  </>
                ) : order.imageCount && order.imageCount > 0 ? (
                  <>
                    <b>
                      {order.imageCount.toLocaleString("en-US", {
                        maximumFractionDigits: 0,
                      })}
                    </b>{" "}
                    {pluralize("image", order.imageCount)}
                  </>
                ) : (
                  <>
                    {!order.isCreatingStudies && (
                      <>
                        <b>
                          {order.studyCount.toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                          })}
                        </b>{" "}
                        {pluralize("study", order.studyCount)}
                      </>
                    )}
                  </>
                )}
              </div>
            </LinkedTableCell>
            <LinkedTableCell to={path} className="max-w-[580px]">
              {!order.isCreatingStudies && (
                <div className="truncate font-semibold">
                  {currency(
                    isEstimate
                      ? order.estimatedPriceCents
                      : order.priceCents || 0
                  )}
                  {isEstimate && " est."}
                </div>
              )}
            </LinkedTableCell>
          </>
        )}
        <LinkedTableCell to={path} className="max-w-[580px]">
          <div className="truncate">
            {dateMedium(new Date(order.createdAt))}
          </div>
        </LinkedTableCell>
        <TableCell>
          <div className="flex gap-2">
            <ButtonLink to={path} display="inline">
              View Order
            </ButtonLink>
            {!compact && <OrderRowActions id={order.id} />}
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}

export default OrderRow
