import Badge from "./ui/Badge"

const PurchasedStudyBadge = () => {
  return (
    <Badge variant="red" className="w-[102px]">
      Past Purchase
    </Badge>
  )
}

export default PurchasedStudyBadge
