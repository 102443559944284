import { useQuery } from "@apollo/client"
import clsx from "clsx"
import { ChevronLeft } from "lucide-react"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { adminBulkUpdatesPath } from "~/common/paths"
import DateTimeCell from "~/components/DateTimeCell"
import Container from "~/ui/Container"
import { TD, TH, Table } from "~/ui/DetailTable"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import Loading from "~/ui/Loading"

export const AdminBulkUpdate_BulkUpdateFragment = gql(`
  fragment AdminBulkUpdate_BulkUpdateFragment on BulkUpdate {
    id
    number
    description
    importedSeriesImportRowsCount
    completedAt
    createdAt
    studyCount
    importErrorCounts
    importSkippedCounts
    csvUrl
  }
`)

const AdminStudyArchiveScreen_Query = gql(`
  query AdminStudyArchiveScreen_Query($id: ID!) {
    node(id: $id) {
      ...AdminBulkUpdate_BulkUpdateFragment
    }
  }
`)

const AdminStudyArchiveScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")

  const { data, loading, error } = useQuery(AdminStudyArchiveScreen_Query, {
    variables: {
      id: id,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "BulkUpdate")
    return <Error error={error} />

  const bulkUpdate = getFragmentData(
    AdminBulkUpdate_BulkUpdateFragment,
    data.node
  )
  const backPath = adminBulkUpdatesPath.pattern

  return (
    <div className="flex flex-col gap-8">
      <div
        className={clsx(
          "flex flex-col gap-4",
          "lg:flex-row lg:items-center lg:justify-between"
        )}
      >
        <div className="flex items-center">
          <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
            <ChevronLeft size={28} />
          </Link>
          <H1 margin={false}>Study Archive {bulkUpdate.number}</H1>
        </div>
      </div>
      <HR padding={false} />
      <Container size="md">
        <Table>
          <thead>
            <tr>
              <TH colSpan={2}>Details</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD>#</TD>
              <TD>{bulkUpdate.number}</TD>
            </tr>
            <tr>
              <TD>Description</TD>
              <TD>
                <div className="whitespace-pre-wrap">
                  {bulkUpdate.description}
                </div>
              </TD>
            </tr>
            <tr>
              <TD>CSV</TD>
              <TD>
                {!!bulkUpdate.csvUrl && (
                  <Link to={bulkUpdate.csvUrl} target="_blank" variant="blue">
                    Download
                  </Link>
                )}
              </TD>
            </tr>
            <tr>
              <TD>Created</TD>
              <TD>
                <DateTimeCell date={bulkUpdate.createdAt} />
              </TD>
            </tr>
          </tbody>
        </Table>
      </Container>
    </div>
  )
}

export default AdminStudyArchiveScreen
