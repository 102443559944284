import { currency } from "./currency"

export const studyOrderPrice = (count: number) => {
  return currency(studyOrderPriceCents(count))
}

export const studyOrderPriceCents = (count: number) => {
  return count * perStudyPriceCents(count)
}

// Duplicated in app/models/order.rb
const perStudyPriceCents = (count: number) => {
  if (count <= 1000) {
    return 4000
  } else if (count <= 10000) {
    return 2000
  } else if (count <= 100000) {
    return 1000
  } else {
    return 500
  }
}

export const imageOrderPriceCents = (count: number) => {
  return count * 5
}
