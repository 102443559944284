"use client"

import { Check, ChevronDown } from "lucide-react"
import * as React from "react"

import clsx from "clsx"
import { cn } from "~/lib/utils"
import Button from "~/ui/Button"
import { OptionType } from "~/ui/FormikField"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./Command"
import { Popover, PopoverContent, PopoverTrigger } from "./Popover"

const Combobox = ({
  options,
  value,
  onChange,
  search = true,
  placeholder,
  triggerClassName,
}: {
  options: OptionType[]
  value: string | undefined
  onChange: (newValue: string | undefined) => void
  search?: boolean
  placeholder?: string
  triggerClassName?: string
}) => {
  const [open, setOpen] = React.useState(false)
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outlineSecondary"
          role="combobox"
          aria-expanded={open}
          className={clsx(
            "justify-between rounded-sm bg-white",
            triggerClassName
          )}
        >
          <div className="truncate">
            {value
              ? options.find((option) => option.value === value)?.label
              : placeholder || "Select..."}
          </div>
          <ChevronDown
            className={clsx("ml-2 h-4 w-4 shrink-0 opacity-50", {
              "rotate-180": open,
            })}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" align="start">
        <Command options={options}>
          {search && <CommandInput placeholder="Search option..." />}
          <CommandEmpty>0 results</CommandEmpty>
          <CommandGroup>
            <div className="max-h-[200px] overflow-y-auto">
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={(_currentValue) => {
                    // Using option.value instead of currentValue due to https://github.com/pacocoursey/cmdk/issues/150
                    onChange(option.value)
                    setOpen(false)
                  }}
                  data-disabled={option.disabled || undefined}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === option.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </div>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default Combobox
