import clsx from "clsx"
import pluralize from "pluralize"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import { ORDERS_LIMIT } from "../AdminOrdersIndexScreen"
import AdminOrderRow, { AdminOrderRow_OrderFragment } from "./AdminOrderRow"

export const AdminOrders_QueryFragment = gql(`
  fragment AdminOrders_QueryFragment on Query {
    orders(page: $page, status: $status, search: $search, userId: $userId, limit: $limit) {
      orders {
        edges {
          node {
            ...AdminOrderRow_OrderFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const AdminOrdersTable = ({
  orders,
  totalCount,
  page,
  loading,
}: {
  orders: FragmentType<typeof AdminOrderRow_OrderFragment>[]
  totalCount: number
  page: number
  loading: boolean
}) => {
  const totalPages = Math.ceil(totalCount / ORDERS_LIMIT)

  return (
    <div
      className={clsx("flex flex-col gap-6 text-base", {
        "animate-pulse opacity-90": loading,
      })}
    >
      <div className="flex flex-col gap-6 text-base">
        <div className="my-1">
          <b>
            {totalCount.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}
          </b>{" "}
          {pluralize("order", totalCount)}
        </div>
      </div>

      {orders.length > 0 && (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="min-w-[140px]">Order Created</TableHead>
                <TableHead className="min-w-[200px]">Status</TableHead>
                <TableHead className="min-w-[120px]">Delivered</TableHead>
                <TableHead className="min-w-[158px]">Buyer</TableHead>
                <TableHead className="min-w-[140px]">Order Number</TableHead>
                <TableHead className="min-w-[200px]">Estimated Price</TableHead>
                <TableHead className="min-w-[200px]">Actual Price</TableHead>
                <TableHead className="min-w-[200px]">Item Count</TableHead>
                <TableHead className="w-[100px]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {orders.map((order) => (
                <AdminOrderRow
                  key={getFragmentData(AdminOrderRow_OrderFragment, order).id}
                  data={order}
                />
              ))}
            </TableBody>
          </Table>
          <Pagination page={page} totalPages={totalPages} />
        </>
      )}
    </div>
  )
}

export default AdminOrdersTable
