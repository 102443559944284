import { useMutation } from "@apollo/client"
import { Form, Formik, FormikHelpers } from "formik"
import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { SearchSexEnum } from "~/__generated__/graphql"
import { studySearchResultsPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import useSearchSubscription from "~/hooks/useSearchSubscription"
import ConfirmDialog from "~/ui/ConfirmDialog"
import {
  StudySearchResultsSummary_SearchFragment,
  StudySearchResultsSummary_SearchParamsFragment,
} from "../../StudySearchResultsScreen/components/StudySearchResultsSummary"
import { boolOrUndefined } from "./NewStudySearchForm"
import StudySearchFields, {
  SearchFormValues,
  searchValidationSchema,
} from "./StudySearchFields"

const StudySearchupdate_Mutation = gql(`
  mutation SearchUpdate($input: SearchUpdateInput!) {
    searchUpdate(input: $input) {
      search {
        ...StudySearchResultsSummary_SearchFragment
      }
    }
  }
`)

const EditStudySearchForm = ({
  data,
}: {
  data: FragmentType<typeof StudySearchResultsSummary_SearchFragment>
}) => {
  const [requiresConfirmation, setRequiresConfirmation] = useState(true)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const navigate = useNavigate()
  const search = getFragmentData(StudySearchResultsSummary_SearchFragment, data)
  const studySearchParams = getFragmentData(
    StudySearchResultsSummary_SearchParamsFragment,
    search.studySearchParams
  )
  invariant(studySearchParams, "Expected study search params")
  useSearchSubscription(search.id)
  const [mutate] = useMutation(StudySearchupdate_Mutation)

  const initialValues: SearchFormValues = {
    patientAgeMin: studySearchParams.patientAgeMin.toString(),
    patientAgeMax: studySearchParams.patientAgeMax.toString(),
    sex: studySearchParams.sex.toString(),

    mr: studySearchParams.mr ?? false,
    mrStrength: studySearchParams.mrStrength
      ? studySearchParams.mrStrength.toString()
      : undefined,
    mrContrast: boolOrUndefined(studySearchParams.mrContrast),
    mrReportKeywords: studySearchParams.mrReportKeywords || "",
    mrDescriptionKeywords: studySearchParams.mrDescriptionKeywords || "",
    mrSeriesDescriptionKeywords:
      studySearchParams.mrSeriesDescriptionKeywords || "",

    ct: studySearchParams.ct ?? false,
    ctContrast: boolOrUndefined(studySearchParams.ctContrast),
    ctReportKeywords: studySearchParams.ctReportKeywords || "",
    ctDescriptionKeywords: studySearchParams.ctDescriptionKeywords || "",
    ctSeriesDescriptionKeywords:
      studySearchParams.ctSeriesDescriptionKeywords || "",

    us: studySearchParams.us ?? false,
    usReportKeywords: studySearchParams.usReportKeywords || "",
    usDescriptionKeywords: studySearchParams.usDescriptionKeywords || "",
    usSeriesDescriptionKeywords:
      studySearchParams.usSeriesDescriptionKeywords || "",

    xr: studySearchParams.xr ?? false,
    xrReportKeywords: studySearchParams.xrReportKeywords || "",
    xrDescriptionKeywords: studySearchParams.xrDescriptionKeywords || "",
    xrSeriesDescriptionKeywords:
      studySearchParams.xrSeriesDescriptionKeywords || "",
  }

  const onSubmit = async (
    values: SearchFormValues,
    { setFieldError }: FormikHelpers<SearchFormValues>
  ) => {
    if (requiresConfirmation) {
      setIsConfirmDialogOpen(true)
      return
    }

    return await mutate({
      variables: {
        input: {
          id: search.id,
          searchInput: {
            name: search.name,
          },
          studySearchParamsInput: {
            ...values,
            patientAgeMin: Number(values.patientAgeMin),
            patientAgeMax: Number(values.patientAgeMax),
            sex: values.sex as SearchSexEnum,
            mrContrast: values.mrContrast
              ? Boolean(values.mrContrast)
              : undefined,
            mrStrength: values.mrStrength
              ? Number(values.mrStrength)
              : undefined,
            ctContrast: values.ctContrast
              ? Boolean(values.ctContrast)
              : undefined,
          },
        },
      },
      onCompleted: () => {
        toast.success("Search has been saved")
        navigate(studySearchResultsPath({ id: search.id }))
      },
      onError: ({ graphQLErrors }) => {
        displayErrors(graphQLErrors, setFieldError)
      },
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={searchValidationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => (
        <Form>
          <StudySearchFields isDuplicatingSearch={search.isDuplicatingSearch} />
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            onOpenChange={setIsConfirmDialogOpen}
            confirm={() => {
              setRequiresConfirmation(false)
              submitForm()
            }}
          >
            Are you sure you want to update this search? Any selected studies
            will be lost
          </ConfirmDialog>
        </Form>
      )}
    </Formik>
  )
}

export default EditStudySearchForm
