import pluralize from "pluralize"
import { Order } from "~/__generated__/graphql"

export const getManualCountTypePlural = ({
  order,
}: {
  order: Pick<Order, "manualCount" | "isImageCount">
}) => {
  const { isImageCount, manualCount } = order
  return pluralize(
    isImageCount ? "image" : "study",
    manualCount?.trim() === "1" ? 1 : 2
  )
}
