import clsx from "clsx"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { ethnicityRaceDistributionPath, logInPath } from "~/common/paths"
import useLoggedInLink from "~/hooks/useLoggedInLink"
import ImportIcon from "~/images/icons/import.svg"
import { ADMIN_ROOT_PATHS, ROOT_PATHS } from "~/layouts/DashboardLayout"

const navigationLinkClasses = ({ isActive }: { isActive: boolean }) =>
  clsx("flex items-center rounded-md px-4 py-1.5 text-sm text-white", {
    "bg-dodger-blue": isActive,
    "hover:opacity-75": !isActive,
  })

const NavigationRow = ({
  path,
  name,
  Icon,
  external,
  requireUser,
}: {
  path: string
  name: string
  Icon: string
  external?: boolean
  requireUser?: boolean
}) => {
  const loggedInLink = useLoggedInLink()

  const IconContainer = (
    <div className="mr-3 text-candlelight">
      <Icon />
    </div>
  )

  if (external) {
    return (
      <a href={path} className={navigationLinkClasses({ isActive: false })}>
        {IconContainer}
        {name}
      </a>
    )
  }

  return (
    <NavLink
      to={requireUser ? loggedInLink(path, true) : path}
      className={navigationLinkClasses}
    >
      {IconContainer}
      {name}
    </NavLink>
  )
}

export const MainNavFooter = () => {
  return (
    <div className="flex flex-col gap-3 text-center text-2xs text-white/50">
      <div>
        <Link
          to={ethnicityRaceDistributionPath.pattern}
          className="hover:underline hover:opacity-75"
        >
          Ethnicity & Race Distribution
        </Link>
      </div>
      <div className="mx-auto max-w-[190px]">
        Copyright © {new Date().getFullYear()} All Rights Reserved. Rad Image
        Net.
      </div>
    </div>
  )
}

const MainNav = () => {
  const { currentUser } = useCurrentUserMaybe()
  const loggedInLink = useLoggedInLink()
  const location = useLocation()
  const isAdminPath = location.pathname.startsWith("/admin")
  const showAdmin = currentUser && currentUser.isAdmin && isAdminPath
  const rootPaths = showAdmin ? ADMIN_ROOT_PATHS : ROOT_PATHS

  return (
    <>
      <div>
        <div className="mb-2 ml-4 text-2xs font-bold uppercase tracking-[0.5px] text-slate-gray">
          {showAdmin ? "Administrator" : "Navigation"}
        </div>
        {showAdmin
          ? Object.values(rootPaths).map(({ name, icon, path, external }) => (
              <NavigationRow
                key={name}
                name={name}
                Icon={icon}
                path={path}
                external={external}
              />
            ))
          : Object.values(rootPaths)
              .filter((p) =>
                p.path.startsWith("/admin") ? currentUser?.isAdmin : true
              )
              .map((p) => {
                if (p.name === ROOT_PATHS.signOut.name && !currentUser) {
                  return {
                    name: "Sign In",
                    icon: ImportIcon,
                    path: loggedInLink(logInPath.pattern, true, true),
                    external: true,
                    requireUser: true,
                  }
                }
                return p
              })
              .map(({ name, icon, path, external, requireUser }) => (
                <NavigationRow
                  key={name}
                  name={name}
                  Icon={icon}
                  path={path}
                  external={external}
                  requireUser={requireUser}
                />
              ))}
      </div>
    </>
  )
}

export default MainNav
