import Container from "~/ui/Container"
import { TD, TH, Table } from "~/ui/DetailTable"
import { H1 } from "~/ui/Headings"
import PageContent from "~/ui/PageContent"

const EthnicityRaceDistributionScreen = () => {
  return (
    <div className="text-sm text-gray-600">
      <PageContent>
        <Container size="md">
          <H1>Description of Data & Project</H1>

          <div className="mb-8 mt-2 text-neutral-500">
            Sample Ethnicity & Race distribution of patients in the dataset:
          </div>

          <div className="flex w-full flex-col gap-8">
            <Table>
              <colgroup>
                <col />
                <col className="w-[50%]" />
              </colgroup>
              <thead>
                <tr>
                  <TH colSpan={2}>Ethnicity</TH>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TD>Hispanic or Latino</TD>
                  <TD>18.73%</TD>
                </tr>
                <tr>
                  <TD>Not Hispanic or Latino</TD>
                  <TD>81.27%</TD>
                </tr>
              </tbody>
            </Table>

            <Table>
              <colgroup>
                <col />
                <col className="w-[50%]" />
              </colgroup>
              <thead>
                <tr>
                  <TH colSpan={2}>Race</TH>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-azul-claro-blue/70">
                  <TD>White</TD>
                  <TD>70.93%</TD>
                </tr>
                <tr className="bg-azul-claro-blue/70">
                  <TD>Black or African America</TD>
                  <TD>14.78%</TD>
                </tr>
                <tr className="bg-azul-claro-blue/70">
                  <TD>American Indian & Alaska Native</TD>
                  <TD>0.01%</TD>
                </tr>
                <tr className="bg-azul-claro-blue/70">
                  <TD>Asian</TD>
                  <TD>7.26%</TD>
                </tr>
                <tr>
                  <TD>Native Hawaiian & Other Pacific Islander</TD>
                  <TD>0.00%</TD>
                </tr>
                <tr>
                  <TD>Other</TD>
                  <TD>0.01%</TD>
                </tr>
              </tbody>
            </Table>
          </div>
        </Container>
      </PageContent>
    </div>
  )
}

export default EthnicityRaceDistributionScreen
