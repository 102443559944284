import clsx from "clsx"
import { ChevronLeft } from "lucide-react"
import { Link } from "react-router-dom"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { adminStudyPath } from "~/common/paths"
import StudyDetailsTable from "~/screens/SearchScreen/screens/StudySearchResultsScreen/screens/StudySearchResultScreen/components/StudyDetailsTable"
import { ButtonLink } from "~/ui/Button"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"
import DetailsTableWithDescription from "./DetailsTableWithDescription"
import PurchasedStudyBadge from "./PurchasedStudyBadge"
import ReportText from "./ReportText"

export const StudyScreenContent_StudyFragment = gql(`
  fragment StudyScreenContent_StudyFragment on Study {
    id
    patientAge
    sex
    date
    modality
    contactSheetUrl
    imageCount
    description
    reportConclusion
    purchased
    seriesDescriptions
    bodyPart {
      id
      name
    }
  }
`)

const StudyScreenContent = ({
  data,
  backPath,
}: {
  data: FragmentType<typeof StudyScreenContent_StudyFragment>
  backPath: string
}) => {
  const study = getFragmentData(StudyScreenContent_StudyFragment, data)
  const { currentUser } = useCurrentUserMaybe()

  return (
    <div>
      <div
        className={clsx(
          "mb-6 flex flex-col gap-6",
          "lg:mb-0 lg:flex-row lg:justify-between"
        )}
      >
        <div className="flex items-end">
          <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
            <ChevronLeft size={28} />
          </Link>
          <H1 margin={false}>
            <div className="flex flex-wrap items-center gap-2">
              {study.description} {study.purchased && <PurchasedStudyBadge />}
            </div>
          </H1>
        </div>
        {currentUser?.isAdmin && (
          <div>
            <ButtonLink to={adminStudyPath({ id: study.id })} variant="primary">
              Admin Study
            </ButtonLink>
          </div>
        )}
      </div>
      <DetailsTableWithDescription
        description={{
          title: "Report Summary",
          content: <ReportText text={study.reportConclusion || ""} />,
        }}
      >
        <StudyDetailsTable data={data} />
      </DetailsTableWithDescription>
      {!!study.contactSheetUrl && (
        <>
          <HR fullWidth={true} />
          <div className="mt-4">
            <img src={study.contactSheetUrl} alt="Contact Sheet" />
          </div>
        </>
      )}
    </div>
  )
}

export default StudyScreenContent
