import { PropsWithChildren } from "react"
import ArrowRight from "~/images/icons/arrow-right.svg"
import Button from "~/ui/Button"

const AdminOrderSendEmailButton = ({
  onClick,
  disabled,
  children,
}: { onClick: () => void; disabled?: boolean } & PropsWithChildren) => {
  return (
    <Button onClick={onClick} disabled={disabled} display="inline">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center justify-between gap-2">
          {children}
        </div>
        <div>
          <ArrowRight />
        </div>
      </div>
    </Button>
  )
}

export default AdminOrderSendEmailButton
