import clsx from "clsx"
import { Fragment, ReactElement } from "react"
import { Link, UIMatch, useMatches } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { logInPath, registrationPath } from "~/common/paths"
import useLoggedInLink from "~/hooks/useLoggedInLink"
import ChevronRight from "~/images/icons/chevron-right.svg"
import ManageSearches from "~/images/icons/manage-searches.svg"
import { ButtonLink } from "~/ui/Button"
import Container from "~/ui/Container"

export const BreadCrumb = ({
  path,
  name,
  index,
  icon: Icon,
}: {
  path: string
  name: string
  index: number
  icon?: string
}) => {
  if (Icon) {
    return (
      <Link
        to={path}
        className={clsx(
          "flex items-center gap-2 font-semibold",
          "hover:opacity-75",
          {
            "text-dodger-blue": index === 0,
          }
        )}
      >
        <Icon />
        {name}
      </Link>
    )
  }

  return (
    <>
      <div>
        <ChevronRight />
      </div>
      <Link
        key={name}
        to={path}
        className={clsx("font-semibold", {
          "text-dodger-blue": index === 0,
        })}
      >
        {name}
      </Link>
    </>
  )
}

const Breadcrumbs = () => {
  const matches = useMatches() as UIMatch<
    unknown,
    { crumb: (path: string, index: number) => ReactElement }
  >[]
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match, index) => {
      return match.handle.crumb(match.pathname, index)
    })
  const { currentUser } = useCurrentUserMaybe()
  const loggedInLink = useLoggedInLink()

  return (
    <>
      <div
        className={clsx(
          "flex flex-1 border-b border-border-gray px-4 py-4",
          "md:px-8"
        )}
      >
        <div className="flex items-center gap-4 text-sm text-slate-gray">
          {crumbs.map((crumb, index) => (
            <Fragment key={index}>{crumb}</Fragment>
          ))}
        </div>
      </div>
      {!currentUser && (
        <div className="-mt-[1px] bg-candlelight">
          <Container padded={true}>
            <div className="flex w-full justify-between gap-4">
              <div className={clsx("flex items-center gap-4", "md:gap-8")}>
                <div>
                  <ManageSearches />
                </div>
                <div className="whitespace-break-spaces">
                  <div
                    className={clsx(
                      "text-xl font-semibold text-blue-zodiac",
                      "md:text-2xl"
                    )}
                  >
                    Manage Your Searches
                  </div>
                  <div
                    className={clsx("textdark-rift text-sm", "md:text-base")}
                  >
                    <Link
                      to={loggedInLink(registrationPath.pattern, true)}
                      className="font-semibold"
                    >
                      Create an Account
                    </Link>
                    {" or "}
                    <Link
                      to={loggedInLink(logInPath.pattern, true, true)}
                      className="font-semibold"
                    >
                      Sign In
                    </Link>
                    {" to see more information and manage your searches."}
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  "hidden flex-shrink-0 items-center gap-2",
                  "lg:flex"
                )}
              >
                <div>
                  <ButtonLink
                    to={loggedInLink(logInPath.pattern, true, true)}
                    variant="outlineDarkBlue"
                  >
                    Sign In
                  </ButtonLink>
                </div>
                <div>
                  <ButtonLink
                    to={loggedInLink(registrationPath.pattern, true)}
                    variant="darkBlue"
                  >
                    Create an Account
                  </ButtonLink>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

export default Breadcrumbs
