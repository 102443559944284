import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"
import { createConsumer } from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"
import * as React from "react"
import { HelmetProvider } from "react-helmet-async"
import { Toaster } from "react-hot-toast"
import { RouterProvider } from "react-router-dom"
import { CurrentUserProvider } from "./auth/CurrentUserContext"
import { csrfToken } from "./common/csrf"
import { router } from "./router"

// @ts-expect-error this is a vite-only feature
if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const cable = createConsumer()

const hasSubscriptionOperation = ({ query: { definitions } }: any) => {
  return definitions.some(
    ({ kind, operation }: any) =>
      kind === "OperationDefinition" && operation === "subscription"
  )
}

let token = csrfToken()

const httpLink = new HttpLink({
  credentials: "same-origin",
  headers: {
    ...(token && { "X-CSRF-Token": token }),
  },
})

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

const apolloClient = new ApolloClient({
  uri: "/graphql",
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

export const App = () => {
  return (
    <div className="flex min-h-full">
      <React.StrictMode>
        <HelmetProvider>
          <ApolloProvider client={apolloClient}>
            <CurrentUserProvider>
              <Toaster />
              <RouterProvider router={router} />
            </CurrentUserProvider>
          </ApolloProvider>
        </HelmetProvider>
      </React.StrictMode>
    </div>
  )
}
