import * as Sentry from "@sentry/react"
import { createRoot } from "react-dom/client"
import { App } from "~/app"
import env from "~/env"

if (env.SENTRY_DSN) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    tracesSampleRate: 0,
    environment: env.ENVIRONMENT,
    enabled: env.ENABLE_SENTRY === "true",
  })
}

const rootEl = document.getElementById("react-root")
if (rootEl) {
  const root = createRoot(rootEl)
  root.render(<App />)
} else {
  console.error("no react-root")
}
