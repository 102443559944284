import clsx from "clsx"
import { Children } from "~/types"
import { FormikFieldType } from "./FormikField"
import { Link } from "./Link"

type Props = Pick<
  FormikFieldType,
  "label" | "light" | "labelLink" | "required" | "labelSize" | "labelInline"
>

export const FieldLabelRequired = () => (
  <span className="ml-1 text-red-400" aria-hidden="true">
    *
  </span>
)

export const InnerFieldLabel = ({
  label,
  light,
  labelLink,
  required,
  labelSize,
}: Props) => {
  const darkColor = labelSize === "sm" ? "text-slate-gray" : "text-elderberry"
  const size = labelSize === "sm" ? "text-xs" : "text-sm"

  return (
    <div className="flex flex-1 justify-between py-2">
      <div
        className={clsx("relative", size, light ? "text-white/80" : darkColor)}
      >
        {label}
        {required && <FieldLabelRequired />}
      </div>
      {!!labelLink && (
        <Link
          to={labelLink.linkTo}
          className="text-xs text-dodger-blue"
          tabIndex={-1}
          underline={false}
        >
          {labelLink.title}
        </Link>
      )}
    </div>
  )
}

const FieldLabelContainer = ({
  label,
  light,
  labelLink,
  required,
  labelSize,
  labelInline,
  children,
}: Props & Children) => {
  if (!label) return <>{children}</>

  return (
    <label
      className={
        labelInline ? "flex flex-row-reverse items-center gap-2" : "block"
      }
    >
      <InnerFieldLabel
        label={label}
        light={light}
        labelLink={labelLink}
        required={required}
        labelSize={labelSize}
      />
      {children || "-"}
    </label>
  )
}

export default FieldLabelContainer
