import clsx from "clsx"
import { useFormikContext } from "formik"
import pluralize from "pluralize"
import { useRef, useState } from "react"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { searchPath } from "~/common/paths"
import Pagination from "~/components/Pagination"
import SearchSupportMessage from "~/components/SearchSupportMessage"
import { Switch } from "~/components/ui/switch"
import ConfirmDialog from "~/ui/ConfirmDialog"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import {
  STUDY_RESULTS_LIMIT,
  StudySearchResultsFormValues,
} from "../StudySearchResultsScreen"
import LockedFeature from "./LockedFeature"
import RequestExportButton from "./RequestExportButton"
import { StudyResultsRow_StudyFragment } from "./StudyResultsRow"
import StudySearchResultsTable from "./StudySearchResultsTable"

export const StudySearchResultsSummary_SearchParamsFragment = gql(`
  fragment StudySearchResultsSummary_SearchParamsFragment on StudySearchParams {
    id
    patientAgeMin
    patientAgeMax
    sex
    mr
    mrStrength
    mrContrast
    mrReportKeywords
    mrDescriptionKeywords
    mrSeriesDescriptionKeywords
    ct
    ctContrast
    ctReportKeywords
    ctDescriptionKeywords
    ctSeriesDescriptionKeywords
    us
    usReportKeywords
    usDescriptionKeywords
    usSeriesDescriptionKeywords
    xr
    xrReportKeywords
    xrDescriptionKeywords
    xrSeriesDescriptionKeywords
  }
`)

export const StudySearchResultsSummary_SearchFragment = gql(`
  fragment StudySearchResultsSummary_SearchFragment on Search {
    id
    name
    studyIdsDiff
    selectAll
    isDuplicatingSearch
    isCreatingResults
    isUpdatingSelections
    resultsCount
    studySearchParams {
      ...StudySearchResultsSummary_SearchParamsFragment
    }
    imageSearchParams {
      id
      imageCount
      ganLabel {
        id
        uid
        name
      }
    }
  }
`)

export const StudySearchResultsSummary_QueryFragment = gql(`
  fragment StudySearchResultsSummary_QueryFragment on Query {
    searchResults(id: $id, searchParams: $searchParams, page: $page, limit: $limit) {
      studies {
        edges {
          node {
            ...StudyResultsRow_StudyFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      search {
        ...StudySearchResultsSummary_SearchFragment
      }
      page
    }
  }
`)

const StudySearchResultsSummary = ({
  data,
  studies,
  studyCount,
  page,
  loading,
}: {
  data: FragmentType<typeof StudySearchResultsSummary_SearchFragment>
  studies: FragmentType<typeof StudyResultsRow_StudyFragment>[]
  studyCount: number
  page: number
  loading: boolean
}) => {
  const { id } = useParams()
  invariant(id)
  const scrollRef = useRef<HTMLDivElement>(null)
  const { currentUser } = useCurrentUserMaybe()
  const { values, setFieldValue } =
    useFormikContext<StudySearchResultsFormValues>()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const search = getFragmentData(StudySearchResultsSummary_SearchFragment, data)
  const studySearchParams = getFragmentData(
    StudySearchResultsSummary_SearchParamsFragment,
    search.studySearchParams
  )
  invariant(studySearchParams)
  const totalPages = Math.ceil(studyCount / STUDY_RESULTS_LIMIT)

  const onToggleSelectAll = () => {
    if (values.studyIdsDiff.length > 0) {
      setIsConfirmDialogOpen(true)
    } else {
      const newValue = !values.selectAll
      setFieldValue("selectAll", newValue)
      setFieldValue("studyIdsDiff", [])
    }
  }

  return (
    <div className="flex flex-col gap-6 text-base">
      <div
        className={clsx(
          "flex flex-col gap-2",
          "lg:flex-row lg:items-center lg:justify-between lg:gap-4"
        )}
      >
        <div ref={scrollRef}>
          <H1>Results Summary</H1>
          <div className="my-1">
            <b>{studyCount.toLocaleString("en-US")}</b>{" "}
            {pluralize("study", studyCount)} found that match your search
            parameters.{" "}
            <Link
              to={searchPath({ id })}
              variant="blue"
              underline={true}
              className="text-sm"
              preserveSearch={true}
            >
              Edit Search
            </Link>
          </div>
        </div>
        <div className="flex gap-4">
          <LockedFeature>
            <label
              className={clsx(
                "inline-flex cursor-pointer items-center gap-2 rounded-md border border-border-gray px-3 py-2 text-sm font-semibold",
                "hover:opacity-90"
              )}
              id="select-all"
            >
              <Switch
                id="select-all"
                checked={values.selectAll}
                data-test="select-all"
                onClick={onToggleSelectAll}
              />
              Select All Studies on All Pages
            </label>
          </LockedFeature>
          {currentUser && <RequestExportButton />}
        </div>
      </div>
      {studies.length > 0 && (
        <div className={loading ? "animate-pulse opacity-50" : undefined}>
          <StudySearchResultsTable studies={studies} />
        </div>
      )}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onOpenChange={setIsConfirmDialogOpen}
        confirm={() => {
          setFieldValue("selectAll", !values.selectAll)
          setFieldValue("studyIdsDiff", [])
          setIsConfirmDialogOpen(false)
        }}
      >
        {values.selectAll
          ? "Are you sure? This will deselect all studies"
          : "Are you sure? This will select all studies"}
      </ConfirmDialog>
      <Pagination
        page={page}
        totalPages={totalPages}
        onClick={() => {
          scrollRef.current?.scrollIntoView()
        }}
      >
        <SearchSupportMessage />
      </Pagination>
    </div>
  )
}

export default StudySearchResultsSummary
