import { useQuery } from "@apollo/client"
import clsx from "clsx"
import { Form, Formik, useFormikContext } from "formik"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { newObjectId, registrationPath, searchPath } from "~/common/paths"
import ResultsHr from "~/components/ResultsHr"
import SearchSupportMessage from "~/components/SearchSupportMessage"
import useImageSearchParamValues from "~/hooks/useImageSearchParamValues"
import useLoggedInLink from "~/hooks/useLoggedInLink"
import Button, { ButtonLink } from "~/ui/Button"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import { ImageSearchFormValues } from "../SearchFormScreen/components/ImageSearchFields"
import ImageSearchResultsStats from "./components/ImageSearchResultsStats"
import ImageSearchResultsSummary, {
  ImageSearchResultsSummary_SearchFragment,
} from "./components/ImageSearchResultsSummary"
import NewImageSearchDialog from "./components/NewImageSearchDialog"

export const ImageSearchResultsScreen_Query = gql(`
  query ImageSearchResultsScreen_Query($id: ID!) {
    search: node(id: $id) {
      ...ImageSearchResultsSummary_SearchFragment
    }
  }
`)

export const ImageSearchResultsGanLabel_Query = gql(`
  query ImageSearchResultsGanLabel_Query($id: ID!) {
    ganLabel(id: $id) {
      ...ImageSearchResultsSummary_GanLabelFragment
    }
  }
`)

export const STUDY_RESULTS_LIMIT = 12

export type StudySearchResultsFormValues = {
  studyIdsDiff: string[]
  selectAll: boolean
  submitType: "search" | "order"
  acceptedTerms: boolean
}

const HeaderActions = () => {
  const { id } = useParams()
  invariant(id, "Expected search id")
  const { currentUser } = useCurrentUserMaybe()
  const { submitForm, isSubmitting } =
    useFormikContext<StudySearchResultsFormValues>()
  const loggedInLink = useLoggedInLink()

  return (
    <div className="flex items-center gap-2">
      <ButtonLink
        to={searchPath({ id })}
        preserveSearch={true}
        display="inline"
      >
        Edit Search
      </ButtonLink>
      {!!currentUser ? (
        <>
          {id === newObjectId ? (
            <NewImageSearchDialog />
          ) : (
            <Button
              variant="outlineSecondary"
              display="inline"
              type="button"
              className={clsx({
                "animate-pulse": isSubmitting,
              })}
              onClick={submitForm}
              disabled={isSubmitting}
            >
              Save Search
            </Button>
          )}
        </>
      ) : (
        <ButtonLink
          to={loggedInLink(registrationPath.pattern, true)}
          display="inline"
          variant="outlineSecondary"
        >
          Save Search
        </ButtonLink>
      )}
    </div>
  )
}

const Content = () => {
  const { values } = useFormikContext<ImageSearchFormValues>()

  const { data, loading, error } = useQuery(ImageSearchResultsGanLabel_Query, {
    variables: {
      id: values.ganLabelId,
    },
  })

  if (loading) return <Loading />
  if (error || !data?.ganLabel) return <Error error={error} />
  invariant(data.ganLabel.__typename === "GanLabel", "Expected ganLabel")

  return (
    <div className="flex min-h-[800px] flex-col justify-between gap-8">
      <div className="flex flex-col gap-8">
        <div
          className={clsx(
            "flex flex-col gap-6",
            "lg:flex-row lg:justify-between"
          )}
        >
          <div>
            <H1 margin={false}>Generative Search Results</H1>
          </div>
          <HeaderActions />
        </div>
        <ImageSearchResultsStats imageCount={Number(values.imageCount)} />
        <ResultsHr />
        <ImageSearchResultsSummary ganLabelData={data?.ganLabel} />
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-row-reverse">
          <SearchSupportMessage />
        </div>
        <ResultsHr />
        <ImageSearchResultsStats imageCount={Number(values.imageCount)} />
      </div>
    </div>
  )
}

const ImageSearchResultsScreen = () => {
  const { id } = useParams()
  invariant(id, "Expected search id")
  const imageSearchParamValues = useImageSearchParamValues()
  const { data, loading, error } = useQuery(ImageSearchResultsScreen_Query, {
    variables: {
      id,
    },
    skip: id === newObjectId,
  })

  if (loading) return <Loading />
  if (error) return <Error error={error} />

  const search =
    data?.search.__typename === "Search"
      ? getFragmentData(ImageSearchResultsSummary_SearchFragment, data.search)
      : undefined

  const initialValues: ImageSearchFormValues = {
    imageCount:
      search?.imageSearchParams?.imageCount?.toString() ||
      imageSearchParamValues.imageCount ||
      "",
    ganLabelId:
      search?.imageSearchParams?.ganLabel.uid ||
      imageSearchParamValues.ganLabelId,
  }

  const onSubmit = async () => {
    toast.success("Search has been saved")
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <Form>
        <Content />
      </Form>
    </Formik>
  )
}

export default ImageSearchResultsScreen
