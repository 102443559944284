import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params"

export const adminOrdersParamValues = {
  status: withDefault(ArrayParam, []),
  search: withDefault(StringParam, ""),
  page: NumberParam,
}

const useAdminOrdersParamValues = () => {
  return useQueryParams(adminOrdersParamValues)
}

export default useAdminOrdersParamValues
