import { useMutation } from "@apollo/client"
import { FormikHelpers } from "formik"
import { ChevronLeft } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { BulkUpdateTypeEnum } from "~/__generated__/graphql"
import { adminBulkUpdatesPath, adminStudyImportPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import AdminBulkUpdateForm, {
  BULK_UPDATE_CREATE_MUTATION,
  BulkUpdateFormValues,
} from "../../components/AdminBulkUpdateForm"

const initialValues = {
  description: "",
  csv: "",
  csvUrl: "",
}

const AdminNewStudyImportScreen = () => {
  const backPath = adminBulkUpdatesPath.pattern
  const [mutate] = useMutation(BULK_UPDATE_CREATE_MUTATION)
  const navigate = useNavigate()

  const onSubmit = async (
    values: BulkUpdateFormValues,
    { setFieldError }: FormikHelpers<BulkUpdateFormValues>
  ) => {
    try {
      const resp = await mutate({
        variables: {
          input: {
            bulkUpdateInput: {
              csv: values.csv,
              description: values.description,
              bulkUpdateType: BulkUpdateTypeEnum.StudyImport,
            },
          },
        },
      })
      toast.success("Study import has been created")
      const id = resp.data?.bulkUpdateCreate?.bulkUpdate?.id
      invariant(id, "expected study import id")
      navigate(adminStudyImportPath({ id }))
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center">
        <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
          <ChevronLeft size={28} />
        </Link>
        <H1 margin={false}>Import Studies</H1>
      </div>
      <HR padding={false} />
      <AdminBulkUpdateForm onSubmit={onSubmit} initialValues={initialValues} />
    </div>
  )
}

export default AdminNewStudyImportScreen
