import { useQuery } from "@apollo/client"
import clsx from "clsx"
import { Form, Formik } from "formik"
import { Check, X } from "lucide-react"
import { getFragmentData, gql } from "~/__generated__"
import { OrderStatusEnum } from "~/__generated__/graphql"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/DropdownMenu"
import useAdminOrdersParamValues from "~/hooks/useAdminOrdersParamValues"
import AdminSearchFields from "~/screens/AdminScreen/components/AdminSearchFields"
import Button from "~/ui/Button"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import SlidersIcon from "../../../../../images/icons/sliders.svg"
import AdminOrdersTable, {
  AdminOrders_QueryFragment,
} from "./components/AdminOrdersTable"

export const AdminOrdersScreen_Query = gql(`
  query AdminOrdersScreen_Query($page: Int, $status: [String!], $search: String, $userId: ID, $limit: Int) {
    ...AdminOrders_QueryFragment
  }
`)

export const ORDERS_LIMIT = 12

export const OrderStatusDisplay: Record<string, string> = {
  [OrderStatusEnum.Received]: "Received",
  [OrderStatusEnum.ContractReview]: "Contract Review",
  [OrderStatusEnum.PoGenerated]: "PO Generated",
  [OrderStatusEnum.PoApproved]: "PO Approved",
  [OrderStatusEnum.DataTransmitted]: "Data Transmitted",
  [OrderStatusEnum.PaymentReceived]: "Payment Received",
  [OrderStatusEnum.OrderCancelled]: "Order Cancelled",
}

const StatusFilter = () => {
  const [searchParams, setSearchParams] = useAdminOrdersParamValues()
  const searchParamsStatus = (searchParams.status || []) as string[]

  return (
    <div>
      <DropdownMenu>
        <div className="flex items-center gap-3">
          {searchParams.status?.length > 0 && (
            <button
              className={clsx("p-2", "hover:opacity-75")}
              title="Clear filters"
              onClick={() => {
                setSearchParams({
                  status: undefined,
                  search: searchParams.search || undefined,
                  page: searchParams.page || undefined,
                })
              }}
            >
              <X className="h-3 w-3 text-neutral-500" />
            </button>
          )}
          <DropdownMenuTrigger asChild>
            <div>
              <Button display="inline" variant="outlineSecondary">
                <div className="flex items-center gap-2">
                  <SlidersIcon />
                  Filter
                  {searchParamsStatus.length > 0 && (
                    <div className="mt-0.5 text-xs">
                      ({searchParamsStatus.length})
                    </div>
                  )}
                </div>
              </Button>
            </div>
          </DropdownMenuTrigger>
        </div>
        <DropdownMenuContent className="w-[240px]" side="bottom" align="end">
          <DropdownMenuGroup>
            {Object.keys(OrderStatusDisplay).map((status) => (
              <DropdownMenuItem asChild={true} key={status}>
                <button
                  className="w-full text-left"
                  onClick={() =>
                    setSearchParams({
                      status: searchParamsStatus.includes(status)
                        ? searchParams.status.filter((s) => s !== status)
                        : [...searchParams.status, status],
                      page: 1,
                    })
                  }
                >
                  <span
                    className={clsx(
                      "flex h-3.5 w-3.5 items-center justify-center text-dodger-blue",
                      {
                        invisible: !searchParamsStatus.includes(status),
                      }
                    )}
                  >
                    <Check className="h-4 w-4" />
                  </span>
                  {OrderStatusDisplay[status]}
                </button>
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const Search = () => {
  const [searchParams, setSearchParams] = useAdminOrdersParamValues()

  return (
    <Formik
      initialValues={{ search: searchParams.search || "" }}
      onSubmit={(values) => {
        setSearchParams({
          status: searchParams.status || undefined,
          search: values.search || undefined,
          page: undefined,
        })
      }}
    >
      <Form>
        <AdminSearchFields placeholder="Search by order number" />
      </Form>
    </Formik>
  )
}

const AdminOrdersIndexScreen = () => {
  const [searchParams] = useAdminOrdersParamValues()

  const page = searchParams.page

  const result = useQuery(AdminOrdersScreen_Query, {
    variables: {
      page: page ? Number(page) : 1,
      status: searchParams.status as string[] | undefined,
      search: searchParams.search,
      limit: ORDERS_LIMIT,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { orders } = getFragmentData(AdminOrders_QueryFragment, data)
  const totalCount = orders.orders.totalCount

  return (
    <div>
      <div
        className={clsx(
          "mb-6 flex flex-col gap-6",
          "lg:mb-0 lg:flex-row lg:justify-between"
        )}
      >
        <div>
          <H1>All Orders</H1>
        </div>
        <div className="flex items-center gap-6">
          <Search />
          <div className="h-[38px] border-l border-border-gray" />
          <StatusFilter />
        </div>
      </div>
      <AdminOrdersTable
        orders={orders.orders.edges.map((edge) => edge.node)}
        totalCount={totalCount}
        page={orders.page}
        loading={loading}
      />
    </div>
  )
}

export default AdminOrdersIndexScreen
