import { Outlet } from "react-router-dom"
import PageContent from "~/ui/PageContent"

const ProfileScreen = () => {
  return (
    <div className="text-xs text-gray-600">
      <PageContent>
        <Outlet />
      </PageContent>
    </div>
  )
}

export default ProfileScreen
