import { PropsWithChildren } from "react"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip"

const LockedFeature = ({ children }: PropsWithChildren) => {
  const { currentUser } = useCurrentUserMaybe()

  if (!!currentUser) {
    return <>{children}</>
  }

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger
          className="flex cursor-default items-center"
          asChild={true}
        >
          <div className="pointer-events-none flex items-center opacity-40 grayscale">
            {children}
          </div>
        </TooltipTrigger>
        <TooltipContent className="font-normal">
          Create an account to customize your study selection
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default LockedFeature
