import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import { SEARCHES_LIMIT } from "../SearchesScreen"
import SearchRow, { SearchRow_SearchFragment } from "./SearchRow"

export const Searches_QueryFragment = gql(`
  fragment Searches_QueryFragment on Query {
    searches(page: $page, limit: $limit) {
      searches {
        edges {
          node {
            ...SearchRow_SearchFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const SearchesTable = ({
  searches,
  totalCount,
  page,
}: {
  searches: FragmentType<typeof SearchRow_SearchFragment>[]
  totalCount: number
  page: number
}) => {
  const totalPages = Math.ceil(totalCount / SEARCHES_LIMIT)

  return (
    <div className="flex flex-col gap-6 text-base">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[250px]">Search Name</TableHead>
            <TableHead className="min-w-[120px]">Modalities</TableHead>
            <TableHead className="min-w-[130px]">Items Found</TableHead>
            <TableHead className="min-w-[140px]">Date Created</TableHead>
            <TableHead className="w-[212px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {searches.map((search) => (
            <SearchRow
              key={getFragmentData(SearchRow_SearchFragment, search).id}
              data={search}
              page={page}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination page={page} totalPages={totalPages} />
    </div>
  )
}

export default SearchesTable
