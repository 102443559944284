import { useField } from "formik"
import { useRef } from "react"
import CheckboxCheckedIcon from "~/images/icons/checkbox-checked.svg"
import CheckboxUncheckedIcon from "~/images/icons/checkbox-unchecked.svg"
import { FormikFieldType } from "./FormikField"

export const CheckboxIcon = ({ checked }: { checked: boolean }) => (
  <div data-test={checked ? "checked" : "unchecked"}>
    {checked ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
  </div>
)

export const FormikCheckboxField = ({
  name,
  value,
  type,
  checked,
}: Pick<FormikFieldType, "name" | "value" | "type" | "checked">) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [field] = useField({ name, type, value })
  const isChecked = checked === undefined ? field.checked : checked
  return (
    <label id={name} className="cursor-pointer">
      <input className="hidden" type={type} ref={inputRef} {...field} />
      <CheckboxIcon checked={!!isChecked} />
    </label>
  )
}

export default FormikCheckboxField
