import { useMutation } from "@apollo/client"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import toast from "react-hot-toast"
import * as Yup from "yup"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { OrderStatusEnum } from "~/__generated__/graphql"
import { displayErrors } from "~/common/validations"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "~/components/ui/Dialog"
import { currencyValidator } from "~/lib/utils"
import Button from "~/ui/Button"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"
import { OrderStatusDisplay } from "../../AdminOrdersIndexScreen/AdminOrdersIndexScreen"
import { AdminOrderScreen_OrderFragment } from "../AdminOrderScreen"

type Values = {
  status: string
  priceDollars: string
}

export const orderValidationSchema = Yup.object({
  status: Yup.string().required("Status is required"),
  priceDollars: currencyValidator,
})

export const ORDER_UPDATE_MUTATION = gql(`
  mutation OrderUpdate($input: OrderUpdateInput!) {
    orderUpdate(input: $input) {
      order {
        ...AdminOrderScreen_OrderFragment
      }
    }
  }
`)

const AdminOrderFormDialog = ({
  data,
  setIsOpen,
}: {
  data: FragmentType<typeof AdminOrderScreen_OrderFragment>
  setIsOpen: (isOpen: boolean) => void
}) => {
  const order = getFragmentData(AdminOrderScreen_OrderFragment, data)

  const [mutate] = useMutation(ORDER_UPDATE_MUTATION)

  const initialValues = {
    status: order.status.toString(),
    priceDollars: order.priceCents ? (order.priceCents / 100).toString() : "",
  }

  const onSubmit = async (
    values: Values,
    { setFieldError }: FormikHelpers<Values>
  ) => {
    try {
      await mutate({
        variables: {
          input: {
            id: order.id,
            orderInput: {
              status: values.status as OrderStatusEnum,
              priceCents: values.priceDollars
                ? Math.round(Number(values.priceDollars) * 100)
                : undefined,
            },
          },
        },
      })
      setIsOpen(false)
      toast.success("Order has been updated")
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <Dialog open={true} onOpenChange={(open) => setIsOpen(open)}>
      <DialogPortal>
        <DialogOverlay className="DialogOverlay" />
        <DialogContent className="DialogContent">
          <DialogTitle className="DialogTitle">Edit Order</DialogTitle>
          <div className="mb-4 mt-6">
            <div className="mb-1 text-2xl font-medium text-blue-zodiac">
              Edit Order
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validationSchema={orderValidationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }: FormikProps<Values>) => (
              <Form className="w-full">
                <FieldGroup>
                  <FormikField
                    name="status"
                    label="Status"
                    placeholder="Select Status"
                    type="select"
                    options={Object.keys(OrderStatusDisplay).map((key) => ({
                      label: OrderStatusDisplay[key],
                      value: key,
                    }))}
                    required={true}
                  />
                  {!order.studyPackage && (
                    <FormikField
                      name="priceDollars"
                      label="Final Price"
                      placeholder="Price"
                    />
                  )}
                </FieldGroup>
                <DialogFooter>
                  <Button type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                </DialogFooter>
              </Form>
            )}
          </Formik>

          <DialogClose asChild>
            <button className="IconButton" aria-label="Close" />
          </DialogClose>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

export default AdminOrderFormDialog
