import pluralize from "pluralize"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { currency } from "~/common/currency"
import { getManualCountTypePlural } from "~/common/order"
import { adminOrderPath } from "~/common/paths"
import DateTimeCell from "~/components/DateTimeCell"
import { LinkedTableCell, TableCell, TableRow } from "~/components/ui/Table"
import OrderStatus from "~/screens/OrdersScreen/screens/OrdersIndexScreen/components/OrderStatus"
import { ButtonLink } from "~/ui/Button"

export const AdminOrderRow_OrderFragment = gql(`
  fragment AdminOrderRow_OrderFragment on Order {
    id
    orderNumber
    status
    statusDisplay
    studyCount
    imageCount
    manualCount
    isImageCount
    estimatedPriceCents
    priceCents
    createdAt
    deliveredAt
    user {
      id
      company
      name
    }
  }
`)

const AdminOrderRow = ({
  data,
}: {
  data: FragmentType<typeof AdminOrderRow_OrderFragment>
}) => {
  const order = getFragmentData(AdminOrderRow_OrderFragment, data)
  const path = adminOrderPath({
    id: order.id,
  })

  return (
    <TableRow>
      <LinkedTableCell to={path}>
        <DateTimeCell date={order.createdAt} />
      </LinkedTableCell>
      <LinkedTableCell to={path} className="max-w-[580px]">
        <OrderStatus
          status={order.status}
          statusDisplay={order.statusDisplay}
        />
      </LinkedTableCell>
      <LinkedTableCell to={path}>
        {order.deliveredAt && <DateTimeCell date={order.deliveredAt} />}
      </LinkedTableCell>
      <LinkedTableCell to={path} className="max-w-[580px]">
        {order.user.company || order.user.name}
      </LinkedTableCell>
      <LinkedTableCell to={path} className="max-w-[580px]">
        <div className="truncated font-mono">{order.orderNumber}</div>
      </LinkedTableCell>
      <LinkedTableCell to={path} className="max-w-[580px]">
        {order.estimatedPriceCents > 0 && (
          <div className="truncate">{currency(order.estimatedPriceCents)}</div>
        )}
      </LinkedTableCell>
      <LinkedTableCell to={path} className="max-w-[580px]">
        <div className="truncate">
          {order.priceCents ? currency(order.priceCents) : ""}
        </div>
      </LinkedTableCell>
      <LinkedTableCell to={path} className="max-w-[580px]">
        <div className="truncate">
          {order.manualCount ? (
            <>
              <b>{order.manualCount}</b> {getManualCountTypePlural({ order })}
            </>
          ) : order.imageCount && order.imageCount > 0 ? (
            <>
              <b>
                {order.imageCount.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                })}
              </b>{" "}
              {pluralize("image", order.imageCount)}
            </>
          ) : (
            <>
              <b>
                {order.studyCount.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                })}
              </b>{" "}
              {pluralize("study", order.studyCount)}
            </>
          )}
        </div>
      </LinkedTableCell>
      <TableCell>
        <div className="flex gap-2">
          <ButtonLink to={path} display="inline">
            View
          </ButtonLink>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default AdminOrderRow
