import { useQuery } from "@apollo/client"
import clsx from "clsx"
import { getFragmentData, gql } from "~/__generated__"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { newObjectId, searchPath } from "~/common/paths"
import FunnelLgIcon from "~/images/icons/funnel-lg.svg"
import { ButtonLink } from "~/ui/Button"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import Loading from "~/ui/Loading"
import DashboardOrders, {
  DashboardOrders_QueryFragment,
} from "./components/DashboardOrders"
import DashboardPackages, {
  DashboardStudyPackages_QueryFragment,
} from "./components/DashboardPackages"
import DashboardSavedSearches, {
  DashboardSearches_QueryFragment,
} from "./components/DashboardSavedSearches"
import DashboardTitle from "./components/DashboardTitle"

const DashboardScreen_Query = gql(`
  query DashboardScreen_Query($userId: ID!) {
    ...DashboardStudyPackages_QueryFragment
    ...DashboardSearches_QueryFragment
    ...DashboardOrders_QueryFragment
  }
`)

export const DashboardScreen = () => {
  const currentUser = useCurrentUser()

  const { loading, data, error } = useQuery(DashboardScreen_Query, {
    variables: {
      userId: currentUser.id,
    },
  })

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { studyPackages } = getFragmentData(
    DashboardStudyPackages_QueryFragment,
    data
  )
  const { searches } = getFragmentData(DashboardSearches_QueryFragment, data)
  const { orders } = getFragmentData(DashboardOrders_QueryFragment, data)

  return (
    <div className="flex flex-col gap-2">
      <div className="px-8">
        <div className="my-8 text-3xl font-semibold">
          Welcome {currentUser.firstName}!
        </div>
        <div className="mb-4">
          <DashboardTitle text="Start searching studies or generated images.">
            <FunnelLgIcon />
          </DashboardTitle>
        </div>
        <div className="mb-8 text-sm text-neutral-400">
          Search our database of medical imaging studies and generated images
        </div>
        <ButtonLink
          to={searchPath({ id: newObjectId })}
          className="rounded-md bg-candlelight px-8 py-4 font-semibold"
          display="inline"
        >
          Start a Query
        </ButtonLink>
      </div>
      <HR />
      <div className="px-8">
        {studyPackages.studyPackages.edges.length > 0 && (
          <DashboardPackages
            studyPackages={studyPackages.studyPackages.edges.map(
              (edge) => edge.node
            )}
          />
        )}
      </div>
      <HR />
      <div className="mb-6 px-8">
        <div className={clsx("flex flex-col gap-4", "xl:flex-row")}>
          <div className="flex-1">
            <DashboardSavedSearches
              searches={searches.searches.edges.map((edge) => edge.node)}
            />
          </div>
          <div className="-mx-8 my-2 xl:hidden">
            <HR />
          </div>
          <div className="flex-1">
            <DashboardOrders
              orders={orders.orders.edges.map((edge) => edge.node)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
