import { useQuery } from "@apollo/client"
import { getFragmentData, gql } from "~/__generated__"

export const GAN_LABEL_FRAGMENT = gql(`
  fragment GanLabelFragment on GanLabel {
    id
    uid
    name
  }
`)

export const GET_GAN_LABELS_QUERY = gql(`
  query GanLabels {
    ganLabels(first: 1000) {
      nodes {
        ...GanLabelFragment
      }
    }
  }
`)

const useGanLabelsQuery = () => {
  const { data, loading, error } = useQuery(GET_GAN_LABELS_QUERY)

  const ganLabels = data?.ganLabels.nodes?.map((ganlabel) =>
    getFragmentData(GAN_LABEL_FRAGMENT, ganlabel)
  )

  return { ganLabels, data, loading, error }
}

export default useGanLabelsQuery
