import { useQuery } from "@apollo/client"
import clsx from "clsx"
import pluralize from "pluralize"
import { useSearchParams } from "react-router-dom"
import { getFragmentData, gql } from "~/__generated__"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import OrdersTable, { Orders_QueryFragment } from "./components/OrdersTable"

export const OrdersScreen_Query = gql(`
  query OrdersScreen_Query($userId: ID!, $page: Int, $limit: Int) {
    ...Orders_QueryFragment
  }
`)

export const ORDERS_LIMIT = 12

const OrdersIndexScreen = () => {
  const [searchParams] = useSearchParams()
  const page = searchParams.get("page")
  const currentUser = useCurrentUser()

  const result = useQuery(OrdersScreen_Query, {
    variables: {
      page: page ? Number(page) : 1,
      limit: ORDERS_LIMIT,
      userId: currentUser.id,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { orders } = getFragmentData(Orders_QueryFragment, data)
  const totalCount = orders.orders.totalCount

  return (
    <div>
      <div
        className={clsx(
          "flex flex-col gap-6",
          "lg:flex-row lg:justify-between"
        )}
      >
        <div>
          <H1>My Orders</H1>
        </div>
      </div>
      <div className="mb-6 flex flex-col gap-6 text-base">
        <div className="my-1">
          <b>{totalCount}</b> {pluralize("purchase", totalCount)}
        </div>
      </div>

      {orders.orders.edges.length > 0 && (
        <OrdersTable
          orders={orders.orders.edges.map((edge) => edge.node)}
          totalCount={totalCount}
          page={orders.page}
          loading={loading}
        />
      )}
    </div>
  )
}

export default OrdersIndexScreen
