import { useApolloClient, useMutation } from "@apollo/client"
import clsx from "clsx"
import { useState } from "react"
import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import { gql } from "~/__generated__"
import { searchPath } from "~/common/paths"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/DropdownMenu"
import useSearchSubscription from "~/hooks/useSearchSubscription"
import DuplicateSvg from "~/images/icons/duplicate.svg"
import EllipsisSvg from "~/images/icons/ellipsis.svg"
import FunnelSvg from "~/images/icons/funnel.svg"
import PencilSvg from "~/images/icons/pencil.svg"
import TrashSvg from "~/images/icons/trash.svg"
import DisabledSearchSave from "~/screens/SearchScreen/screens/StudySearchResultsScreen/components/DisabledSearchSave"
import Button from "~/ui/Button"
import { SearchesScreen_Query } from "../SearchesScreen"
import RenameSearchDialog from "./RenameSearchDialog"

const SEARCH_DUPLICATE_MUTATION = gql(`
  mutation SearchDuplicate($input: SearchDuplicateInput!) {
    searchDuplicate(input: $input) {
      search {
        ...SearchRow_SearchFragment
      }
    }
  }
`)

const SEARCH_ARCHIVE_MUTATION = gql(`
  mutation SearchArchive($input: SearchArchiveInput!) {
    searchArchive(input: $input) {
      search {
        id
      }
    }
  }
`)

const DuplicateSearch = ({
  id,
  page,
  isCreatingResults,
}: {
  id: string
  page: number
  isCreatingResults: boolean
}) => {
  const client = useApolloClient()
  useSearchSubscription(id, !isCreatingResults)

  const [mutateDuplicateSearch, { loading: loadingDuplicateSearch }] =
    useMutation(SEARCH_DUPLICATE_MUTATION)
  const duplicateSearch = () => {
    mutateDuplicateSearch({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => {
        toast.success("Search has been duplicated")
        if (page === 1) {
          client.refetchQueries({
            include: [SearchesScreen_Query],
          })
        }
      },
      onError: () => {
        toast.error("Search has not been duplicated, please try again")
      },
    })
  }

  return (
    <>
      <DisabledSearchSave disabled={isCreatingResults}>
        <DropdownMenuItem asChild={true}>
          <button
            type="button"
            className={clsx("w-full", {
              "animate-pulse": loadingDuplicateSearch,
            })}
            onClick={duplicateSearch}
            disabled={loadingDuplicateSearch}
          >
            <DropdownMenuIcon>
              <DuplicateSvg />
            </DropdownMenuIcon>
            Duplicate
          </button>
        </DropdownMenuItem>
      </DisabledSearchSave>
    </>
  )
}

const ArchiveSearch = ({ id }: { id: string }) => {
  const client = useApolloClient()
  const [mutateArchiveSearch, { loading: loadingArchiveSearch }] = useMutation(
    SEARCH_ARCHIVE_MUTATION
  )

  const archiveSearch = () => {
    mutateArchiveSearch({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => {
        toast.success("Search has been archived")
        client.refetchQueries({
          include: [SearchesScreen_Query],
        })
      },
      update: (cache) => {
        const normalizedId = cache.identify({
          id,
          __typename: "Search",
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      onError: () => {
        toast.error("Search has not been archived, please try again")
      },
    })
  }

  return (
    <DropdownMenuItem asChild={true}>
      <button
        type="button"
        className={clsx("w-full", {
          "animate-pulse": loadingArchiveSearch,
        })}
        onClick={archiveSearch}
        disabled={loadingArchiveSearch}
      >
        <DropdownMenuIcon>
          <TrashSvg />
        </DropdownMenuIcon>
        Archive
      </button>
    </DropdownMenuItem>
  )
}

const SearchRowActions = ({
  searchId,
  searchName,
  isCreatingResults,
  page,
}: {
  searchId: string
  searchName: string
  isCreatingResults: boolean
  page: number
}) => {
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false)

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outlineSecondary"
            display="inline"
            className="bg-white"
          >
            <EllipsisSvg />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[150px]" side="bottom" align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem asChild={true}>
              <Link to={searchPath({ id: searchId })}>
                <DropdownMenuIcon>
                  <FunnelSvg />
                </DropdownMenuIcon>
                Edit Query
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild={true}>
              <button
                type="button"
                className="w-full"
                onClick={() => setIsRenameDialogOpen(true)}
              >
                <DropdownMenuIcon>
                  <PencilSvg />
                </DropdownMenuIcon>
                Rename
              </button>
            </DropdownMenuItem>
            <DuplicateSearch
              id={searchId}
              page={page}
              isCreatingResults={isCreatingResults}
            />
            <ArchiveSearch id={searchId} />
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <RenameSearchDialog
        id={searchId}
        name={searchName}
        isOpen={isRenameDialogOpen}
        setIsOpen={setIsRenameDialogOpen}
      />
    </>
  )
}

export default SearchRowActions
