// Dec 5, 2023
export const dateMedium = (date: Date) => {
  return new Intl.DateTimeFormat("en", {
    dateStyle: "medium",
  }).format(date)
}

// 10:00 AM
export const time = (date: Date, includeTz = false) => {
  const timeStr = new Intl.DateTimeFormat("en", {
    timeStyle: "short",
  }).format(date)

  const tz = new Date()
    .toLocaleTimeString("en", { timeZoneName: "short" })
    .split(" ")[2]

  return includeTz ? `${timeStr} ${tz}` : timeStr
}

export const dateTime = (date: Date, includeTz = false) => {
  return `${dateMedium(date)} ${time(date, includeTz)}`
}
