import { PropsWithChildren } from "react"

const DashboardTitle = ({
  text,
  children,
}: { text: string } & PropsWithChildren) => (
  <div className="text-2xl">
    <span className="float-left mr-2 mt-[4px]">{children}</span>
    <span className="d-inline text-blue-zodiac">{text}</span>
  </div>
)

export default DashboardTitle
