import { gql, useMutation } from "@apollo/client"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import { Download } from "lucide-react"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import Button from "~/ui/Button"
import Container from "~/ui/Container"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"
import AdminOrderDownloadSection from "./AdminOrderDownloadSection"
import AdminOrderSendEmailButton from "./AdminOrderSendEmailButton"

type Values = {
  downloadUrl: string
}

const ORDER_UPDATE_MUTATION = gql(`
  mutation OrderDownloadUrlUpdate($input: OrderUpdateInput!) {
    orderUpdate(input: $input) {
      order {
        id
        downloadUrl
      }
    }
  }
`)

const SEND_DOWNLOAD_LINK_EMAIL = gql(`
  mutation SendOrderDownloadLinkEmail($input: SendOrderDownloadLinkEmailInput!) {
    sendOrderDownloadLinkEmail(input: $input) {
      order {
        id
        sentOrderStudiesCsvAt
      }
    }
  }
`)

const AdminOrderDownloadLinkForm = ({
  orderId,
  sentAt,
  downloadUrl,
}: {
  orderId: string
  sentAt?: string
  downloadUrl?: string | null | undefined
}) => {
  const [mutate] = useMutation(ORDER_UPDATE_MUTATION)
  const [
    sendOrderDownloadLinkEmail,
    { loading: sendOrderDownloadLinkEmailLoading },
  ] = useMutation(SEND_DOWNLOAD_LINK_EMAIL, {
    onCompleted: () => {
      toast.success("Download link has been sent")
    },
    onError: (error) => {
      console.error(error)
      toast.error("Download link has not been sent")
    },
  })

  const initialValues = {
    downloadUrl: downloadUrl || "",
  }

  const onSubmit = async (
    values: Values,
    { setFieldError }: FormikHelpers<Values>
  ) => {
    try {
      await mutate({
        variables: {
          input: {
            id: orderId,
            orderInput: {
              downloadUrl: values.downloadUrl,
            },
          },
        },
      })
      toast.success("Order has been updated")
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <Container size="lg">
      <AdminOrderDownloadSection title="Download Link" sentAt={sentAt}>
        <div>
          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }: FormikProps<Values>) => (
              <Form className="w-full">
                <FieldGroup>
                  <FormikField
                    name="downloadUrl"
                    placeholder="Link URL"
                    type="url"
                  />
                </FieldGroup>
                <div className="mt-4 flex items-center justify-between">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    display="inline"
                  >
                    Save
                  </Button>

                  <div>
                    <AdminOrderSendEmailButton
                      onClick={() =>
                        sendOrderDownloadLinkEmail({
                          variables: { input: { orderId } },
                        })
                      }
                      disabled={
                        !downloadUrl || sendOrderDownloadLinkEmailLoading
                      }
                    >
                      <Download />
                      Send Download Link
                    </AdminOrderSendEmailButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </AdminOrderDownloadSection>
    </Container>
  )
}

export default AdminOrderDownloadLinkForm
