import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { TableCell, TableRow } from "~/components/ui/Table"

export const AdminSeriesImportRowRow_SeriesImportRowFragment = gql(`
  fragment AdminSeriesImportRowRow_SeriesImportRowFragment on SeriesImportRow {
    id
    index
    accessionNumber
    patientId
    studyDescription
    importErrorMessage
    importSkippedMessage
  }
`)

const AdminSeriesImportRowRow = ({
  data,
}: {
  data: FragmentType<typeof AdminSeriesImportRowRow_SeriesImportRowFragment>
}) => {
  const seriesImportRow = getFragmentData(
    AdminSeriesImportRowRow_SeriesImportRowFragment,
    data
  )

  return (
    <TableRow>
      <TableCell>
        {seriesImportRow.index.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        })}
      </TableCell>
      <TableCell>{seriesImportRow.accessionNumber}</TableCell>
      <TableCell>{seriesImportRow.patientId}</TableCell>
      <TableCell>{seriesImportRow.studyDescription}</TableCell>
      <TableCell>{seriesImportRow.importErrorMessage}</TableCell>
      <TableCell>{seriesImportRow.importSkippedMessage}</TableCell>
    </TableRow>
  )
}

export default AdminSeriesImportRowRow
