import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { currency } from "~/common/currency"
import { imageSearchNewOrderPath } from "~/common/paths"
import { imageOrderPriceCents } from "~/common/price"
import HeaderStatsContainer from "~/components/HeaderStats/HeaderStatsContainer"
import HeaderStatsItem from "~/components/HeaderStats/HeaderStatsItem"
import useLoggedInLink from "~/hooks/useLoggedInLink"
import BookSvg from "~/images/icons/book.svg"
import DollarSvg from "~/images/icons/dollar.svg"
import { ButtonLink } from "~/ui/Button"

const ImageSearchResultsStats = ({ imageCount }: { imageCount: number }) => {
  const { id } = useParams()
  const loggedInLink = useLoggedInLink()
  invariant(id, "Expected search id")

  const price = imageOrderPriceCents(imageCount)

  return (
    <HeaderStatsContainer>
      <HeaderStatsItem
        value={imageCount.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        })}
      >
        <BookSvg />
        <div>Total Images</div>
      </HeaderStatsItem>
      <HeaderStatsItem
        value={price === 0 ? "-" : currency(price)}
        data-test="estimated-price"
      >
        <DollarSvg />
        <div>Estimated Price</div>
      </HeaderStatsItem>
      <div className="flex flex-1">
        <ButtonLink
          variant="primary"
          size="xl"
          to={loggedInLink(
            `${imageSearchNewOrderPath({
              id,
            })}${window.location.search}`,
            true
          )}
        >
          Order Packaged Data Set
        </ButtonLink>
      </div>
    </HeaderStatsContainer>
  )
}

export default ImageSearchResultsStats
