import { useQuery } from "@apollo/client"
import clsx from "clsx"
import invariant from "tiny-invariant"
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParams,
} from "use-query-params"
import { getFragmentData, gql } from "~/__generated__"
import { ModalityEnum } from "~/__generated__/graphql"
import Container from "~/ui/Container"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import StudyPackagesFilters from "./components/StudyPackagesFilters"
import StudyPackagesList, {
  StudyPackagesList_QueryFragment,
} from "./components/StudyPackagesList"

export const StudyPackagesScreen_Query = gql(`
  query StudyPackagesScreen_Query($modalities: [ModalityEnum!], $bodyPartIds: [ID!], $featured: Boolean, $page: Int, $limit: Int) {
    ...StudyPackagesList_QueryFragment
  }
`)

export const STUDY_PACKAGES_LIMIT = 12

export const studyPackagesQuerySchema = {
  modalities: ArrayParam,
  bodyPartIds: ArrayParam,
  page: StringParam,
  featured: BooleanParam,
}

const StudyPackagesIndexScreen = () => {
  const [searchParams] = useQueryParams(studyPackagesQuerySchema)
  const page = searchParams.page

  const result = useQuery(StudyPackagesScreen_Query, {
    variables: {
      page: page ? Number(page) : 1,
      limit: STUDY_PACKAGES_LIMIT,
      modalities: searchParams.modalities?.filter((modality) => {
        invariant(modality)
        return modality
      }) as ModalityEnum[],
      bodyPartIds: searchParams.bodyPartIds?.filter((id) => {
        invariant(id, `Invalid body part id: ${id}`)
        return id
      }) as string[],
      featured: searchParams.featured,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { studyPackages } = getFragmentData(
    StudyPackagesList_QueryFragment,
    data
  )
  const totalCount = studyPackages.studyPackages.totalCount

  return (
    <div>
      <H1 id="query-builder">Image Packages</H1>
      <Container size="md">
        <div className="mb-8 pt-4 text-sm text-slate-gray">
          Here are some previously created images packages available for order.
        </div>
      </Container>

      <StudyPackagesFilters />

      <div
        className={clsx({
          "animate-pulse opacity-90": loading,
        })}
      >
        {studyPackages.studyPackages.edges.length > 0 ? (
          <StudyPackagesList
            studyPackages={studyPackages.studyPackages.edges.map(
              (edge) => edge.node
            )}
            totalCount={totalCount}
            page={studyPackages.page}
          />
        ) : (
          <div>
            <b>0</b> image packages
          </div>
        )}
      </div>
    </div>
  )
}

export default StudyPackagesIndexScreen
