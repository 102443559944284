import { Popover } from "@headlessui/react"
import clsx from "clsx"
import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { newObjectId, searchPath } from "~/common/paths"
import HeaderLogoDark from "~/images/header-logo-dark.svg"
import HeaderLogoLight from "~/images/header-logo-light.svg"
import MobileNavOff from "~/images/icons/mobile-nav-off.svg"
import MobileNavOn from "~/images/icons/mobile-nav-on.svg"
import { ButtonLink } from "~/ui/Button"
import MainNav, { MainNavFooter } from "./MainNav"

const Header = ({ open }: { open: boolean }) => {
  return (
    <div
      className={clsx(
        "flex items-end justify-between border-b px-4 pb-4 pt-10",
        open ? "border-gray-200/10 bg-blue-zodiac" : "border-gray-200 bg-white"
      )}
    >
      <Link to={"/"}>{open ? <HeaderLogoLight /> : <HeaderLogoDark />}</Link>
      <div className="flex gap-2">
        <div>
          <Popover.Button
            className={clsx(
              "flex items-center justify-center outline-gray-200",
              "hover:opacity-50"
            )}
            data-test="main-nav-mobile-button"
          >
            {open ? <MobileNavOn /> : <MobileNavOff />}
          </Popover.Button>
        </div>
        <ButtonLink
          to={searchPath({ id: newObjectId })}
          className="rounded-[6px] bg-candlelight px-[20px] text-xs font-semibold"
          display="inline"
          size="sm"
        >
          Start a Query
        </ButtonLink>
      </div>
    </div>
  )
}

const Content = ({ open }: { open: boolean }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [open])

  return (
    <div className={clsx("h-full w-full", "md:hidden")}>
      <Header open={false} />
      <Popover.Panel className="fixed bottom-0 left-0 top-0 z-50 w-full overflow-y-auto bg-blue-zodiac">
        <Header open={true} />
        <div className="flex flex-col px-4 pb-10 pt-10">
          <MainNav />
          <div className="mt-auto pb-10 pt-36">
            <MainNavFooter />
          </div>
        </div>
      </Popover.Panel>
    </div>
  )
}

const MobileNav = () => {
  const location = useLocation()
  return (
    <div key={location.pathname}>
      <Popover>{({ open }) => <Content open={open} />}</Popover>
    </div>
  )
}

export default MobileNav
