import { ChevronLeft } from "lucide-react"
import { Link } from "react-router-dom"
import { currency } from "~/common/currency"
import Button, { ButtonLink } from "~/ui/Button"
import Container from "~/ui/Container"
import { Table, TD, TH } from "~/ui/DetailTable"
import FormikField from "~/ui/FormikField"
import { H1 } from "~/ui/Headings"

const NewOrderDetailsTable = ({
  studyCountDisplay,
  imageCountDisplay,
  priceCents,
  isEstimate,
}: {
  studyCountDisplay?: string
  imageCountDisplay?: string
  priceCents: number
  isEstimate?: boolean
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <TH colSpan={2}>Details</TH>
        </tr>
      </thead>
      <tbody>
        {!!studyCountDisplay && (
          <tr>
            <TD>Study Count</TD>
            <TD>{studyCountDisplay}</TD>
          </tr>
        )}
        {imageCountDisplay && (
          <tr>
            <TD>Image Count</TD>
            <TD>{imageCountDisplay}</TD>
          </tr>
        )}
        <tr>
          <TD>{isEstimate ? "Estimated Total" : "Total"}</TD>
          <TD>{currency(priceCents)}</TD>
        </tr>
      </tbody>
    </Table>
  )
}

const NewOrderScreenContent = ({
  backPath,
  studyCountDisplay,
  imageCountDisplay,
  onSubmit,
  disabled,
  priceCents,
  isEstimate,
}: {
  backPath: string
  studyCountDisplay?: string
  imageCountDisplay?: string
  onSubmit: () => void
  disabled: boolean
  priceCents: number
  isEstimate?: boolean
}) => {
  return (
    <div>
      <div className="mb-8 flex items-end">
        <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
          <ChevronLeft size={28} />
        </Link>
        <H1 margin={false}>Order Summary</H1>
      </div>
      <Container size="md">
        <div className="flex flex-col gap-8">
          <NewOrderDetailsTable
            studyCountDisplay={studyCountDisplay}
            imageCountDisplay={imageCountDisplay}
            priceCents={priceCents}
            isEstimate={isEstimate}
          />
          <div className="flex gap-6 bg-medici-blue p-5 text-sm leading-6 text-white/80">
            <FormikField name="acceptedTerms" type="checkbox" />
            <div>
              By clicking 'Place Order', you acknowledge that you have read,
              understood, and agree to RadImageNet's{" "}
              <a href="/contract.pdf" target="_blank" className="underline">
                Terms of Sale.
              </a>
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <Button
                type="button"
                name="purchase"
                disabled={disabled}
                display="inline"
                onClick={onSubmit}
              >
                Place Order
              </Button>
            </div>
            <div>
              <ButtonLink
                to={backPath}
                variant="outlineSecondary"
                className="px-10 font-normal text-neutral-400"
              >
                Cancel
              </ButtonLink>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NewOrderScreenContent
