import clsx from "clsx"
import { File } from "~/__generated__/graphql"
import { ButtonLink } from "~/ui/Button"
import { TD, TH, Table } from "~/ui/DetailTable"

const FileRow = ({
  filename,
  url,
  kb,
}: {
  filename: string
  url: string
  kb?: string
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col-reverse gap-2",
        "md:flex-row md:items-center md:gap-8"
      )}
    >
      <div>
        <ButtonLink to={url} target="_blank" display="inline">
          Download File
        </ButtonLink>
      </div>
      <div>
        <div className="font-semibold">{filename}</div>
        {kb && `${kb} KB`}
      </div>
    </div>
  )
}

const OrderDownloads = ({
  poDocument,
  contractDocument,
  defaultContractDocument,
  downloadUrl,
}: {
  poDocument?: File
  contractDocument?: File
  defaultContractDocument?: File
  downloadUrl?: string
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <TH colSpan={2}>Downloads</TH>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TD colSpan={2}>
              <div className={clsx("flex flex-col gap-6 py-4", "md:gap-4")}>
                {contractDocument ? (
                  <FileRow
                    filename={contractDocument.filename}
                    url={contractDocument.url}
                    kb={contractDocument.kb}
                  />
                ) : defaultContractDocument ? (
                  <FileRow
                    filename={defaultContractDocument.filename}
                    url={defaultContractDocument.url}
                    kb={defaultContractDocument.kb}
                  />
                ) : (
                  <div>Generating contract...</div>
                )}

                {poDocument && (
                  <FileRow
                    filename={poDocument.filename}
                    url={poDocument.url}
                    kb={poDocument.kb}
                  />
                )}
              </div>
            </TD>
          </tr>
        </tbody>
      </Table>
      {!!downloadUrl && (
        <Table>
          <thead>
            <tr>
              <TH colSpan={2}>Data Set</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD colSpan={2}>
                <div className="flex flex-col gap-4 py-4">
                  <div>
                    <ButtonLink
                      to={downloadUrl}
                      target="_blank"
                      display="inline"
                    >
                      Download
                    </ButtonLink>
                  </div>
                </div>
              </TD>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  )
}

export default OrderDownloads
