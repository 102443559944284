import { Outlet } from "react-router-dom"
import PageContent from "~/ui/PageContent"

const OrdersScreen = () => {
  return (
    <PageContent>
      <Outlet />
    </PageContent>
  )
}

export default OrdersScreen
