import { csrfToken } from "./csrf"

type Response = ["success", any] | ["error", any] | ["failure", Error]

export const request = async (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  data?: any
): Promise<Response> => {
  let token = csrfToken()
  try {
    const response = await fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        accept: "application/json",
        ...(token && { "X-CSRF-Token": token }),
        ...(data && {
          "Content-Type": "application/json",
        }),
      },
      body: JSON.stringify(data),
    })

    // throws an error if response content type is not json
    let contentType = response.headers.get("content-type")
    if (!contentType?.includes("application/json")) {
      return [
        "failure",
        new Error(`Expected a json response but got ${contentType}`),
      ]
    }

    if (response.body == null) {
      return [response.ok ? "success" : "error", null]
    }

    const responseData = await response.json()
    return [response.ok ? "success" : "error", responseData]
  } catch (err: any) {
    return ["failure", err as Error]
  }
}
