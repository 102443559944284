import { FragmentType, getFragmentData } from "~/__generated__"
import { Table, TableBody, TableHeader, TableRow } from "~/components/ui/Table"
import StudyPackageStudyRow, {
  StudyPackageStudyRow_StudyFragment,
} from "./StudyPackageStudyRow"
import { StudiesTableSharedHeadings } from "~/components/StudiesTable"

const StudyPackageStudiesTable = ({
  studyPackageId,
  studies,
}: {
  studyPackageId: string
  studies: FragmentType<typeof StudyPackageStudyRow_StudyFragment>[]
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <StudiesTableSharedHeadings />
        </TableRow>
      </TableHeader>
      <TableBody>
        {studies.map((study) => (
          <StudyPackageStudyRow
            key={getFragmentData(StudyPackageStudyRow_StudyFragment, study).id}
            studyPackageId={studyPackageId}
            data={study}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default StudyPackageStudiesTable
