import {
  defaultDataIdFromObject,
  useApolloClient,
  useSubscription,
} from "@apollo/client"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"

export const SearchUpdatedSubscriptionFragment = gql(`
  fragment SearchUpdatedSubscriptionFragment on Search {
    id
    isDuplicatingSearch
    isCreatingResults
    isUpdatingSelections
    resultsCount
  }
`)

const SearchUpdated_Subscription = gql(`
  subscription SearchUpdated($id: ID!) {
    searchUpdated(id: $id) {
      search {
        ...SearchUpdatedSubscriptionFragment
      }
    }
  }
`)

const useSearchSubscription = (id: string, skip?: boolean) => {
  const client = useApolloClient()

  const { data, loading, error } = useSubscription(SearchUpdated_Subscription, {
    variables: { id },
    skip,
    onData: ({ data: { data } }) => {
      const updatedSearch = getFragmentData(
        SearchUpdatedSubscriptionFragment,
        data?.searchUpdated.search
      )
      invariant(updatedSearch, "expected search to exist")
      client.cache.writeFragment({
        id: defaultDataIdFromObject({ __typename: "Search", id }),
        fragment: SearchUpdatedSubscriptionFragment,
        data: {
          id: updatedSearch.id,
          isDuplicatingSearch: updatedSearch.isDuplicatingSearch,
          isCreatingResults: updatedSearch.isCreatingResults,
          isUpdatingSelections: updatedSearch.isUpdatingSelections,
          resultsCount: updatedSearch.resultsCount,
        },
      })
    },
    onError: (e) => {
      console.error(e)
      toast.error("Search update, please try again")
    },
  })

  return { data, loading, error }
}

export default useSearchSubscription
