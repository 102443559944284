import { useQuery } from "@apollo/client"
import { useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { newObjectId } from "~/common/paths"
import Button from "~/ui/Button"
import Container from "~/ui/Container"
import Error from "~/ui/Error"
import { H1, H2 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import { StudySearchResultsSummary_SearchFragment } from "../StudySearchResultsScreen/components/StudySearchResultsSummary"
import EditImageSearchForm from "./components/EditImageSearchForm"
import EditStudySearchForm from "./components/EditStudySearchForm"
import NewImageSearchForm from "./components/NewImageSearchForm"
import NewStudySearchForm from "./components/NewStudySearchForm"

const SearchFormScreen_Query = gql(`
  query SearchFormScreen_Query($id: ID!) {
    node(id: $id) {
      ...StudySearchResultsSummary_SearchFragment
    }
  }
`)

export enum SearchTypeEnum {
  Image = "Image",
  Study = "Study",
}

const Content = ({
  id,
  data,
}: {
  id: string
  data?: FragmentType<typeof StudySearchResultsSummary_SearchFragment>
}) => {
  const [searchParams] = useSearchParams()

  const hasImageParams =
    !!searchParams.get("ganLabelId") && !!searchParams.get("imageCount")

  const search = data
    ? getFragmentData(StudySearchResultsSummary_SearchFragment, data)
    : undefined

  const [searchType, setSearchType] = useState<SearchTypeEnum | undefined>(
    search?.imageSearchParams || hasImageParams
      ? SearchTypeEnum.Image
      : SearchTypeEnum.Study
  )

  return (
    <div>
      <Container size="md">
        {id === newObjectId && (
          <>
            <H2>Are you looking for studies or generated images?</H2>

            <div className="mt-2 rounded bg-gray-100 p-1">
              <Button
                variant={searchType === SearchTypeEnum.Study ? "blue" : "ghost"}
                size="sm"
                display="inline"
                onClick={() => setSearchType(SearchTypeEnum.Study)}
                className={
                  searchType !== SearchTypeEnum.Study
                    ? "text-gray-400"
                    : undefined
                }
              >
                Studies
              </Button>
              <Button
                variant={searchType === SearchTypeEnum.Image ? "blue" : "ghost"}
                size="sm"
                display="inline"
                onClick={() => setSearchType(SearchTypeEnum.Image)}
                className={
                  searchType !== SearchTypeEnum.Image
                    ? "text-gray-400"
                    : undefined
                }
              >
                Generative images
              </Button>
            </div>
          </>
        )}
      </Container>

      <div className="mt-10 w-full">
        {searchType === SearchTypeEnum.Image && (
          <>
            {data ? (
              <EditImageSearchForm data={data} />
            ) : (
              <NewImageSearchForm id={id} />
            )}
          </>
        )}
        {searchType === SearchTypeEnum.Study && (
          <>
            {data ? (
              <EditStudySearchForm data={data} />
            ) : (
              <NewStudySearchForm />
            )}
          </>
        )}
      </div>
    </div>
  )
}

const SearchFormScreen = () => {
  const { id } = useParams()
  invariant(id, "expected search id")

  const { data, loading, error } = useQuery(SearchFormScreen_Query, {
    variables: {
      id: id,
    },
    skip: id === newObjectId,
  })

  if (loading) return <Loading />
  if (error) return <Error error={error} />

  return (
    <div className="flex flex-col gap-8">
      <H1 id="query-builder">
        {id === newObjectId ? "Query Builder" : "Edit Search"}
      </H1>
      <Content
        id={id}
        data={data?.node?.__typename === "Search" ? data?.node : undefined}
      />
    </div>
  )
}

export default SearchFormScreen
