import clsx from "clsx"
import { PropsWithChildren } from "react"

const DashboardEmptyTable = ({
  text,
  wide,
  children,
}: {
  text: string
  wide?: boolean
} & PropsWithChildren) => {
  return (
    <div className="flex h-full flex-1 flex-col items-center justify-center gap-8">
      <div
        className={clsx(
          "px-6 text-center text-xl",
          wide ? "max-w-500px" : "max-w-[300px]"
        )}
      >
        {text}
      </div>
      {children}
    </div>
  )
}

export default DashboardEmptyTable
