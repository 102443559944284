import * as React from "react"
import { Link } from "react-router-dom"

import { cn } from "~/lib/utils"

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="overflow-x-auto">
    <table
      ref={ref}
      className={cn("w-full caption-bottom text-sm", className)}
      {...props}
    />
  </div>
))
Table.displayName = "Table"

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & { outerBorder?: boolean }
>(({ className, outerBorder, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      "[&_th:first-child]:border-l [&_th]:border-r [&_th]:border-border-gray [&_tr]:border-b",
      className,
      {
        "[&_tr]:border-t": outerBorder,
      }
    )}
    {...props}
  />
))
TableHeader.displayName = "TableHeader"

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody ref={ref} className={cn(className)} {...props} />
))
TableBody.displayName = "TableBody"

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn("border-t bg-neutral-100/50 font-medium", className)}
    {...props}
  />
))
TableFooter.displayName = "TableFooter"

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & { outerBorder?: boolean }
>(({ className, outerBorder = true, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "group border-b border-border-gray transition-colors hover:bg-azul-claro-blue data-[state=selected]:bg-neutral-100 [&_td]:border-border-gray",
      className,
      {
        "[&_td:first-child]:border-l [&_td:last-child]:border-r": outerBorder,
      }
    )}
    {...props}
  />
))
TableRow.displayName = "TableRow"

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-10 border-none px-4 text-left align-middle font-bold text-dark-rift [&:has([role=checkbox])]:pr-0",
      className
    )}
    {...props}
  />
))
TableHead.displayName = "TableHead"

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "h-[70px] p-4 align-middle [&:has([role=checkbox])]:pr-0",
      className
    )}
    {...props}
  />
))
TableCell.displayName = "TableCell"

const LinkedTableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & { to: string }
>(({ className, children, to, ...props }, ref) => (
  <td ref={ref} className={cn(className)} {...props}>
    <Link to={to} className="flex h-[70px] items-center p-4">
      {children}
    </Link>
  </td>
))
LinkedTableCell.displayName = "LinkedTableCell"

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-neutral-500", className)}
    {...props}
  />
))
TableCaption.displayName = "TableCaption"

export {
  LinkedTableCell,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
}
