import { useMutation } from "@apollo/client"
import clsx from "clsx"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import { toast } from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { gql } from "~/__generated__"
import { logInPath, registrationPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import LoggedOutAccountFooter from "~/components/LoggedOutAccountFooter"
import Button from "~/ui/Button"
import FormikField from "~/ui/FormikField"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"

const FORGOT_PASSWORD_MUTATION = gql(`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      email
    }
  }
`)

type Values = {
  email: string
}

const initialValues = {
  email: "",
}

const validationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
})

const ForgotPasswordScreen = () => {
  const navigate = useNavigate()
  const [mutate] = useMutation(FORGOT_PASSWORD_MUTATION)

  const onSubmit = async (
    values: Values,
    { setFieldError }: FormikHelpers<Values>
  ) => {
    return await mutate({
      variables: {
        input: {
          email: values.email,
        },
      },
      onCompleted: () => {
        navigate(logInPath.pattern)
        toast.success(
          "Check your email for instructions to reset your password."
        )
      },
      onError: ({ graphQLErrors }) => {
        displayErrors(graphQLErrors, setFieldError)
      },
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }: FormikProps<Values>) => (
        <Form>
          <H1 id="reset-password" font="tight">
            Forgot Password?
          </H1>
          <div
            className={clsx("mb-8 text-center text-xs text-white", "md:-mx-6")}
          >
            No worries we are here to help. Please provide the email address
            associated with your account and we will send password reset
            instructions.
          </div>

          <FormikField
            name="email"
            type="email"
            label="Email Address"
            autoFocus={true}
            light={true}
          />
          <HR dark={true} />
          <Button disabled={isSubmitting}>Send Reset Link</Button>

          <div className="my-8 text-center text-sm">
            <Link
              to={registrationPath.pattern}
              className="text-white no-underline hover:underline"
            >
              Not a member? Create account
            </Link>
          </div>

          <LoggedOutAccountFooter action="Send Reset Link" />
        </Form>
      )}
    </Formik>
  )
}

export default ForgotPasswordScreen
