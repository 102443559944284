import clsx from "clsx"
import { useFormikContext } from "formik"
import { PropsWithChildren, useRef } from "react"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { currency } from "~/common/currency"
import { searchPath } from "~/common/paths"
import { imageOrderPriceCents } from "~/common/price"
import ContactSheetThumb from "~/components/ContactSheetThumb"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import { ImageSearchFormValues } from "../../SearchFormScreen/components/ImageSearchFields"

export const ImageSearchResultsSummary_SearchFragment = gql(`
  fragment ImageSearchResultsSummary_SearchFragment on Search {
    id
    imageSearchParams {
      id
      imageCount
      ganLabel {
        id
        uid
        name
      }
    }
  }
`)

export const ImageSearchResultsSummary_GanLabelFragment = gql(`
  fragment ImageSearchResultsSummary_GanLabelFragment on GanLabel {
    id
    name
  }
`)

const TD = ({
  right,
  bold,
  children,
}: { right?: boolean; bold?: boolean } & PropsWithChildren) => (
  <td
    className={clsx("py-0.5", {
      "text-right": right,
      "font-semibold": bold,
    })}
  >
    {children}
  </td>
)

const ImageSearchResultsSummary = ({
  ganLabelData,
}: {
  ganLabelData: FragmentType<typeof ImageSearchResultsSummary_GanLabelFragment>
}) => {
  const { id } = useParams()
  invariant(id)
  const scrollRef = useRef<HTMLDivElement>(null)
  const { values } = useFormikContext<ImageSearchFormValues>()
  const imageCount = Number(values.imageCount)

  const ganLabel = getFragmentData(
    ImageSearchResultsSummary_GanLabelFragment,
    ganLabelData
  )

  return (
    <div className="flex flex-col gap-6 text-base">
      <div className="flex flex-col gap-6 text-base">
        <div ref={scrollRef}>
          <H1>Results Summary</H1>
          <div className="my-1">
            <b>1</b> result found that matches your search parameters.{" "}
            <Link
              to={searchPath({ id })}
              variant="blue"
              underline={true}
              className="text-sm"
              preserveSearch={true}
            >
              Edit Search
            </Link>
          </div>
        </div>

        <div className="flex min-h-[200px] max-w-[450px] flex-col justify-between gap-4 rounded-md bg-daylight-blue p-4 text-elderberry">
          <div>
            <div className="mb-3 flex items-center gap-5">
              <ContactSheetThumb />
              <div className="text-base font-bold">{ganLabel.name}</div>
            </div>
            <table className="w-full text-sm">
              <tbody>
                <tr>
                  <TD>Image Count</TD>
                  <TD right={true} bold={true}>
                    {imageCount.toLocaleString("en-US", {
                      maximumFractionDigits: 0,
                    })}
                  </TD>
                </tr>
                <tr>
                  <TD>Label</TD>
                  <TD right={true} bold={true}>
                    {ganLabel.name}
                  </TD>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <table className="w-full text-sm">
              <tbody>
                <tr>
                  <TD>Estimated Price</TD>
                  <TD right={true} bold={true}>
                    {currency(imageOrderPriceCents(imageCount))}
                  </TD>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageSearchResultsSummary
