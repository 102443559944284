import { Link } from "react-router-dom"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { ordersPath } from "~/common/paths"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import WalletIcon from "~/images/icons/wallet.svg"
import OrderRow, {
  OrderRow_OrderFragment,
} from "~/screens/OrdersScreen/screens/OrdersIndexScreen/components/OrderRow"
import DashboardEmptyTable from "./DashboardEmptyTable"
import DashboardTitle from "./DashboardTitle"

export const DashboardOrders_QueryFragment = gql(`
  fragment DashboardOrders_QueryFragment on Query {
    orders(userId: $userId, limit: 21) {
      orders {
        edges {
          node {
            ...OrderRow_OrderFragment
          }
        }
      }
    }
  }
`)

const DashboardOrders = ({
  orders,
}: {
  orders: FragmentType<typeof OrderRow_OrderFragment>[]
}) => {
  return (
    <div className="flex flex-col gap-8">
      <Link to={ordersPath.pattern}>
        <DashboardTitle text="Orders">
          <WalletIcon />
        </DashboardTitle>
      </Link>
      <div className="h-[460px] overflow-auto border border-border-gray">
        {orders.length > 0 ? (
          <Table>
            <TableHeader outerBorder={false}>
              <TableRow outerBorder={false}>
                <TableHead className="min-w-[150px]">Order Number</TableHead>
                <TableHead className="min-w-[162px]">Status</TableHead>
                <TableHead className="min-w-[120px]">Order Date</TableHead>
                <TableHead className="min-w-[142px]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {orders.map((order) => (
                <OrderRow
                  key={getFragmentData(OrderRow_OrderFragment, order).id}
                  data={order}
                  compact={true}
                  outerBorder={false}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <DashboardEmptyTable text="No purchases, place an order to view them here." />
        )}
      </div>
    </div>
  )
}

export default DashboardOrders
