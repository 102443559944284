import { Outlet } from "react-router-dom"

const AdminExportRequestsScreen = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default AdminExportRequestsScreen
