import { useRef } from "react"
import { FragmentType, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import { STUDY_RESULTS_LIMIT } from "~/screens/SearchScreen/screens/StudySearchResultsScreen/StudySearchResultsScreen"
import StudyPackageStudiesTable from "./StudyPackageStudiesTable"
import { StudyPackageStudyRow_StudyFragment } from "./StudyPackageStudyRow"

export const StudyPackageStudies_QueryFragment = gql(`
  fragment StudyPackageStudies_QueryFragment on Query {
    studies(studyPackageId: $id, page: $page, limit: $limit) {
      studies {
        edges {
          node {
            ...StudyPackageStudyRow_StudyFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const StudyPackageStudies = ({
  studyPackageId,
  studies,
  page,
  studyCount,
}: {
  studyPackageId: string
  studies: FragmentType<typeof StudyPackageStudyRow_StudyFragment>[]
  page: number
  studyCount: number
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const totalPages = Math.ceil(studyCount / STUDY_RESULTS_LIMIT)
  return (
    <div className="flex flex-col gap-6 text-base">
      {studies.length > 0 && (
        <StudyPackageStudiesTable
          studyPackageId={studyPackageId}
          studies={studies}
        />
      )}
      <Pagination
        page={page}
        totalPages={totalPages}
        onClick={() => {
          scrollRef.current?.scrollIntoView()
        }}
      />
    </div>
  )
}

export default StudyPackageStudies
