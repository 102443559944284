import { useMutation, useQuery } from "@apollo/client"
import { Form, Formik, FormikHelpers, useFormikContext } from "formik"
import { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import {
  Outlet,
  matchPath,
  unstable_usePrompt,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import {
  newObjectId,
  orderSuccessPath,
  studySearchNewOrderPath,
  studySearchResultPath,
  studySearchResultsPath,
} from "~/common/paths"
import { displayErrors } from "~/common/validations"
import useSearchParamValues from "~/hooks/useSearchParamValues"
import useSearchSubscription from "~/hooks/useSearchSubscription"
import Error from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { StudyResultsRow_StudyFragment } from "./components/StudyResultsRow"
import {
  StudySearchResultsSummary_QueryFragment,
  StudySearchResultsSummary_SearchFragment,
} from "./components/StudySearchResultsSummary"

const StudySearchResultsScreen_Query = gql(`
  query StudySearchResultsScreen_Query($id: ID!, $searchParams: StudySearchParamsInput, $page: Int, $limit: Int) {
    ...StudySearchResultsSummary_QueryFragment
  }
`)

const SearchStudySelectionUpdate_Mutation = gql(`
  mutation SearchStudySelectionUpdate($input: SearchStudySelectionUpdateInput!) {
    searchStudySelectionUpdate(input: $input) {
      search {
        ...StudySearchResultsSummary_SearchFragment
      }
    }
  }
`)

const StudyOrderCreate_Mutation = gql(`
  mutation StudyOrderCreate($input: StudyOrderCreateInput!) {
    studyOrderCreate(input: $input) {
      order {
        id
      }
    }
  }
`)

export const STUDY_RESULTS_LIMIT = 12

export type StudySearchResultsFormValues = {
  studyIdsDiff: string[]
  selectAll: boolean
  submitType: "search" | "order"
  acceptedTerms: boolean
}

type UpdateSearchStudies = (
  searchId: string,
  values: Pick<StudySearchResultsFormValues, "studyIdsDiff" | "selectAll">
) => void

export type SearchResultsScreenOutletContext = {
  search: FragmentType<typeof StudySearchResultsSummary_SearchFragment>
  studyCount: number
  studies: FragmentType<typeof StudyResultsRow_StudyFragment>[]
  page: number
  requestedPurchase: boolean
  setRequestedPurchase: (requestedPurchase: boolean) => void
  loading: boolean
  isDuplicatingSearch: boolean
  isCreatingResults: boolean
}

const Content = (context: SearchResultsScreenOutletContext) => {
  const { dirty } = useFormikContext<StudySearchResultsFormValues>()

  unstable_usePrompt({
    message:
      "You have unsaved study selections, are you sure you want to leave?",
    // @ts-expect-error
    when: ({ currentLocation, nextLocation }) => {
      return (
        dirty &&
        currentLocation.pathname !== nextLocation.pathname &&
        !matchPath(studySearchResultsPath.pattern, nextLocation.pathname) &&
        !matchPath(studySearchResultPath.pattern, nextLocation.pathname) &&
        !matchPath(studySearchNewOrderPath.pattern, nextLocation.pathname) &&
        !matchPath(orderSuccessPath.pattern, nextLocation.pathname)
      )
    },
  })

  return <Outlet context={context} />
}

const StudySearchResultsScreen = () => {
  const { id } = useParams()
  invariant(id, "Expected search id")
  const [rawSearchParams] = useSearchParams()
  const [requestedPurchase, setRequestedPurchase] = useState(false)
  const navigate = useNavigate()
  const searchParamValues = useSearchParamValues()
  const page = rawSearchParams.get("page") || "1"
  const [mutateSearch] = useMutation(SearchStudySelectionUpdate_Mutation)
  const [mutateOrder] = useMutation(StudyOrderCreate_Mutation)
  const isUpdatingSelectionsRef = useRef(false)

  const result = useQuery(StudySearchResultsScreen_Query, {
    variables: {
      id,
      searchParams: id === newObjectId ? searchParamValues : undefined,
      page: page ? Number(page) : 1,
      limit: STUDY_RESULTS_LIMIT,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  const searchResults = getFragmentData(
    StudySearchResultsSummary_QueryFragment,
    data
  )?.searchResults

  const searchData = getFragmentData(
    StudySearchResultsSummary_SearchFragment,
    searchResults?.search
  )

  const isDuplicatingSearch = searchData?.isDuplicatingSearch
  const isCreatingResults = searchData?.isCreatingResults

  useSearchSubscription(
    id,
    loading || (!isDuplicatingSearch && !isCreatingResults)
  )

  useEffect(() => {
    if (
      isUpdatingSelectionsRef.current === true &&
      searchData?.isUpdatingSelections === false
    ) {
      toast.success("Search has been saved")
    }
    isUpdatingSelectionsRef.current = !!searchData?.isUpdatingSelections
  }, [searchData?.isUpdatingSelections])

  if (loading && !data)
    return (
      <div className="h-screen">
        <LoadingIndicatorCentered />
      </div>
    )
  if (error || !data) return <Error error={error} />

  invariant(searchResults, "Expected searchResults")

  const search = searchResults?.search
  const studies = searchResults?.studies

  const { totalCount: studyCount, edges: studyEdges } = studies
  const { studyIdsDiff, selectAll, studySearchParams } = getFragmentData(
    StudySearchResultsSummary_SearchFragment,
    searchResults.search
  )
  invariant(studySearchParams, "Expected studySearchParams")

  // studyIds represent ids that were changed from their default state.
  // The default state is determined by the value of selectAll.
  const initialValues: StudySearchResultsFormValues = {
    studyIdsDiff,
    selectAll,
    submitType: "search",
    acceptedTerms: true,
  }

  const updateSearchStudies: UpdateSearchStudies = async (searchId, values) => {
    const { data } = await mutateSearch({
      variables: {
        input: {
          id: searchId,
          studyIdsDiff: values.studyIdsDiff,
          selectAll: values.selectAll,
        },
      },
    })

    const isUpdatingSelections = getFragmentData(
      StudySearchResultsSummary_SearchFragment,
      data?.searchStudySelectionUpdate.search
    )?.isUpdatingSelections

    if (!isUpdatingSelections) {
      toast.success("Search has been saved")
    }
  }

  const createOrder = async (values: StudySearchResultsFormValues) => {
    const resp = await mutateOrder({
      variables: {
        input: {
          searchId: id,
          studyIdsDiff: values.studyIdsDiff,
          selectAll: values.selectAll,
          studySearchParams: id === newObjectId ? searchParamValues : undefined,
        },
      },
    })

    const orderId = resp?.data?.studyOrderCreate?.order?.id
    invariant(orderId, "Expected order id")
    navigate(orderSuccessPath({ id: orderId }), { replace: true })
  }

  const onSubmit = async (
    values: StudySearchResultsFormValues,
    { setFieldError, resetForm }: FormikHelpers<StudySearchResultsFormValues>
  ) => {
    try {
      // Save search studies
      if (values.submitType === "search" && id !== newObjectId) {
        await updateSearchStudies(id, values)
      } else if (values.submitType === "order") {
        await createOrder(values)
      }
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }

    resetForm({ values })
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <Form>
        <Content
          search={search}
          isDuplicatingSearch={!!isDuplicatingSearch}
          isCreatingResults={!!isCreatingResults}
          studyCount={studyCount}
          studies={studyEdges.map((edge) => edge.node)}
          page={Number(page)}
          requestedPurchase={requestedPurchase}
          setRequestedPurchase={setRequestedPurchase}
          loading={loading}
        />
      </Form>
    </Formik>
  )
}

export default StudySearchResultsScreen
