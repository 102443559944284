import clsx from "clsx"

const FieldGroup = ({
  row = false,
  hidden = false,
  margin = true,
  gap = true,
  className,
  children,
}: {
  row?: boolean
  hidden?: boolean
  margin?: boolean
  gap?: boolean
  className?: string
  children: React.ReactNode
}) => {
  const gapClass = row ? "gap-2 md:gap-8" : "gap-1.5"
  return (
    <div
      className={clsx("flex w-full", gap ? gapClass : undefined, className, {
        "flex-col": !row,
        hidden: hidden,
        "mt-1.5": margin,
        "flex-1 flex-col md:flex-row": row,
      })}
    >
      {children}
    </div>
  )
}

export default FieldGroup
