import clsx from "clsx"
import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { currency } from "~/common/currency"
import { studyPackageNewOrderPath, studyPackagePath } from "~/common/paths"
import { getStudyCountDisplay, getStudyCountType } from "~/common/studyPackage"
import { ButtonLink } from "~/ui/Button"

export const StudyPackageListItem_StudyPackageFragment = gql(`
  fragment StudyPackageListItem_StudyPackageFragment on StudyPackage {
    id
    name
    priceCents
    studyCount
    manualCount
    isImageCount
    showStudies
    createdAt
    modalities
    bodyPart {
      id
      name
    }
  }
`)

const TD = ({
  right,
  bold,
  children,
}: { right?: boolean; bold?: boolean } & PropsWithChildren) => (
  <td
    className={clsx("py-0.5", {
      "text-right": right,
      "font-semibold": bold,
    })}
  >
    {children}
  </td>
)

const StudyPackageListItem = ({
  data,
}: {
  data: FragmentType<typeof StudyPackageListItem_StudyPackageFragment>
}) => {
  const studyPackage = getFragmentData(
    StudyPackageListItem_StudyPackageFragment,
    data
  )
  const path = studyPackagePath({ id: studyPackage.id })

  return (
    <div className="relative flex min-h-[250px] flex-1 justify-between rounded-md bg-daylight-blue text-elderberry">
      <Link
        to={path}
        className={clsx(
          "block h-full w-full p-4 pb-[120px]",
          "md:pb-80px",
          "hover:opacity-75"
        )}
      >
        <div className="mb-3 flex items-center gap-5">
          <div className="text-base font-bold">{studyPackage.name}</div>
        </div>
        <table className="w-full text-sm">
          <tbody>
            <tr>
              <TD>
                <span className="capitalize">
                  {getStudyCountType({ studyPackage })} count
                </span>
              </TD>
              <TD right={true} bold={true}>
                {getStudyCountDisplay({ studyPackage })}
              </TD>
            </tr>
            <tr>
              <TD>Body Part</TD>
              <TD right={true} bold={true}>
                {studyPackage.bodyPart.name}
              </TD>
            </tr>
            <tr>
              <TD>Modality</TD>
              <TD right={true} bold={true}>
                <div className="uppercase">
                  {studyPackage.modalities.join(", ")}
                </div>
              </TD>
            </tr>
            <tr>
              <TD>Price</TD>
              <TD right={true} bold={true}>
                {currency(studyPackage.priceCents)}
              </TD>
            </tr>
          </tbody>
        </table>
      </Link>

      <div className="absolute bottom-4 left-4 flex flex-wrap gap-2">
        <ButtonLink to={path} variant="secondary" display="inline">
          View Package
        </ButtonLink>
        <ButtonLink
          to={studyPackageNewOrderPath({ id: studyPackage.id })}
          variant="blue"
          display="inline"
          data-test="purchase-package"
        >
          Order
        </ButtonLink>
      </div>
    </div>
  )
}

export default StudyPackageListItem
