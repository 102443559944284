import { useQuery } from "@apollo/client"
import { ChevronLeft } from "lucide-react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { studyPackagesPath } from "~/common/paths"
import { getStudyCountDisplay } from "~/common/studyPackage"
import DetailsTableWithDescription from "~/components/DetailsTableWithDescription"
import ResultsHr from "~/components/ResultsHr"
import { STUDY_RESULTS_LIMIT } from "~/screens/SearchScreen/screens/StudySearchResultsScreen/StudySearchResultsScreen"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import StudyPackageDetailsTable from "./components/StudyPackageDetailsTable"
import StudyPackageStats from "./components/StudyPackageStats"
import StudyPackageStudies, {
  StudyPackageStudies_QueryFragment,
} from "./components/StudyPackageStudies"

export const StudyPackageScreen_StudyPackageFragment = gql(`
  fragment StudyPackageScreen_StudyPackageFragment on StudyPackage {
    id
    name
    manualCount
    studyCount
    showStudies
    isImageCount
    priceCents
    modalities
    description
    bodyPart {
      id
      name
    }
  }
`)

const StudyPackageScreen_Query = gql(`
  query StudyPackageScreen_Query($id: ID!, $page: Int, $limit: Int) {
    node(id: $id) {
      ...StudyPackageScreen_StudyPackageFragment
    }
    ...StudyPackageStudies_QueryFragment
  }
`)

const StudyPackageScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")

  const [searchParams] = useSearchParams()

  const { data, loading, error } = useQuery(StudyPackageScreen_Query, {
    variables: {
      id: id,
      page: Number(searchParams.get("page") || 1),
      limit: STUDY_RESULTS_LIMIT,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "StudyPackage")
    return <Error error={error} />

  const studyPackage = getFragmentData(
    StudyPackageScreen_StudyPackageFragment,
    data.node
  )

  const { studies } = getFragmentData(StudyPackageStudies_QueryFragment, data)
  const backPath = studyPackagesPath.pattern

  return (
    <div className="flex flex-col gap-6">
      <div className="mb-8 flex items-end">
        <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
          <ChevronLeft size={28} />
        </Link>
        <H1 margin={false}>{studyPackage.name}</H1>
      </div>
      <StudyPackageStats
        studyPackageId={id}
        studyCountDisplay={getStudyCountDisplay({ studyPackage })}
        isImageCount={studyPackage.isImageCount}
        priceCents={studyPackage.priceCents}
      />
      <DetailsTableWithDescription
        description={
          studyPackage.description
            ? { title: "Description", content: studyPackage.description }
            : undefined
        }
      >
        <StudyPackageDetailsTable
          studyPackage={studyPackage}
          modalities={studyPackage.modalities}
          bodyPartName={studyPackage.bodyPart.name}
        />
      </DetailsTableWithDescription>
      <StudyPackageStudies
        studyPackageId={id}
        studies={studies.studies.edges.map((edge) => edge.node)}
        page={studies.page}
        studyCount={studies.studies.totalCount}
      />
      <ResultsHr />
      <StudyPackageStats
        studyPackageId={id}
        studyCountDisplay={getStudyCountDisplay({ studyPackage })}
        isImageCount={studyPackage.isImageCount}
        priceCents={studyPackage.priceCents}
      />
    </div>
  )
}

export default StudyPackageScreen
