import { cva } from "class-variance-authority"
import clsx from "clsx"
import { Children } from "~/types"

type Props = {
  id?: string
  centered?: boolean
  light?: boolean
  font?: "default" | "tight"
  margin?: boolean
  variant?: "h1" | "h2" | "h3"
} & Children

const headingVariants = cva(["font-semibold w-full", "disabled:opacity-50"], {
  variants: {
    variant: {
      h1: "text-3xl",
      h2: "text-base font-bold",
      h3: "text-sm",
    },
    font: {
      default: "",
      tight: "font-tight",
    },
  },
})

export const Heading = ({
  as: As = "h1",
  id,
  centered = false,
  light = false,
  font = "default",
  margin = true,
  variant,
  children,
}: Props & {
  as: "h1" | "h2" | "h3"
}) => {
  return (
    <As
      className={clsx(
        "text-3xl font-semibold",
        light ? "text-white" : "text-blue-zodiac",
        headingVariants({ variant: variant || As, font }),
        {
          "text-center": centered,
          "mb-2": margin,
        }
      )}
      id={id}
    >
      {children}
    </As>
  )
}

export const H1 = (props: Props) => <Heading {...props} as="h1" />
export const H2 = (props: Props) => <Heading {...props} as="h2" />
export const H3 = (props: Props) => <Heading {...props} as="h3" />
