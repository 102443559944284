import { useFormikContext } from "formik"
import { useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { studySearchResultPath } from "~/common/paths"
import { StudiesTableSharedColumns } from "~/components/StudiesTable"
import { TableCell, TableRow } from "~/components/ui/Table"
import FormikField from "~/ui/FormikField"
import { StudySearchResultsFormValues } from "../StudySearchResultsScreen"
import LockedFeature from "./LockedFeature"

export const StudyResultsRow_StudyFragment = gql(`
  fragment StudyResultsRow_StudyFragment on Study {
    id
    uid
    patientAge
    description
    sex
    date
    modality
    contactSheetUrl
    purchased
    reportConclusion
  }
`)

const StudyResultsRow = ({
  data,
}: {
  data: FragmentType<typeof StudyResultsRow_StudyFragment>
}) => {
  const { values } = useFormikContext<StudySearchResultsFormValues>()

  const { id } = useParams()
  invariant(id, "Search ID is required")
  const [searchParams] = useSearchParams()
  const study = getFragmentData(StudyResultsRow_StudyFragment, data)
  const studyPath = `${studySearchResultPath({
    id,
    studyId: study.id,
  })}?${searchParams.toString()}`

  const checked = values.selectAll
    ? !values.studyIdsDiff.includes(study.uid)
    : values.studyIdsDiff.includes(study.uid)

  return (
    <TableRow>
      <TableCell>
        <LockedFeature>
          <div
            className="scale-[0.9] text-border-gray group-hover:text-gray-300"
            data-test="study-checkbox"
            data-test-id={study.uid}
          >
            <FormikField
              name="studyIdsDiff"
              value={study.uid}
              type="checkbox"
              checked={checked}
            />
          </div>
        </LockedFeature>
      </TableCell>
      <StudiesTableSharedColumns
        description={study.description}
        purchased={study.purchased}
        patientAge={study.patientAge}
        sex={study.sex}
        modality={study.modality}
        reportConclusion={study.reportConclusion}
        studyPath={studyPath}
        contactSheetUrl={study.contactSheetUrl}
      />
    </TableRow>
  )
}

export default StudyResultsRow
