import { PropsWithChildren } from "react"
import { dateTime } from "~/common/dates"

const AdminOrderDownloadSection = ({
  title,
  sentAt,
  children,
}: { title: string; sentAt?: string } & PropsWithChildren) => {
  return (
    <div className="w-full divide-y border border-border-gray">
      <div className="flex items-center justify-between p-2 px-4 font-bold">
        <div>{title}</div>
        {!!sentAt && (
          <div className="text-xs font-normal text-gray-400">
            {sentAt && ` Sent on ${dateTime(new Date(sentAt))}`}
          </div>
        )}
      </div>
      <div className="p-4">{children}</div>
    </div>
  )
}

export default AdminOrderDownloadSection
