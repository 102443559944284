import { BooleanParam, StringParam, useQueryParams } from "use-query-params"
import { SearchSexEnum } from "~/__generated__/graphql"

export const searchParamSchema = {
  patientAgeMin: StringParam,
  patientAgeMax: StringParam,
  sex: StringParam,
  mr: BooleanParam,
  mrStrength: StringParam,
  mrContrast: BooleanParam,
  mrReportKeywords: StringParam,
  mrDescriptionKeywords: StringParam,
  mrSeriesDescriptionKeywords: StringParam,
  ct: BooleanParam,
  ctContrast: BooleanParam,
  ctReportKeywords: StringParam,
  ctDescriptionKeywords: StringParam,
  ctSeriesDescriptionKeywords: StringParam,
  us: BooleanParam,
  usReportKeywords: StringParam,
  usDescriptionKeywords: StringParam,
  usSeriesDescriptionKeywords: StringParam,
  xr: BooleanParam,
  xrReportKeywords: StringParam,
  xrDescriptionKeywords: StringParam,
  xrSeriesDescriptionKeywords: StringParam,
}

const useSearchParamValues = () => {
  const [searchParams] = useQueryParams(searchParamSchema)
  return {
    ...searchParams,
    patientAgeMin: Number(searchParams.patientAgeMin),
    patientAgeMax: Number(searchParams.patientAgeMax),
    sex: searchParams.sex as SearchSexEnum,
    mr: Boolean(searchParams.mr),
    mrStrength: searchParams.mrStrength
      ? Number(searchParams.mrStrength)
      : undefined,
    ct: Boolean(searchParams.ct),
    us: Boolean(searchParams.us),
    xr: Boolean(searchParams.xr),
  }
}

export default useSearchParamValues
