import clsx from "clsx"
import { useFormikContext } from "formik"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { currency } from "~/common/currency"
import { studySearchNewOrderPath } from "~/common/paths"
import { studyOrderPriceCents } from "~/common/price"
import { getSelectedStudyCount } from "~/common/search"
import HeaderStatsContainer from "~/components/HeaderStats/HeaderStatsContainer"
import HeaderStatsItem from "~/components/HeaderStats/HeaderStatsItem"
import useLoggedInLink from "~/hooks/useLoggedInLink"
import BookSvg from "~/images/icons/book.svg"
import DollarSvg from "~/images/icons/dollar.svg"
import { ButtonLink } from "~/ui/Button"
import { StudySearchResultsFormValues } from "../StudySearchResultsScreen"

const StudySearchResultsStats = ({ studyCount }: { studyCount: number }) => {
  const { id } = useParams()
  invariant(id, "Expected search id")
  const loggedInLink = useLoggedInLink()

  const { isSubmitting } = useFormikContext<StudySearchResultsFormValues>()
  const { values } = useFormikContext<StudySearchResultsFormValues>()
  const selectedStudiesCount = getSelectedStudyCount(
    values.selectAll,
    values.studyIdsDiff.length,
    studyCount
  )
  const price = studyOrderPriceCents(selectedStudiesCount)

  return (
    <HeaderStatsContainer>
      <HeaderStatsItem
        value={selectedStudiesCount.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        })}
      >
        <BookSvg />
        <div>Selected Studies</div>
      </HeaderStatsItem>
      <HeaderStatsItem
        value={price === 0 ? "-" : currency(price)}
        data-test="estimated-price"
      >
        <DollarSvg />
        <div>Estimated Price</div>
      </HeaderStatsItem>
      <div className="flex flex-1">
        <ButtonLink
          variant="primary"
          size="xl"
          className={clsx("h-full", {
            "pointer-events-none": isSubmitting,
          })}
          to={loggedInLink(
            `${studySearchNewOrderPath({
              id,
            })}${window.location.search}`,
            true
          )}
        >
          Order Selected Data Sets
        </ButtonLink>
      </div>
    </HeaderStatsContainer>
  )
}

export default StudySearchResultsStats
