import { ModalityEnum, StudyPackage } from "~/__generated__/graphql"
import { getStudyCountDisplay, getStudyCountType } from "~/common/studyPackage"
import { TD, TH, Table } from "~/ui/DetailTable"

const StudyPackageDetailsTable = ({
  modalities,
  bodyPartName,
  studyPackage,
}: {
  modalities: ModalityEnum[]
  bodyPartName: string
  studyPackage: Pick<
    StudyPackage,
    "studyCount" | "showStudies" | "isImageCount" | "manualCount"
  >
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <TH colSpan={2}>Details</TH>
        </tr>
      </thead>
      <tbody>
        <>
          <tr>
            <TD>
              <span className="capitalize">
                {getStudyCountType({ studyPackage })} count
              </span>
            </TD>
            <TD>{getStudyCountDisplay({ studyPackage })}</TD>
          </tr>
          <tr>
            <TD>Modality</TD>
            <TD>
              <div className="uppercase">{modalities.join(", ")}</div>
            </TD>
          </tr>
        </>
        <tr>
          <TD>Body Part</TD>
          <TD>{bodyPartName}</TD>
        </tr>
      </tbody>
    </Table>
  )
}

export default StudyPackageDetailsTable
