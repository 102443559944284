import clsx from "clsx"
import { PropsWithChildren } from "react"

const HeaderStatsContainer = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={clsx(
        "flex flex-col items-stretch gap-4 text-sm",
        "xl:flex-row"
      )}
      children={children}
    />
  )
}

export default HeaderStatsContainer
