import { Slot } from "@radix-ui/react-slot"
import { VariantProps, cva } from "class-variance-authority"
import clsx from "clsx"
import { forwardRef } from "react"
import {
  LinkProps,
  Link as ReactRouterLink,
  useLocation,
} from "react-router-dom"

const buttonVariants = cva(
  [
    "font-medium inline-flex justify-center items-center",
    "disabled:opacity-50",
  ],
  {
    variants: {
      variant: {
        primary: [
          "bg-candlelight text-blue-zodiac border-candlelight border",
          "hover:opacity-80",
        ],
        secondary: [
          "bg-white border border-white",
          "text-gray-800",
          "border-gray-400",
          "hover:bg-gray-100",
        ],
        outlineSecondary: ["border border-border-gray", "hover:opacity-80"],
        blue: [
          "bg-dodger-blue text-white border border-dodger-blue",
          "hover:opacity-80",
        ],
        darkBlue: [
          "bg-blue-zodiac text-white border border-blue-zodiac",
          "hover:opacity-80",
        ],
        outlineDarkBlue: [
          "border border-blue-zodiac text-blue-zodiac",
          "hover:opacity-80",
        ],
        ghost: ["hover:opacity-80"],
      },
      size: {
        sm: ["text-sm rounded-sm px-3 py-1.5"],
        md: ["text-sm rounded-md px-4 min-h-[42px] py-2.5"],
        lg: ["text-sm rounded-md px-4 py-3.5"],
        xl: ["text-base rounded-md px-4 py-4"],
      },
      display: {
        block: "w-full",
        inline: "w-auto",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
      display: "block",
    },
  }
)

type Props = VariantProps<typeof buttonVariants>

export const ButtonLink = ({
  to,
  variant,
  size,
  display,
  preserveSearch = false,
  className,
  ...props
}: LinkProps & {
  external?: boolean
  preserveSearch?: boolean
  to: string
} & React.RefAttributes<HTMLAnchorElement> &
  Props) => {
  const location = useLocation()
  return (
    <ReactRouterLink
      className={clsx(buttonVariants({ variant, size, display }), className)}
      to={preserveSearch ? { pathname: to, search: location.search } : to}
      {...props}
    />
  )
}

type ButtonProps = Props &
  React.ButtonHTMLAttributes<HTMLButtonElement> & { asChild?: boolean }
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, size, display, className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={clsx(buttonVariants({ variant, size, display }), className)}
        ref={ref}
        {...props}
      />
    )
  }
)

export default Button
