import { useQuery } from "@apollo/client"
import { ChevronLeft } from "lucide-react"
import { Link, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { ordersPath } from "~/common/paths"
import Container from "~/ui/Container"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import OrderDetailsTable from "./components/OrderDetailsTable"
import OrderDownloads from "./components/OrderDownloads"

export const OrderScreen_OrderFragment = gql(`
  fragment OrderScreen_OrderFragment on Order {
    id
    createdAt
    statusDisplay
    orderNumber
    studyCount
    imageCount
    estimatedPriceCents
    priceCents
    downloadUrl
    contractDocument {
      filename
      url
      kb
    }
    defaultContractDocument {
      filename
      url
      kb
    }
    poDocument {
      filename
      url
      kb
    }
  }
`)

const OrderScreen_Query = gql(`
  query OrderScreen_Query($id: ID!) {
    node(id: $id) {
      ...OrderScreen_OrderFragment
    }
  }
`)

const OrderScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")

  const { data, loading, error } = useQuery(OrderScreen_Query, {
    variables: {
      id: id,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "Order")
    return <Error error={error} />

  const order = getFragmentData(OrderScreen_OrderFragment, data.node)
  const backPath = ordersPath.pattern

  return (
    <div>
      <div className="mb-8 flex items-end">
        <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
          <ChevronLeft size={28} />
        </Link>
        <H1 margin={false}>Order Details</H1>
      </div>
      <Container size="md">
        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full flex-col gap-8">
            <OrderDetailsTable
              createdAt={order.createdAt}
              statusDisplay={order.statusDisplay}
              orderNumber={order.orderNumber}
              studyCount={order.studyCount}
              imageCount={order.imageCount || undefined}
              priceCents={order.priceCents || undefined}
              estimatedPriceCents={order.estimatedPriceCents}
            />
          </div>
          <OrderDownloads
            poDocument={order.poDocument || undefined}
            contractDocument={order.contractDocument || undefined}
            defaultContractDocument={order.defaultContractDocument || undefined}
            downloadUrl={order.downloadUrl || undefined}
          />
        </div>
      </Container>
    </div>
  )
}

export default OrderScreen
