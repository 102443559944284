import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import { useRef, useState } from "react"
import { toast } from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import * as Yup from "yup"
import { deviseRegistrationCreatePath, logInPath } from "~/common/paths"
import { request } from "~/common/request"
import { displayActiveRecordErrors } from "~/common/validations"
import LoggedOutAccountFooter from "~/components/LoggedOutAccountFooter"
import env from "~/env"
import Button from "~/ui/Button"
import Error from "~/ui/Error"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"

type LoginFields = {
  email: string
  password: string
  passwordConfirmation: string
  firstName: string
  lastName: string
  company: string
  companyAddress1: string
  companyAddress2: string
  companyCity: string
  companyState: string
  companyZip: string
}

const initialValues = {
  email: "",
  password: "",
  passwordConfirmation: "",
  firstName: "",
  lastName: "",
  company: "",
  companyAddress1: "",
  companyAddress2: "",
  companyCity: "",
  companyState: "",
  companyZip: "",
}

const validationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string()
    .required("A password is required")
    .min(6, "Must be at least 6 characters in length"),
  passwordConfirmation: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value
    }
  ),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  company: Yup.string(),
  companyAddress1: Yup.string(),
  companyAddress2: Yup.string(),
  companyCity: Yup.string(),
  companyState: Yup.string(),
  companyZip: Yup.string(),
})

const captchaEnabled = env.ENABLE_CAPTCHA === "true"

export const stateOptions = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
].map((s) => ({ label: s, value: s }))

export const RegistrationScreen = () => {
  const [searchParams] = useSearchParams()
  const [captchaStatus, setCaptchaStatus] = useState<
    "solved" | "expired" | "error" | undefined
  >()
  const captchaRef = useRef<TurnstileInstance | undefined>()

  const onSubmit = async (
    values: LoginFields,
    { setFieldError }: FormikHelpers<LoginFields>
  ) => {
    try {
      const turnstileToken = captchaRef.current?.getResponse()
      const [status, response] = await request(
        deviseRegistrationCreatePath({}),
        { method: "POST" },
        {
          user: {
            email: values.email,
            password: values.password,
            password_confirmation: values.passwordConfirmation,
            first_name: values.firstName,
            last_name: values.lastName,
            company: values.company,
            company_address1: values.companyAddress1,
            company_address2: values.companyAddress2,
            company_city: values.companyCity,
            company_state: values.companyState,
            company_zip: values.companyZip,
          },
          "cf-turnstile-response": turnstileToken,
        }
      )

      if (status === "success") {
        const redirectUrl = searchParams.get("redirect")
        if (redirectUrl) {
          window.location.replace(decodeURIComponent(redirectUrl))
        } else {
          window.location.replace("/")
        }
      } else {
        const validationErrors = response.errors
        if (validationErrors) {
          displayActiveRecordErrors(validationErrors, setFieldError)
        } else {
          const message = response?.error || "Sign up failed, please try again"
          toast.error(message)
        }
      }
    } catch (error) {
      console.error(error)
      toast.error("Sign up failed, please try again")
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }: FormikProps<LoginFields>) => (
        <Form className="w-full">
          <H1 id="create-account" light={true} centered={true} font="tight">
            Create Account
          </H1>
          <div className="flex flex-col gap-2.5">
            <div>
              <FieldGroup row={true} gap={false} className="gap-[1px]">
                <FormikField
                  name="firstName"
                  label="First Name*"
                  inputClassName="md:rounded-e-none"
                  light={true}
                  autoFocus={true}
                  labelSize="sm"
                />
                <FormikField
                  name="lastName"
                  label="Last Name*"
                  inputClassName="md:rounded-s-none"
                  light={true}
                  labelSize="sm"
                />
              </FieldGroup>
              <FieldGroup>
                <FormikField
                  name="email"
                  type="email"
                  label="Email Address*"
                  light={true}
                  labelSize="sm"
                />

                <div className="mt-7 text-xs text-white">
                  School / Corporate Affiliation
                </div>
                <HR dark={true} padding={false} />

                <FormikField
                  name="company"
                  label="Name"
                  labelSize="sm"
                  light={true}
                />
                <FormikField
                  name="companyAddress1"
                  label="Address"
                  labelSize="sm"
                  light={true}
                />
                <FormikField
                  name="companyAddress2"
                  label="Address 2"
                  labelSize="sm"
                  light={true}
                />
                <FormikField
                  name="companyCity"
                  label="City"
                  labelSize="sm"
                  light={true}
                />
                <FieldGroup row={true} gap={false} className="gap-[1px]">
                  <FormikField
                    name="companyState"
                    label="State"
                    placeholder="Select State"
                    type="select"
                    labelSize="sm"
                    inputClassName="md:rounded-e-none"
                    options={[
                      { label: "Select State", value: "" },
                      ...stateOptions,
                    ]}
                    search={true}
                    light={true}
                  />
                  <FormikField
                    name="companyZip"
                    label="Zip"
                    inputClassName="md:rounded-s-none"
                    labelSize="sm"
                    light={true}
                  />
                </FieldGroup>
              </FieldGroup>
            </div>

            <div className="mt-6 text-xs text-white">
              Create a Unique Password
            </div>
            <HR dark={true} padding={false} />

            <FieldGroup row={true} gap={false} className="gap-[1px]">
              <FormikField
                name="password"
                type="password"
                label="Create Password*"
                inputClassName="md:rounded-e-none"
                light={true}
                labelSize="sm"
              />
              <FormikField
                name="passwordConfirmation"
                type="password"
                label="Confirm Password*"
                inputClassName="md:rounded-s-none"
                light={true}
                labelSize="sm"
              />
            </FieldGroup>
          </div>

          {captchaEnabled && (
            <div className="mt-6">
              <Turnstile
                siteKey={env.TURNSTILE_SITE_KEY}
                options={{
                  theme: "dark",
                  size: "normal",
                }}
                onError={() => setCaptchaStatus("error")}
                onExpire={() => setCaptchaStatus("expired")}
                onSuccess={() => setCaptchaStatus("solved")}
                ref={captchaRef}
              />
              {captchaStatus === "error" && (
                <Error message="Captcha error. Please try again." />
              )}
              {captchaStatus === "expired" && (
                <Error message="Captcha expired. Please try again." />
              )}
            </div>
          )}

          <HR dark={true} />
          <Button
            disabled={
              isSubmitting || (captchaEnabled && captchaStatus !== "solved")
            }
          >
            Create Account
          </Button>

          <div className="my-8 text-center text-sm">
            <Link
              to={logInPath.pattern}
              className="text-white no-underline hover:underline"
            >
              Already have an account? Sign In
            </Link>
          </div>

          <LoggedOutAccountFooter>
            By clicking “Create Account” above, you acknowledge that you have
            read and understood, and agree to RadImageNet's{" "}
          </LoggedOutAccountFooter>
        </Form>
      )}
    </Formik>
  )
}
