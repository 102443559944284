import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import * as Yup from "yup"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const boolToString = (bool: boolean) => (bool ? "Yes" : "No")

let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/
export const currencyValidator = Yup.number()
  .positive()
  .test("is-decimal", "Please enter a valid dollar amount", (val: any) => {
    if (val !== undefined) {
      return patternTwoDigisAfterComma.test(val)
    }
    return true
  })
  .min(0, "minimum 5")
  .required("Is required")
