import clsx from "clsx"
import { PropsWithChildren } from "react"
import {
  Link as RRLink,
  LinkProps as RRLinkProps,
  useLocation,
} from "react-router-dom"

type StyleProps = {
  variant?: "default" | "blue"
  underline?: boolean
  className?: string
}

type LinkType = RRLinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    to: string
    preserveSearch?: boolean
  } & StyleProps

const getClassNames = ({ variant, underline, className }: StyleProps) => {
  return clsx("hover:opacity-75", className, {
    "underline underline-offset-3": underline,
    "text-dodger-blue": variant === "blue",
  })
}

export const LinkButton = ({
  onClick,
  variant = "default",
  underline,
  className,
  disabled,
  children,
}: { onClick: () => void; disabled?: boolean } & StyleProps &
  PropsWithChildren) => {
  const classes = getClassNames({ variant, underline, className })
  return (
    <button className={classes} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}

export const Link = ({
  to,
  variant = "default",
  underline,
  preserveSearch,
  className,
  ...props
}: LinkType) => {
  const location = useLocation()
  const classes = getClassNames({ variant, underline, className })

  return (
    <RRLink
      {...props}
      className={classes}
      to={preserveSearch ? { pathname: to, search: location.search } : to}
    />
  )
}
