import clsx from "clsx"
import { useField, useFormikContext } from "formik"
import { PropsWithChildren, useEffect, useState } from "react"
import * as Yup from "yup"
import { ModalityEnum } from "~/__generated__/graphql"
import MinusIcon from "~/images/icons/minus.svg"
import PlusIcon from "~/images/icons/plus.svg"
import Button from "~/ui/Button"
import Container from "~/ui/Container"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"
import DisabledSearchSave from "../../StudySearchResultsScreen/components/DisabledSearchSave"

export type SearchFormValues = {
  patientAgeMin: string
  patientAgeMax: string
  sex: string

  mr: boolean
  mrStrength: string | undefined
  mrContrast: boolean | undefined
  mrReportKeywords: string | undefined
  mrDescriptionKeywords: string | undefined
  mrSeriesDescriptionKeywords: string | undefined

  ct: boolean
  ctContrast: boolean | undefined
  ctReportKeywords: string | undefined
  ctDescriptionKeywords: string | undefined
  ctSeriesDescriptionKeywords: string | undefined

  us: boolean
  usReportKeywords: string | undefined
  usDescriptionKeywords: string | undefined
  usSeriesDescriptionKeywords: string | undefined

  xr: boolean
  xrReportKeywords: string | undefined
  xrDescriptionKeywords: string | undefined
  xrSeriesDescriptionKeywords: string | undefined
}

export const searchValidationSchema = Yup.object({
  patientAgeMin: Yup.number().required("Patient age min is required"),
  patientAgeMax: Yup.number().required("Patient age max is required"),
  sex: Yup.string().required("Patient sex is required"),
  mr: Yup.boolean(),
})

const ageOptions = Array.from({ length: 100 }, (_, i) => ({
  label: `${i + 1}`,
  value: `${i + 1}`,
}))

const mrStrengthOptions = [
  { label: "0.3", value: "0.3" },
  { label: "1.5", value: "1.5" },
  { label: "3.0", value: "3" },
]

const ivContrastOptions = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
]

const sexOptions = [
  { label: "All", value: "a" },
  { label: "Male", value: "m" },
  { label: "Female", value: "f" },
]

const SeriesSubheading = ({ children }: PropsWithChildren) => (
  <div className="mb-3 mt-8 text-2xl text-blue-zodiac" children={children} />
)

const SeriesFilter = ({
  title,
  name,
  children,
}: { title: string; name: keyof SearchFormValues } & PropsWithChildren) => {
  const { values } = useFormikContext<SearchFormValues>()
  const [field] = useField(name)
  const [isOpen, setIsOpen] = useState(!!field.value)
  const toggleOpen = () => setIsOpen((prev) => !prev)

  useEffect(() => {
    if (values[name]) setIsOpen(true)
  }, [values, name])

  return (
    <div
      className={clsx("p-4", "md:px-8 md:py-6", "lg:p-8", {
        "bg-azul-claro-blue": isOpen,
      })}
    >
      <div className="flex items-center text-neutral-500">
        <div
          className={clsx(
            "mr-4",
            isOpen ? "text-gray-300" : "text-border-gray"
          )}
        >
          <FormikField name={name} type="checkbox" />
        </div>
        <div
          className={clsx(
            "flex-1 text-base font-medium",
            "sm:text-xl",
            "lg:text-3xl",
            {
              "text-blue-zodiac": isOpen,
            }
          )}
        >
          <button type="button" onClick={toggleOpen} className="text-left">
            {title}
          </button>
        </div>
        <button type="button" onClick={toggleOpen}>
          {isOpen ? <MinusIcon /> : <PlusIcon />}
        </button>
      </div>
      {isOpen && (
        <div className="mt-6">
          <Container size="md">{children}</Container>
        </div>
      )}
    </div>
  )
}

const StudySearchFields = ({
  isDuplicatingSearch,
}: {
  isDuplicatingSearch: boolean
}) => {
  const { values, setFieldValue, isSubmitting } =
    useFormikContext<SearchFormValues>()

  const enableModalityOnFilterChange = (
    modality: "mr" | "ct" | "us" | "xr",
    newValue: string | boolean | undefined
  ) => {
    if (!!newValue && !values[modality]) {
      setFieldValue(modality, true)
    }
  }

  return (
    <>
      <Container size="md">
        <div
          className={clsx(
            "flex w-full flex-col gap-2",
            "md:grid md:grid-cols-4 md:gap-8"
          )}
        >
          <FormikField
            name="patientAgeMin"
            label="Patient Age Min"
            placeholder="Select Age Min"
            type="select"
            options={ageOptions.map((option) => ({
              ...option,
              disabled:
                !!values.patientAgeMax &&
                Number(option.value) > Number(values.patientAgeMax),
            }))}
            autoFocus={true}
            required={true}
          />
          <FormikField
            name="patientAgeMax"
            label="Patient Age Max"
            placeholder="Select Age Max"
            type="select"
            options={ageOptions.map((option) => ({
              ...option,
              disabled:
                !!values.patientAgeMin &&
                Number(option.value) < Number(values.patientAgeMin),
            }))}
            required={true}
          />
          <FormikField
            name="sex"
            label="Patient Sex"
            placeholder="Select Patient Sex"
            type="select"
            options={sexOptions}
            search={false}
          />
        </div>
      </Container>
      <div
        className={clsx(
          "-mx-4 my-10 divide-y divide-border-gray border-b border-t border-border-gray",
          "md:-mx-8"
        )}
      >
        <SeriesFilter title="Magnetic Resonance Imaging (MRI)" name="mr">
          <FieldGroup row={true}>
            <FormikField
              name="mrStrength"
              label="Machine Strength"
              placeholder="Select Machine Strength"
              type="select"
              options={mrStrengthOptions}
              search={false}
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Mr, newValue)
              }
            />
            <FormikField
              name="mrContrast"
              label="IV Contrast"
              placeholder="Select IV Contrast"
              type="select"
              options={ivContrastOptions}
              search={false}
              booleanSelect={true}
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(
                  ModalityEnum.Mr,
                  typeof newValue === "boolean"
                )
              }
            />
          </FieldGroup>

          <SeriesSubheading>Keyword Search</SeriesSubheading>

          <FieldGroup>
            <FormikField
              name="mrReportKeywords"
              label="Report Keywords"
              beforeChange={(newValue) => {
                enableModalityOnFilterChange(ModalityEnum.Mr, newValue)
              }}
            />
            <FormikField
              name="mrDescriptionKeywords"
              label="Study Description Keywords"
              beforeChange={(newValue) => {
                enableModalityOnFilterChange(ModalityEnum.Mr, newValue)
              }}
            />
            <FormikField
              name="mrSeriesDescriptionKeywords"
              label="Series Description Keywords"
              beforeChange={(newValue) => {
                enableModalityOnFilterChange(ModalityEnum.Mr, newValue)
              }}
            />
          </FieldGroup>
        </SeriesFilter>
        <SeriesFilter title="Computed Topography (CT)" name="ct">
          <FieldGroup row={true}>
            <FormikField
              name="ctContrast"
              label="IV Contrast"
              placeholder="Select IV Contrast"
              type="select"
              options={ivContrastOptions}
              search={false}
              booleanSelect={true}
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(
                  ModalityEnum.Ct,
                  typeof newValue === "boolean"
                )
              }
            />
          </FieldGroup>

          <SeriesSubheading>Keyword Search</SeriesSubheading>

          <FieldGroup>
            <FormikField
              name="ctReportKeywords"
              label="Report Keywords"
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Ct, newValue)
              }
            />
            <FormikField
              name="ctDescriptionKeywords"
              label="Study Description Keywords"
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Ct, newValue)
              }
            />
            <FormikField
              name="ctSeriesDescriptionKeywords"
              label="Series Description Keywords"
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Ct, newValue)
              }
            />
          </FieldGroup>
        </SeriesFilter>
        <SeriesFilter title="Ultrasound" name="us">
          <SeriesSubheading>Keyword Search</SeriesSubheading>
          <FieldGroup>
            <FormikField
              name="usReportKeywords"
              label="Report Keywords"
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Us, newValue)
              }
            />
            <FormikField
              name="usDescriptionKeywords"
              label="Study Description Keywords"
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Us, newValue)
              }
            />
            <FormikField
              name="usSeriesDescriptionKeywords"
              label="Series Description Keywords"
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Us, newValue)
              }
            />
          </FieldGroup>
        </SeriesFilter>
        <SeriesFilter title="X-Ray" name="xr">
          <SeriesSubheading>Keyword Search</SeriesSubheading>
          <FieldGroup>
            <FormikField
              name="xrReportKeywords"
              label="Report Keywords"
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Xr, newValue)
              }
            />
            <FormikField
              name="xrDescriptionKeywords"
              label="Study Description Keywords"
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Xr, newValue)
              }
            />
            <FormikField
              name="xrSeriesDescriptionKeywords"
              label="Series Description Keywords"
              beforeChange={(newValue) =>
                enableModalityOnFilterChange(ModalityEnum.Xr, newValue)
              }
            />
          </FieldGroup>
        </SeriesFilter>
      </div>
      <div className="mt-6 flex justify-between">
        <DisabledSearchSave disabled={isDuplicatingSearch}>
          <Button disabled={isSubmitting} display="inline" type="submit">
            Search Studies
          </Button>
        </DisabledSearchSave>
      </div>
    </>
  )
}

export default StudySearchFields
