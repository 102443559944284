import clsx from "clsx"
import { Form, Formik, useFormikContext } from "formik"
import { useEffect } from "react"
import { useQueryParams } from "use-query-params"
import useBodyPartsQuery from "~/hooks/useBodyPartsQuery"
import Error from "~/ui/Error"
import { OptionType } from "~/ui/FormikField"
import FormikMultiSelectDropdown from "~/ui/FormikMultiSelectDropdown"
import Loading from "~/ui/Loading"
import { studyPackagesQuerySchema } from "../StudyPackagesIndexScreen"
import { modalityOptions } from "~/screens/AdminScreen/AdminStudyPackagesScreen/components/AdminStudyPackageForm"

type Values = {
  modalities: string[]
  bodyPartIds: string[]
  featured: boolean
}

const StudyPackagesFilterFields = ({
  bodyPartOptions,
}: {
  bodyPartOptions: OptionType[]
}) => {
  const { values, setFieldValue } = useFormikContext<Values>()
  const [_searchParams, setSearchParams] = useQueryParams(
    studyPackagesQuerySchema
  )

  useEffect(() => {
    setSearchParams(
      {
        modalities: values.modalities || undefined,
        bodyPartIds: values.bodyPartIds || undefined,
        featured: values.featured || undefined,
      },
      "replace"
    )
  }, [values, setSearchParams])

  return (
    <div className="mb-8 flex justify-between">
      <div className="flex gap-2">
        <FormikMultiSelectDropdown
          name="modalities"
          placeholder="Modality"
          options={modalityOptions}
          buttonClasses="min-w-[125px]"
        />
        <FormikMultiSelectDropdown
          name="bodyPartIds"
          placeholder="Body Part"
          options={bodyPartOptions}
          buttonClasses="min-w-[140px]"
        />
      </div>

      <div className="rounded-lg border border-border-gray p-1">
        <button
          type="button"
          className={clsx(
            "text-primary rounded px-3.5 py-1.5 text-sm",
            "hover:opacity-75",
            {
              "bg-candlelight": !values.featured,
            }
          )}
          onClick={() => setFieldValue("featured", false)}
        >
          Newest
        </button>
        <button
          type="button"
          className={clsx(
            "text-primary rounded px-3.5 py-1.5 text-sm",
            "hover:opacity-75",
            {
              "bg-candlelight": !!values.featured,
            }
          )}
          onClick={() => setFieldValue("featured", true)}
        >
          Featured
        </button>
        <button type="submit" className="text-primary"></button>
      </div>
    </div>
  )
}

const StudyPackagesFilters = () => {
  const { bodyParts, loading, error } = useBodyPartsQuery()
  const [searchParams] = useQueryParams(studyPackagesQuerySchema)

  const onSubmit = () => {}

  if (loading) return <Loading />
  if (error || !bodyParts?.length) return <Error />

  const bodyPartOptions = bodyParts.map((bodyPart) => ({
    label: bodyPart.name,
    value: bodyPart.uid,
  }))

  const initialValues = {
    modalities: searchParams.modalities || "",
    bodyPartIds: searchParams.bodyPartIds || "",
    featured: searchParams.featured || false,
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <Form>
        <StudyPackagesFilterFields bodyPartOptions={bodyPartOptions} />
      </Form>
    </Formik>
  )
}

export default StudyPackagesFilters
