import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { adminExportRequestPath } from "~/common/paths"
import DateTimeCell from "~/components/DateTimeCell"
import { LinkedTableCell, TableCell, TableRow } from "~/components/ui/Table"
import { ButtonLink } from "~/ui/Button"

export const AdminExportRequestRow_ExportRequestFragment = gql(`
  fragment AdminExportRequestRow_ExportRequestFragment on ExportRequest {
    id
    createdAt
    status
    search {
      id
    }
    user {
      id
      company
      name
      email
    }
  }
`)

const AdminExportRequestRow = ({
  data,
}: {
  data: FragmentType<typeof AdminExportRequestRow_ExportRequestFragment>
}) => {
  const exportRequest = getFragmentData(
    AdminExportRequestRow_ExportRequestFragment,
    data
  )
  const path = adminExportRequestPath({
    id: exportRequest.id,
  })

  return (
    <TableRow>
      <LinkedTableCell to={path}>{exportRequest.user.name}</LinkedTableCell>
      <LinkedTableCell to={path}>{exportRequest.user.email}</LinkedTableCell>
      <LinkedTableCell to={path}>{exportRequest.user.company}</LinkedTableCell>
      <LinkedTableCell to={path}>
        <DateTimeCell date={exportRequest.createdAt} />
      </LinkedTableCell>
      <TableCell>
        <div className="capitalize">{exportRequest.status}</div>
      </TableCell>
      <TableCell>
        <div className="flex gap-2">
          <ButtonLink to={path} display="inline">
            View
          </ButtonLink>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default AdminExportRequestRow
