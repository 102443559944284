import { gql, useMutation } from "@apollo/client"
import clsx from "clsx"
import toast from "react-hot-toast"
import UploadButton from "~/components/UploadButton"
import ReceiptItemSvg from "~/images/icons/receipt-item.svg"
import Container from "~/ui/Container"
import { Link } from "~/ui/Link"
import AdminOrderDownloadSection from "./AdminOrderDownloadSection"
import AdminOrderSendEmailButton from "./AdminOrderSendEmailButton"

const ORDER_UPDATE_MUTATION = gql(`
  mutation OrderPoDocumentUpdate($input: OrderUpdateInput!) {
    orderUpdate(input: $input) {
      order {
        id
        poDocument{
          filename
          url
          kb
        }
      }
    }
  }
`)

const SEND_PO_DOC_EMAIL_MUTATION = gql(`
  mutation SendPoDocEmail($input: SendPoDocEmailInput!) {
    sendPoDocEmail(input: $input) {
      order {
        id
        sentPoDocumentAt
      }
    }
  }
`)

const AdminOrderPODocument = ({
  orderId,
  sentAt,
  poDocumentUrl,
}: {
  orderId: string
  sentAt?: string
  poDocumentUrl?: string | null | undefined
}) => {
  const [mutate, { loading }] = useMutation(ORDER_UPDATE_MUTATION)
  const [sendPoDocEmail, { loading: sendPoDocEmailLoading }] = useMutation(
    SEND_PO_DOC_EMAIL_MUTATION,
    {
      onCompleted: () => {
        toast.success("Purchase order has been sent")
      },
      onError: (error) => {
        console.error(error)
        toast.error("Purchase order has not been sent")
      },
    }
  )

  const onFileUploadSuccess = (signedId: string) => {
    mutate({
      variables: {
        input: {
          id: orderId,
          orderInput: {
            poDocument: signedId,
          },
        },
      },
      onCompleted: () => {
        toast.success("PO Document has been uploaded")
      },
      onError: (error) => {
        console.error(error)
        toast.error("PO Document upload has failed")
      },
    })
  }

  return (
    <Container size="lg">
      <AdminOrderDownloadSection title="PO Document" sentAt={sentAt}>
        <div>
          <div className="text-sm text-gray-400">
            * Changing the final price will automatically generate a new PO
            document
          </div>
          <div
            className={clsx(
              "flex flex-col justify-between gap-4",
              "lg:flex-row"
            )}
          >
            <div className="flex items-center gap-6">
              <UploadButton loading={loading} onSuccess={onFileUploadSuccess} />
              {!!poDocumentUrl && (
                <Link
                  to={poDocumentUrl}
                  variant="blue"
                  className="text-sm"
                  target="_blank"
                >
                  Download Document
                </Link>
              )}

              <Link
                to="https://docs.google.com/document/d/1qr5Jvni9f0LWhScwCeYu8GK4zFmAdf1o/edit"
                variant="blue"
                className="text-sm"
                target="_blank"
              >
                Download Template
              </Link>
            </div>
            <div className="flex items-center">
              <AdminOrderSendEmailButton
                onClick={() =>
                  sendPoDocEmail({ variables: { input: { orderId } } })
                }
                disabled={!poDocumentUrl || sendPoDocEmailLoading}
              >
                <ReceiptItemSvg />
                Send Purchase Order
              </AdminOrderSendEmailButton>
            </div>
          </div>
        </div>
      </AdminOrderDownloadSection>
    </Container>
  )
}

export default AdminOrderPODocument
