import { ApolloError } from "@apollo/client"

export const GraphqlError = ({ error }: { error: ApolloError }) => (
  <div className="flex-shrink-0 overflow-auto">
    <div className="flex-shrink-0 overflow-auto rounded border border-red-700 p-2 text-red-700">
      <div>GraphQL Error</div>
      {error.graphQLErrors.map((e, i) => (
        <pre key={i}>{JSON.stringify(e, null, 2)}</pre>
      ))}
      {error.graphQLErrors.length === 0 ? (
        <pre>{JSON.stringify(error, null, 2)}</pre>
      ) : null}
    </div>
  </div>
)
