import { useQuery } from "@apollo/client"
import clsx from "clsx"
import { ChevronLeft } from "lucide-react"
import { useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import {
  adminAdminsPath,
  adminEditUserPath,
  adminUsersPath,
} from "~/common/paths"
import DateTimeCell from "~/components/DateTimeCell"
import { boolToString } from "~/lib/utils"
import AdminOrdersTable, {
  AdminOrders_QueryFragment,
} from "~/screens/AdminScreen/AdminOrdersScreen/screens/AdminOrdersIndexScreen/components/AdminOrdersTable"
import { ButtonLink } from "~/ui/Button"
import Container from "~/ui/Container"
import { TD, TH, Table } from "~/ui/DetailTable"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import { H1, H3 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import Loading from "~/ui/Loading"

export const AdminUserScreen_UserFragment = gql(`
  fragment AdminUserScreen_UserFragment on User {
    id
    name
    firstName
    lastName
    email
    company
    companyAddress1
    companyAddress2
    companyCity
    companyState
    companyZip
    isAdmin
    updatedAt
    createdAt
  }
`)

const AdminUserScreen_Query = gql(`
  query AdminUserScreen_Query($id: ID!, $page: Int, $status: [String!], $search: String, $userId: ID, $limit: Int) {
    node(id: $id) {
      ...AdminUserScreen_UserFragment
    }
    ...AdminOrders_QueryFragment
  }
`)

const AdminUserScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")
  const [searchParams] = useSearchParams()
  const page = Number(searchParams.get("page")) || 1

  const { data, loading, error } = useQuery(AdminUserScreen_Query, {
    variables: {
      id: id,
      page: page,
      userId: id,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "User")
    return <Error error={error} />

  const user = getFragmentData(AdminUserScreen_UserFragment, data.node)
  const backPath = user.isAdmin
    ? adminAdminsPath.pattern
    : adminUsersPath.pattern

  const { orders } = getFragmentData(AdminOrders_QueryFragment, data)

  return (
    <div className="flex flex-col gap-8">
      <div
        className={clsx(
          "flex flex-col gap-4",
          "lg:flex-row lg:items-center lg:justify-between"
        )}
      >
        <div className="flex items-center">
          <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
            <ChevronLeft size={28} />
          </Link>
          <H1 margin={false}>{user.name}</H1>
        </div>

        <div className="flex items-center">
          <ButtonLink
            to={adminEditUserPath({ id })}
            className="text-sm font-semibold"
          >
            Edit
          </ButtonLink>
        </div>
      </div>
      <HR padding={false} />
      <Container size="md">
        <Table>
          <thead>
            <tr>
              <TH colSpan={2}>Details</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD>Name</TD>
              <TD>{user.name}</TD>
            </tr>
            <tr>
              <TD>Email</TD>
              <TD>{user.email}</TD>
            </tr>
            <tr>
              <TD>Company</TD>
              <TD>{user.company}</TD>
            </tr>
            <tr>
              <TD>Address</TD>
              <TD>{user.companyAddress1}</TD>
            </tr>
            <tr>
              <TD>Address 2</TD>
              <TD>{user.companyAddress2}</TD>
            </tr>
            <tr>
              <TD>City</TD>
              <TD>{user.companyCity}</TD>
            </tr>
            <tr>
              <TD>State</TD>
              <TD>{user.companyState}</TD>
            </tr>
            <tr>
              <TD>Zip</TD>
              <TD>{user.companyZip}</TD>
            </tr>
            <tr data-test="user-admin-tr">
              <TD>Admin</TD>
              <TD>{boolToString(user.isAdmin)}</TD>
            </tr>
            <tr>
              <TD>Updated</TD>
              <TD>
                <DateTimeCell date={user.updatedAt} />
              </TD>
            </tr>
            <tr>
              <TD>Created</TD>
              <TD>
                <DateTimeCell date={user.createdAt} />
              </TD>
            </tr>
          </tbody>
        </Table>
      </Container>
      <HR padding={false} />
      <H3 margin={false}>Orders</H3>

      <AdminOrdersTable
        orders={orders.orders.edges.map((edge) => edge.node)}
        totalCount={orders.orders.totalCount}
        page={orders.page}
        loading={loading}
      />
    </div>
  )
}

export default AdminUserScreen
