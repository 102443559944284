import { useMutation } from "@apollo/client"
import { Dialog } from "@radix-ui/react-dialog"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import toast from "react-hot-toast"
import * as Yup from "yup"
import { gql } from "~/__generated__"
import { displayErrors } from "~/common/validations"
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "~/components/ui/Dialog"
import Button from "~/ui/Button"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
})

const SEARCH_RENAME_MUTATION = gql(`
  mutation SearchRename($input: SearchRenameInput!) {
    searchRename(input: $input) {
      search {
        id
        name
      }
    }
  }
`)

type Values = {
  name: string
}

const RenameSearchDialog = ({
  id,
  name,
  isOpen,
  setIsOpen,
}: {
  id: string
  name: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const [mutate] = useMutation(SEARCH_RENAME_MUTATION)

  const onSubmit = async (
    values: Values,
    { setFieldError }: FormikHelpers<Values>
  ) => {
    return await mutate({
      variables: {
        input: {
          id,
          name: values.name,
        },
      },
      optimisticResponse: {
        searchRename: {
          search: {
            id,
            name,
            __typename: "Search",
          },
        },
      },
      onCompleted: () => {
        toast.success("Search has been saved")
        setIsOpen(false)
      },
      onError: ({ graphQLErrors }) => {
        displayErrors(graphQLErrors, setFieldError)
      },
    })
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogPortal>
        <DialogOverlay className="DialogOverlay" />
        <DialogContent className="DialogContent">
          <DialogTitle className="DialogTitle">Rename Search</DialogTitle>
          <div className="mb-4 mt-6">
            <div className="mb-1 text-2xl font-medium text-blue-zodiac">
              Rename your search
            </div>
            <DialogDescription className="DialogDescription">
              Saving your search allows you to quickly view prior query builder
              results.
            </DialogDescription>
          </div>

          <Formik
            initialValues={{ name }}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }: FormikProps<Values>) => (
              <Form className="w-full">
                <FieldGroup margin={false}>
                  <FormikField name="name" label="Name" autoFocus={true} />
                </FieldGroup>
                <DialogFooter>
                  <div className="flex gap-2">
                    <Button display="inline" disabled={isSubmitting}>
                      Save Search
                    </Button>
                    <DialogClose asChild>
                      <Button
                        display="inline"
                        variant="outlineSecondary"
                        className="text-neutral-500"
                      >
                        Cancel
                      </Button>
                    </DialogClose>
                  </div>
                </DialogFooter>
              </Form>
            )}
          </Formik>
          <DialogClose asChild>
            <button className="IconButton" aria-label="Close" />
          </DialogClose>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

export default RenameSearchDialog
