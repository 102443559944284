import clsx from "clsx"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData } from "~/__generated__"
import { searchSexDisplay } from "~/common/study"
import {
  StudySearchResultsSummary_SearchFragment,
  StudySearchResultsSummary_SearchParamsFragment,
} from "./StudySearchResultsSummary"

const Panel = ({ label, value }: { label: string; value: string }) => (
  <div className="flex flex-1 items-center justify-center gap-1 p-2 text-base">
    <div className="">{label}:</div>
    <div className="font-bold">{value}</div>
  </div>
)

const StudySearchResultsAppliedStudyFilters = ({
  search,
}: {
  search: FragmentType<typeof StudySearchResultsSummary_SearchFragment>
}) => {
  const { studySearchParams } = getFragmentData(
    StudySearchResultsSummary_SearchFragment,
    search
  )
  invariant(studySearchParams, "Expected studySearchParams")
  const { patientAgeMin, patientAgeMax, sex } = getFragmentData(
    StudySearchResultsSummary_SearchParamsFragment,
    studySearchParams
  )

  return (
    <div
      className={clsx(
        "flex flex-col items-stretch divide-y divide-border-gray rounded-lg border border-border-gray text-sm",
        "xl:flex-row xl:divide-x xl:divide-y-0"
      )}
    >
      <Panel label="Age" value={patientAgeMin + " - " + patientAgeMax} />
      <Panel label="Sex" value={searchSexDisplay(sex)} />
    </div>
  )
}

export default StudySearchResultsAppliedStudyFilters
