import { useMutation, useQuery } from "@apollo/client"
import clsx from "clsx"
import { ChevronLeft } from "lucide-react"
import toast from "react-hot-toast"
import { useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { adminBulkUpdatesPath } from "~/common/paths"
import DateTimeCell from "~/components/DateTimeCell"
import Container from "~/ui/Container"
import { TD, TH, Table } from "~/ui/DetailTable"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import { H1, H3 } from "~/ui/Headings"
import { Link, LinkButton } from "~/ui/Link"
import Loading from "~/ui/Loading"
import AdminSeriesImportRowsTable, {
  AdminSeriesImportRows_QueryFragment,
} from "./components/AdminSeriesImportRowsTable"

export const AdminBulkUpdate_BulkUpdateFragment = gql(`
  fragment AdminBulkUpdate_BulkUpdateFragment on BulkUpdate {
    id
    number
    description
    importedSeriesImportRowsCount
    completedAt
    createdAt
    studyCount
    importErrorCounts
    importSkippedCounts
    csvUrl
  }
`)

export const AdminBulkUpdateScreen_SeriesImportRowFragment = gql(`
  fragment AdminBulkUpdateScreen_SeriesImportRowFragment on SeriesImportRow {
    id
    accessionNumber
    studyDescription
    patientSex
    patientAge
    patientAgeStr
    patientId
    studyDate
    magneticFieldStrength
    modality
    seriesDescription
    bodyPartExamined
    contrastOrBolusAgent
    index
    importErrorMessage
    importSkippedMessage
    sliceThickness
    studyId
    completedImportAt
    createdAt
  }
`)

const AdminBulkUpdateScreen_Query = gql(`
  query AdminBulkUpdateScreen_Query($id: ID!, $page: Int, $bulkUpdateId: ID, $limit: Int) {
    node(id: $id) {
      ...AdminBulkUpdate_BulkUpdateFragment
    }
    ...AdminSeriesImportRows_QueryFragment
  }
`)

export const ExportRequirestCreate_Mutation = gql(`
  mutation BulkUpdateExportSkipped($input: BulkUpdateExportSkippedInput!) {
    bulkUpdateExportSkipped(input: $input) {
      id
    }
  }
`)

const SkippedErroredExport = ({ id }: { id: string }) => {
  const [mutate, { loading }] = useMutation(ExportRequirestCreate_Mutation)

  const requestExport = async () => {
    try {
      await mutate({
        variables: {
          input: {
            id,
          },
        },
      })
      toast.success("Export requested")
    } catch (error) {
      console.error(error)
      toast.error("Error requesting export")
    }
  }
  return (
    <LinkButton onClick={requestExport} variant="blue" disabled={loading}>
      Email CSV
    </LinkButton>
  )
}

const AdminStudyImportScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")
  const [searchParams] = useSearchParams()
  const page = Number(searchParams.get("page")) || 1

  const { data, loading, error } = useQuery(AdminBulkUpdateScreen_Query, {
    variables: {
      id: id,
      page: page,
      bulkUpdateId: id,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "BulkUpdate")
    return <Error error={error} />

  const bulkUpdate = getFragmentData(
    AdminBulkUpdate_BulkUpdateFragment,
    data.node
  )
  const backPath = adminBulkUpdatesPath.pattern

  const { seriesImportRows } = getFragmentData(
    AdminSeriesImportRows_QueryFragment,
    data
  )

  return (
    <div className="flex flex-col gap-8">
      <div
        className={clsx(
          "flex flex-col gap-4",
          "lg:flex-row lg:items-center lg:justify-between"
        )}
      >
        <div className="flex items-center">
          <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
            <ChevronLeft size={28} />
          </Link>
          <H1 margin={false}>Study Import {bulkUpdate.number}</H1>
        </div>
      </div>
      <HR padding={false} />
      <div>
        <Link
          to={"/sidekiq"}
          variant="blue"
          className="mb-2 text-sm"
          target="_blank"
        >
          Background Jobs
        </Link>
      </div>
      <Container size="md">
        <Table>
          <thead>
            <tr>
              <TH colSpan={2}>Details</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD>#</TD>
              <TD>{bulkUpdate.number}</TD>
            </tr>
            <tr>
              <TD>Description</TD>
              <TD>
                <div className="whitespace-pre-wrap">
                  {bulkUpdate.description}
                </div>
              </TD>
            </tr>
            <tr>
              <TD>Total Studies Created</TD>
              <TD>
                {bulkUpdate.studyCount.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                })}
              </TD>
            </tr>
            <tr>
              <TD>Total CSV Rows Imported</TD>
              <TD>
                {bulkUpdate.importedSeriesImportRowsCount.toLocaleString(
                  "en-US",
                  {
                    maximumFractionDigits: 0,
                  }
                )}
              </TD>
            </tr>
            <tr>
              <TD>CSV Row Errors</TD>
              <TD>
                <div>
                  <pre>
                    {JSON.stringify(bulkUpdate.importErrorCounts, null, 2)}
                  </pre>
                </div>
              </TD>
            </tr>
            <tr>
              <TD>Skipped CSV Rows</TD>
              <TD>
                <div>
                  <pre>
                    {JSON.stringify(bulkUpdate.importSkippedCounts, null, 2)}
                  </pre>
                </div>
              </TD>
            </tr>
            <tr>
              <TD>CSV</TD>
              <TD>
                {!!bulkUpdate.csvUrl && (
                  <Link to={bulkUpdate.csvUrl} target="_blank" variant="blue">
                    Download
                  </Link>
                )}
              </TD>
            </tr>
            <tr>
              <TD>Skipped/errored CSV</TD>
              <TD>
                <SkippedErroredExport id={bulkUpdate.id} />
              </TD>
            </tr>
            <tr>
              <TD>Created</TD>
              <TD>
                <DateTimeCell date={bulkUpdate.createdAt} />
              </TD>
            </tr>
          </tbody>
        </Table>
      </Container>

      <HR padding={false} />
      <H3 margin={false}>Skipped/errored CSV Rows</H3>

      <AdminSeriesImportRowsTable
        seriesImportRows={seriesImportRows.seriesImportRows.edges.map(
          (edge) => edge.node
        )}
        totalCount={seriesImportRows.seriesImportRows.totalCount}
        page={seriesImportRows.page}
        loading={loading}
      />
    </div>
  )
}

export default AdminStudyImportScreen
