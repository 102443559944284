import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query
export const CURRENT_USER_FRAGMENT = gql(`
  fragment CurrentUser on User {
    id
    email
    firstName
    lastName
    company
    companyAddress1
    companyAddress2
    companyCity
    companyState
    companyZip
    isAdmin
    __typename
  }
`)

export const CURRENT_USER_QUERY_DOCUMENT = gql(`
  query CurrentUserProvider {
    currentUser {
      ...CurrentUser
    }
  }
`)
