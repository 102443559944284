import { supportEmailPath } from "~/common/paths"
import { Link } from "~/ui/Link"

const SearchSupportMessage = () => {
  return (
    <div>
      Not finding what you are looking for? Reach out to us for{" "}
      <Link variant="blue" to={supportEmailPath}>
        custom searches
      </Link>
    </div>
  )
}

export default SearchSupportMessage
