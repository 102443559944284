import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { dateMedium, time } from "~/common/dates"
import { TD, TH, Table } from "~/ui/DetailTable"

export const OrderSuccessScreenOrder_Fragment = gql(`
  fragment OrderSuccessScreenOrder on Order {
    id
    orderNumber
    createdAt
  }
`)

const OrderSummaryTable = ({
  order,
}: {
  order: FragmentType<typeof OrderSuccessScreenOrder_Fragment>
}) => {
  const { orderNumber, createdAt } = getFragmentData(
    OrderSuccessScreenOrder_Fragment,
    order
  )
  return (
    <Table>
      <thead>
        <tr>
          <TH colSpan={2}>Details</TH>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TD>Order Number</TD>
          <TD>{orderNumber}</TD>
        </tr>
        <tr>
          <TD>Order Status</TD>
          <TD>Requested</TD>
        </tr>
        <tr>
          <TD>Order Date</TD>
          <TD>{dateMedium(new Date(createdAt))}</TD>
        </tr>
        <tr>
          <TD>Order Time</TD>
          <TD>{time(new Date(createdAt))}</TD>
        </tr>
      </tbody>
    </Table>
  )
}

export default OrderSummaryTable
