import { Outlet } from "react-router-dom"
import { QueryParamProvider } from "use-query-params"
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6"
import { useGooglePageTracking } from "~/hooks/useGooglePageTracking"

const Layout = () => {
  useGooglePageTracking()

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter} options={{}}>
      <Outlet />
    </QueryParamProvider>
  )
}

export default Layout
