import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import ImageSvg from "~/images/icons/image.svg"
import { STUDIES_LIMIT } from "../AdminStudiesIndexScreen"
import AdminStudyRow, { AdminStudyRow_StudyFragment } from "./AdminStudyRow"

export const AdminStudies_QueryFragment = gql(`
  fragment AdminStudies_QueryFragment on Query {
    studies(recent: $recent, archived: $archived, page: $page, limit: $limit) {
      studies {
        edges {
          node {
            ...AdminStudyRow_StudyFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const AdminStudiesTable = ({
  studies,
  totalCount,
  page,
}: {
  studies: FragmentType<typeof AdminStudyRow_StudyFragment>[]
  totalCount: number
  page: number
}) => {
  const totalPages = Math.ceil(totalCount / STUDIES_LIMIT)

  return (
    <div className="flex flex-col gap-6 text-base">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[65px] text-center">
              <div className="flex items-center justify-center">
                <ImageSvg />
              </div>
            </TableHead>
            <TableHead className="min-w-[250px]">Description</TableHead>
            <TableHead className="min-w-[110px]">Accession</TableHead>
            <TableHead className="min-w-[140px]">MRN</TableHead>
            <TableHead className="min-w-[100px]">Modality</TableHead>
            <TableHead className="min-w-[100px]">Min Slice</TableHead>
            <TableHead className="min-w-[100px]">Contrast</TableHead>
            <TableHead className="min-w-[100px]">Age</TableHead>
            <TableHead className="min-w-[100px]">Sex</TableHead>
            <TableHead className="min-w-[120px]">Archived</TableHead>
            <TableHead className="min-w-[130px]">Date</TableHead>
            <TableHead className="min-w-[400px]">Report Text</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {studies.map((study) => (
            <AdminStudyRow
              key={getFragmentData(AdminStudyRow_StudyFragment, study).id}
              data={study}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination page={page} totalPages={totalPages} />
    </div>
  )
}

export default AdminStudiesTable
