import clsx from "clsx"
import { useField } from "formik"
import { HTMLInputTypeAttribute } from "react"
import FieldLabelContainer from "./FieldLabelContainer"
import FormikCheckboxField from "./FormikCheckboxField"
import FormikFileUpload from "./FormikFileUpload"
import FormikInputField from "./FormikInputField"
import FormikMultiSelectDropdown from "./FormikMultiSelectDropdown"
import FormikRadioField from "./FormikRadioField"
import FormikSelectField from "./FormikSelectField"
import ValidationError from "./ValidationError"

export type OptionType = {
  label: string
  value: string
  disabled?: boolean
}

export type FormikFieldType = {
  name: string
  hideErrors?: boolean
  hidden?: boolean
  autoFocus?: boolean
  light?: boolean
  label?: string | React.ReactElement
  type?: HTMLInputTypeAttribute
  inputClassName?: string
  labelLink?: {
    linkTo: string
    title: string
  }
  placeholder?: string
  options?: OptionType[]
  search?: boolean
  required?: boolean
  value?: string
  labelSize?: "sm" | "md"
  checked?: boolean
  min?: string
  step?: string
  beforeChange?: (newValue: string | boolean | undefined) => void
  booleanSelect?: boolean
  disabled?: boolean
  labelInline?: boolean
  uploadedFileUrl?: string
}

export type FormikInputFieldType = FormikFieldType

export type FormikSelectFieldType = FormikFieldType & {
  type?: "select"
  options: OptionType[]
}

const FormikField = ({
  name,
  hideErrors = false,
  hidden = false,
  autoFocus = false,
  light = false,
  label,
  type,
  inputClassName,
  labelLink,
  search,
  placeholder,
  options,
  required,
  value,
  labelSize = "md",
  checked,
  min,
  step,
  beforeChange,
  booleanSelect,
  disabled,
  labelInline,
  uploadedFileUrl,
}: FormikInputFieldType | FormikSelectFieldType) => {
  const [_field, meta] = useField({ name, type })
  return (
    <div
      className={clsx("w-full flex-1", {
        hidden: hidden,
      })}
      data-test={`${name}-field`}
    >
      <FieldLabelContainer
        label={label}
        light={light}
        labelLink={labelLink}
        labelInline={labelInline}
        required={required}
        labelSize={labelSize}
      >
        {type === "select" ? (
          <FormikSelectField
            name={name}
            autoFocus={autoFocus}
            inputClassName={inputClassName}
            options={options!}
            search={search}
            placeholder={placeholder}
            beforeChange={beforeChange}
            booleanSelect={booleanSelect}
          />
        ) : type === "multiselect" ? (
          <FormikMultiSelectDropdown
            name={name}
            options={options!}
            search={search}
            placeholder={placeholder}
          />
        ) : type === "checkbox" ? (
          <FormikCheckboxField
            name={name}
            value={value}
            type="checkbox"
            checked={checked}
          />
        ) : type === "radio" ? (
          <FormikRadioField name={name} value={value} type="radio" />
        ) : type === "file" ? (
          <FormikFileUpload
            name={name}
            type={type}
            uploadedFileUrl={uploadedFileUrl}
          />
        ) : (
          <FormikInputField
            name={name}
            type={type}
            autoFocus={autoFocus}
            inputClassName={inputClassName}
            beforeChange={beforeChange}
            min={min}
            step={step}
            disabled={disabled}
            placeholder={placeholder}
          />
        )}
      </FieldLabelContainer>

      {!hideErrors && meta.touched && meta.error ? (
        <ValidationError>{meta.error}</ValidationError>
      ) : null}
    </div>
  )
}

export default FormikField
