import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import { bulkUpdatesLimit } from "../AdminUsersIndexScreen"
import AdminBulkUpdateRow, {
  AdminBulkUpdateRow_BulkUpdateFragment,
} from "./AdminBulkUpdateRow"

export const AdminBulkUpdates_QueryFragment = gql(`
  fragment AdminBulkUpdates_QueryFragment on Query {
    bulkUpdates(page: $page, limit: $limit) {
      bulkUpdates {
        edges {
          node {
            ...AdminBulkUpdateRow_BulkUpdateFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const AdminBulkUpdatesTable = ({
  bulkUpdates,
  totalCount,
  page,
}: {
  bulkUpdates: FragmentType<typeof AdminBulkUpdateRow_BulkUpdateFragment>[]
  totalCount: number
  page: number
}) => {
  const totalPages = Math.ceil(totalCount / bulkUpdatesLimit)

  return (
    <div className="flex flex-col gap-6 text-base">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">#</TableHead>
            <TableHead className="w-[120px]">Type</TableHead>
            <TableHead className="min-w-[200px]">Description</TableHead>
            <TableHead className="w-[130px]">Created</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {bulkUpdates.map((bulkUpdates) => (
            <AdminBulkUpdateRow
              key={
                getFragmentData(
                  AdminBulkUpdateRow_BulkUpdateFragment,
                  bulkUpdates
                ).id
              }
              data={bulkUpdates}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination page={page} totalPages={totalPages} />
    </div>
  )
}

export default AdminBulkUpdatesTable
