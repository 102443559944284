import { StringParam, useQueryParams } from "use-query-params"

export const imageSearchParamSchema = {
  imageCount: StringParam,
  ganLabelId: StringParam,
}

const useImageSearchParamValues = () => {
  const [searchParams] = useQueryParams(imageSearchParamSchema)

  return {
    ...searchParams,
    ganLabelId: searchParams.ganLabelId!,
  }
}

export default useImageSearchParamValues
