import { useQuery } from "@apollo/client"
import clsx from "clsx"
import pluralize from "pluralize"
import { useSearchParams } from "react-router-dom"
import { getFragmentData, gql } from "~/__generated__"
import { adminNewStudyPackagePath } from "~/common/paths"
import { ButtonLink } from "~/ui/Button"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import AdminStudyPackagesTable, {
  AdminStudyPackages_QueryFragment,
} from "./components/AdminStudyPackagesTable"

export const AdminStudyPackagesScreen_Query = gql(`
  query AdminStudyPackagesScreen_Query($page: Int, $limit: Int) {
    ...AdminStudyPackages_QueryFragment
  }
`)

export const STUDY_PACKAGES_LIMIT = 12

const AdminStudyPackagesIndexScreen = () => {
  const [searchParams] = useSearchParams()
  const page = searchParams.get("page")

  const result = useQuery(AdminStudyPackagesScreen_Query, {
    variables: {
      page: page ? Number(page) : 1,
      limit: STUDY_PACKAGES_LIMIT,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { studyPackages } = getFragmentData(
    AdminStudyPackages_QueryFragment,
    data
  )
  const totalCount = studyPackages.studyPackages.totalCount

  return (
    <div>
      <div
        className={clsx(
          "flex flex-col gap-6",
          "lg:flex-row lg:justify-between"
        )}
      >
        <div>
          <H1>Image Packages</H1>
        </div>
        <div>
          <ButtonLink
            className="text-sm font-semibold"
            to={adminNewStudyPackagePath.pattern}
          >
            New Image Package
          </ButtonLink>
        </div>
      </div>
      <div className="mb-6 flex flex-col gap-6 text-base">
        <div className="my-1">
          <b>
            {totalCount.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}
          </b>{" "}
          image {pluralize("package", totalCount)}
        </div>
      </div>
      <div
        className={clsx({
          "animate-pulse opacity-90": loading,
        })}
      >
        {studyPackages.studyPackages.edges.length > 0 && (
          <AdminStudyPackagesTable
            studyPackages={studyPackages.studyPackages.edges.map(
              (edge) => edge.node
            )}
            totalCount={totalCount}
            page={studyPackages.page}
          />
        )}
      </div>
    </div>
  )
}

export default AdminStudyPackagesIndexScreen
