import clsx from "clsx"
import { PropsWithChildren } from "react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip"

const DisabledSearchSave = ({
  disabled,
  loading,
  children,
}: { disabled: boolean; loading?: boolean } & PropsWithChildren) => {
  if (disabled) {
    return (
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger
            className={clsx("flex cursor-default items-center", {
              "animate-pulse opacity-60": loading,
            })}
            asChild={true}
          >
            <div>
              <div
                className="pointer-events-none"
                data-test="disabled-search-save-button"
              >
                {children}
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent className="animate-pulse font-normal">
            Please wait while search results are being updated
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  return <>{children}</>
}

export default DisabledSearchSave
