import { useRef, useState } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import useFileUpload, { UploadProgress } from "~/hooks/useFileUpload"
import Button from "~/ui/Button"

const UploadButton = ({
  loading,
  onSuccess,
}: {
  loading: boolean
  onSuccess: (signedId: string) => void
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [isUploading, setIsUploading] = useState(false)

  const { uploadFile, progress, checksumProgress } = useFileUpload()
  const openUploadDialog = () => hiddenFileInput?.current?.click()

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true)
    const file = e?.target?.files?.[0]
    if (!file) {
      toast.error("Missing file, please try again")
      setIsUploading(false)
      return
    }

    try {
      const signedId = await uploadFile(file)
      invariant(signedId, "Missing signedId")
      onSuccess(signedId)
    } catch (error) {
      console.error(error)
      toast.error("File upload failed, please try again")
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <div className="pt-[18px]">
      <Button
        onClick={openUploadDialog}
        display="inline"
        type="button"
        disabled={loading || isUploading}
      >
        Upload
      </Button>
      <UploadProgress progress={progress} checksumProgress={checksumProgress} />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={onChange}
        className="hidden"
      />
    </div>
  )
}

export default UploadButton
