import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { studyPackageStudyPath } from "~/common/paths"
import { StudiesTableSharedColumns } from "~/components/StudiesTable"
import { TableRow } from "~/components/ui/Table"

export const StudyPackageStudyRow_StudyFragment = gql(`
  fragment StudyPackageStudyRow_StudyFragment on Study {
    id
    uid
    patientAge
    sex
    date
    modality
    contactSheetUrl
    purchased
    description
    reportConclusion
  }
`)

const StudyPackageStudyRow = ({
  studyPackageId,
  data,
}: {
  studyPackageId: string
  data: FragmentType<typeof StudyPackageStudyRow_StudyFragment>
}) => {
  const study = getFragmentData(StudyPackageStudyRow_StudyFragment, data)
  const studyPath = `${studyPackageStudyPath({
    id: studyPackageId,
    studyId: study.id,
  })}${window.location.search}`

  return (
    <TableRow>
      <StudiesTableSharedColumns
        description={study.description}
        purchased={study.purchased}
        patientAge={study.patientAge}
        sex={study.sex}
        modality={study.modality}
        reportConclusion={study.reportConclusion}
        studyPath={studyPath}
        contactSheetUrl={study.contactSheetUrl}
      />
    </TableRow>
  )
}

export default StudyPackageStudyRow
