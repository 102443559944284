import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import { STUDY_PACKAGES_LIMIT } from "../AdminStudyPackagesIndexScreen"
import AdminStudyPackageRow, {
  AdminStudyPackageRow_StudyPackageFragment,
} from "./AdminStudyPackageRow"

export const AdminStudyPackages_QueryFragment = gql(`
  fragment AdminStudyPackages_QueryFragment on Query {
    studyPackages(includeUnpublished: true, page: $page, limit: $limit) {
      studyPackages {
        edges {
          node {
            ...AdminStudyPackageRow_StudyPackageFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const AdminStudyPackagesTable = ({
  studyPackages,
  totalCount,
  page,
}: {
  studyPackages: FragmentType<
    typeof AdminStudyPackageRow_StudyPackageFragment
  >[]
  totalCount: number
  page: number
}) => {
  const totalPages = Math.ceil(totalCount / STUDY_PACKAGES_LIMIT)

  return (
    <div className="flex flex-col gap-6 text-base">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[140px]">Name</TableHead>
            <TableHead className="min-w-[100px]">Study Count</TableHead>
            <TableHead className="min-w-[100px]">Modality</TableHead>
            <TableHead className="min-w-[158px]">Body Part</TableHead>
            <TableHead className="min-w-[100px]">Price</TableHead>
            <TableHead className="min-w-[100px]">Featured</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {studyPackages.map((studyPackage) => (
            <AdminStudyPackageRow
              key={
                getFragmentData(
                  AdminStudyPackageRow_StudyPackageFragment,
                  studyPackage
                ).id
              }
              data={studyPackage}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination page={page} totalPages={totalPages} />
    </div>
  )
}

export default AdminStudyPackagesTable
