import { useFormikContext } from "formik"
import { uniq } from "lodash"
import { useMemo } from "react"
import { FragmentType, getFragmentData } from "~/__generated__"
import { StudiesTableSharedHeadings } from "~/components/StudiesTable"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import { CheckboxIcon } from "~/ui/FormikCheckboxField"

import { StudySearchResultsFormValues } from "../StudySearchResultsScreen"
import LockedFeature from "./LockedFeature"
import StudyResultsRow, {
  StudyResultsRow_StudyFragment,
} from "./StudyResultsRow"

const StudySearchResultsTable = ({
  studies,
}: {
  studies: FragmentType<typeof StudyResultsRow_StudyFragment>[]
}) => {
  const { values, setFieldValue } =
    useFormikContext<StudySearchResultsFormValues>()
  const studyIdsDiff = useMemo(
    () =>
      studies.map(
        (study) => getFragmentData(StudyResultsRow_StudyFragment, study).uid
      ),
    [studies]
  )

  const areAllPageStudiesChecked = values.selectAll
    ? !studyIdsDiff.some((id) => values.studyIdsDiff.includes(id))
    : studyIdsDiff.every((id) => values.studyIdsDiff.includes(id))

  const onSelectAllClick = () => {
    if (values.selectAll) {
      const newValue = areAllPageStudiesChecked
        ? uniq([...studyIdsDiff, ...values.studyIdsDiff])
        : values.studyIdsDiff.filter((id) => !studyIdsDiff.includes(id))
      setFieldValue("studyIdsDiff", newValue)
    } else {
      setFieldValue(
        "studyIdsDiff",
        areAllPageStudiesChecked
          ? values.studyIdsDiff.filter((id) => !studyIdsDiff.includes(id))
          : uniq([...studyIdsDiff, ...values.studyIdsDiff])
      )
    }
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[55px]">
            <LockedFeature>
              <div className="scale-[0.7] text-border-gray group-hover:text-gray-300">
                <button
                  type="button"
                  onClick={onSelectAllClick}
                  data-test="toggle-all-page-studies"
                >
                  <CheckboxIcon checked={areAllPageStudiesChecked} />
                </button>
              </div>
            </LockedFeature>
          </TableHead>
          <StudiesTableSharedHeadings />
        </TableRow>
      </TableHeader>
      <TableBody>
        {studies.map((study) => (
          <StudyResultsRow
            key={getFragmentData(StudyResultsRow_StudyFragment, study).id}
            data={study}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default StudySearchResultsTable
