import SpinnerIcon from "../images/icons/spinner.svg"

const Loading = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <SpinnerIcon />
    </div>
  )
}

export default Loading
