import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "~/components/ui/Dialog"
import Button from "./Button"

const ConfirmDialog = ({
  isOpen,
  onOpenChange,
  confirm,
  "data-test": dataTest,
  children,
}: {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  confirm: () => void
  "data-test"?: string
  children: React.ReactNode
}) => {
  const onConfirmClick = () => {
    confirm()
    onOpenChange(false)
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogOverlay className="DialogOverlay" />
        <DialogContent className="DialogContent" data-test={dataTest}>
          <DialogTitle className="DialogTitle" />
          <div className="mb-4 mt-6">
            <div className="mb-7 mt-1 text-center">{children}</div>
          </div>

          <div className="flex gap-2">
            <Button type="button" onClick={onConfirmClick} display="inline">
              Yes
            </Button>
            <DialogClose asChild>
              <Button
                type="button"
                onClick={() => onOpenChange(false)}
                variant="outlineSecondary"
                display="inline"
              >
                Cancel
              </Button>
            </DialogClose>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

export default ConfirmDialog
