import { useMutation } from "@apollo/client"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { adminOrdersPath } from "~/common/paths"
import Button from "~/ui/Button"

const OrderArchive_Mutation = gql(`
  mutation OrderArchive($input: OrderArchiveInput!) {
    orderArchive(input: $input) {
      order {
        id
      }
    }
  }
`)

const ArchiveOrderButton = ({ id }: { id: string }) => {
  const [mutate] = useMutation(OrderArchive_Mutation)
  const navigate = useNavigate()

  const ArchiveOrder = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure?")) {
      try {
        await mutate({
          variables: {
            input: {
              id,
            },
          },
        })
        toast.success("Order has been archived")
        navigate(adminOrdersPath.pattern)
      } catch (error: any) {
        console.error(error)
        toast.error("Archive failed")
      }
    }
  }

  return (
    <Button
      onClick={ArchiveOrder}
      variant="ghost"
      className="px-0 text-red-500"
      display="inline"
    >
      Mark as Archived
    </Button>
  )
}

export default ArchiveOrderButton
