import clsx from "clsx"
import { Link } from "react-router-dom"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { studyPackagesPath } from "~/common/paths"
import NextRoundIcon from "~/images/icons/next-round.svg"
import PackageIcon from "~/images/icons/package.svg"
import StudyPackageListItem, {
  StudyPackageListItem_StudyPackageFragment,
} from "~/screens/StudyPackagesScreen/screens/StudyPackagesIndexScreen/components/StudyPackagesListItem"
import DashboardTitle from "./DashboardTitle"

export const DashboardStudyPackages_QueryFragment = gql(`
  fragment DashboardStudyPackages_QueryFragment on Query {
    studyPackages(sortFeatured: true, limit: 3) {
      studyPackages {
        edges {
          node {
            ...StudyPackageListItem_StudyPackageFragment
          }
        }
      }
    }
  }
`)

const DashboardPackages = ({
  studyPackages,
}: {
  studyPackages: FragmentType<
    typeof StudyPackageListItem_StudyPackageFragment
  >[]
}) => {
  return (
    <div className="flex flex-col gap-8">
      <Link to={studyPackagesPath.pattern}>
        <DashboardTitle text="Image Packages">
          <PackageIcon />
        </DashboardTitle>
      </Link>

      <div className={clsx("flex flex-col gap-4", "xl:flex-row")}>
        {studyPackages.map((studyPackage) => (
          <StudyPackageListItem
            key={
              getFragmentData(
                StudyPackageListItem_StudyPackageFragment,
                studyPackage
              ).id
            }
            data={studyPackage}
          />
        ))}
        <div className="flex min-h-[100px] flex-1 items-center justify-center">
          <Link
            className={clsx(
              "flex flex-col items-center gap-2 text-sm text-neutral-500",
              "hover:opacity-75"
            )}
            to={studyPackagesPath.pattern}
          >
            <NextRoundIcon />
            <div className="text-center">All Image Packages</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default DashboardPackages
