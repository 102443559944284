import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import * as Yup from "yup"
import { ModalityEnum } from "~/__generated__/graphql"
import { stateOptions } from "~/screens/RegistrationScreen"
import Button from "~/ui/Button"
import Container from "~/ui/Container"
import FieldGroup from "~/ui/FieldGroup"
import FormActions from "~/ui/FormActions"
import FormikField from "~/ui/FormikField"

export type UserFormValues = {
  email: string
  firstName: string
  lastName: string
  company: string
  companyAddress1: string
  companyAddress2: string
  companyCity: string
  companyState: string
  companyZip: string
  isAdmin: boolean
}

export const adminUserValidationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  company: Yup.string(),
  companyAddress1: Yup.string(),
  companyAddress2: Yup.string(),
  companyCity: Yup.string(),
  companyState: Yup.string(),
  companyZip: Yup.string(),
  isAdmin: Yup.boolean().required(),
})

export const modalityOptions = Object.values(ModalityEnum).map((value) => ({
  label: value.toLocaleUpperCase(),
  value,
}))

const AdminUserForm = ({
  onSubmit,
  initialValues,
}: {
  onSubmit: (
    values: UserFormValues,
    { setFieldError }: FormikHelpers<UserFormValues>
  ) => void
  initialValues: UserFormValues
}) => {
  return (
    <Container size="md">
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={adminUserValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }: FormikProps<UserFormValues>) => (
          <Form className="w-full">
            <FieldGroup>
              <FormikField
                name="email"
                label="Email"
                placeholder="Email"
                required={true}
              />
              <FormikField
                name="firstName"
                label="First Name"
                placeholder="First Name"
                required={true}
              />
              <FormikField
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                required={true}
              />
              <FormikField
                name="company"
                label="Company"
                placeholder="Company"
              />
              <FormikField
                name="companyAddress1"
                label="Address"
                labelSize="sm"
              />
              <FormikField
                name="companyAddress2"
                label="Address 2"
                labelSize="sm"
              />
              <FormikField name="companyCity" label="City" labelSize="sm" />
              <FormikField
                name="companyState"
                label="State"
                placeholder="Select State"
                type="select"
                labelSize="sm"
                inputClassName="md:rounded-e-none"
                options={[
                  { label: "Select State", value: "" },
                  ...stateOptions,
                ]}
                search={true}
              />
              <FormikField
                name="companyZip"
                label="Zip"
                inputClassName="md:rounded-s-none"
                labelSize="sm"
              />
              <br />
              <FormikField
                name="isAdmin"
                type="checkbox"
                label="Is Admin"
                labelInline={true}
              />
            </FieldGroup>
            <FormActions>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </FormActions>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default AdminUserForm
