import clsx from "clsx"

const HR = ({
  padding = true,
  dark = false,
  fullWidth = false,
  className,
}: {
  padding?: boolean
  dark?: boolean
  fullWidth?: boolean
  className?: string
}) => {
  return (
    <div
      className={clsx(className, {
        "py-6": padding,
        "-mx-4 md:-mx-8": fullWidth,
      })}
    >
      <hr
        className={clsx(dark ? "border-chathams-blue" : "border-border-gray")}
      />
    </div>
  )
}

export default HR
