import { useField } from "formik"
import { ChevronDown } from "lucide-react"
import { useState } from "react"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "~/components/ui/DropdownMenu"

import clsx from "clsx"
import Button from "./Button"
import { FormikSelectFieldType } from "./FormikField"

const FormikMultiSelectDropdown = ({
  options,
  placeholder,
  buttonClasses,
  ...props
}: { buttonClasses?: string } & FormikSelectFieldType) => {
  const [open, setOpen] = useState(false)
  const [field, _meta, { setValue }] = useField(props)

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outlineSecondary"
            display="inline"
            className={clsx(
              "flex justify-between gap-1 bg-white",
              buttonClasses
            )}
          >
            <div className="flex gap-1">
              {placeholder}
              {field.value.length > 0 && (
                <div className="mt-0.5 text-xs">({field.value.length})</div>
              )}
            </div>
            <ChevronDown size={12} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[150px]" side="bottom" align="end">
          {options.map((option) => (
            <DropdownMenuCheckboxItem
              key={option.value}
              checked={field.value.includes(option.value)}
              onCheckedChange={(checked) => {
                if (checked) {
                  setValue([...field.value, option.value])
                } else {
                  setValue(
                    field.value.filter((v: string) => v !== option.value)
                  )
                }
              }}
              onSelect={(event) => event.preventDefault()}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default FormikMultiSelectDropdown
