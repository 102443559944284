import { useQuery } from "@apollo/client"
import clsx from "clsx"
import pluralize from "pluralize"
import { useSearchParams } from "react-router-dom"
import { getFragmentData, gql } from "~/__generated__"
import {
  adminNewStudyArchivePath,
  adminNewStudyImportPath,
} from "~/common/paths"
import { ButtonLink } from "~/ui/Button"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import AdminBulkUpdatesTable, {
  AdminBulkUpdates_QueryFragment,
} from "./components/AdminBulkUpdatesTable"

export const AdminBulkUpdatesScreen_Query = gql(`
  query AdminBulkUpdatesScreen_Query($page: Int, $limit: Int) {
    ...AdminBulkUpdates_QueryFragment
  }
`)

export const bulkUpdatesLimit = 12

const AdminBulkUpdatesIndexScreen = () => {
  const [searchParams] = useSearchParams()
  const page = searchParams.get("page")
  const result = useQuery(AdminBulkUpdatesScreen_Query, {
    variables: {
      page: page ? Number(page) : 1,
      limit: bulkUpdatesLimit,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { bulkUpdates } = getFragmentData(AdminBulkUpdates_QueryFragment, data)
  const totalCount = bulkUpdates.bulkUpdates.totalCount

  return (
    <div>
      <div
        className={clsx(
          "flex flex-col gap-6",
          "lg:flex-row lg:justify-between"
        )}
      >
        <div>
          <H1>Bulk Updates</H1>
        </div>
        <div className="flex gap-2">
          <ButtonLink
            className="text-sm font-semibold"
            to={adminNewStudyArchivePath.pattern}
            display="inline"
            variant="outlineSecondary"
          >
            Archive Studies
          </ButtonLink>
          <ButtonLink
            className="text-sm font-semibold"
            to={adminNewStudyImportPath.pattern}
            display="inline"
          >
            Import Studies
          </ButtonLink>
        </div>
      </div>
      <div className="mb-6 flex flex-col gap-6 text-base">
        <div className="my-1">
          <b>
            {totalCount.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}
          </b>{" "}
          {pluralize("bulk update", totalCount)}
        </div>
      </div>
      <div
        className={clsx({
          "animate-pulse opacity-90": loading,
        })}
      >
        {bulkUpdates.bulkUpdates.edges.length > 0 && (
          <AdminBulkUpdatesTable
            bulkUpdates={bulkUpdates.bulkUpdates.edges.map((edge) => edge.node)}
            totalCount={totalCount}
            page={bulkUpdates.page}
          />
        )}
      </div>
    </div>
  )
}

export default AdminBulkUpdatesIndexScreen
