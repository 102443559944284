import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { adminOrdersPath, dashboardPath } from "~/common/paths"
import useScrollToTop from "~/hooks/useScrollToTop"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const RequireUserSignedOut = () => {
  useScrollToTop()
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserMaybe()

  useEffect(() => {
    if (currentUser) {
      navigate(
        currentUser.isAdmin ? adminOrdersPath.pattern : dashboardPath.pattern
      )
    }
  })

  return <Outlet />
}
