import clsx from "clsx"
import { OrderStatusEnum } from "~/__generated__/graphql"

export const ORDER_STATUS_CLASSES = {
  [OrderStatusEnum.Received]: "border-lamplight",
  [OrderStatusEnum.ContractReview]: "border-lamplight",
  [OrderStatusEnum.PoGenerated]: "border-dodger-blue text-dodger-blue",
  [OrderStatusEnum.PoApproved]: "border-woods-green text-woods-green",
  [OrderStatusEnum.DataTransmitted]: "border-woods-green text-woods-green",
  [OrderStatusEnum.PaymentReceived]: "border-woods-green text-woods-green",
  [OrderStatusEnum.OrderCancelled]: "border-woods-green text-woods-green",
}

const OrderStatus = ({
  status,
  statusDisplay,
  compact,
}: {
  status: OrderStatusEnum
  statusDisplay: string
  compact?: boolean
}) => {
  return (
    <div
      className={clsx(
        "rounded-full border",
        compact ? "px-3 py-1.5 text-xs" : "px-4 py-2",
        ORDER_STATUS_CLASSES[status]
      )}
      title={statusDisplay}
    >
      <span className="line-clamp-1 break-all">{statusDisplay}</span>
    </div>
  )
}

export default OrderStatus
