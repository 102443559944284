import { useMutation } from "@apollo/client"
import { Dialog } from "@radix-ui/react-dialog"
import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  useFormikContext,
} from "formik"
import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { studySearchResultsPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import SaveNewSearchFields, {
  saveNewSearchValidationSchema,
} from "~/components/SaveNewSearchFields"
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui/Dialog"
import useSearchParamValues from "~/hooks/useSearchParamValues"
import Button from "~/ui/Button"
import { StudySearchResultsFormValues } from "../StudySearchResultsScreen"

export const SearchCreate_Mutation = gql(`
  mutation SearchCreate($input: SearchCreateInput!) {
    searchCreate(input: $input) {
      search {
        id
        name
        isDuplicatingSearch
        isCreatingResults
      }
    }
  }
`)

type Values = {
  name: string
}

const NewStudySearchDialog = () => {
  const [mutate] = useMutation(SearchCreate_Mutation)
  const [isOpen, setIsOpen] = useState(false)
  const searchParamValues = useSearchParamValues()
  const navigate = useNavigate()
  const { values: searchResultsFormValues } =
    useFormikContext<StudySearchResultsFormValues>()

  const onSubmit = async (
    values: Values,
    { setFieldError }: FormikHelpers<Values>
  ) => {
    try {
      const resp = await mutate({
        variables: {
          input: {
            searchInput: {
              name: values.name,
            },
            studyIdsDiff: searchResultsFormValues.studyIdsDiff,
            selectAll: searchResultsFormValues.selectAll,
            studySearchParamsInput: {
              ...searchParamValues,
            },
          },
        },
      })

      const id = resp?.data?.searchCreate?.search?.id
      invariant(id, "Expected search id")

      toast.success("Search has been saved")
      navigate(studySearchResultsPath({ id }))
      setIsOpen(false)
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DialogTrigger asChild>
        <Button variant="outlineSecondary" display="inline" type="button">
          Save Search
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay className="DialogOverlay" />
        <DialogContent className="DialogContent">
          <DialogTitle className="DialogTitle">Save Search</DialogTitle>
          <div className="mb-4 mt-6">
            <div className="mb-1 text-2xl font-medium text-blue-zodiac">
              Save Search Criteria
            </div>
            <DialogDescription className="DialogDescription">
              Saving your search allows you to quickly view prior query builder
              results.
            </DialogDescription>
          </div>
          <Formik
            initialValues={{ name: "" }}
            validationSchema={saveNewSearchValidationSchema}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }: FormikProps<Values>) => (
              <Form className="w-full">
                <SaveNewSearchFields disabled={isSubmitting} />
              </Form>
            )}
          </Formik>
          <DialogClose asChild>
            <button className="IconButton" aria-label="Close" />
          </DialogClose>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

export default NewStudySearchDialog
