import { useQuery } from "@apollo/client"
import clsx from "clsx"
import pluralize from "pluralize"
import { useSearchParams } from "react-router-dom"
import { getFragmentData, gql } from "~/__generated__"
import { newObjectId, searchPath } from "~/common/paths"
import { ButtonLink } from "~/ui/Button"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"
import PageContent from "~/ui/PageContent"
import SearchesTable, {
  Searches_QueryFragment,
} from "./components/SearchesTable"

export const SearchesScreen_Query = gql(`
  query SearchesScreen_Query($page: Int, $limit: Int) {
    ...Searches_QueryFragment
  }
`)

export const SEARCHES_LIMIT = 12

const SearchesScreen = () => {
  const [searchParams] = useSearchParams()
  const page = searchParams.get("page")

  const result = useQuery(SearchesScreen_Query, {
    variables: {
      page: page ? Number(page) : 1,
      limit: SEARCHES_LIMIT,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { searches } = getFragmentData(Searches_QueryFragment, data)
  const totalCount = searches.searches.totalCount

  return (
    <div className="text-xs text-gray-600">
      <PageContent>
        <div
          className={clsx(
            "flex flex-col gap-6",
            "lg:flex-row lg:justify-between"
          )}
        >
          <div>
            <H1>Saved Searches</H1>
          </div>
          <ButtonLink
            to={searchPath({ id: newObjectId })}
            preserveSearch={true}
            display="inline"
          >
            Create New Search
          </ButtonLink>
        </div>
        <div className="mb-6 flex flex-col gap-6 text-base">
          <div className="my-1">
            <b>{totalCount}</b> saved {pluralize("searches", totalCount)} found.
          </div>
        </div>
        {searches.searches.edges.length > 0 && (
          <SearchesTable
            searches={searches.searches.edges.map((edge) => edge.node)}
            totalCount={totalCount}
            page={searches.page}
          />
        )}
      </PageContent>
    </div>
  )
}

export default SearchesScreen
