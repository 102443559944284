import clsx from "clsx"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import useBodyPartsQuery from "~/hooks/useBodyPartsQuery"
import Error from "~/ui/Error"
import Loading from "~/ui/Loading"
import { STUDY_PACKAGES_LIMIT } from "../StudyPackagesIndexScreen"
import StudyPackageListItem, {
  StudyPackageListItem_StudyPackageFragment,
} from "./StudyPackagesListItem"

export const StudyPackagesList_QueryFragment = gql(`
  fragment StudyPackagesList_QueryFragment on Query {
    studyPackages(modalities: $modalities, bodyPartIds: $bodyPartIds, featured: $featured, page: $page, limit: $limit) {
      studyPackages {
        edges {
          node {
            ...StudyPackageListItem_StudyPackageFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const StudyPackagesList = ({
  studyPackages,
  totalCount,
  page,
}: {
  studyPackages: FragmentType<
    typeof StudyPackageListItem_StudyPackageFragment
  >[]
  totalCount: number
  page: number
}) => {
  const totalPages = Math.ceil(totalCount / STUDY_PACKAGES_LIMIT)
  const { bodyParts, loading, error } = useBodyPartsQuery()

  if (loading) return <Loading />
  if (error || !bodyParts?.length) return <Error />

  return (
    <div className="mt-6 flex flex-col gap-6 text-base">
      <div
        className={clsx(
          "grid grid-cols-1 gap-4",
          "md:grid-cols-2",
          "md:gap-6 lg:grid-cols-3"
        )}
      >
        {studyPackages.map((studyPackage) => (
          <StudyPackageListItem
            key={
              getFragmentData(
                StudyPackageListItem_StudyPackageFragment,
                studyPackage
              ).id
            }
            data={studyPackage}
          />
        ))}
      </div>
      <Pagination page={page} totalPages={totalPages} />
    </div>
  )
}

export default StudyPackagesList
