import { useQuery } from "@apollo/client"
import clsx from "clsx"
import pluralize from "pluralize"
import { useSearchParams } from "react-router-dom"
import { getFragmentData } from "~/__generated__"
import {
  AdminUsersScreen_Query,
  USERS_LIMIT,
  UserSearch,
} from "~/screens/AdminScreen/AdminUsersScreen/screens/AdminUsersIndexScreen/AdminUsersIndexScreen"
import AdminUsersTable, {
  AdminUsers_QueryFragment,
} from "~/screens/AdminScreen/AdminUsersScreen/screens/AdminUsersIndexScreen/components/AdminUsersTable"
import Error from "~/ui/Error"
import { H1 } from "~/ui/Headings"
import Loading from "~/ui/Loading"

const AdminAdminsIndexScreen = () => {
  const [searchParams] = useSearchParams()
  const page = searchParams.get("page")
  const result = useQuery(AdminUsersScreen_Query, {
    variables: {
      admin: true,
      search: searchParams.get("search") || undefined,
      page: page ? Number(page) : 1,
      limit: USERS_LIMIT,
    },
  })

  const { loading, error } = result
  const data = loading ? result.previousData : result.data

  if (loading && !data) return <Loading />
  if (error || !data) return <Error error={error} />

  const { users } = getFragmentData(AdminUsers_QueryFragment, data)
  const totalCount = users.users.totalCount

  return (
    <div>
      <div
        className={clsx(
          "mb-6 flex flex-col gap-6",
          "lg:mb-0 lg:flex-row lg:justify-between"
        )}
      >
        <div>
          <H1>Admins</H1>
        </div>
        <div>
          <UserSearch />
        </div>
      </div>
      <div className="mb-6 flex flex-col gap-6 text-base">
        <div className="my-1">
          <b>
            {totalCount.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}
          </b>{" "}
          {pluralize("admin", totalCount)}
        </div>
      </div>
      <div
        className={clsx({
          "animate-pulse opacity-90": loading,
        })}
      >
        {users.users.edges.length > 0 && (
          <AdminUsersTable
            users={users.users.edges.map((edge) => edge.node)}
            totalCount={totalCount}
            page={users.page}
          />
        )}
      </div>
    </div>
  )
}

export default AdminAdminsIndexScreen
