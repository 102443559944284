import clsx from "clsx"
import HR from "~/ui/HR"

const ResultsHr = () => {
  return (
    <div className={clsx("-mx-4", "md:-mx-8")}>
      <HR padding={false} />
    </div>
  )
}

export default ResultsHr
