import clsx from "clsx"
import { PropsWithChildren } from "react"

const VARIANTS = {
  black: "text-black border-black",
  red: "text-coral-red border-coral-red",
}

const Badge = ({
  variant = "black",
  className,
  children,
}: {
  variant?: keyof typeof VARIANTS
  className?: string
} & PropsWithChildren) => {
  return (
    <div
      className={clsx(
        "ml-2.5 flex items-center justify-center whitespace-nowrap rounded-sm border  px-2 pb-[3px] pt-1 text-2xs font-bold uppercase",
        VARIANTS[variant],
        className
      )}
    >
      {children}
    </div>
  )
}

export default Badge
