import { Form, Formik, FormikProps } from "formik"
import { toast } from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import * as Yup from "yup"
import {
  deviseSessionCreatePath,
  forgotPasswordPath,
  registrationPath,
} from "~/common/paths"
import { request } from "~/common/request"
import Button from "~/ui/Button"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"
import { H1 } from "~/ui/Headings"
import HR from "~/ui/HR"
import { Link } from "~/ui/Link"

type LoginFields = {
  email: string
  password: string
}

const initialValues = {
  email: "",
  password: "",
}

const validationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("A password is required"),
})

export const LoginScreen = () => {
  const [searchParams] = useSearchParams()

  const onSubmit = async (values: LoginFields) => {
    try {
      const [status, response] = await request(
        deviseSessionCreatePath({}),
        { method: "POST" },
        { user: values }
      )

      if (status === "success") {
        const redirectUrl = searchParams.get("redirect")
        if (redirectUrl) {
          window.location.replace(decodeURIComponent(redirectUrl))
        } else {
          window.location.replace("/")
        }
      } else {
        toast.error(response.error || response.message)
      }
    } catch (error) {
      console.error(error)
      const message = "Sign in failed, please try again"
      toast.error(message)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }: FormikProps<LoginFields>) => (
        <Form className="w-full">
          <H1 id="sign-in" light={true} centered={true} font="tight">
            Sign In
          </H1>
          <div className="mb-8 text-center text-xs text-white">
            Enter your credentials to access your account.
          </div>
          <FieldGroup>
            <FormikField
              name="email"
              type="email"
              label="Email Address"
              autoFocus={true}
              light={true}
              labelSize="sm"
            />
            <FormikField
              name="password"
              label="Password"
              labelLink={{
                linkTo: forgotPasswordPath.pattern,
                title: "Forgot Password?",
              }}
              type="password"
              light={true}
              labelSize="sm"
            />
          </FieldGroup>

          <HR dark={true} />

          <Button disabled={isSubmitting}>Sign In</Button>

          <div className="my-8 text-center text-sm">
            <Link
              to={registrationPath.pattern}
              className="text-white no-underline hover:underline"
            >
              Not a member? Create account
            </Link>
          </div>

          <div className="mx-auto mb-8 max-w-[270px] text-center text-2xs leading-4 text-white opacity-[0.5]">
            By clicking “Sign In” above, you agree to RadImageNet’s{" "}
            <span className="underline">Terms & Conditions</span> and{" "}
            <span className="underline">Privacy Policy</span>.
          </div>
        </Form>
      )}
    </Formik>
  )
}
