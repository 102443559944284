import clsx from "clsx"
import pluralize from "pluralize"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import Pagination from "~/components/Pagination"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/Table"
import { EXPORT_REQUESTS_LIMIT } from "../AdminExportRequestsIndexScreen"
import AdminExportRequestRow, {
  AdminExportRequestRow_ExportRequestFragment,
} from "./AdminExportRequestRow"

export const AdminExportRequests_QueryFragment = gql(`
  fragment AdminExportRequests_QueryFragment on Query {
    exportRequests(page: $page, limit: $limit) {
      exportRequests {
        edges {
          node {
            ...AdminExportRequestRow_ExportRequestFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      page
    }
  }
`)

const AdminExportRequestsTable = ({
  exportRequests,
  totalCount,
  page,
  loading,
}: {
  exportRequests: FragmentType<
    typeof AdminExportRequestRow_ExportRequestFragment
  >[]
  totalCount: number
  page: number
  loading: boolean
}) => {
  const totalPages = Math.ceil(totalCount / EXPORT_REQUESTS_LIMIT)

  return (
    <div
      className={clsx("flex flex-col gap-6 text-base", {
        "animate-pulse opacity-90": loading,
      })}
    >
      <div className="flex flex-col gap-6 text-base">
        <div className="my-1">
          <b>
            {totalCount.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}
          </b>{" "}
          {pluralize("export request", totalCount)}
        </div>
      </div>

      {exportRequests.length > 0 && (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="min-w-[100px]">Name</TableHead>
                <TableHead className="min-w-[100px]">Email</TableHead>
                <TableHead className="min-w-[100px]">Company</TableHead>
                <TableHead className="min-w-[150px]">Date</TableHead>
                <TableHead className="min-w-[120px]">Status</TableHead>
                <TableHead className="w-[100px]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {exportRequests.map((exportRequest) => (
                <AdminExportRequestRow
                  key={
                    getFragmentData(
                      AdminExportRequestRow_ExportRequestFragment,
                      exportRequest
                    ).id
                  }
                  data={exportRequest}
                />
              ))}
            </TableBody>
          </Table>
          <Pagination page={page} totalPages={totalPages} />
        </>
      )}
    </div>
  )
}

export default AdminExportRequestsTable
