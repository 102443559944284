import { useMutation } from "@apollo/client"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { gql } from "~/__generated__"
import { CurrentUserFragment } from "~/__generated__/graphql"
import { profilePath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import { stateOptions } from "~/screens/RegistrationScreen"
import Button, { ButtonLink } from "~/ui/Button"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"
import { H3 } from "~/ui/Headings"

type Values = {
  email: string
  firstName: string
  lastName: string
  company: string
  companyAddress1: string
  companyAddress2: string
  companyCity: string
  companyState: string
  companyZip: string
}

const validationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  company: Yup.string(),
  companyAddress1: Yup.string(),
  companyAddress2: Yup.string(),
  companyCity: Yup.string(),
  companyState: Yup.string(),
  companyZip: Yup.string(),
})

export const USER_UPDATE_MUTATION = gql(`
  mutation UserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...CurrentUser
      }
    }
  }
`)

const EditProfileForm = ({ user }: { user: CurrentUserFragment }) => {
  const [mutate] = useMutation(USER_UPDATE_MUTATION)
  const navigate = useNavigate()

  const initialValues = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    company: user.company || "",
    companyAddress1: user.companyAddress1 || "",
    companyAddress2: user.companyAddress2 || "",
    companyCity: user.companyCity || "",
    companyState: user.companyState || "",
    companyZip: user.companyZip || "",
  }

  const onSubmit = async (
    values: Values,
    { setFieldError }: FormikHelpers<Values>
  ) => {
    try {
      await mutate({
        variables: {
          input: {
            id: user.id,
            userInput: values,
          },
        },
      })

      toast.success("Profile has been updated")
      navigate(profilePath.pattern)
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }: FormikProps<Values>) => (
        <Form className="w-full">
          <H3 id="personal-information">Personal Information</H3>
          <FieldGroup row={true} margin={false} gap={false}>
            <div className="z-10 -mr-[1px] flex-1">
              <FormikField
                name="firstName"
                label="First Name"
                inputClassName="md:rounded-e-none"
                autoFocus={true}
                labelSize="sm"
              />
            </div>
            <div className="flex-1">
              <FormikField
                name="lastName"
                label="Last Name"
                inputClassName="md:rounded-s-none"
                labelSize="sm"
              />
            </div>
          </FieldGroup>
          <FieldGroup>
            <FormikField
              name="email"
              type="email"
              label="Email Address"
              labelSize="sm"
            />
          </FieldGroup>
          <div className="mt-7 text-xs text-white">
            <H3 id="personal-information">School / Corporate Affiliation</H3>
          </div>
          <FormikField name="company" label="Name" labelSize="sm" />
          <FormikField name="companyAddress1" label="Address" labelSize="sm" />
          <FormikField
            name="companyAddress2"
            label="Address 2"
            labelSize="sm"
          />
          <FormikField name="companyCity" label="City" labelSize="sm" />
          <FieldGroup row={true} margin={false} gap={false}>
            <div className="z-10 -mr-[1px] flex-1">
              <FormikField
                name="companyState"
                label="State"
                placeholder="Select State"
                type="select"
                labelSize="sm"
                inputClassName="md:rounded-e-none"
                options={[
                  { label: "Select State", value: "" },
                  ...stateOptions,
                ]}
                search={true}
              />
            </div>
            <FormikField
              name="companyZip"
              label="Zip"
              inputClassName="md:rounded-s-none"
              labelSize="sm"
            />
          </FieldGroup>

          <div className="mt-10 flex justify-between">
            <Button disabled={isSubmitting} display="inline">
              Save & Update
            </Button>
            <ButtonLink
              to={profilePath.pattern}
              variant="outlineSecondary"
              display="inline"
            >
              Cancel
            </ButtonLink>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default EditProfileForm
