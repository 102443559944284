// use static-path to generate type-safe paths
// https://github.com/garybernhardt/static-path

import { path } from "static-path"

export const newObjectId = "new"

export const loadingPath = path("/")
export const registrationPath = path("/create-account")
export const logInPath = path("/sign-in")
export const forgotPasswordPath = path("/forgot-password")
export const resetPasswordPath = path("/reset-password")
export const dashboardPath = path("/dashboard")
export const resetPasswordFormPath = path("/users/password")
export const profilePath = path("/profile")
export const editProfilePath = path("/profile/edit")
export const editPasswordPath = path("/profile/update-password")
export const searchPath = path("/searches/:id")
export const searchesPath = path("/saved-searches")
export const imageSearchResultsPath = path("/searches/:id/image/results")
export const imageSearchNewOrderPath = path("/searches/:id/image/results/order")
export const studySearchResultsPath = path("/searches/:id/results")
export const studySearchResultPath = path("/searches/:id/results/:studyId")
export const studySearchNewOrderPath = path("/searches/:id/results/order")
export const ordersPath = path("/orders")
export const orderSuccessPath = path("/orders/:id/confirmation")
export const orderPath = path("/orders/:id")
export const studyPackagesPath = path("/image-packages")
export const studyPackagePath = path("/image-packages/:id")
export const studyPackageNewOrderPath = path("/image-packages/:id/order")
export const studyPackageStudyPath = path(
  "/image-packages/:id/studies/:studyId"
)
export const ethnicityRaceDistributionPath = path(
  "/ethnicity-race-distribution"
)

export const adminPath = path("/admin")
export const adminOrdersPath = path("/admin/orders")
export const adminOrderPath = path("/admin/orders/:id")
export const adminUsersPath = path("/admin/users")
export const adminAdminsPath = path("/admin/admins")
export const adminUserPath = path("/admin/users/:id")
export const adminEditUserPath = path("/admin/users/:id/edit")
export const adminStudyPackagesPath = path("/admin/image-packages")
export const adminStudyPackagePath = path("/admin/image-packages/:id")
export const adminNewStudyPackagePath = path("/admin/image-packages/new")
export const adminEditStudyPackagePath = path("/admin/image-packages/:id/edit")
export const adminStudiesPath = path("/admin/studies")
export const adminStudyPath = path("/admin/studies/:id")
export const adminBulkUpdatesPath = path("/admin/bulk-updates")
export const adminNewStudyImportPath = path(
  "/admin/bulk-updates/study-imports/new"
)
export const adminStudyImportPath = path(
  "/admin/bulk-updates/study-imports/:id"
)
export const adminNewStudyArchivePath = path(
  "/admin/bulk-updates/study-archives/new"
)
export const adminStudyArchivePath = path(
  "/admin/bulk-updates/study-archives/:id"
)
export const adminExportRequestsPath = path("/admin/export-requests")
export const adminExportRequestPath = path("/admin/export-requests/:id")

// server routes
export const deviseSessionCreatePath = path("/users/sign_in")
export const deviseRegistrationCreatePath = path("/users")
export const destroySessionPath = path("/users/sign_out")

// external paths
export const supportEmail = "support@radimagenet.com"
export const supportEmailPath = "mailto:support@radimagenet.com"
