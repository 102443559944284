import clsx from "clsx"

const SIZE_CLASSES = {
  sm: "w-full max-w-[464px]",
  md: "w-full max-w-[642px]",
  lg: "w-full max-w-[920px]",
  full: "w-full",
}

const Container = ({
  size = "full",
  centerSelf = false,
  padded = false,
  centerContent = false,
  children,
}: {
  size?: keyof typeof SIZE_CLASSES
  centerSelf?: boolean
  padded?: boolean
  centerContent?: boolean
  children: React.ReactNode
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col",
        SIZE_CLASSES[size],
        centerContent ? "items-center" : "items-start",
        {
          "px-4 py-6 md:px-8": padded,
          "mx-auto": centerSelf,
        }
      )}
    >
      {children}
    </div>
  )
}

export default Container
