import pluralize from "pluralize"
import { StudyPackage } from "~/__generated__/graphql"

export const getStudyCountType = ({
  studyPackage,
}: {
  studyPackage: Pick<
    StudyPackage,
    "studyCount" | "showStudies" | "isImageCount"
  >
}) => {
  const { showStudies, isImageCount } = studyPackage
  return showStudies ? "study" : isImageCount ? "image" : "study"
}

export const getStudyCountTypePlural = ({
  studyPackage,
}: {
  studyPackage: Pick<
    StudyPackage,
    "studyCount" | "showStudies" | "isImageCount" | "manualCount"
  >
}) => {
  const { showStudies, studyCount, manualCount } = studyPackage
  const type = getStudyCountType({ studyPackage })
  return pluralize(
    type,
    showStudies ? studyCount || 0 : manualCount?.trim() === "1" ? 1 : 2
  )
}

export const getStudyCountDisplay = ({
  studyPackage,
}: {
  studyPackage: Pick<StudyPackage, "studyCount" | "manualCount" | "showStudies">
}) => {
  const { showStudies, studyCount, manualCount } = studyPackage

  let count = ""

  if (showStudies) {
    count = (studyCount || 0).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    })
  } else if (manualCount) {
    count = manualCount
  }

  return count
}
