import { dateMedium, time } from "~/common/dates"

const DateTimeCell = ({ date }: { date: string }) => {
  if (!date) return null
  return (
    <div className="truncated">
      {dateMedium(new Date(date))}
      <div className="text-2xs text-neutral-400">
        {time(new Date(date), true)}
      </div>
    </div>
  )
}

export default DateTimeCell
