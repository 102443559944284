import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover"
import clsx from "clsx"
import { PropsWithChildren, useState } from "react"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData } from "~/__generated__"
import { ModalityEnum } from "~/__generated__/graphql"
import { modalityDisplay } from "~/common/study"
import {
  StudySearchResultsSummary_SearchFragment,
  StudySearchResultsSummary_SearchParamsFragment,
} from "./StudySearchResultsSummary"

const KeywordPopover = ({
  admin,
  children,
}: { admin?: boolean } & PropsWithChildren) => {
  const [open, setOpen] = useState(false)

  if (admin) {
    return <>{children}</>
  }

  return (
    <div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <button
            aria-expanded={open}
            className="justify-between bg-white text-dodger-blue"
          >
            View
          </button>
        </PopoverTrigger>

        <PopoverContent className="min-h-[120px] min-w-[300px] rounded-sm border border-border-gray bg-white p-4 text-left shadow-md">
          {children}
        </PopoverContent>
      </Popover>
    </div>
  )
}

const DataSection = ({ children }: PropsWithChildren) => (
  <div className="flex-1 ">
    <div className="divide-y divide-border-gray border border-border-gray">
      {children}
    </div>
  </div>
)

const DataHeader = ({ label }: { label: string }) => (
  <div className="px-4 py-2 text-base font-bold text-blue-zodiac">
    <div>{label}</div>
  </div>
)

const DataRow = ({
  label,
  children,
}: { label: string } & PropsWithChildren) => (
  <div className="flex flex-1 items-center justify-between gap-2 px-4 py-1 text-sm">
    <div className="text-elderberry">{label}</div>
    <div className="text-right font-semibold">{children}</div>
  </div>
)

const StudySearchResultsAppliedSeriesFilters = ({
  search,
  admin,
}: {
  search: FragmentType<typeof StudySearchResultsSummary_SearchFragment>
  admin?: boolean
}) => {
  const { studySearchParams } = getFragmentData(
    StudySearchResultsSummary_SearchFragment,
    search
  )
  invariant(studySearchParams, "Expected studySearchParams")
  const {
    mr,
    ct,
    us,
    xr,
    mrStrength,
    mrContrast,
    ctContrast,
    mrDescriptionKeywords,
    mrSeriesDescriptionKeywords,
    mrReportKeywords,
    ctDescriptionKeywords,
    ctSeriesDescriptionKeywords,
    ctReportKeywords,
    usDescriptionKeywords,
    usSeriesDescriptionKeywords,
    usReportKeywords,
    xrDescriptionKeywords,
    xrSeriesDescriptionKeywords,
    xrReportKeywords,
  } = getFragmentData(
    StudySearchResultsSummary_SearchParamsFragment,
    studySearchParams
  )

  if (!mr && !ct && !us && !xr && mrStrength === null && mrContrast === null) {
    return null
  }

  return (
    <div className={clsx("flex w-full flex-col gap-4", "lg:flex-row")}>
      {mr && (
        <DataSection>
          <DataHeader label={modalityDisplay(ModalityEnum.Mr)} />
          <DataRow label="Machine Strength">{mrStrength}</DataRow>
          <DataRow label="IV Contrast">
            {mrContrast === null ? "" : mrContrast ? "Yes" : "No"}
          </DataRow>
          <DataRow label="Report Keywords">
            {mrReportKeywords && (
              <KeywordPopover admin={admin}>{mrReportKeywords}</KeywordPopover>
            )}
          </DataRow>
          <DataRow label="Study Description Keywords">
            {mrDescriptionKeywords && (
              <KeywordPopover admin={admin}>
                {mrDescriptionKeywords}
              </KeywordPopover>
            )}
          </DataRow>
          <DataRow label="Series Description Keywords">
            {mrSeriesDescriptionKeywords && (
              <KeywordPopover admin={admin}>
                {mrSeriesDescriptionKeywords}
              </KeywordPopover>
            )}
          </DataRow>
        </DataSection>
      )}
      {ct && (
        <DataSection>
          <DataHeader label={modalityDisplay(ModalityEnum.Ct)} />
          <DataRow label="IV Contrast">
            {ctContrast === null ? "" : ctContrast ? "Yes" : "No"}
          </DataRow>
          <DataRow label="Report Keywords">
            {ctReportKeywords && (
              <KeywordPopover admin={admin}>{ctReportKeywords}</KeywordPopover>
            )}
          </DataRow>
          <DataRow label="Study Description Keywords">
            {ctDescriptionKeywords && (
              <KeywordPopover admin={admin}>
                {ctDescriptionKeywords}
              </KeywordPopover>
            )}
          </DataRow>
          <DataRow label="Series Description Keywords">
            {ctSeriesDescriptionKeywords && (
              <KeywordPopover admin={admin}>
                {ctSeriesDescriptionKeywords}
              </KeywordPopover>
            )}
          </DataRow>
        </DataSection>
      )}
      {us && (
        <DataSection>
          <DataHeader label={modalityDisplay(ModalityEnum.Us)} />
          <DataRow label="Report Keywords">
            {usReportKeywords && (
              <KeywordPopover admin={admin}>{usReportKeywords}</KeywordPopover>
            )}
          </DataRow>
          <DataRow label="Study Description Keywords">
            {usDescriptionKeywords && (
              <KeywordPopover admin={admin}>
                {usDescriptionKeywords}
              </KeywordPopover>
            )}
          </DataRow>
          <DataRow label="Series Description Keywords">
            {usSeriesDescriptionKeywords && (
              <KeywordPopover admin={admin}>
                {usSeriesDescriptionKeywords}
              </KeywordPopover>
            )}
          </DataRow>
        </DataSection>
      )}
      {xr && (
        <DataSection>
          <DataHeader label={modalityDisplay(ModalityEnum.Xr)} />
          <DataRow label="Report Keywords">
            {xrReportKeywords && (
              <KeywordPopover admin={admin}>{xrReportKeywords}</KeywordPopover>
            )}
          </DataRow>
          <DataRow label="Study Description Keywords">
            {xrDescriptionKeywords && (
              <KeywordPopover admin={admin}>
                {xrDescriptionKeywords}
              </KeywordPopover>
            )}
          </DataRow>
          <DataRow label="Series Description Keywords">
            {xrSeriesDescriptionKeywords && (
              <KeywordPopover admin={admin}>
                {xrSeriesDescriptionKeywords}
              </KeywordPopover>
            )}
          </DataRow>
        </DataSection>
      )}
    </div>
  )
}

export default StudySearchResultsAppliedSeriesFilters
