import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { dashboardPath, logInPath } from "~/common/paths"

export const LoadingScreen = () => {
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (currentUser) {
      navigate(dashboardPath.pattern)
    } else if (!result.loading) {
      navigate(logInPath.pattern)
    }
  })

  return null
}
