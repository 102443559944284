import { currency } from "~/common/currency"
import { dateMedium } from "~/common/dates"
import { TD, TH, Table } from "~/ui/DetailTable"

const OrderDetailsTable = ({
  createdAt,
  statusDisplay,
  orderNumber,
  studyCount,
  imageCount,
  estimatedPriceCents,
  priceCents,
}: {
  createdAt: string
  statusDisplay: string
  orderNumber: string
  studyCount?: number
  imageCount?: number
  estimatedPriceCents: number
  priceCents?: number
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <TH colSpan={2}>Details</TH>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TD>Order Date</TD>
          <TD>{dateMedium(new Date(createdAt))}</TD>
        </tr>
        <tr>
          <TD>Status</TD>
          <TD>{statusDisplay}</TD>
        </tr>
        <tr>
          <TD>Order Number</TD>
          <TD>{orderNumber}</TD>
        </tr>
        {!!studyCount && studyCount > 0 && (
          <tr>
            <TD>Study Count</TD>
            <TD>
              {studyCount.toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
            </TD>
          </tr>
        )}
        {!!imageCount && imageCount > 0 && (
          <tr>
            <TD>Image Count</TD>
            <TD>
              {imageCount.toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
            </TD>
          </tr>
        )}
        {!!priceCents && priceCents > 0 ? (
          <tr>
            <TD>Total</TD>
            <TD>{currency(priceCents)}</TD>
          </tr>
        ) : (
          <tr>
            <TD>Estimated Total</TD>
            <TD>{currency(estimatedPriceCents)}</TD>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default OrderDetailsTable
