import clsx from "clsx"
import queryString from "query-string"
import { PropsWithChildren } from "react"
import { Link, useLocation, useSearchParams } from "react-router-dom"
import { usePagination } from "~/lib/usePagination"

const Page = ({
  active,
  hover = true,
  children,
}: {
  active: boolean
  hover?: boolean
  children: React.ReactNode
}) => {
  return (
    <div
      className={clsx(
        "-mr-[1px] flex min-h-[40px] min-w-[45px] border border-border-gray first:rounded-l-md last:rounded-r-md",
        active
          ? "pointer-events-none border-dodger-blue bg-dodger-blue text-white"
          : "border-border-gray",
        {
          "hover:bg-gray-50": hover && !active,
        }
      )}
      data-test={active ? "active-page" : "page"}
      children={children}
    />
  )
}

const Pagination = ({
  totalPages,
  page,
  onClick,
  children,
}: {
  totalPages: number
  page: number
  onClick?: () => void
} & PropsWithChildren) => {
  const { range, active } = usePagination({
    total: totalPages,
    initialPage: 1,
    page,
    boundaries: 2,
  })
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const currentSearchParams = queryString.parse(searchParams.toString())

  return (
    <div
      className={clsx(
        "flex flex-col justify-between gap-6",
        "xl:flex-row xl:items-center"
      )}
      data-test="pagination"
    >
      <div className="flex flex-wrap">
        {range.map((page) => {
          if (page === "dots") {
            return (
              <Page active={false} hover={false} key={page}>
                <div className="-mt-1 flex h-full w-full items-center justify-center">
                  ...
                </div>
              </Page>
            )
          }

          return (
            <Page active={active === page} key={page}>
              <Link
                key={page}
                to={{
                  pathname: location.pathname,
                  search: queryString.stringify({
                    ...currentSearchParams,
                    page: page,
                  }),
                }}
                className="block h-full w-full px-4 py-2 text-center"
                onClick={onClick}
              >
                {page}
              </Link>
            </Page>
          )
        })}
      </div>
      <div>{children}</div>
    </div>
  )
}

export default Pagination
