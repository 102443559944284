import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { BulkUpdateTypeEnum } from "~/__generated__/graphql"
import { adminStudyArchivePath, adminStudyImportPath } from "~/common/paths"
import DateTimeCell from "~/components/DateTimeCell"
import { LinkedTableCell, TableCell, TableRow } from "~/components/ui/Table"
import { ButtonLink } from "~/ui/Button"

export const AdminBulkUpdateRow_BulkUpdateFragment = gql(`
  fragment AdminBulkUpdateRow_BulkUpdateFragment on BulkUpdate {
    id
    number
    description
    completedCsvImportAt
    completedAt
    createdAt
    bulkUpdateType
  }
`)

const AdminBulkUpdateRow = ({
  data,
}: {
  data: FragmentType<typeof AdminBulkUpdateRow_BulkUpdateFragment>
}) => {
  const bulkUpdate = getFragmentData(
    AdminBulkUpdateRow_BulkUpdateFragment,
    data
  )

  const path =
    bulkUpdate.bulkUpdateType === BulkUpdateTypeEnum.StudyImport
      ? adminStudyImportPath({
          id: bulkUpdate.id,
        })
      : adminStudyArchivePath({
          id: bulkUpdate.id,
        })

  return (
    <TableRow>
      <LinkedTableCell to={path}>{bulkUpdate.number}</LinkedTableCell>
      <LinkedTableCell to={path}>{bulkUpdate.bulkUpdateType}</LinkedTableCell>
      <LinkedTableCell to={path}>
        <div className="line-clamp-2">{bulkUpdate.description}</div>
      </LinkedTableCell>
      <LinkedTableCell to={path}>
        <DateTimeCell date={bulkUpdate.createdAt} />
      </LinkedTableCell>
      <TableCell>
        <div className="flex gap-2">
          <ButtonLink to={path} display="inline">
            View
          </ButtonLink>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default AdminBulkUpdateRow
