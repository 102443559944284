import Button from "~/ui/Button"
import FormikInputField from "~/ui/FormikInputField"

const AdminSearchFields = ({ placeholder }: { placeholder?: string }) => {
  return (
    <div className="flex items-center gap-4">
      <div className="block w-[220px]">
        <FormikInputField name="search" placeholder={placeholder || "Search"} />
      </div>
      <div>
        <Button type="submit" variant="outlineSecondary" className="block">
          Search
        </Button>
      </div>
    </div>
  )
}

export default AdminSearchFields
