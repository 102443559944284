import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { dateMedium } from "~/common/dates"
import { adminStudyPath } from "~/common/paths"
import { sexDisplay } from "~/common/study"
import ContactSheetThumb from "~/components/ContactSheetThumb"
import { LinkedTableCell, TableCell, TableRow } from "~/components/ui/Table"
import { boolToString } from "~/lib/utils"
import { ButtonLink } from "~/ui/Button"

export const AdminStudyRow_StudyFragment = gql(`
  fragment AdminStudyRow_StudyFragment on Study {
    id
    uid
    patientAge
    description
    sex
    date
    modality
    contactSheetUrl
    purchased
    reportConclusion
    mrn
    accession
    sliceThicknessMin
    contrast
    archived
  }
`)

const AdminStudyRow = ({
  data,
}: {
  data: FragmentType<typeof AdminStudyRow_StudyFragment>
}) => {
  const study = getFragmentData(AdminStudyRow_StudyFragment, data)
  const path = adminStudyPath({
    id: study.id,
  })

  return (
    <TableRow>
      <TableCell className="p-3">
        <ContactSheetThumb contactSheetUrl={study.contactSheetUrl} />
      </TableCell>
      <LinkedTableCell to={path}>{study.description}</LinkedTableCell>
      <LinkedTableCell to={path}>{study.accession}</LinkedTableCell>
      <LinkedTableCell to={path}>{study.mrn}</LinkedTableCell>
      <LinkedTableCell to={path}>
        <div className="uppercase">{study.modality}</div>
      </LinkedTableCell>
      <LinkedTableCell to={path}>{study.sliceThicknessMin}</LinkedTableCell>
      <LinkedTableCell to={path}>
        {boolToString(study.contrast)}
      </LinkedTableCell>
      <LinkedTableCell to={path}>{study.patientAge}</LinkedTableCell>
      <LinkedTableCell to={path}>{sexDisplay(study.sex)}</LinkedTableCell>
      <LinkedTableCell to={path}>
        {boolToString(study.archived)}
      </LinkedTableCell>
      <LinkedTableCell to={path}>
        {study.date ? dateMedium(new Date(study.date)) : ""}
      </LinkedTableCell>
      <LinkedTableCell to={path}>
        <div className="line-clamp-2">{study.reportConclusion}</div>
      </LinkedTableCell>
      <TableCell>
        <div className="flex gap-2">
          <ButtonLink to={path} display="inline">
            View
          </ButtonLink>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default AdminStudyRow
