import { useQuery } from "@apollo/client"
import { getFragmentData, gql } from "~/__generated__"

export const BODY_PART_FRAGMENT = gql(`
  fragment BodyPartFragment on BodyPart {
    id
    uid
    name
  }
`)

export const GET_BODY_PARTS_QUERY = gql(`
  query BodyParts {
    bodyParts(first: 1000) {
      nodes {
        ...BodyPartFragment
      }
    }
  }
`)

const useBodyPartsQuery = () => {
  const { data, loading, error } = useQuery(GET_BODY_PARTS_QUERY)

  const bodyParts = data?.bodyParts.nodes?.map((bodypart) =>
    getFragmentData(BODY_PART_FRAGMENT, bodypart)
  )

  return { bodyParts, data, loading, error }
}

export default useBodyPartsQuery
