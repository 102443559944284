import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { deviseRegistrationCreatePath, profilePath } from "~/common/paths"
import { request } from "~/common/request"
import { displayActiveRecordErrors } from "~/common/validations"
import Button, { ButtonLink } from "~/ui/Button"
import FieldGroup from "~/ui/FieldGroup"
import FormikField from "~/ui/FormikField"

type Values = {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

const initialValues = {
  currentPassword: "",
  password: "",
  passwordConfirmation: "",
}

const validationSchema = Yup.object({
  currentPassword: Yup.string().required("Your current password is required"),
  password: Yup.string()
    .min(6, "New password must be at least 6 characters")
    .required("A new password is required"),
  passwordConfirmation: Yup.string()
    .required("A password confirmation is required")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
})

const errorMessage = "Password update failed, please try again"

const EditPasswordForm = () => {
  const navigate = useNavigate()

  const onSubmit = async (
    values: Values,
    { setFieldError }: FormikHelpers<Values>
  ) => {
    try {
      const [status, response] = await request(
        deviseRegistrationCreatePath.pattern,
        { method: "PUT" },
        {
          user: {
            current_password: values.currentPassword,
            password: values.password,
            password_confirmation: values.passwordConfirmation,
          },
        }
      )

      if (status === "success") {
        toast.success("Password has been updated")
        navigate(profilePath.pattern)
      } else {
        const validationErrors = response.errors
        if (
          validationErrors &&
          (validationErrors["current_password"] ||
            validationErrors["password"] ||
            validationErrors["password_confirmation"])
        ) {
          displayActiveRecordErrors(validationErrors, setFieldError)
        } else {
          const message = response?.error || errorMessage
          toast.error(message)
        }
      }
    } catch (error) {
      console.error(error)
      toast.error(errorMessage)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }: FormikProps<Values>) => (
        <Form className="w-full">
          <FieldGroup margin={false}>
            <FormikField
              name="currentPassword"
              type="password"
              label="Current Password"
              autoFocus={true}
              labelSize="sm"
            />
            <FormikField
              name="password"
              type="password"
              label="Create New Password"
              labelSize="sm"
            />
            <FormikField
              name="passwordConfirmation"
              type="password"
              label="Confirm New Password"
              labelSize="sm"
            />
          </FieldGroup>
          <div className="mt-6 flex justify-between">
            <Button disabled={isSubmitting} display="inline">
              Save & Update
            </Button>
            <ButtonLink
              to={profilePath.pattern}
              variant="outlineSecondary"
              display="inline"
            >
              Cancel
            </ButtonLink>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default EditPasswordForm
