export function csrfToken() {
  const csrfEl = document.querySelector("meta[name=csrf-token]")
  const csrfToken = csrfEl ? csrfEl.getAttribute("content") : null

  if (csrfToken == null) {
    console.warn("missing csrf token")
  }

  return csrfToken
}
