import clsx from "clsx"
import { Link } from "react-router-dom"
import { dashboardPath } from "~/common/paths"
import useLoggedInLink from "~/hooks/useLoggedInLink"
import logo from "~/images/login-logo.png"
import MainNav, { MainNavFooter } from "./MainNav"

const SidebarNav = () => {
  const loggedInLink = useLoggedInLink()

  return (
    <div
      data-test="sidebar"
      className={clsx(
        "hidden w-[250px] flex-shrink-0 flex-col justify-between bg-blue-zodiac px-1 py-6",
        "md:flex"
      )}
      style={{ minHeight: "calc(100vh + 200px)" }}
    >
      <div>
        <Link
          to={loggedInLink(dashboardPath.pattern, true)}
          className="mb-10 flex justify-center"
        >
          <img src={logo} className="h-[58px] w-[153px]" alt="RadImageNet" />
        </Link>
        <MainNav />
      </div>
      <MainNavFooter />
    </div>
  )
}

export default SidebarNav
